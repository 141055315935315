export const settlementCycle = [
  {
    id: 1,
    settlementNumber: "1",
  },
  {
    id: 2,
    settlementNumber: "5",
  },
  {
    id: 3,
    settlementNumber: "10",
  },
  {
    id: 4,
    settlementNumber: "15",
  },
  {
    id: 5,
    settlementNumber: "30",
  },
];
