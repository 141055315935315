import React from 'react';

export interface AudioTrailData {
	user: string;
	activity: string;
	date: string;
}

export const ColumnAudioTrail = [
	{
		Header: 'User',
		accessor: 'user',
	},
	{
		Header: 'Activity',
		accessor: 'activity',
	},
	{
		Header: 'Date logged',
		accessor: 'date',
	},
];
