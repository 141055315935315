import React from 'react';
import styles from './Createcustomer.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import OutlineTextInput from '../../input/OutlineTextInput';
import client from '../../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import OutlineTextInputSpecial from '../../input/OutlineTextInputSpecial';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';

const CreateCustomer = ({ closeModal, fetchCustomers, roles }: any) => {
	const dispatch = useDispatch();
	interface initTypes {
		firstName: string;
		email: string;
		lastName: string;
		role: string;
	}

	const initProps: initTypes = {
		firstName: '',
		email: '',
		lastName: '',
		role: '',
	};
	const [inputs, setInputs] = React.useState(initProps);

	const disableFunction = () => {
		if (inputs.firstName && inputs.email && inputs.lastName && inputs.role) {
			return true;
		}
	};

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};

	const createNewCustomer = async () => {
		dispatch(openLoader());
		try {
			const data: any = await client.post('user/create', inputs);

			const message = data?.data?.message;
			fetchCustomers();
			dispatch(closeLoader());

			closeModal();
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			const message = error?.response?.data?.message;
			dispatch(closeLoader());

			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		createNewCustomer();
	};
	return (
		<div className={styles.modalBody}>
			<div className={styles.modalHeader}>
				<div className={styles.background}>
					<div className={styles.linkDetails}>New member</div>
					<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
				</div>
			</div>
			<div className={styles.businessForm}>
				<form onSubmit={handleSubmit} className={styles.form}>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='firstName'
						inputLabel='First name'
						inputValue={inputs.firstName}
					/>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='lastName'
						inputLabel='Last name'
						inputValue={inputs.lastName}
					/>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='email'
						inputLabel='Email address'
						inputValue={inputs.email}
					/>

					<OutlineTextInput
						handleChange={updateProps}
						inputName='role'
						inputLabel='Role'
						inputValue={inputs.role}
						inputSelect
						inputOption={roles}
					/>
				</form>
			</div>
			<div className={styles.modalFooter}>
				<div className={styles.cancel} onClick={closeModal}>
					Cancel
				</div>
				{disableFunction() ? (
					<div className={styles.deactivate} onClick={handleSubmit}>
						Create new customer
					</div>
				) : (
					<div className={styles.notClickable}>Create new customer</div>
				)}
			</div>
		</div>
	);
};

export default CreateCustomer;
