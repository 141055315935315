import React, { useEffect, useState } from "react";
import styles from "./Business.module.scss";
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
import OutlineTextInput from "../../../components/input/OutlineTextInput";
import { Divider } from "antd";
import client from "../../../api/client";
import { useDispatch, useSelector } from "react-redux";
import { getMccsAction, getSubsidiaryGroupsAction, getAccountManagersAction, getReferralIdsAction } from "../../../redux/actions/appSettings/appSettings";
import { closeLoader, openLoader } from "../../../redux/actions/loader/loaderActions";
import report from "../../../api/report";

function UpdateDetailModal({ closeModal, fieldName, currentValue, id }: any) {
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();
  const { mccs, subsidiaryGroups, subsidiaryStatuses, accountManagers, referralIds } = useSelector(state => state.appSettingsReducer)
  function closeModalSingle(message?: string, err?: string) {
    closeModal(message, err);
  }

  const [inputs, setInputs] = React.useState<any>({
    value: currentValue
  });

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs({
      ...inputs,
      value: newValue,
    });
  };

  const options = (() => {
    let value;
    switch (fieldName) {
      case "subsidiaryGroupId": value = subsidiaryGroups; break;
      case "status": value = subsidiaryStatuses; break;
      // case "AccountManagerId": value = accountManagers; break;
      // case "ReferralId": value = referralIds; break;
      case "AccountManagerId": value = [
        { value: 1, name: "Account Manager 1" },
        { value: 2, name: "Account Manager 2" },
      ]; break ;
      case "ReferralId":
        value = [
          { value: 101, name: "Referral 1" },
          { value: 102, name: "Referral 2" },
        ];
        break;
      default: value = mccs; break;
    }
    console.log(value)
    return value;
  })();


  const mapMccs = (mccs: any[]) => {
    const dd: any[] = [];
    mccs.map(x => {
      dd.push({ value: x.id, name: `${x.mcc} - ${x.description}` })
    })
    dispatch(getMccsAction(dd));
    // setOptions([...dd]);
  }

  const mapAccountManagers = (managers: any[]) => {
    const dd = managers.map(x => ({ value: x.id, name: x.name }));
    dispatch({ type: 'SET_ACCOUNT_MANAGERS', payload: dd });
  };
  const mapReferralIds = (referrals: any[]) => {
    const dd = referrals.map(x => ({ value: x.id, name: x.name }));
    dispatch({ type: 'SET_REFERRAL_IDS', payload: dd });
  };


  const mapSubsidiaryGroups = (mccs: any[]) => {
    const dd: any[] = [];
    mccs.map(x => {
      dd.push({ value: x.id, name: `${x.name}` })
    })
    dispatch(getSubsidiaryGroupsAction(dd));
    // setOptions([...dd]);
  }
  const getMccs = () => {
    if (mccs.length < 1) {
      dispatch(openLoader());
      client.get(`/mcc/all`).then((data: any) => {
        mapMccs(data?.data?.merchantCategoryCodes ?? [])
      }).catch((err: any) => {
      }).finally(() => {
        dispatch(closeLoader());
      })
    }
  }

  const getAccountManagers = () => {
    if (!accountManagers?.length) {
      console.log("Fetching mock account managers...");
      const mockManagers = [
        { id: 1, name: "Account Manager 1" },
        { id: 2, name: "Account Manager 2" },
      ];
      mapAccountManagers(mockManagers); // Use mock data
    }
  };
  
  const getReferralIds = () => {
    if (!referralIds?.length) {
      console.log("Fetching mock referral IDs...");
      const mockReferrals = [
        { id: 101, name: "Referral 1" },
        { id: 102, name: "Referral 2" },
      ];
      mapReferralIds(mockReferrals); // Use mock data
    }
  };
  
  // const getAccountManagers = () => {
  //   if (!accountManagers?.length) {
  //     dispatch(openLoader());
  //     client.get(`/accountManagers/all`).then((data: any) => {
  //       mapAccountManagers(data?.data ?? []);
  //     }).catch((err: any) => {
  //       console.error(err);
  //     }).finally(() => {
  //       dispatch(closeLoader());
  //     });
  //   }
  // };

  // const getReferralIds = () => {
  //   if (!referralIds?.length) {
  //     dispatch(openLoader());
  //     client.get(`/referrals/all`).then((data: any) => {
  //       mapReferralIds(data?.data ?? []);
  //     }).catch((err: any) => {
  //       console.error(err);
  //     }).finally(() => {
  //       dispatch(closeLoader());
  //     });
  //   }
  // };


  const getSubsidiayGroups = () => {
    if (mccs.length < 1) {
      dispatch(openLoader());
      report.getSubsidiaryGroups().then((res: any) => {
        mapSubsidiaryGroups(res?.data?.data ?? [])
      }).catch((err: any) => {
      }).finally(() => {
        dispatch(closeLoader());
      })
    }
  }
  const handleSubmit = (e: React.SyntheticEvent) => {
    setInputs({ value: '' })
    client.post(`subsidiary/update`, { subsidiaryId: id, [fieldName]: inputs.value })
      .then((data: any) => {
        closeModalSingle(data?.data?.message ?? "Merchant information updated successfully", "");
      })
      .catch((err: any) => {
        closeModalSingle("", err.response?.data?.message ?? "Unable to update merchant information");
      })
  }

  useEffect(() => {
    switch (fieldName) {
      case "subsidiaryGroupId":
        getSubsidiayGroups(); break;
        case "AccountManagerId":
          getAccountManagers();
          break;
        case "ReferralId":
          getReferralIds();
          break;
      case "status": break;
      case "mccid":
      default:
        getMccs();
        break;
    }
  }, [])
  const disabledFunction = () => {
    if (inputs.value === '' || options.length < 1) {
      return true;
    } else {
      return false;
    }
  };
  const getModalTitle = () => {
    switch (fieldName) {
      case "subsidiaryGroupId":
        return "Subsidiary Group";
      case "status":
        return "Update Merchant Status";
      case "AccountManagerId":
        return "Account Manager Id";
      case "ReferralId":
        return "Referral Id";
      case "mccid":
      default:
        return "Update Merchant MCC"
    }
  }
  return (
    <div className={styles.modalwrapper}>
      <div className={styles.modalhead}>
        <h3 className={styles.modalheadh3}>{getModalTitle()}</h3>
        <CloseIcon onClick={() => closeModal()} style={{ cursor: 'pointer' }} />
      </div>
      <Divider style={{ margin: 0, padding: 0 }} />
      <div className={styles.modalcontent}>
        <div className={styles.input}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName={fieldName}
            inputLabel={fieldName}
            inputValue={inputs.value}
            inputSelect
            inputOption={options}
            style={{ width: '100%' }}
          />
        </div>
        <Divider style={{ margin: 0, padding: 0 }} />

        <div className={styles.modalFooter}>
          <button className={styles.cancel} onClick={closeModal}>
            Cancel
          </button>
          <button
            disabled={disabledFunction()}
            onClick={handleSubmit}
            className={styles.fund}>
            Update
          </button>
        </div>
      </div>
    </div>);
}

export default UpdateDetailModal;
