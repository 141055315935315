import client from "./client";

const getPaymentMethods = () => client.get("payment-options");
const getPricingGroups = () => client.get("groups/pricings");
const addPricingGroups = (payload) => client.post("groups/pricings", payload);
const editPricingGroups = (payload) => client.patch("groups/pricings", payload);
const getSubsidiaryGroups = () => client.get("groups/subsidiaries");
const addSubsidiaryGroups = (payload) => client.post("groups/subsidiaries", payload);
const editSubsidiaryGroups = (payload) => client.patch("groups/subsidiaries", payload);
const getSettlementGroups = () => client.get("groups/settlements");
const addSettlementGroups = (payload) => client.post("groups/settlements", payload);
const editSettlementGroups = (payload) => client.patch("groups/settlements", payload);
const getLimitGroups = () => client.get("groups/limits");
const addLimitGroups = (payload) => client.post("groups/limits", payload);
const editLimitGroups = (payload) => client.patch("groups/limits", payload);
const getPaymentOptionGroups = () => client.get("groups/paymentoptions");
const addPaymentOptionGroups = (payload) => client.post("groups/paymentoptions", payload);
const editPaymentOptionGroups = (payload) => client.patch("groups/paymentoptions", payload);
const getLimitItems = () => client.get("limit/items");
const getDataTypes = () => client.get("groups/data/types");
export default {
  getPaymentMethods, addPricingGroups, editPricingGroups, getPricingGroups,
  getSubsidiaryGroups,
  addSubsidiaryGroups,
  editSubsidiaryGroups,
  getSettlementGroups,
  addSettlementGroups,
  editSettlementGroups,
  getLimitGroups,
  getDataTypes,
  addLimitGroups,
  editLimitGroups,
  getPaymentOptionGroups,
  addPaymentOptionGroups,
  editPaymentOptionGroups,
  getLimitItems
};
