export const dataDashboard = [
	{
		id: 1,
		menu: 'Summary',
		url: '/dashboard',
	},
	{
		id: 2,
		menu: 'KYC Requests & Compliance',
		url: '/dashboard/compliance',
	},
];

export const dataAccount = [
  {
    id: 1,
    menu: "Businesses",
    url: "/dashboard/accounts",
  },
  {
    id: 2,
    menu: "Subsidiaries",
    url: "/dashboard/accounts/subsidiaries",
  },
  {
    id: 3,
    menu: "Settlements",
    url: "/dashboard/accounts/settlements",
  },
];

export const dataTransaction = [
	{
		id: 1,
		menu: 'All Transactions',
		url: '/dashboard/transactions',
	},
	{
		id: 2,
		menu: 'Customers',
		url: '/dashboard/transactions/customers',
	},
	{
		id: 3,
		menu: 'Invoices',
		url: '/dashboard/transactions/invoices',
	},
	{
		id: 4,
		menu: 'Disputes & Chargebacks',
		url: '/dashboard/transactions/chargebacks',
	},
];

export const dataSettings = [
  {
    id: 1,
    menu: "Users/Admins",
    url: "/dashboard/settings",
  },
  {
    id: 2,
    menu: "Roles & Permissions",
    url: "/dashboard/settings/roles",
  },
  {
    id: 3,
    menu: "Settlement Cycles",
    url: "/dashboard/settings/settlement-cycle",
  },
  {
    id: 4,
    menu: "Settlement Groups",
    url: "/dashboard/settings/settlement-groups",
  },
  {
    id: 5,
    menu: "Notifications",
    url: "/dashboard/settings/notifications",
  },
  {
    id: 6,
    menu: "Profile",
    url: "/dashboard/settings/profile",
  }
];
