import React, { useState, ReactNode, Fragment, useEffect } from 'react';
import styles from './Usermenu.module.scss';
import { ReactComponent as MenuIcon } from '../../../assets/images/Icons/menu.svg';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ReactComponent as LinkIcon } from '../../../assets/images/Icons/link-icon.svg';
import ViewIcon from '../../../assets/images/Icons/view-details.svg';
import CopyIcon from '../../../assets/images/Icons/copy.svg';
import LinkOffIcon from '../../../assets/images/Icons/link-off.svg';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import Menus from '../Menus/Menus';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import client from '../../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import PaymentModal from '../../ModalContent/PaymentModal/PaymentModal';
import OutlineTextInput from '../../input/OutlineTextInput';
import useApi from '../../../hooks/useApi';
import rolesApi from '../../../api/roles';
import OutlineTextInputSpecial from '../../input/OutlineTextInputSpecial';
import Select from 'react-select';
import permissionsApi from '../../../api/permissions';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';
import AddAdminUser from '../../ModalContent/AddAdminUser/AddAdminUser';

type MenuProps = {
	menuContent: string;
};

const isAlphabets = (str: string) => /^[a-zA-Z]*$/.test(str);
const isAlphabetsOrNumbers = (str: string) => /^[A-Za-z0-9 ]*$/.test(str);

const UserMenu = ({ userInfo, fetchUsers }: any) => {
	const dispatch = useDispatch();
	const [menuOption, setMenuOption] = useState(false);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const getPermissionsApi = useApi(permissionsApi.getPermissions);

	function closeModal() {
		setIsOpen(false);
		fetchUsers();
	}

	const handleClickAway = () => {
		setMenuOption(false);
	};
	const handleMenu = () => {
		setMenuOption(!menuOption);
	};

	const fetchPermissions = async () => {
		getPermissionsApi.request();
	};

	const getRolesApi = useApi(rolesApi.getRoles);

	const fetchRoles = async () => {
		getRolesApi.request();
	};
	const permissionsData = getPermissionsApi?.data?.data;

	// let permissionOptions = permissionsData?.map(function (permission: any) {
	// 	return { value: permission?.id, label: permission?.name };
	// });

	const rolesData = getRolesApi?.data?.data ?? []

	const rolesOptions = rolesData?.map((role: any) => ({ value: role?.id, label: role?.name }));

	const [name, setName] = React.useState('');
	const [description, setDescription] = React.useState('');
	const [permissions, setPermissions] = React.useState<any>([]);

	useEffect(() => {
		fetchRoles();
		fetchPermissions();
	}, [userInfo?.role, name]);
	const disableFunction = () => {
		if (name !== '' && description !== '' && permissions.length > 0) {
			return true;
		}
	};

	const handleSubmit = async () => {
		dispatch(openLoader());

		try {
			const reducePermission = permissions.map((item: any) => item.value);

			if (reducePermission) {
				const data: any = await client.post('role/edit', {
					name,
					description,
					roleId: rolesData[0]?.id,
					permissions: reducePermission,
				});
				const message = data?.data?.message;
				fetchUsers();
				fetchRoles();
				dispatch(closeLoader());

				closeModal();
				setPermissions([]);
				setName('');
				setDescription('');
				dispatch(
					openToastAndSetContent({
						toastStyles: ToastSuccessStyles,
						toastContent: message,
					})
				);
			}
		} catch (error: any) {
			const message = error?.response?.data?.message;
			dispatch(closeLoader());

			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	const updateStatus = async (identity: string) => {
		try {
			const data: any = await client.patch(
				`user/update/status/${userInfo?.id}/${identity}`
			);
			const message = data?.data?.message;
			fetchUsers();
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			const message = error?.response?.data?.message;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	return (
		<Fragment>
			<ClickAwayListener onClickAway={handleClickAway}>
				<div className={styles.menuBody}>
					<div className={styles.menuIcon} onClick={handleMenu}>
						<MenuIcon />
					</div>

					{menuOption && (
						<div className={styles.menuContent} onClick={handleClickAway}>
							<Menus
								icon={ViewIcon}
								optionName='Edit User'
								onclick={() => setIsOpen(true)}
							/>
							<Menus
								icon={ViewIcon}
								optionName={(userInfo?.status === 'Active' || userInfo?.status === 'New') ? 'Deactivate User' : 'Activate User'}
								onclick={() => {
									updateStatus((userInfo?.status === 'Active' || userInfo?.status === 'New') ? 'deactivate' : 'activate');
								}}
							/>
						</div>
					)}
				</div>
			</ClickAwayListener>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}
			// className={styles.modal}
			>
				<AddAdminUser closeModal={closeModal} roles={rolesData} data={userInfo} />
			</Modal>
		</Fragment>
	);
};

export default UserMenu;
