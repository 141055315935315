import React, { Dispatch, useEffect, MouseEventHandler } from "react";
import styles from "./Settlementmenu.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import { numberWithCommas } from "../../../utils/formatNumber";
import moment from "moment";

type PaymentProps = {
  closeModal: MouseEventHandler<SVGSVGElement>;
  menuData: any;
};
const SettlementMenuModal = ({ closeModal, menuData }: PaymentProps) => {
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Settlement details</div>
          <CloseIcon onClick={closeModal} />
        </div>
      </div>
      <div className={styles.modalContent}>
        <div className={styles.modalDetails}>
          <div className={styles.link}>Business Name</div>
          <div className={styles.content}>{menuData?.subsidiary_name}</div>
        </div>
        <div className={styles.modalDetails}>
          <div className={styles.link}>Settlement amount</div>
          <div className={styles.content}>
            {`${menuData?.currency} ${numberWithCommas(
              menuData?.transaction_amount
            )}`}
          </div>
        </div>
        <div className={styles.modalDetails}>
          <div className={styles.link}>Settlement fee</div>
          <div className={styles.content}>
            {`${menuData?.currency} ${numberWithCommas(menuData?.fee)}`}
          </div>
        </div>
        <div className={styles.modalDetails}>
          <div className={styles.link}>Currency</div>
          <div className={styles.content}>{menuData?.currency}</div>
        </div>
        <div className={styles.modalDetails}>
          <div className={styles.link}>Status</div>
          <div className={styles.content}>
            {menuData?.settlement_status ? (
              <div className={styles.active}>Completed</div>
            ) : (
              <div className={styles.inactive}>Inactive</div>
            )}
          </div>
        </div>
        <div className={styles.modalDetails}>
          <div className={styles.link}>Settlement cycle</div>
          <div className={styles.content}>{menuData?.settlement_cycle}</div>
        </div>
        <div className={styles.modalDetails}>
          <div className={styles.link}>Next payment date</div>
          <div className={styles.content}>
            {moment(menuData?.final_settlement_date).format(
              "MMMM Do YYYY, h:mm a"
            )}
          </div>
        </div>
        <div className={styles.modalDetails}>
          <div className={styles.link}>Description</div>
          <div className={styles.content}>{menuData?.description|| "N/A"}</div>
        </div>
      </div>
    </div>
  );
};

export default SettlementMenuModal;
