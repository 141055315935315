import React from 'react';

export interface ChargebacksData {
	customerName: string;
	emailAddress: string;
	invoiceNumber: string;
	transactionAmount: string;
	dateIssued: string;
	dateDue: string;
	status: React.ReactNode;
	action: React.ReactNode;
}

export const ColumnChargebacks = [
	{
		Header: 'Customer Name',
		accessor: 'customerName',
	},
	{
		Header: 'Customer email',
		accessor: 'emailAddress',
	},
	{
		Header: 'Invoice number',
		accessor: 'invoiceNumber',
	},
	{
		Header: 'Transaction Amount',
		accessor: 'transactionAmount',
	},
	{
		Header: 'Date issued',
		accessor: 'dateIssued',
	},
	{
		Header: 'Due date',
		accessor: 'dateDue',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
	{
		Header: '',
		accessor: 'action',
	},
];
