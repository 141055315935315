import React from 'react';
import styles from './ChartDashboard.module.scss';
import { ReactComponent as DropArrow } from '../../assets/images/Icons/drop-arrow.svg';
import ChartGraph from '../ChartGraph/ChartGraph';
import ExportButton from '../ExportButton/ExportButton';
import { ChartData } from '../../constants/chartData';
import FilterButton from '../FilterButton/FilterButton';

const ChartDashboard = ({ inflowData, filterParam, setFilterParam }: any) => {
	return (
		<div className={styles.chartGraph}>
			<div className={styles.transactionContent}>
				<div className={styles.transactionDetails}>
					<h3 className={styles.transactions}>Transaction</h3>
					<p className={styles.flow}>
						Track inflow and outflow of money over time
					</p>
				</div>
				{filterParam === 'year' ? (
					<div className={styles.timeLine}>
						<FilterButton time='This year' setFilterParam={setFilterParam} />
						<ExportButton data={inflowData} fileName='Transactions Flow' />
					</div>
				) : (
					<div className={styles.timeLine}>
						<FilterButton time='This week' setFilterParam={setFilterParam} />
						<ExportButton data={inflowData} fileName='Transactions Flow' />
					</div>
				)}
			</div>
			<div className={styles.total}>
				<div className={styles.inflow}>
					<div className={styles.blue} />
					<span>Total inflow</span>
				</div>
				<div className={styles.outflow}>
					<div className={styles.orange} />
					<span>Total Outflow</span>
				</div>
			</div>
			<div style={{ height: '400px' }}>
				<ChartGraph inflowData={inflowData} />
			</div>
		</div>
	);
};

export default ChartDashboard;
