import React, { Dispatch, useEffect, MouseEventHandler } from "react";
import styles from "./LimitModal.module.scss";
import client from '../../../api/client';
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import { numberWithCommas } from "../../../utils/formatNumber";
import { Divider } from "antd";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { InputLabel, TextField } from '@material-ui/core';
import SelectWrapper from '../../Select';
import { useDispatch, useSelector } from "react-redux";
import { closeLoader, openLoader } from "../../../redux/actions/loader/loaderActions";
import Mark from '../../../assets/images/Icons/u_save.svg';
import { ToastErrorStyles, ToastSuccessStyles } from "../../../constants/toastStyles";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import { makeStyles } from '@material-ui/core';
import useApi from "../../../hooks/useApi";
import reports from '../../../api/report';

type FeeModalProps = {
  closeModal: Function | any;
  cb: Function | any;
  data?: any;
};
const LimitModal = ({ closeModal, data, cb }: FeeModalProps) => {
  const getLimitItems = useApi(reports.getLimitItems);
  const getLimitGroups = useApi(reports.getLimitGroups);
  const getDataTypes = useApi(reports.getDataTypes);
  const isUpdate = !!data;
  useEffect(() => {
    getLimitItems.request();
    getLimitGroups.request();
    getDataTypes.request();
  }, []);

  const dispatch = useDispatch();
  const useStyles = makeStyles({
    root: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-input.MuiOutlinedInput-input':
      {
        textAlign: 'center',
        padding: '8.1px 14px',
      },
    },
    select: {
      '& .MuiOutlinedInput-root': {
        color: '#414141',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        borderRadius: '10px',
      },
      '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        outline: 'none',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
        backgroundColor: '#ffffff',
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: '#E0E0E0',
        backgroundColor: '#ffffff',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #E0E0E0',
      },
      '& .Mui-disabled': {
        opacity: '0.7',
        cursor: 'not-allowed',
      },
    },
  });
  const classes = useStyles();
  const { currencies } = useSelector((state) => state.appSettingsReducer);
  const currencyOption = currencies.map((curr: any) => ({ name: `${curr.name} (${curr.shortName})`, val: curr.id }));
  const validate = Yup.object({
    internationalValue: Yup.number().required('Required'),
    currencyId: Yup.string().required('Required'),
    value: Yup.string().required('Required'),
    valueType: Yup.string().required('Required'),
    limitGroupId: Yup.string().required('Required'),
    limitItemId: Yup.string().required('Required'),
  });

  // const [currencyOption, setCurrencyOption] = useState([{ name: 'USD' }, { name: 'NGN' }]);
  console.log({ data })
  const mapPaymentOptions = () => (getLimitItems?.data?.data ?? []).map((x: any) => { return { name: x.name, val: x.id } })
  const paymeth = mapPaymentOptions();
  const mapLimitGroups = () => (getLimitGroups?.data?.data ?? []).map((x: any) => { return { name: x.name, val: x.id } })
  const limitGroupOptions = mapLimitGroups();
  const mapDataTypes = () => (getDataTypes?.data?.data ?? []).map((x: any) => { return { name: x, val: x } })
  const dataTypeOptions = mapDataTypes();


  const submitHandler = (values: any) => {
    console.log(values);
    dispatch(openLoader());
    const path = data ? `${data.id}/update` : `add`
    const method = data ? client.patch : client.post;
    method(`limit/item/value/${path}`, { ...values, ...setUpdateConfigValue(false) })
      .then((res: any) => {
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: res.data.message,
            toastStyles: ToastSuccessStyles,
          })
        );
        closeModal();
        cb();
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  }
  const setUpdateConfigValue = (isUnput: boolean = true) => {
    if (data == null) return {};
    if (isUnput) return {
      limitGroupId: isUpdate ? data?.limitGroup?.name : data?.limitGroupId,
      currencyId: isUpdate ? data?.currency : data?.currencyId,
      limitItemId: isUpdate ? data?.limitItem?.name : data?.limitItemId,
      value: (data?.value ?? "").replace(/,/g, ''),
      internationalValue: (data?.internationalValue ?? "").replace(/,/g, '')
    }
    else return {
      limitGroupId: data?.limitGroupId,
      currencyId: data?.currencyId,
      limitItemId: data?.limitItemId,
    }
  }
  return (
    <div className={styles.modalwrapper}>
      <div className={styles.modalhead}>
        <h3 className={styles.modalheadh3}>{data ? "Update Limit" : "Add a Limit"}</h3>
        <CloseIcon
          onClick={closeModal}
          style={{ cursor: 'pointer' }}
        />
      </div>
      <Divider style={{ margin: 0, padding: 0 }} />
      <Formik
        initialValues={{
          ...data,
          ...setUpdateConfigValue()
        }}
        validationSchema={validate}
        onSubmit={submitHandler}>
        {(props) => (
          <div
            style={{
              padding: '32px 24px',
            }}>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>Limit Type</span>
                    </InputLabel>
                    <Field
                      as={isUpdate ? TextField : SelectWrapper}
                      disabled={!!isUpdate}
                      helperText={
                        <ErrorMessage name='limitItemId'>
                          {(msg) => (
                            <span style={{ color: 'red' }}>{msg}</span>
                          )}
                        </ErrorMessage>
                      }
                      name='limitItemId'
                      variant='outlined'
                      margin='normal'
                      type='text'
                      size='small'
                      fullWidth
                      options={paymeth}
                      className={classes.select}
                      placeholder='Limit Item Type'
                    // defaultValue={paymeth && paymeth[0]}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>Limit Group</span>
                    </InputLabel>
                    <Field
                      as={isUpdate ? TextField : SelectWrapper}
                      disabled={!!isUpdate}
                      helperText={
                        <ErrorMessage name='limitGroupId'>
                          {(msg) => (
                            <span style={{ color: 'red' }}>{msg}</span>
                          )}
                        </ErrorMessage>
                      }
                      name='limitGroupId'
                      variant='outlined'
                      margin='normal'
                      type='text'
                      size='small'
                      fullWidth
                      options={limitGroupOptions}
                      className={classes.select}
                      placeholder=''
                    // defaultValue={pricingGroupOptions && pricingGroupOptions[0]}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>Currency</span>
                    </InputLabel>
                    <Field
                      as={isUpdate ? TextField : SelectWrapper}
                      disabled={!!isUpdate}
                      helperText={
                        <ErrorMessage name='currencyId'>
                          {(msg) => (
                            <span style={{ color: 'red' }}>{msg}</span>
                          )}
                        </ErrorMessage>
                      }
                      name='currencyId'
                      variant='outlined'
                      margin='normal'
                      type='text'
                      size='small'
                      fullWidth
                      options={currencyOption}
                      className={classes.select}
                      placeholder='Currency'
                    // defaultValue={currencyOption && currencyOption[0]}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>Limit Value</span>
                    </InputLabel>
                    <Field
                      as={TextField}
                      helperText={
                        <ErrorMessage name='value'>
                          {(msg) => (
                            <span style={{ color: 'red' }}>{msg}</span>
                          )}
                        </ErrorMessage>
                      }
                      name='value'
                      variant='outlined'
                      margin='normal'
                      type='text'
                      size='small'
                      fullWidth
                      className={classes.select}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>Limit Value (International)</span>
                    </InputLabel>
                    <Field
                      as={TextField}
                      helperText={
                        <ErrorMessage name='internationalValue'>
                          {(msg) => (
                            <span style={{ color: 'red' }}>{msg}</span>
                          )}
                        </ErrorMessage>
                      }
                      name='internationalValue'
                      variant='outlined'
                      margin='normal'
                      type='text'
                      size='small'
                      fullWidth
                      className={classes.select}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>Value Type</span>
                    </InputLabel>
                    <Field
                      as={SelectWrapper}
                      helperText={
                        <ErrorMessage name='valueType'>
                          {(msg) => (
                            <span style={{ color: 'red' }}>{msg}</span>
                          )}
                        </ErrorMessage>
                      }
                      name='valueType'
                      variant='outlined'
                      margin='normal'
                      type='text'
                      size='small'
                      fullWidth
                      options={dataTypeOptions}
                      className={classes.select}
                      placeholder=''
                    // defaultValue={pricingGroupOptions && pricingGroupOptions[0]}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <button type='submit' className={styles.Downloadbutton}>
                    <span className={styles.Downloadbutton_span}>
                      <img src={Mark} alt='' />
                    </span>
                    {data ? "Update Limit" : "Add Limit"}
                  </button>
                </Grid>{' '}
              </Grid>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default LimitModal;
