import React, { useState } from 'react';
import styles from './Signin.module.scss';
import OutlineTextInput from '../../components/input/OutlineTextInput';
import {
  openLoader,
  closeLoader,
} from '../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import client from '../../api/client';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from '../../constants/toastStyles';
import Img from '../../assets/images/Logos/landing.png';
import showPwdImg from '../../assets/images/Icons/Icons/show-password.svg';
import hidePwdImg from '../../assets/images/Icons/Icons/hide-password.svg';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const { email } = useParams<{ email: string }>();

  interface initTypes {
    email: string;
    otp: string;
    password: string;
  }

  const initProps: initTypes = {
    email: `${email}`,
    otp: "",
    password: "",
  };
  const [inputs, setInputs] = React.useState(initProps);
  const [otp, setOtp] = React.useState<number | null>(null);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOtpOpen, setIsOtpOpen] = React.useState(false);

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    if (!email) window.location.replace(`/`);
    e.preventDefault();
    dispatch(openLoader());
    client
      .post('user/complete/forgot/password', inputs)
      .then((data: any) => {
        dispatch(closeLoader());
        const message = data?.data?.message;
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastSuccessStyles,
            toastContent: message,
          })
        );
        setTimeout(() => {
          window.location.replace('/')
        }, 200);
      })
      .catch((error: any) => {
        // <ErrorToast error={error} />;
        if (error.response) {
          dispatch(closeLoader());
          const { message } = error.response.data || { message: "Unable to complete your password reset" };
          dispatch(
            openToastAndSetContent({
              toastContent: message,
              toastStyles: ToastErrorStyles,
            })
          );
        } else if (error.request) {
          dispatch(closeLoader());
        } else {
          dispatch(closeLoader());
        }
      });
  };

  return (
    <div className={styles.signWrapper}>
      <div className={styles.rightwrapper}>
        <img className={styles.rightwrapper_img} src={Img} alt='' />
      </div>
      <div className={styles.leftwrapper}>
        {/* <div className={styles.image}>
					<img src={logo} alt='' />
				</div> */}
        <div className={styles.formwrapper}>
          <h1 className={styles.form_heading}>Reset your password</h1>
          <p className={styles.form_p}>
            Enter your details to access your account
          </p>
          <div className={styles.businessForm}>
            <form onSubmit={handleSubmit} className={styles.form}>
              <OutlineTextInput
                style={{ marginTop: '10px' }}
                handleChange={updateProps}
                inputName='otp'
                inputLabel='One-Time Password (OTP'
                inputValue={inputs.otp}
              />
              <div className={styles.passwordInp}>
                <OutlineTextInput
                  style={{ marginTop: '20px' }}
                  handleChange={updateProps}
                  inputName='password'
                  inputLabel='Password'
                  inputType={isRevealPwd ? 'text' : 'password'}
                  inputValue={inputs.password}
                />
                <img
                  alt='/'
                  className={styles.inputImg}
                  title={isRevealPwd ? 'Hide password' : 'Show password'}
                  src={isRevealPwd ? hidePwdImg : showPwdImg}
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                />
              </div>
              <p className={styles.forgetPassword}>
                Remeber Password? {' '}
                <span
                  onClick={() => window.location.assign("/")}
                  className={styles.forgetPasswordspan}>
                  Login here
                </span>
              </p>
              <button onClick={handleSubmit} className={styles.signInButton}>
                Reset Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
