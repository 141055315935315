export const menuContent = [
	{
		id: 1,
		name: 'Bank Account',
		value: 'bank account',
	},
	{
		id: 2,
		name: 'Wallet',
		value: 'wallet',
	},
	{
		id: 3,
		name: 'Successful',
		value: 'Successful',
	},
	{
		id: 4,
		name: 'Awaiting Confirmation',
		value: 'Awaiting-Confirmation',
	},
	{
		id: 5,
		name: 'Initiated',
		value: 'Initiated',
	},
	{
		id: 6,
		name: 'Default',
		value: 'Default',
	},
	{
		id: 7,
		name: 'In Stock',
		value: 'true',
	},
	{
		id: 8,
		name: 'Out of Stock',
		value: 'false',
	},
	{
		id: 9,
		name: 'Active',
		value: 'Active',
	},
	{
		id: 10,
		name: 'InActive',
		value: 'Inactive',
	},
	{
		id: 11,
		name: 'Approved',
		value: 'Approved',
	},
	{
		id: 12,
		name: 'Processing',
		value: 'Processing',
	},
	{
		id: 13,
		name: 'Credit',
		value: 'C',
	},
	{
		id: 14,
		name: 'Debit',
		value: 'D',
	},
	{
		id: 15,
		name: 'Completed',
		value: 'Completed',
	},
	{
		id: 16,
		name: 'Successful',
		value: 'Successful',
	},
	{
		id: 17,
		name: 'Approved',
		value: 'Approved',
	},
	{
		id: 18,
		name: 'Processing',
		value: 'Processing',
	},
	{
		id: 19,
		name: 'Rejected',
		value: 'Rejected',
	},
];
