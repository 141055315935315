/* eslint-disable import/no-anonymous-default-export */
import client from './client';

const getInflowOutflow = () =>
	client.get('/metric/inflow/outflow?type=year');

const getPiechartTransactionMethod = () =>
	client.get('/metric/piechart/transaction/method');
const getPiechartTransactionStatus = () =>
	client.get('/metric/piechart/transaction/status');
const getPiechartBank = () =>
	client.get('/metric/piechart/customer/banks');
const getFailureReason = () =>
	client.get('/metric/transaction/failure/reasons');
const getPayDay = () =>
	client.get('/metric/barchart/customer/payment/days');
const getCustomerSpending = () =>
	client.get('/metric/top/merchants?type=value');
const getCustomerCount = () =>
	client.get('/metric/top/merchants?type=count');
const getCustomerDevices = () =>
	client.get('/metric/piechart/customer/devices');

export default {
	getInflowOutflow,
	getPiechartTransactionMethod,
	getPiechartTransactionStatus,
	getPiechartBank,
	getFailureReason,
	getPayDay,
	getCustomerSpending,
	getCustomerCount,
	getCustomerDevices,
};
