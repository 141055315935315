import React, { useState, Fragment, useEffect } from 'react';
import styles from './Transfer.module.scss';
import PaginationTable from '../../../components/table/pagination-table';
import {
	ColumnTransfer,
	TransferData,
} from '../../../types/TablesTypes/collection';
import StatusView from '../../../components/StatusView/StatusView';
import TableHeader from '../../../components/TableHeader/TableHeader';
import TransactionsMenu from '../../../components/MenuOption/TransactionsMenu/TransactionsMenu';
import transactionsApi from '../../../api/transactions';
import useApi from '../../../hooks/useApi';
import client from '../../../api/client';
import { numberWithCommas } from '../../../utils/formatNumber';
import moment from 'moment';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	AppstoreOutlined,
	MailOutlined,
	SettingOutlined,
} from '@ant-design/icons';
import Filtered from '../../../components/filterComponent/Filtered';
import { menuContent } from '../../../utils/menuContent';
import Mocked from './transfer.json';
import RefundsMenu from '../../../components/MenuOption/RefundsMenu/RefundsMenu';
import { Menu, Dropdown as MenuFunc } from 'antd';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import Mark from '../../../assets/images/Icons/u_save.svg';
import { Divider } from 'antd';
import { Grid } from '@material-ui/core';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { InputLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import SelectWrapper from '../../../components/Select';
import * as Yup from 'yup';
import UpdateLimitModal from './UpdateLimitModal';

const useStyles = makeStyles({
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-input.MuiOutlinedInput-input':
		{
			textAlign: 'center',
			padding: '8.1px 14px',
		},
	},
	select: {
		'& .MuiOutlinedInput-root': {
			color: '#414141',
			fontFamily: 'Roboto',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '14px',
			lineHeight: '16px',
			borderRadius: '10px',
		},
		'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			outline: 'none',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
			backgroundColor: '#ffffff',
		},
		'& .MuiInputLabel-root.Mui-focused': {
			color: '#E0E0E0',
			backgroundColor: '#ffffff',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: '1px solid #E0E0E0',
		},
	},
});

const Transfer = () => {
	const dispatch = useDispatch();
	const [status, setStatus] = useState<string>('');
	const [value, setValue] = useState('');
	const [tableRow, setTableRow] = useState<any[]>();
	const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
	const [transfer, setTransfer] = useState<any>();
	const [category, setCategory] = useState();
	const classes = useStyles();

	const validate = Yup.object({
		single_limit: Yup.string().required('Required'),
		frequency: Yup.string().required('Required'),
		cumm_daily_limit: Yup.string().required('Required'),
		industry: Yup.string().required('Required'),
		risk_category: Yup.string().required('Required'),
	});

	const businessTypeOption = [
		{ name: 'Company', val: 2 },
		{ name: 'Individual', val: 1 },
	];
	const industry = [
		{
			name: 'Education',
		},
		{
			name: 'Finance',
		},
		{
			name: 'Agriculture',
		},
		{
			name: 'Commerce',
		},
		{
			name: 'Fashion',
		},
	];
	// const [apiObject, setApiObject] = useState(null);

	// const { keyB: trxRef } = apiObject?.keyA || [{

	// }];

	function closeModalSingle() {
		setIsOpenSingle(false);
	}
	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//for filtering
	const [valueChannel, setValueChannel] = useState('');
	const [valueStatus, setValueStatus] = useState('');
	const [dropdown, setDropdown] = useState(false);

	const fetchRiskCategory = async () => {
		try {
			const res: any = await client.get(`limits/risk/categories`);
			setCategory(res.data.data);
		} catch (error: any) {
			const { message } = error.response.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useEffect(() => {
		fetchRiskCategory();
	}, []);

	const fetchTransfer = async () => {
		dispatch(openLoader());
		try {
			const res: any = await client.get(`/limits/payout?status=${status}`);
			setTransfer(res.data.data);
			dispatch(closeLoader());
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};

	useEffect(() => {
		fetchTransfer();
	}, [pageNumber, rowsPerPage]);

	useEffect(() => {
		setPageNumber(transfer?.page?.currentPage || 1);
		setPreviousPage(transfer?.page?.previousPage);
		setNextPage(transfer?.page?.nextPage);
	}, [transfer]);

	const dataTransaction = () => {
		const tempArr: TransferData[] = [];
		transfer?.items
			?.slice(0)
			.reverse()
			.forEach((transaction: any, index: number) => {
				tempArr.push({
					transactionType: transaction.transactionType,
					currency: transaction.currency,
					frequencyLimit: transaction.frequency,
					limitGroup: transaction.limitGroup ?? "Not available",
					limitGroupId: transaction.limitGroupId ?? 0,
					transferLimit: transaction?.transferLimit,
					cummulativeDaily: transaction?.cummulativeDaily,
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransaction());
	}, [transfer?.items]);

	return (
		<div style={{ marginTop: '38px' }}>
			<TableHeader
				pageName='collection'
				dataLength={transfer?.page?.total}
				data={transfer?.items}
				value={value}
				setValue={setValue}
				exporting={false}
				filtering={false}
				searchfn={false}
				newButton={
					<div
						onClick={() => setIsOpenSingle(true)}
						className={styles.createLink}>
						Update Limit
					</div>
				}
				FilterComponent={
					<>
						<Filtered
							menuContent={menuContent}
							dropdown={dropdown}
							setDropdown={setDropdown}
							valueChannel={valueChannel}
							setValueChannel={setValueChannel}
							valueStatus={valueStatus}
							setValueStatus={setValueStatus}
							status={true}
							channel={true}
						/>
					</>
				}
			/>

			<PaginationTable
				data={tableRow ? tableRow : []}
				columns={ColumnTransfer ? ColumnTransfer : []}
				value={value}
				emptyPlaceHolder={
					transfer?.page?.total == 0
						? 'You currently do not have any data'
						: 'Loading...'
				}
				total={transfer?.page?.total}
				totalPage={transfer?.page?.totalPage}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				nextPage={nextPage}
				setNextPage={setNextPage}
				previousPage={previousPage}
				setPreviousPage={setPreviousPage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
			/>

			{/* //MODAL FOR SINGLE */}
			<Modal
				isOpen={modalIsOpenSingle}
				onRequestClose={closeModalSingle}
				contentLabel='Example Modal'
				style={customStyles}>
				<UpdateLimitModal closeModal={closeModalSingle} />
			</Modal>
		</div>
	);
};

export default Transfer;
