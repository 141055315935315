import { useState } from 'react';
import styles from './../Chargeback.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import { Divider } from 'antd';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import client from '../../../api/client';
import { ToastErrorStyles, ToastSuccessStyles, } from '../../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ErrorMessage, Form, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { openLoader, closeLoader, } from '../../../redux/actions/loader/loaderActions';
import { ReactComponent as IndividualIcon } from '../../../assets/images/Icons/won.svg';
import { ReactComponent as BusinessIcon } from '../../../assets/images/Icons/lost.svg';
import Mark from '../../../assets/images/Icons/u-mark.svg';
import Times from '../../../assets/images/Icons/u_multiply (1).svg';
import * as Yup from 'yup';

interface dataComingTypes {
	currency: string;
	reference: string;
	amount: number;
	frequency: number;
	reason: string;
	due_date: string;
	type: string;
}

function ExtendDueDate({ closeModal, object }: any) {
	const [extendDateValue, setValues] = useState({
		chargebackId: object.id,
		newDueDate: ""
	});
	const dispatch = useDispatch();
	const history = useHistory();
	const validate = Yup.object({
		newDueDate: Yup.string().required('Required'),
	});
	const extendDueDateHandler = async (values: any) => {
		{
			try {
				if (!extendDateValue.newDueDate) throw new Error("Invalid due date selected")
				dispatch(openLoader());
				client.post(`/chargeback/extend`, extendDateValue).then((res: any) => {
					dispatch(closeLoader());
					// setBearer(true);
					dispatch(
						openToastAndSetContent({
							toastStyles: ToastSuccessStyles,
							toastContent: res.data.message,
						})
					);
					closeModal();
				}).catch((error) => {
					dispatch(closeLoader());
					if (error.response) {
						const message = error?.response?.data?.message;
						dispatch(
							openToastAndSetContent({
								toastStyles: ToastErrorStyles,
								toastContent: message,
							})
						);
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log('Error', error.message);
					}
				});
			} catch (error: any) {
				dispatch(
					openToastAndSetContent({
						toastStyles: ToastErrorStyles,
						toastContent: error.message,
					}))
			}
		};
	}

	return (
		<div className={styles.modalwrapper}>
			<div className={styles.modalhead}>
				<h3 className={styles.modalheadh3}>Extend chargeback due date</h3>
				<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
			</div>
			<Divider style={{ margin: 0, padding: 0 }} />
			<div className={styles.modalcontent}>
				<Formik
					initialValues={extendDateValue}
					// validationSchema={validate}
					onSubmit={(values) => extendDueDateHandler(values)}>
					{(props) => (
						<div
							style={{
								padding: '32px 24px',
							}}>
							<Form>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<div className={styles.customerInfo}>
											<div className={styles.detailsValue}>
												Current due date
											</div>
											<div className={styles.detailsKey}>
												{object?.dueDate}
											</div>
										</div>
									</Grid>
									<Grid item xs={12}>
										<div className={styles.customerInfo}>
											<div className={styles.detailsValue}>
												Transaction reference
											</div>
											<div className={styles.detailsKey}>
												{object?.reference}
											</div>
										</div>
									</Grid>
									<Divider />
									<Grid item xs={12}>
										<div className={styles.customerInfo}>
											<OutlineTextInput
												inputName='newDueDate'
												inputLabel='Due Date'
												inputType='date'
												handleChange={(value) => { setValues({ ...extendDateValue, newDueDate: value.target.value }) }}
												InputHelper={<ErrorMessage name='newDueDate' />}
												error={props.errors.newDueDate && props.touched.newDueDate
												}
											/>
										</div>
									</Grid>

									<Grid item xs={12} sm={6}>
										<button onClick={closeModal} type='button' className={styles.Downloadbutton_faint}>
											<span className={styles.Downloadbutton_span}>
												<img src={Times} alt='' />
											</span>
											Cancel
										</button>
									</Grid>
									<Grid item xs={12} sm={6}>
										<button type='submit' className={styles.Downloadbutton} disabled={!extendDateValue.newDueDate}>
											<span className={styles.Downloadbutton_span}>
												<img src={Mark} alt='' />
											</span>
											Extend Due Date
										</button>
									</Grid>
								</Grid>
							</Form>
						</div>
					)}
				</Formik>
			</div>
		</div>
	);
}

export default ExtendDueDate;
