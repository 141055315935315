import React, { useState, Fragment, useEffect } from "react";
import styles from "./ApprovePayouts.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import { ColumnPayouts, PayoutsData } from "../../../types/TablesTypes/Payouts";
import TableHeader from "../../../components/TableHeader/TableHeader";
import getPendingPayouts from "../../../api/allPayouts";
import useApi from "../../../hooks/useApi";
import { numberWithCommas } from "../../../utils/formatNumber";
import moment from "moment";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/link-icon.svg";
import { customStyles } from "../../../constants/modalStyles";
import Modal from "react-modal";
// import InitiatePayout from "../../components/ModalContent/InitiatePayout/InitialPayout";
import client from "../../../api/client";
import {
  closeLoader,
  openLoader,
} from "../../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import { ToastErrorStyles } from "../../../constants/toastStyles";
// import PayoutMenu from "../../components/MenuOption/PayoutMenu/PayoutMenu";
import Filtered from "../../../components/filterComponent/Filtered";
import { menuContent } from "../../../utils/menuContent";
import StatusView from "../../../components/StatusView/StatusView";
import FilterModal from "../../../components/filterConfig/FilterModal";
import { Dayjs } from "dayjs";
import { dateNow, endOfYear, sevenDaysAgo, startOfYear, thirtyDaysAgo } from "../../../utils/datefunction";
import { table } from "console";
import ApprovePayoutMenu from "../../../components/MenuOption/ApprovePayoutMenu/ApprovePayoutMenu";
import FilterButtonNew from "../../../components/FilterButtonNew/FilterButtonNew";

const ApprovePayouts = ({ payout, cb }: any) => {
  const dispatch = useDispatch();

  const [payouts, setPayouts] = useState<any>();
  const [tableRow, setTableRow] = useState<any[]>();
  const getPayoutsApi = useApi(getPendingPayouts.getPendingPayouts);
  const getPendingPayoutsApi = useApi(getPendingPayouts.getAllPayouts);
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [status, setStatus] = useState<string>("");
  const [SelectedSearchColumn, setSelectedSearchColumn] = useState<string>("");
  const [SearchableColumns, setSearchableColumns] = useState<string>("");
  const [Keyword, setKeyword] = useState<string>("");
  //FILTERING STARTS
  const [eventDate, setEventDate] = useState("");
  const [fromDate, setFromDate] = useState<Dayjs | null | string>("");
  const [toDate, setToDate] = useState<Dayjs | null | string>("");
  const [bearer, setBearer] = useState(false);
  const [batchId, setBatchId] = useState<number | null>(null);
  const [amount, setAmount] = useState<number | null>(null);
  const [accountNumber, setAccountNumber] = useState("");
  const [payoutReference, setPayoutReference] = useState("");
  const [subsidiaryId, setSubsidiaryId] = useState<number | null>(null);
  const [subsidiaryName, setSubsidiaryName] = useState("");
  const [merchantId, setMerchantId] = useState("");
  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  
  function closeModal() {
    setIsOpen(false);
  }
  interface StatusViewProps {
    status: string;
  }

  //Date filter logic Starts
    useEffect(() => {
      if (eventDate === "today") {
        setFromDate(dateNow);
        setToDate(dateNow);
        // console.log("datenow:", dateNow);
      } else if (eventDate === "last7days") {
        setFromDate(sevenDaysAgo);
        setToDate(dateNow);
      } else if (eventDate === "last30days") {
        setFromDate(thirtyDaysAgo);
        setToDate(dateNow);
      } else if (eventDate === "oneyear") {
        setFromDate(startOfYear);
        setToDate(endOfYear);
      } else {
        setFromDate("");
        setToDate("");
      }
    }, [eventDate]);
  //Date filter logic Ends

  // Apply filters when merchantId or status is updated
    useEffect(() => {
      if (status) {
        setSearchableColumns("Status");
        setKeyword(status);
      } else {
        setSearchableColumns("");
        setKeyword("");
      }
    }, [status]);

    useEffect(() => {
        setStatus("Pending"); // Automatically filter to show only "Pending"
      }, []);
      

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setStatus("");
    setDropdown(false);
    setBearer(true);
    setSearchableColumns("");
  };

  const filteredArray: {
		name: string;
		value: string | Dayjs | null;
		setValue: React.Dispatch<React.SetStateAction<any>>;
		selective?: { name: string; value: string }[];
		selectHelper?: boolean;
		type: "select" | "date" | "text";
	  }[]  = [
      {
        name: "Date Range",
        value: fromDate,
        setValue: setFromDate,
        type: "date", // Explicitly set as "date"
      },
    {
      name: "Merchant Id",
      value: merchantId,
      setValue: setMerchantId,
      type:'text'
    },
    {
      name: "Status",
      value: status,
      setValue: setStatus,
      type:'select',
      selective: [
        { name: "Failed", value: "Failed"  },
        { name: "Awaiting Verification",value: "Awaiting Verification" },
        { name: "Pending", value: "Pending"  },
        { name: "Processing", value: "Processing"  },
        { name: "Initiated", value: "Initiated" },
        { name: "Successful", value: "Successful" },
        { name: "Awaiting Approval", value: "Awaiting Approval" },
      ],
    },
  ];
  //FILTERING ENDS

  const fetchPayouts = async () => {
    dispatch(openLoader());
    try {
      // const response = await getAllPayouts.getAllPayouts({
        const response: any = await getPendingPayouts.getPendingPayouts({
        PageNumber: pageNumber,
        PageSize: rowsPerPage,
        StartDate: fromDate ? fromDate: '',
        EndDate: toDate ? toDate : '',
        Status:'Pending', 
        Filter: merchantId || '',
        SearchableColumns: SearchableColumns || '',
        Keyword: Keyword || '',
      });

      const payoutData = response?.data?.data;
      setPayouts(payoutData);
      setBearer(false);
      setTotalItems(payoutData?.totalCount || 0);
    } catch (error) {
      dispatch(
        openToastAndSetContent({
          toastContent: "Error fetching payouts",
          toastStyles: ToastErrorStyles,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  useEffect(() => {
    fetchPayouts();
  }, [pageNumber, bearer,rowsPerPage]);

  interface StatusViewProps {
    status: string;
  }

  useEffect(() => {
    setPageNumber(payouts?.pageNumber || 1);
    setPreviousPage(payouts?.pageNumber - 1);
    setNextPage(payouts?.pageNumber + 1);
  }, [payouts]);

  const dataPayout = () => {
    const tempArr: PayoutsData[] = [];
    payouts?.items
      ?.slice(0)
      .reverse()
      .forEach((payout: any, index: number) => {
        tempArr.push({
          dateInitiated: moment(payout?.dateInitiated).isValid()
          ? moment(payout?.dateInitiated).format('MMMM Do YYYY, h:mm a')
          : 'Invalid Date',
        
          recipientName: payout.recipientName,
          merchantName: payout?.merchantName,
          subsidiaryId: payout?.subsidiaryId,
          accountNumber: payout?.accountNumber,
          currency: payout.debitCurrency,
          type: payout?.type,
          transferType: payout?.transferType,
          amount: `${payout?.debitCurrency} ${numberWithCommas(
            payout?.amount
          )}`,
          fee: `${payout?.debitCurrency} ${numberWithCommas(payout?.fee)}`,
          status: (
            <StatusView
              status={payout?.status}
              green={"Approved || Successful"}
              red={"Rejected || Failed || Declined"}
              orange={"Processing || Pending"}
              blue={"Awaiting Verification"}
              purple="Awaiting Approval"
            />
          ),
          action: <ApprovePayoutMenu data={payout} />,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    const newTableRow = dataPayout();
    setTableRow(newTableRow);
}, [payouts]);

  
  return (
    <Fragment>
      <TableHeader
        pageName="Approve Payouts"
        dataLength={payouts?.totalItems}
        data={payouts?.items}
        value={value}
        setValue={setValue}
        dropdown={dropdown}
        placeHolder="Search Merchant name"
        setDropdown={setDropdown}
        fetchDataHandler={fetchPayouts}
        FilterComponent={
            <FilterButtonNew
              eventDate={eventDate}
              setEventDate={setEventDate}
              dropdown={dropdown}
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              fromDate={fromDate}
              toDate={toDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              clearAllHandler={clearHandler}
              filteredArray={filteredArray}       
            />
          }
      />

      {getPayoutsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <>
        {/* <button className={styles.button} onClick={changePayoutRoute}>Payout Routing</button> */}
        <p style={{fontSize:'large', fontWeight:'bold'}}>Payout(s) - {payouts?.totalCount}</p>
        <PaginationTable
          data={tableRow ? tableRow : []}
          value={value}
          columns={ColumnPayouts ? ColumnPayouts : []}
          emptyPlaceHolder="You currently do not have any payouts"
          total={payouts?.totalCount}
          totalPage={payouts?.totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          nextPage={nextPage}
          setNextPage={setNextPage}
          previousPage={previousPage}
          setPreviousPage={setPreviousPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
        </>
      )}
    </Fragment>
  );
};

export default ApprovePayouts;
