import React, { useState, useEffect } from 'react';
import styles from './BaseBasicGroup.module.scss';
import PaginationTable from '../../../components/table/pagination-table';
import TableHeader from '../../../components/TableHeader/TableHeader';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import { makeStyles } from '@material-ui/core';
import { Dayjs } from 'dayjs';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../../utils/datefunction';
import * as Yup from 'yup';
import FilterModal from '../../../components/filterConfig/FilterModal';
import moment from 'moment';
import MenuOption from '../../../components/MenuOption/MenuOption';
import useApi from '../../../hooks/useApi';
import reports from '../../../api/report';
import { ColumnPrcingGroup, ColumnSettlementGroup, PrcingGroupData } from '../../../types/TablesTypes/PricingGroup';
import BasicGroupModal from '../../../components/ModalContent/BasicGroupModal/BasicGroupModal';
import FilterButtonNew from '../../../components/FilterButtonNew/FilterButtonNew';

const useStyles = makeStyles({
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-input.MuiOutlinedInput-input':
		{
			textAlign: 'center',
			padding: '8.1px 14px',
		},
	},
	select: {
		'& .MuiOutlinedInput-root': {
			color: '#414141',
			fontFamily: 'Roboto',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '14px',
			lineHeight: '16px',
			borderRadius: '10px',
		},
		'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			outline: 'none',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
			backgroundColor: '#ffffff',
		},
		'& .MuiInputLabel-root.Mui-focused': {
			color: '#E0E0E0',
			backgroundColor: '#ffffff',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: '1px solid #E0E0E0',
		},
	},
});
interface BaseBasicGroupViewProps {
	name: string
}
const BaseBasicGroupView = ({ name }: BaseBasicGroupViewProps) => {
	const dispatch = useDispatch();
	const [tableRow, setTableRow] = useState<any[]>();
	const [modalIsOpenSingle, setIsOpenSingle] = React.useState({
		isOpened: false, data: null
	});
	var groupedName = `get${name}Groups`
	const isSubsidiaryGroup = groupedName?.indexOf("SubsidiaryGroup") > -1;
	const _rr: any = reports;
	const api: any = _rr[groupedName];
	const getPricingGroups = useApi(api);
	const { currencies } = useSelector((state) => state.appSettingsReducer);
	const fees = getPricingGroups?.data?.data ?? [];
	interface initTypes {
		currency: string;
		paymentOption: string;
		fee: string;
		cap: string;
		flatValue: null | string;
	}

	const validate = Yup.object({
		fee: Yup.number().required('Required').min(0.1).max(1),
		payment_option: Yup.string().required('Required'),
		cap: Yup.string().required('Required'),
		flat_value: Yup.string().required('Required'),
		currency: Yup.string().required('Required'),
	});
	// const [apiObject, setApiObject] = useState(null);

	// const { keyB: trxRef } = apiObject?.keyA || [{

	// }];

	function closeModalSingle() {
		setIsOpenSingle({ isOpened: false, data: null });
	}

	function openModalSingle(data: any = null) {
		console.log({ data })
		setIsOpenSingle({ isOpened: true, data: data });
	}

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [country, setCountry] = useState('');
	const [status, setStatus] = useState('');
	const [bearer, setBearer] = useState(false);
	const [businessname, setBusinessname] = useState('');
	const [businesstype, setBusinesstype] = useState('');

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setCountry('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
		setBusinesstype('');
	};

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const filteredArray: {
		name: string;
		value: string | Dayjs | null;
		setValue: React.Dispatch<React.SetStateAction<any>>;
		selective?: { name: string; value: string }[];
		selectHelper?: boolean;
		type: "select" | "date" | "text";
	  }[] = [
		{
			name: "Date Range",
			value: fromDate,
			setValue: setFromDate,
			type: "date", // Explicitly set as "date"
		},
		{
			name: 'Business Type',
			value: businesstype,
			setValue: setBusinesstype,
			type:'select',
			selective: [{ name: 'Individual', value:'Individual' }, { name: 'Company', value:'Company' }],
		},
		{
			name: 'Status',
			value: status,
			setValue: setStatus,
			type:'select',
			selective: [
				{ name: 'Active', value:'Active' },
				{ name: 'Pending-Verification', value:'Pending-Verification' },
				{ name: 'New', value:'New' },
				{ name: 'Pending-Approval', value:'Pending-Approval' },
			],
		},
	];

	useEffect(() => {
		getPricingGroups.request();
	}, []);

	useEffect(() => { })


	// useEffect(() => {
	// 	fetchBusinesses();
	// }, [bearer, pageNumber, rowsPerPage]);

	useEffect(() => {
		setPageNumber(1);
		setPreviousPage(0);
		setNextPage(2);
	}, [fees]);

	const dataTransaction = () => {
		const tempArr: PrcingGroupData[] = [];
		fees.forEach((transaction: any, index: number) => {
			tempArr.push({
				id: transaction.id,
				name: transaction.name,
				isActive: transaction.isActive,
				description: transaction.description ?? "Not provided",
				pricingGroupId: transaction?.pricingGroupId,
				pricingGroup: transaction?.pricingGroup?.name,
				limitGroupId: transaction?.limitGroupId,
				limitGroup: transaction?.limitGroup?.name,
				dateCreated: moment(transaction?.dateCreated).format('MMMM Do YYYY, h:mm a'),
				action: (
					<MenuOption
						menuContent={
							<div>
								{[].map((option: any) => (
									<div
										key={option?.id}
										onClick={(e) => {
											option?.onclick(transaction, e);
										}}
										className={styles.menuContent}>
										<img
											src={option?.icon}
											alt={option?.name()}
											width={24}
											height={24}
										/>
										<div className={styles.optionName}>{option?.name()}</div>
									</div>
								))}
							</div>
						}
					/>
				),
			});
		});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransaction());
	}, [getPricingGroups?.data?.data]);


	return (
		<div style={{ marginTop: '38px' }}>
			<TableHeader
				pageName={`${name} Groups`}
				dataLength={fees?.page?.total}
				data={fees?.items}
				value={value}
				setValue={setValue}
				exporting={false}
				filtering={false}
				searchfn={false}
				newButton={
					<div
						onClick={() => openModalSingle()}
						className={styles.createLink}>
						Add New Group
					</div>
				}
				FilterComponent={
					<FilterButtonNew
						eventDate={eventDate}
						setEventDate={setEventDate}
						dropdown={dropdown}
						setDropdown={setDropdown}
						setFromDate={setFromDate}
						setToDate={setToDate}
						fromDate={fromDate}
						toDate={toDate}
						setBearer={setBearer}
						clearHandler={clearHandler}
						clearAllHandler={clearHandler}
						filteredArray={filteredArray}
					/>
				}
			/>

			<PaginationTable
				data={tableRow ? tableRow : []}
				columns={isSubsidiaryGroup ? ColumnSettlementGroup : ColumnPrcingGroup ? ColumnPrcingGroup : []}
				value={value}
				emptyPlaceHolder={getPricingGroups.loading ? 'Loading...' : getPricingGroups.data?.data?.length <= 0
					? 'You currently do not have any data'
					: ''
				}
				total={getPricingGroups.data?.data?.length}
				totalPage={getPricingGroups.data?.data?.totalPage * 1.5}
				pageNumber={pageNumber}
				clickAction={(value: any) => { openModalSingle(value) }}
				setPageNumber={setPageNumber}
				nextPage={nextPage}
				setNextPage={setNextPage}
				previousPage={previousPage}
				setPreviousPage={setPreviousPage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
			/>

			{/* //MODAL FOR SINGLE */}
			<Modal
				isOpen={modalIsOpenSingle.isOpened}
				onRequestClose={closeModalSingle}
				contentLabel='Example Modal'
				style={customStyles}>
				<BasicGroupModal isSubsidiaryGroup={isSubsidiaryGroup} fnName={groupedName} data={modalIsOpenSingle.data} closeModal={closeModalSingle} cb={getPricingGroups.request} />
			</Modal>
		</div>
	);
};

export default BaseBasicGroupView;
