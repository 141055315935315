import React from "react";
import styles from "./Role.module.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import SelectDropdown from "../../input/SelectDropdown";
import Select from "react-select";
import { closeLoader, openLoader } from "../../../redux/actions/loader/loaderActions";

const isAlphabets = (str: string) => /^[a-zA-Z ]*$/.test(str);

const EditRole = ({
  closeModal,
  fetchPaymentLink,
  permissions,
  roleDetails,
  allPermissions,
}: any) => {
  const dispatch = useDispatch();
  interface initTypes {
    name: string;
    description: string;
  }

  const initProps: initTypes = {
    name: roleDetails?.name,
    description: roleDetails?.description,
  };
  const [inputs, setInputs] = React.useState(initProps);
  const [errorText, setErrorText] = React.useState("");
  const [touchedName, setTouchedName] = React.useState(false);
  const [touchedDescription, setTouchDescription] = React.useState(false);

  const disableFunction = () => {
    if (
      inputs.name.length >= 3 &&
      isAlphabets(inputs.name) &&
      inputs.description.length >= 3 &&
      isAlphabets(inputs.description) &&
      selectedId
    ) {
      return true;
    } else if (
      isAlphabets(inputs.name) == false ||
      isAlphabets(inputs.description) == false
    ) {
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: "Please input only alphabets",
        })
      );
    }
  };

  let permissionOptions = permissions?.map(function (permission: any) {
    return { value: permission?.id, label: permission?.permission };
  });

  const [selectedOption, setSelectedOption] =
    React.useState<any>(permissionOptions);

  console.log(
    "selected",
    selectedOption?.map((item: any) => item.value)
  );

  const selectedId = selectedOption?.map((item: any) => item.value);

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;

    if (isAlphabets(newValue)) {
      setErrorText("");
      setInputs((prevState: initTypes) => {
        return {
          ...prevState,
          [inputName]: newValue,
        };
      });
    } else {
      setErrorText("Please input alphabets only");
    }
  };

  let allRolePermissions = allPermissions?.map(function (permission: any) {
    console.log("all permission", permission);
    return { value: permission?.id, label: permission?.name };
  });

  const createPaymentLink = async () => {
    try {
      dispatch(openLoader())
      const data: any = await client.post("role/edit", {
        ...inputs,
        permissions: selectedId,
        roleId: roleDetails?.id,
      });
      closeModal();
      const message = data?.data?.message;
      fetchPaymentLink();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      closeModal();

      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
    finally {
      dispatch(closeLoader())
    }
  };

  const handleTouchName = () => {
    setTouchDescription(false);
    setTouchedName(true);
  };

  const handleTouchDescription = () => {
    setTouchedName(false);
    setTouchDescription(true);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (inputs.name.length >= 3 && inputs.description.length >= 3) {
      createPaymentLink();
    } else {
      return dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: "Please input at least 3 alphabets",
        })
      );
    }
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Edit role</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="name"
            inputLabel="Role name"
            inputValue={inputs.name}
            onFocus={handleTouchName}
            InputHelper={touchedName && errorText}
            error={touchedName && errorText}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="description"
            inputLabel="Description"
            inputValue={inputs.description}
            onFocus={handleTouchDescription}
            InputHelper={touchedDescription && errorText}
            error={touchedDescription && errorText}
          />
          <div>
            <label className={styles.inputLabel}>Permissions</label>
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={allRolePermissions}
              isMulti
              // styles={customStyles}
              className={styles.select}
            />
          </div>
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Save changes
          </div>
        ) : (
          <div className={styles.notClickable}>Save changes</div>
        )}
      </div>
    </div>
  );
};

export default EditRole;
