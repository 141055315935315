import React, { useEffect } from 'react';
import styles from './Settlements.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
import { ReactComponent as CopyIcon } from '../../../assets/images/Icons/u_copy.svg';
import Select from 'react-select';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import { Divider } from 'antd';
import { Grid } from '@material-ui/core';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import client from '../../../api/client';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import { ErrorMessage, Form, Formik, Field, useFormikContext } from 'formik';
import { InputLabel, TextField } from '@material-ui/core';
import Mark from '../../../assets/images/Icons/u-mark.svg';
import { makeStyles } from '@material-ui/core';
import SelectWrapper from '../../../components/Select';
import * as Yup from 'yup';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';
import Item from 'antd/lib/list/Item';

function Single({ closeModal, fn }: any) {
	const [banks, setBanks] = React.useState<any>();
	const [input, setInput] = React.useState<any>();
	const [resolveData, setResolvedData] = React.useState<any>();

	const fetchBanks = async () => {
		try {
			const data: any = await client.get('payout/banks');
			const resData = data?.data?.banks.map((item: any) => ({
				...item,
				val: item.bankCode,
			}));
			setBanks(resData);
		} catch (error: any) {
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	useEffect(() => {
		fetchBanks();
	}, []);
	console.log({ resolveData })
	const resolveAccount = async () => {
		if (input?.accountNumber.length >= 10) {
			dispatch(openLoader());
			try {
				const data: any = await client.post('resolve/account', {
					bankCode: input.bankCode,
					accountNumber: input?.accountNumber,
				});
				setResolvedData(data?.data);
				const message = data?.data?.message;
				dispatch(closeLoader());
				dispatch(
					openToastAndSetContent({
						toastStyles: ToastSuccessStyles,
						toastContent: message,
					})
				);
			} catch (error: any) {
				console.log({ error })
				const { message } = error?.response?.data ?? error;
				console.log({ message })
				dispatch(closeLoader());
				dispatch(
					openToastAndSetContent({
						toastStyles: ToastErrorStyles,
						toastContent: !!message ? message : "Unable to resolve bank account"
					})
				);
			}
		}
	};

	useEffect(() => {
		resolveAccount();
	}, [input?.accountNumber]);

	interface initTypes {
		settlementAccount: string;
		currency: string;
		amount: string;
		ref: string;
		narration: string;
		merchantBank: string;
		type: string;
	}

	const useStyles = makeStyles({
		root: {
			'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
				border: 'none',
			},
			'& .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-input.MuiOutlinedInput-input':
			{
				textAlign: 'center',
				padding: '8.1px 14px',
			},
		},
		select: {
			'& .MuiOutlinedInput-root': {
				color: '#414141',
				fontFamily: 'Roboto',
				fontStyle: 'normal',
				fontWeight: 'normal',
				fontSize: '14px',
				lineHeight: '16px',
				borderRadius: '10px',
			},
			'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
				outline: 'none',
			},
			'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
				backgroundColor: '#ffffff',
			},
			'& .MuiInputLabel-root.Mui-focused': {
				color: '#E0E0E0',
				backgroundColor: '#ffffff',
			},
			'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
				border: '1px solid #E0E0E0',
			},
		},
	});

	const classes = useStyles();

	const currencyOption = [{ name: 'USD' }, { name: 'NGN' }];

	const validate = Yup.object({
		accountNumber: Yup.string().required('Required'),
		bankCode: Yup.string().required('Required'),
		amount: Yup.string().required('Required'),
		description: Yup.string().required('Required'),
	});
	const dispatch = useDispatch();

	const FormObserver: React.FC = () => {
		const { values } = useFormikContext();

		useEffect(() => {
			setInput(values);
		}, [values]);

		return null;
	};

	useEffect(() => {
		console.log('inputField:', input);
	}, [input]);

	return (
		<div className={styles.modalwrapper}>
			<div className={styles.modalhead}>
				<h3 className={styles.modalheadh3}>Log a single settlement</h3>
				<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
			</div>
			<Divider style={{ margin: 0, padding: 0 }} />

			<Formik
				initialValues={{
					accountNumber: '',
					bankCode: '',
					amount: '',
					description: '',
					creditCurrency: 'NGN',
				}}
				validationSchema={validate}
				onSubmit={(values) => {
					console.log(values);
					dispatch(openLoader());
					client
						.post(`settlement/log`, {
							payoutRequests: [
								{
									accountNumber: values.accountNumber,
									bankCode: values.bankCode,
									amount: values.amount,
									description: values.description,
									creditCurrency: values.creditCurrency,
									accountName: resolveData?.data?.accountName,
								},
							],
						})
						.then((res: any) => {
							dispatch(closeLoader());
							dispatch(
								openToastAndSetContent({
									toastContent: res.data.message,
									toastStyles: ToastSuccessStyles,
								})
							);
							fn()
							closeModal();
						})
						.catch((error: any) => {
							dispatch(closeLoader());
							const { message } = error?.response?.data;
							dispatch(
								openToastAndSetContent({
									toastContent: message,
									toastStyles: ToastErrorStyles,
								})
							);
						});
				}}>
				{(props) => (
					<div
						style={{
							padding: '32px 24px',
						}}>
						<Form>
							<FormObserver />
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<div className={styles.customerInfoSingle}>
										<InputLabel>
											<span className={styles.label}>Bank</span>
										</InputLabel>
										<Field
											as={SelectWrapper}
											helperText={
												<ErrorMessage name='bankCode'>
													{(msg) => <span style={{ color: 'red' }}>{msg}</span>}
												</ErrorMessage>
											}
											name='bankCode'
											variant='outlined'
											margin='normal'
											type='text'
											size='small'
											fullWidth
											options={banks}
											className={classes.select}
											placeholder='BANKS'
											defaultValue={banks && banks[0]}
										/>
									</div>
								</Grid>
								<Grid item xs={12} sm={6}>
									<div className={styles.customerInfoSingle}>
										<InputLabel>
											<span className={styles.label}>Account Number</span>
										</InputLabel>
										<Field
											as={TextField}
											helperText={
												<ErrorMessage name='accountNumber'>
													{(msg) => <span style={{ color: 'red' }}>{msg}</span>}
												</ErrorMessage>
											}
											name='accountNumber'
											variant='outlined'
											margin='normal'
											type='text'
											size='small'
											fullWidth
											className={classes.select}
										/>
									</div>
								</Grid>
								{resolveData && (
									<p>
										Account Name:{' '}
										<span style={{ color: 'green' }}>
											{resolveData?.data?.accountName}
										</span>{' '}
									</p>
								)}
								<Grid item xs={12}>
									<div className={styles.customerInfoSingle}>
										<InputLabel>
											<span className={styles.label}>Amount</span>
										</InputLabel>
										<Field
											as={TextField}
											helperText={
												<ErrorMessage name='amount'>
													{(msg) => <span style={{ color: 'red' }}>{msg}</span>}
												</ErrorMessage>
											}
											name='amount'
											variant='outlined'
											margin='normal'
											type='text'
											size='small'
											fullWidth
											className={classes.select}
										/>
									</div>
								</Grid>
								<Grid item xs={12}>
									<div className={styles.customerInfoSingle}>
										<InputLabel>
											<span className={styles.label}>Description</span>
										</InputLabel>
										<Field
											as={TextField}
											helperText={
												<ErrorMessage name='description'>
													{(msg) => <span style={{ color: 'red' }}>{msg}</span>}
												</ErrorMessage>
											}
											name='description'
											variant='outlined'
											margin='normal'
											type='text'
											size='small'
											fullWidth
											className={classes.select}
										/>
									</div>
								</Grid>
								<Grid item xs={12}>
									<button
										disabled={!resolveData?.data?.accountName ? true : false}
										type='submit'
										className={styles.Downloadbutton}>
										<span className={styles.Downloadbutton_span}>
											<img src={Mark} alt='' />
										</span>
										Submit
									</button>
								</Grid>{' '}
							</Grid>
						</Form>
					</div>
				)}
			</Formik>
		</div>
	);
}

export default Single;
