import React from 'react';

export interface PrcingGroupData {
	id: string;
	name: string;
	description: string;
	isActive: boolean;
	pricingGroupId?: number,
	limitGroupId?: number,
	pricingGroup?: string,
	limitGroup?: string,
	dateCreated: string;
	action?: React.ReactNode
}

export const ColumnPrcingGroup = [
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Description',
		accessor: 'description',
	},
	{
		Header: 'Date Created',
		accessor: 'dateCreated',
	},
	{
		Header: '',
		accessor: 'action',
	},
];

export const ColumnSettlementGroup = [
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Description',
		accessor: 'description',
	},
	{
		Header: 'Pricing Group',
		accessor: 'pricingGroup',
	},
	{
		Header: 'limit Group',
		accessor: 'limitGroup',
	},
	{
		Header: 'Date Created',
		accessor: 'dateCreated',
	},
	{
		Header: '',
		accessor: 'action',
	},
];
