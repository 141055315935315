import React, { useState, useEffect } from 'react';
import styles from './Settlements.module.scss';
import PaginationTable from '../../../components/table/pagination-table';
import { ColumnSettlementsFull, SettlementsFullData, } from '../../../types/TablesTypes/Settlementsful';
import StatusView from '../../../components/StatusView/StatusView';
import TableHeader from '../../../components/TableHeader/TableHeader';
import useApi from '../../../hooks/useApi';
import { numberWithCommas } from '../../../utils/formatNumber';
import moment from 'moment';
import { openLoader, closeLoader, } from '../../../redux/actions/loader/loaderActions';
import { useDispatch, useSelector } from 'react-redux';
import { ToastErrorStyles, ToastSuccessStyles, } from '../../../constants/toastStyles';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import { Menu } from 'antd';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import Single from './Single';
import Bulk from './Bulk';
import { Dayjs } from 'dayjs';
import FilterModal from '../../../components/filterConfig/FilterModal';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../../utils/datefunction';
import settlements from '../../../api/settlements';
import SettlementTransactions from './SettlementTransactions/SettlementTransactions';
import FilterButtonNew from '../../../components/FilterButtonNew/FilterButtonNew';

const SettlementsApproved = (tab?: string) => {
	const dispatch = useDispatch();
	const [tableRow, setTableRow] = useState<any[]>();
	const [modalIsOpenSingle, setIsOpenSingle] = React.useState({
		isOpened: false,
		data: null
	});
	const [modalIsOpenBulk, setIsOpenBulk] = React.useState(false);
	const allSettlementApi = useApi(settlements.getApprovedSettlements);
	const settlement = allSettlementApi?.data?.data || {}
	// const [settlement, setSettlement] = useState<any>({})
	const { currency } = useSelector((state) => state.appSettingsReducer || {});
	const [bulkActionData, setBulkActionData] = useState<any>({})

	// const [apiObject, setApiObject] = useState(null);

	// const { keyB: trxRef } = apiObject?.keyA || [{

	// }];

	function closeModalSingle() {
		setIsOpenSingle({ isOpened: false, data: null });
	}
	console.log({ settlement })
	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [paymentreference, setPaymentReference] = useState('');
	const [orderreference, setOrderReference] = useState('');
	const [status, setStatus] = useState('');
	const [email, setEmail] = useState('');
	const [bearer, setBearer] = useState(false);

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setPaymentReference('');
		setOrderReference('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
	};

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	// useEffect(() => {
	// 	console.log("settings settllement")
	// 	setSettlement({ ...settlement })
	// }, [settlement])

	const filteredArray: {
		name: string;
		value: string | Dayjs | null;
		setValue: React.Dispatch<React.SetStateAction<any>>;
		selective?: { name: string; value: string }[];
		selectHelper?: boolean;
		type: "select" | "date" | "text";
	  }[]  = [
		{
			name: "Date Range",
			value: fromDate,
			setValue: setFromDate,
			type: "date", // Explicitly set as "date"
		},
		{
			name: 'Payment Reference',
			value: paymentreference,
			setValue: setPaymentReference,
			type:'text'
		},
		{
			name: 'Order Reference',
			value: orderreference,
			setValue: setOrderReference,
			type:'text'
		},
		{
			name: 'Status',
			value: status,
			setValue: setStatus,
			type:'select',
			selective: [
				{ name: 'Successful', value:'Successful' },
				{ name: 'Initiated', value:'Initiated' },
				{ name: 'Failed', value:'Failed' },
				{ name: 'Reversed', value:'Reversed' },
			],
		},
	];

	const fetchSettlement = async () => {
		status.replace(/-|\s/g, '');
		dispatch(openLoader());
		try {
			allSettlementApi.request(`currency=${currency}&email=${email}&settlementstatus=${tab ?? status}&fromdate=${fromDate}&todate=${toDate}&limit=${rowsPerPage}&size=${rowsPerPage}&page=${pageNumber}`)
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	const openTransactionDetails = async (settlement: any) => {
		setIsOpenSingle({ isOpened: true, data: settlement })
	};

	const processBulkSettlement = async () => {
		console.log("Processing bulk payment")
		console.log(bulkActionData)
		const payload = []
		for (const key in bulkActionData) {
			if (key == "header") continue
			const record = bulkActionData[key]
			if (!record.checkboxMarked) continue;
			if (record._status != "Pending") continue;
			payload.push(record.id)
		}
		processBulkSettlementHandler(payload);
	};

	const processBulkSettlementHandler = async (payload: any) => {
		try {
			console.log("Processing bulk payment")
			console.log(payload)
			dispatch(openLoader());
			settlements.processBulkSettlementApproved({ settlements: payload })
				.then((d: any) => {
					dispatch(
						openToastAndSetContent({
							toastContent: d?.data?.message,
							toastStyles: ToastSuccessStyles,
						})
					);
				})
				.catch((error) => {
					const { message } = error.response.data;
					dispatch(
						openToastAndSetContent({
							toastContent: message,
							toastStyles: ToastErrorStyles,
						})
					);
				})
				.finally(() => {
					dispatch(closeLoader());
					fetchSettlement();
				})
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error.response.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};
	useEffect(() => {
		fetchSettlement();
	}, [bearer, pageNumber, rowsPerPage, email, currency]);

	useEffect(() => {
		setPageNumber(settlement?.page || 1);
		setPreviousPage(settlement?.page - 1);
		setNextPage(settlement?.page + 1);
		setTableRow(dataSettlement());
		// setSettlement(_settlement);
	}, [allSettlementApi.data]);

	const dataSettlement = () => {
		const tempArr: SettlementsFullData[] = [];
		const _settlement = settlement?.items || [];
		console.log({ _settlement })
		_settlement?.slice(0).reverse()
			.forEach((settlement: any, index: number) => {
				console.log({ se: settlement })
				tempArr.push({
					businessName: settlement?.subsidiary?.name,
					destination: settlement?.settlementDestination?.name,
					merchantId: settlement?.subsidiaryId,
					id: settlement?.id,
					currency: settlement?.currency?.shortName,
					amount: `${settlement?.currency?.shortName} ${numberWithCommas(settlement?.settlementAmount)}`,
					date: moment(settlement?.dateCreated).format('MMMM Do YYYY, h:mm a'),
					_status: settlement?.status?.name,
					status: (
						<StatusView
							status={settlement?.status?.name}
							green='Completed'
							red='Failed'
							orange='Awaiting Approval'
						/>
					),
					// action: <SettlementFMenu customerId={settlement.subsidiary_id} />,
				});
			});
		return tempArr;
	};

	console.log({ settlement })
	return (
		<div className={styles.dashbordContainer}>
			<TableHeader
				pageName='All Settlement'
				data={settlement?.items}
				dataLength={settlement?.total}
				value={email}
				setValue={setEmail}
				dropdown={dropdown}
				setDropdown={setDropdown}
				searchfn={true}
				placeHolder='Search by name'
				newButton={
					<div className={styles.tableButtonWrapper}>
						<div onClick={processBulkSettlement} className={styles.createLink}>Approve Bulk</div>
					</div>
				}
				FilterComponent={
					<>
						<FilterButtonNew
							eventDate={eventDate}
							setEventDate={setEventDate}
							dropdown={dropdown}
							setDropdown={setDropdown}
							setFromDate={setFromDate}
							setToDate={setToDate}
							fromDate={fromDate}
							toDate={toDate}
							setBearer={setBearer}
							clearHandler={clearHandler}
							clearAllHandler={clearHandler}
							filteredArray={filteredArray}
						/>
					</>
				}
			/>

			<PaginationTable
				data={tableRow ? tableRow : []}
				columns={ColumnSettlementsFull ? ColumnSettlementsFull : []}
				value={value}
				emptyPlaceHolder={
					settlement?.items?.length == 0
						? 'You currently do not have any data'
						: 'Loading...'
				}
				total={settlement?.total}
				totalPage={settlement?.totalPage}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				nextPage={nextPage}
				setNextPage={setNextPage}
				previousPage={previousPage}
				setPreviousPage={setPreviousPage}
				rowsPerPage={rowsPerPage}
				allowBulkAction={true}
				updateBulkActionData={setBulkActionData}
				setRowsPerPage={setRowsPerPage}
				clickAction={(obj) => openTransactionDetails(obj)}
			/>

			{/* //MODAL FOR SINGLE */}
			<Modal
				isOpen={modalIsOpenSingle.isOpened}
				onRequestClose={closeModalSingle}
				contentLabel='Example Modal'
				style={customStyles}>
				<SettlementTransactions closeModal={closeModalSingle} settlementType={tab == 'pending' ? 'smt-pending-process' : 'smt-approved-process'} data={modalIsOpenSingle.data} />
			</Modal>
		</div>
	);
};

export default SettlementsApproved;
