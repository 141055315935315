import React from "react";
import styles from "./PayoutVerificationModal.module.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import { closeLoader, openLoader } from "../../../redux/actions/loader/loaderActions";

const PayoutBankToBankVerificationModal = ({ closeModal, data }: any) => {
  const dispatch = useDispatch();
  interface initTypes {
    otp: string | undefined;
  }
  const initProps: initTypes = {
    otp: undefined,
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (inputs.otp) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const verifyPayout = async () => {
    dispatch(openLoader());
    try {
      const res: any = await client.post('/wema/debit/approve-first-level', {
        otp: inputs.otp,
        payoutId: data?.payoutId,
        batchId: data?.batchId
      });
      const message = res?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
      closeModal && closeModal();
    } catch (error: any) {
      console.error('Error Response:', error); // Log the full error response for debugging
      const  message  = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
    finally {
      dispatch(closeLoader());
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    verifyPayout();
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Verify Payout</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <p>{data?.message || "Enter the One-Time Password (OTP) sent to your email"}</p>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="otp"
            inputLabel="One-Time Password (OTP)"
            inputValue={inputs.otp}
          />
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Verify Payout
          </div>
        ) : (
          <div className={styles.notClickable}>Verify Payout</div>
        )}
      </div>
    </div>
  );
};

export default PayoutBankToBankVerificationModal;
