import React, { useEffect, useState } from 'react';
import client from '../../../api/client';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import Credit from '../Credit/Credit';
import CreditSummary from '../CreditSummary/CreditSummary';
import Debit from '../Debit/Debit';
import ProviderSummary from '../ProviderSummary/ProviderSummary';
import styles from './WalletTab.module.scss';
import { useDispatch } from 'react-redux';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { numberWithCommas } from '../../../utils/formatNumber';
import { Grid } from '@material-ui/core';

function WalletTab() {
	const dispatch = useDispatch();
	const [summary, setSummary] = useState<summaryType>();
	interface dataTypes {
		id: Number;
		menu: String;
	}

	interface summaryType {
		data: {
			currency: string;
			funding: number;
			refund: number;
			chargeback: number;
			rollingReserve: number;
		};
		status: string;
		statusCode: string;
		message: string;
	}
	const [selectedNav, setSelectedNav] = useState<Number>(1);
	const fetchSummary = async () => {
		try {
			const { data }: { data: summaryType } = await client.get(
				`wallet/product/summary`
			);
			setSummary(data);
		} catch (error: any) {
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useEffect(() => {
		fetchSummary();
	}, []);
	const dataRef: dataTypes[] = [
		{
			id: 1,
			menu: 'Credit',
		},
		// {
		// 	id: 2,
		// 	menu: 'Credit summary',
		// },
		// {
		// 	id: 3,
		// 	menu: 'Provider summary',
		// },
		{
			id: 4,
			menu: 'Debit/Transfer',
		},
	];

	const dataRes = [
		{
			title: 'FUNDING',
			amount: summary?.data?.funding,
		},
		{
			title: 'REFUND',
			amount: summary?.data?.refund,
		},
		{
			title: 'CHARGEBACK',
			amount: summary?.data?.chargeback,
		},
		{
			title: 'ROLLING RESERVE',
			amount: summary?.data?.rollingReserve,
		},
	];
	return (
		<>
			<div className={styles.tabContainer}>
				{dataRef.map(({ id, menu }: dataTypes, i) => (
					<div
						key={i}
						onClick={() => {
							setSelectedNav(id);
						}}
						className={
							selectedNav === id ? styles.activeMenuItem : styles.subMenuItem
						}>
						{selectedNav === id ? (
							<div className={styles.activeItemColor}>{menu}</div>
						) : (
							<div style={{ color: 'rgba(10, 9, 3, 0.6)' }}>{menu}</div>
						)}
					</div>
				))}
			</div>
			<Grid container spacing={2}>
				{dataRes?.map((item, i) => (
					<Grid item xs={3}>
						<div className={styles.top_Container_box}>
							<h5>{item.title}</h5>

							<div className={styles.top_Container_box_left}>
								<div className={styles.top_Container_box_leftdiv}>
									<span>NGN</span>
									<h4>{item.amount && numberWithCommas(item.amount)}</h4>
								</div>
							</div>
						</div>
					</Grid>
				))}
			</Grid>

			<div>
				{selectedNav === 1 ? (
					<Credit />
				) : selectedNav === 2 ? (
					<CreditSummary />
				) : selectedNav === 3 ? (
					<ProviderSummary />
				) : selectedNav === 4 ? (
					<Debit />
				) : (
					''
				)}
			</div>
		</>
	);
}

export default WalletTab;
