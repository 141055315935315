import React, { useState, useEffect } from 'react';
import styles from './Transfer.module.scss';
import client from '../../../api/client';
import {
    openLoader,
    closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import { useDispatch, useSelector } from 'react-redux';
import {
    ToastSuccessStyles,
    ToastErrorStyles,
} from '../../../constants/toastStyles';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import Mark from '../../../assets/images/Icons/u_save.svg';
import { Divider } from 'antd';
import { Grid } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { InputLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import SelectWrapper from '../../../components/Select';
import * as Yup from 'yup';
import useApi from '../../../hooks/useApi';
import reports from '../../../api/report';

const useStyles = makeStyles({
    root: {
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-input.MuiOutlinedInput-input':
        {
            textAlign: 'center',
            padding: '8.1px 14px',
        },
    },
    select: {
        '& .MuiOutlinedInput-root': {
            color: '#414141',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '16px',
            borderRadius: '10px',
        },
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            outline: 'none',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
            backgroundColor: '#ffffff',
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#E0E0E0',
            backgroundColor: '#ffffff',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E0E0E0',
        },
    },
});

const UpdateLimitModal = ({ closeModal }: any) => {
    const dispatch = useDispatch();
    const getLimitGroups = useApi(reports.getLimitGroups);
    const { currencies } = useSelector(state => state.appSettingsReducer)
    const [status, setStatus] = useState<string>('');
    const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
    const [transfer, setTransfer] = useState<any>();
    const classes = useStyles();

    const validate = Yup.object({
        singleLimit: Yup.string().required('Required'),
        frequency: Yup.string().required('Required'),
        cummDailyLimit: Yup.string().required('Required'),
        // industry: Yup.string().required('Required'),
        currency: Yup.string().required('Required'),
        limitGroupId: Yup.string().required('Required'),
        // riskCategory: Yup.string().required('Required'),
    });

    const limitGroupOptions = getLimitGroups?.data?.data?.map((limit: any) => ({ name: limit.name, val: limit.id }))
    const currencyOptions = currencies?.map((limit: any) => ({ name: limit.shortName }))

    function closeModalSingle() {
        closeModal && closeModal(false);
    }

    const handleSubmit = async (values: any) => {
        try {
            console.log(values);
            dispatch(openLoader());
            client
                .post(`limits/payout/add`, {
                    currency: values.currency,
                    singleLimit: Number(values.singleLimit),
                    cummDailyLimit: Number(values.cummDailyLimit),
                    frequency: Number(values.frequency),
                    industry: values.industry,
                    riskCategory: values.riskCategory,
                    limitGroupId: values.limitGroupId,
                })
                .then((res: any) => {
                    dispatch(closeLoader());
                    dispatch(
                        openToastAndSetContent({
                            toastContent: res.data.message,
                            toastStyles: ToastSuccessStyles,
                        })
                    );
                    closeModalSingle();
                })
                .catch((error: any) => {
                    dispatch(closeLoader());
                    const { message } = error?.response?.data;
                    dispatch(
                        openToastAndSetContent({
                            toastContent: message,
                            toastStyles: ToastErrorStyles,
                        })
                    );
                });
        } catch (error: any) {
            const { message } = error.response.data;
            dispatch(
                openToastAndSetContent({
                    toastContent: message,
                    toastStyles: ToastErrorStyles,
                })
            );
        }
    };

    useEffect(() => {
        getLimitGroups.request();
    }, []);

    return (
        <div style={{ marginTop: '38px' }}>
            <div className={styles.modalwrapper}>
                <div className={styles.modalhead}>
                    <h3 className={styles.modalheadh3}>Add a new payout limit</h3>
                    <CloseIcon
                        onClick={closeModalSingle}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
                <Divider style={{ margin: 0, padding: 0 }} />
                <Formik
                    initialValues={{
                        currency: 'NGN',
                        singleLimit: '',
                        cummDailyLimit: '',
                        frequency: '',
                        industry: '',
                        riskCategory: '',
                        limitGroupId: 1
                    }}
                    validationSchema={validate}
                    onSubmit={handleSubmit}>
                    {(props) => (
                        <div
                            style={{
                                padding: '32px 24px',
                            }}>
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className={styles.customerInfoSingle}>
                                            <InputLabel>
                                                <span className={styles.label}>Limit Group</span>
                                            </InputLabel>
                                            <Field
                                                as={SelectWrapper}
                                                helperText={
                                                    <ErrorMessage name='limitGroupId'>
                                                        {(msg) => (
                                                            <span style={{ color: 'red' }}>{msg}</span>
                                                        )}
                                                    </ErrorMessage>
                                                }
                                                name='limitGroupId'
                                                variant='outlined'
                                                margin='normal'
                                                type='text'
                                                size='small'
                                                fullWidth
                                                options={limitGroupOptions}
                                                className={classes.select}
                                                placeholder='Limit Groups'
                                                defaultValue={limitGroupOptions && limitGroupOptions[0]}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className={styles.customerInfoSingle}>
                                            <InputLabel>
                                                <span className={styles.label}>Limit Group</span>
                                            </InputLabel>
                                            <Field
                                                as={SelectWrapper}
                                                helperText={
                                                    <ErrorMessage name='currency'>
                                                        {(msg) => (
                                                            <span style={{ color: 'red' }}>{msg}</span>
                                                        )}
                                                    </ErrorMessage>
                                                }
                                                name='currency'
                                                variant='outlined'
                                                margin='normal'
                                                type='text'
                                                size='small'
                                                fullWidth
                                                options={currencyOptions}
                                                className={classes.select}
                                                placeholder='Currencies'
                                                defaultValue={currencyOptions && currencyOptions[0]}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className={styles.customerInfoSingle}>
                                            <InputLabel>
                                                <span className={styles.label}>Single Limit</span>
                                            </InputLabel>
                                            <Field
                                                as={TextField}
                                                helperText={
                                                    <ErrorMessage name='singleLimit'>
                                                        {(msg) => (
                                                            <span style={{ color: 'red' }}>{msg}</span>
                                                        )}
                                                    </ErrorMessage>
                                                }
                                                name='singleLimit'
                                                variant='outlined'
                                                margin='normal'
                                                type='text'
                                                size='small'
                                                fullWidth
                                                className={classes.select}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className={styles.customerInfoSingle}>
                                            <InputLabel>
                                                <span className={styles.label}>
                                                    Cummulative Daily Limit
                                                </span>
                                            </InputLabel>
                                            <Field
                                                as={TextField}
                                                helperText={
                                                    <ErrorMessage name='cummDailyLimit'>
                                                        {(msg) => (
                                                            <span style={{ color: 'red' }}>{msg}</span>
                                                        )}
                                                    </ErrorMessage>
                                                }
                                                name='cummDailyLimit'
                                                variant='outlined'
                                                margin='normal'
                                                type='text'
                                                size='small'
                                                fullWidth
                                                className={classes.select}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className={styles.customerInfoSingle}>
                                            <InputLabel>
                                                <span className={styles.label}>Frequency</span>
                                            </InputLabel>
                                            <Field
                                                as={TextField}
                                                helperText={
                                                    <ErrorMessage name='frequency'>
                                                        {(msg) => (
                                                            <span style={{ color: 'red' }}>{msg}</span>
                                                        )}
                                                    </ErrorMessage>
                                                }
                                                name='frequency'
                                                variant='outlined'
                                                margin='normal'
                                                type='text'
                                                size='small'
                                                fullWidth
                                                className={classes.select}
                                            />
                                        </div>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6}>
                                        <div className={styles.customerInfoSingle}>
                                            <InputLabel>
                                                <span className={styles.label}>Risk category</span>
                                            </InputLabel>
                                            <Field
                                                as={SelectWrapper}
                                                helperText={
                                                    <ErrorMessage name='riskCategory'>
                                                        {(msg) => (
                                                            <span style={{ color: 'red' }}>{msg}</span>
                                                        )}
                                                    </ErrorMessage>
                                                }
                                                name='riskCategory'
                                                variant='outlined'
                                                margin='normal'
                                                type='text'
                                                size='small'
                                                fullWidth
                                                options={category}
                                                className={classes.select}
                                                placeholder='risk'
                                                defaultValue={category && category[0]}
                                            />
                                        </div>
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <button type='submit' className={styles.Downloadbutton}>
                                            <span className={styles.Downloadbutton_span}>
                                                <img src={Mark} alt='' />
                                            </span>
                                            Submit New Limit
                                        </button>
                                    </Grid>{' '}
                                </Grid>
                            </Form>
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default UpdateLimitModal;
