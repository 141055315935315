import React from 'react';

export interface SettlementsFullData {
	businessName: string;
	destination: string;
	merchantId: string;
	id?: string;
	amount: string;
	transactionCount?: number;
	currency?: string;
	dateCreated?: string;
	date: string;
	status: React.ReactNode;
	_status?: string;
	// action: React.ReactNode;
}

export const ColumnSettlementsFull = [
	{
		Header: 'Business name',
		accessor: 'businessName',
	},
	{
		Header: 'Destination',
		accessor: 'destination',
	},
	{
		Header: 'Merchant ID',
		accessor: 'merchantId',
	},
	{
		Header: 'Amount due',
		accessor: 'amount',
	},
	{
		Header: 'Settlement date',
		accessor: 'date',
	},
	{
		Header: 'Transaction Count',
		accessor: 'transactionCount',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
	// {
	// 	Header: 'Action',
	// 	accessor: 'action',
	// },
];
