import React from 'react';
import styles from './Credit.module.scss';
import CreditTable from './CreditTable/CreditTable';



function Credit() {
	
	return (
		<div style={{ width: '100%' }}>
			
			<div>
				<CreditTable />
			</div>
		</div>
	);
}

export default Credit;
