import { useState } from 'react';
import styles from './OperationDetails.module.scss';
import { useDispatch } from 'react-redux';
import { Divider, Form, Input, InputNumber, Select, Switch } from "antd";
import client from "../../../../api/client";
import StatusView from '../../../../components/StatusView/StatusView';
import { closeLoader, openLoader } from '../../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../../redux/actions/toast/toastActions';
import { ToastErrorStyles, ToastSuccessStyles } from '../../../../constants/toastStyles';

interface detailProps {
	id: number;
	coreMerchant: any | undefined;
	merchantid: string;
	channel: string;
	paymentmethod: string;
	rail: string;
	scheme: string;
	bank: string;
	currency: string;
	mid: string;
	mccCode: string;
	username: string;
	password: string;
	url: string;
	createdat: string;
	status: string;
	useStatementDescriptor: string;
}

const OperationDetails = ({ closeModal, identifier, data, setIden, subsidiaryId, subsidiaryName }: any) => {
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [INITIALVALUES, setInputs] = useState({
		remarks: "",
	})

	const handleInputChange = (name: string, event: any) => {
		console.log({ event })
		setInputs({ ...INITIALVALUES, [name]: event })
	};
	const cancelHandler = () => {
		closeModal();
		setIden("");
	};

	const validateMessages = {
		required: "${label} is required!",
		types: {
			email: "${label} is not a valid email!",
			number: "${label} is not a valid number!",
		},
		number: {
			range: "${label} must be between ${min} and ${max}",
		},
	};

	const updatedHandler = (isApprove = true) => {
		const values = form.getFieldsValue()
		if (!values.remarks) return;
		values.action = isApprove ? "Approve" : "Reject"
		dispatch(openLoader());
		const path = `${data.id}/treat`;
		var method = client.post;
		method(`wallet/operation/${path}`, {
			...values,
		})
			.then((res: any) => {
				dispatch(closeLoader());
				dispatch(
					openToastAndSetContent({
						toastContent: res.data.message,
						toastStyles: ToastSuccessStyles,
					})
				);
				cancelHandler();
			})
			.catch((error: any) => {
				dispatch(closeLoader());
				const { message } = error?.response?.data;
				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			});
	};

	return (
		<div className={styles.transactionInfo}>
			<div className={styles.detailsHeader}>Balance Operation details</div>
			<div className={styles.tableContent}>
				<div className={styles.customerInfo}>
					<div className={styles.detailsValue}>Date Requested</div>
					<div className={styles.detailsKey}>{data?.dateCreated}</div>
				</div>
				<div className={styles.customerInfo}>
					<div className={styles.detailsValue}>Subsidiary Name</div>
					<div className={styles.detailsKey}>
						{data?.subsidiaryName}
					</div>
				</div>
				<div className={styles.customerInfo}>
					<div className={styles.detailsValue}>Transaction Amount</div>
					<div className={styles.detailsKey}>
						{data?.transactionAmount}
					</div>
				</div>
				<div className={styles.customerInfo}>
					<div className={styles.detailsValue}>Transaction Fee</div>
					<div className={styles.detailsKey}>
						{data?.fee}
					</div>
				</div>
				<div className={styles.customerInfo}>
					<div className={styles.detailsValue}>Direction (Debit or Credit)</div>
					<div className={styles.detailsKey}>
						{data?.direction}
					</div>
				</div>
				<div className={styles.customerInfo}>
					<div className={styles.detailsValue}>Status</div>
					<div className={styles.detailsKey}>
						<StatusView
							status={data?.operationStatus ?? "Initiated"}
							green='Successful'
							orange='Initiated'
						/>
					</div>
				</div>{' '}
				<Divider />
				{data?.operationStatus == "Initiated" && <Form
					form={form}
					initialValues={INITIALVALUES}
					layout="vertical"
					name="nest-messages"
					validateMessages={validateMessages}
				>
					<div className={styles.flexInput}>
						<Form.Item
							style={{ flex: 1, marginRight: "16px" }}
							label="Remarks"
							name="remarks"
							rules={[
								{
									required: true,
								},
							]}
						>
							<Input
								style={{ borderRadius: "7px", padding: "15px" }}
								className={styles.input}
								placeholder="remarks"
							/>
						</Form.Item>
					</div>
					<div style={{ marginTop: '30px' }} className={styles.chargeBack}>
						<button
							onClick={(values) => { updatedHandler(true) }}
							style={{ marginBottom: '10px' }}
							className={styles.chargeBackbutton}>
							<div>
								{/* <img src={ChargebackIcon} alt='chargeback' /> */}
							</div>
							<div className={styles.ml}>Approve Operation</div>
						</button>
						<button
							onClick={(values) => { updatedHandler(false) }}
							style={{ marginBottom: '10px' }}
							className={styles.chargeBackbuttonReject}>
							<div>
								{/* <img src={TimesIcon} alt='chargeback' /> */}
							</div>
							<div className={styles.ml}>Reject Operation</div>
						</button>
					</div>
				</Form>}
			</div>
		</div>
	);
};

export default OperationDetails;
