import React from "react";
import styles from "./Settlement.module.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch, useSelector } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import SelectDropdown from "../../input/SelectDropdown";
import Select from "react-select";

const isAlphabets = (str: string) => /^[a-zA-Z ]*$/.test(str);
const SettlementgroupsModal = ({
  closeModal,
  fetchSettlementGroups,
  currencies,
  subsidiaries,
  settlementPlans,
}: any) => {
  const dispatch = useDispatch();

  interface initTypes {
    name: string;
  }

  const initProps: initTypes = {
    name: "",
  };
  const [inputs, setInputs] = React.useState(initProps);
  const [selectedCurrency, setSelectedCurrency] = React.useState<any>();
  const [errorText, setErrorText] = React.useState("");
  const [touchedName, setTouchedName] = React.useState(false);

  let currencyOptions = currencies?.map(function (currency: any) {
    return { value: currency.id, label: currency.name };
  });

  const selectedCurrencyId = selectedCurrency?.map((item: any) => item.value);

  const [selectedSubsidiary, setSelectedSubsidiary] = React.useState<any>();
  let subsidiaryOptions = subsidiaries?.map(function (subsidiary: any) {
    return { value: subsidiary.id, label: subsidiary.name };
  });

  const selectedSubsidiaryId = selectedSubsidiary?.map(
    (item: any) => item.value
  );

  const [selectedSettlementCycle, setSelectedSettlementCycle] =
    React.useState<any>();
  let settlementCycleOptions = settlementPlans?.map(function (
    settlementPlan: any
  ) {
    return { value: settlementPlan.id, label: settlementPlan.name };
  });

  const selectedSettlementCycleId = selectedSettlementCycle?.value;

  const disableFunction = () => {
    if (
      inputs.name &&
      selectedSettlementCycleId &&
      selectedSubsidiaryId &&
      selectedCurrencyId
    ) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    if (isAlphabets(newValue)) {
      setErrorText("");
      setInputs((prevState: initTypes) => {
        return {
          ...prevState,
          [inputName]: newValue,
        };
      });
    } else {
      setErrorText("Please input alphabets only");
    }
  };

  const createPaymentLink = async () => {
    try {
      const data: any = await client.post("settlement/group/create", {
        ...inputs,
        settlement_cycle_id: selectedSettlementCycleId,
        currencies: selectedCurrencyId,
        subsidiaries: selectedSubsidiaryId,
      });
      const message = data?.data?.message;
      fetchSettlementGroups();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const handleTouchName = () => {
    setTouchedName(true);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (inputs.name.length >= 3) {
      createPaymentLink();
    } else {
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: "Please input at least 3 alphabets",
        })
      );
    }
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>New Settlement Group</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="name"
            inputLabel="Group name"
            inputValue={inputs.name}
            onFocus={handleTouchName}
            InputHelper={touchedName && errorText}
            error={touchedName && errorText}
          />

          <div>
            <label className={styles.inputLabel}>Add Business</label>
            <Select
              defaultValue={selectedSubsidiary}
              onChange={setSelectedSubsidiary}
              options={subsidiaryOptions}
              isMulti
              // styles={customStyles}
              className={styles.select}
            />
          </div>
          <div>
            <label className={styles.inputLabel}>Currency</label>
            <Select
              defaultValue={selectedCurrency}
              onChange={setSelectedCurrency}
              options={currencyOptions}
              isMulti
              // styles={customStyles}
              className={styles.select}
            />
          </div>

          <div>
            <label className={styles.inputLabel}>Settlement cycle</label>
            <Select
              defaultValue={selectedSettlementCycle}
              onChange={setSelectedSettlementCycle}
              options={settlementCycleOptions}
              // styles={customStyles}
              className={styles.select}
            />
          </div>
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Create
          </div>
        ) : (
          <div className={styles.notClickable}>Create </div>
        )}
      </div>
    </div>
  );
};

export default SettlementgroupsModal;
