import React, { useState, Fragment, useEffect } from 'react';
import styles from './Settlements.module.scss';
import PaginationTable from '../../../components/table/pagination-table';
import { ColumnRefund, RefundData } from '../../../types/TablesTypes/Refund';
import StatusView from '../../../components/StatusView/StatusView';
import TableHeader from '../../../components/TableHeader/TableHeader';
import TransactionsMenu from '../../../components/MenuOption/TransactionsMenu/TransactionsMenu';
import transactionsApi from '../../../api/transactions';
import useApi from '../../../hooks/useApi';
import client from '../../../api/client';
import { numberWithCommas } from '../../../utils/formatNumber';
import moment from 'moment';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	AppstoreOutlined,
	MailOutlined,
	SettingOutlined,
} from '@ant-design/icons';
import Filtered from '../../../components/filterComponent/Filtered';
import { menuContent } from '../../../utils/menuContent';
import Mocked from './refund.json';
import RefundsMenu from '../../../components/MenuOption/RefundsMenu/RefundsMenu';
import { Menu, Dropdown as MenuFunc } from 'antd';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import Single from './Single';
import Bulk from './Bulk';

const SettlementTransaction = () => {
	const dispatch = useDispatch();
	const [status, setStatus] = useState<string>('');
	const [value, setValue] = useState('');
	const [tableRow, setTableRow] = useState<any[]>();
	const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
	const [modalIsOpenBulk, setIsOpenBulk] = React.useState(false);
	const [refund, setRefund] = useState<any>(Mocked);

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//for filtering
	const [valueChannel, setValueChannel] = useState('');
	const [valueStatus, setValueStatus] = useState('');
	const [dropdown, setDropdown] = useState(false);

	const getTransactionsApi = useApi(transactionsApi.getTransactions);

	// const fetchTransactions = async () => {
	// 	dispatch(openLoader());
	// 	try {
	// 		const { data } = await client.get(
	// 			`/subsidiary/dashboard/fetch/orders?status=${status}`
	// 		);
	// 		setTransactions(data);
	// 		dispatch(closeLoader());
	// 	} catch (error: any) {
	// 		dispatch(closeLoader());
	// 		if (error?.response?.data?.message === 'Please login again') {
	// 			sessionStorage.clear();
	// 			window.location.href = '/';
	// 		} else {
	// 			const message = error?.response?.data?.message;

	// 			dispatch(
	// 				openToastAndSetContent({
	// 					toastContent: message,
	// 					toastStyles: ToastErrorStyles,
	// 				})
	// 			);
	// 		}
	// 	}
	// };

	// useEffect(() => {
	// 	fetchTransactions();
	// }, [status]);

	const dataTransaction = () => {
		const tempArr: RefundData[] = [];
		refund
			?.slice(0)
			.reverse()
			.forEach((transaction: any, index: number) => {
				tempArr.push({
					amount: `NGN ${numberWithCommas(transaction?.amount)}`,
					merchantId: transaction.merchantId,
					amountCharged: transaction.amountCharged,
					businessName: transaction.businessName,
					transactionReference: transaction?.transactionReference,
					date: transaction?.date,
					status: (
						<StatusView
							status={transaction?.status}
							green='Completed'
							red='Not Completed'
							orange='Pending'
						/>
					),
					// action: <RefundsMenu customerId={transaction.customer_id} />,
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransaction());
	}, [refund]);

	const itemLength = refund?.length;

	return (
		<div className={styles.dashbordContainer}>
			{getTransactionsApi.loading ? (
				<div className={styles.loading} />
			) : (
				<PaginationTable
					data={tableRow ? tableRow : []}
					columns={ColumnRefund ? ColumnRefund : []}
					value={value}
					emptyPlaceHolder={
						itemLength == 0
							? 'You currently do not have any data'
							: 'Loading...'
					}
				/>
			)}
		</div>
	);
};

export default SettlementTransaction;
