import React, { useState } from 'react';
import Business from '../Business';
import Settlements from '../Settlements';
import Subsidiaries from '../Subsidiaries';
import styles from './AccountTab.module.scss';

function AccountTab() {
	interface dataTypes {
		id: Number;
		menu: String;
	}
	const [selectedNav, setSelectedNav] = useState<Number>(1);
	const dataRef: dataTypes[] = [
		{
			id: 1,
			menu: 'Businesses',
		},
		// {
		// 	id: 2,
		// 	menu: 'Subsidiaries',
		// },
		// {
		// 	id: 3,
		// 	menu: 'Settlements',
		// },
	];
	return (
		<>
			<div className={styles.tabContainer}>
				{dataRef.map(({ id, menu }: dataTypes, i) => (
					<div
						key={i}
						onClick={() => {
							setSelectedNav(id);
						}}
						className={
							selectedNav === id ? styles.activeMenuItem : styles.subMenuItem
						}>
						{selectedNav === id ? (
							<div className={styles.activeItemColor}>{menu}</div>
						) : (
							<div style={{ color: 'rgba(10, 9, 3, 0.6)' }}>{menu}</div>
						)}
					</div>
				))}
			</div>

			<div>
				{selectedNav === 1 ? (
					<Business />
				) : selectedNav === 2 ? (
					<Subsidiaries />
				) : selectedNav === 3 ? (
					<Settlements />
				) : (
					''
				)}
			</div>
		</>
	);
}

export default AccountTab;
