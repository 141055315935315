import React, { useState, ReactNode, Fragment, useEffect } from "react";
import styles from "./Settlementcycle.module.scss";
import { ReactComponent as MenuIcon } from "../../../assets/images/Icons/menu.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ReactComponent as LinkIcon } from "../../../assets/images/Icons/link-icon.svg";
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import EditIcon from "../../../assets/images/Icons/edit-icon.svg";
import CopyIcon from "../../../assets/images/Icons/copy.svg";
import LinkOffIcon from "../../../assets/images/Icons/link-off.svg";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import Menus from "../Menus/Menus";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../constants/toastStyles";
import PaymentModal from "../../ModalContent/PaymentModal/PaymentModal";
import OutlineTextInput from "../../input/OutlineTextInput";

type MenuProps = {
  menuContent: string;
};
const isAlphabets = (str: string) => /^[a-zA-Z ]*$/.test(str);
const isNumbers = (str: string) => /^[0-9]*$/.test(str);
const SettlementcycleMenu = ({
  settlementData,
  fetchSettlementCycles,
}: any) => {
  const dispatch = useDispatch();
  const [referenceData, setReferenceData] = useState<any>();
  const [menuOption, setMenuOption] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenDetails, setIsOpenDetails] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const closeModalDetails = () => {
    setIsOpenDetails(false);
  };
  const handleClickAway = () => {
    setMenuOption(false);
  };
  const handleMenu = () => {
    setMenuOption(!menuOption);
  };

  const deleteCycle = async () => {
    try {
      const data: any = await client.delete(
        `settlement/plan/delete/${settlementData?.id}`
      );
      const message = data?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };
  const handleStatus = (e: React.SyntheticEvent) => {
    e.preventDefault();
    closeModal();
    deleteCycle();
    fetchSettlementCycles();
  };

  interface initTypes {
    settlement_plan_id: number;
    name: string;
    description: string;
    full_payment_days: number;
  }

  const initProps: initTypes = {
    settlement_plan_id: settlementData?.id,
    name: settlementData?.name,
    description: settlementData?.description,
    full_payment_days: settlementData?.no_of_days,
  };
  const [inputs, setInputs] = React.useState(initProps);
  const [errorText, setErrorText] = React.useState("");
  const [touchedName, setTouchedName] = React.useState(false);
  const [touchedNumber, setTouchNumber] = React.useState(false);
  const [touchedDescription, setTouchDescription] = React.useState(false);

  const disableFunction = () => {
    if (
      inputs.name.length >= 3 &&
      inputs.full_payment_days &&
      inputs.description.length >= 3
    ) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;

    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const updatePropsNumbers = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;

    if (isNumbers(newValue)) {
      setErrorText("");
      setInputs((prevState: initTypes) => {
        return {
          ...prevState,
          [inputName]: newValue,
        };
      });
    } else {
      setErrorText("Please input numbers only");
    }
  };

  const updateSettlement = async () => {
    try {
      const data: any = await client.post("settlement/plan/edit", inputs);
      const message = data?.data?.message;
      fetchSettlementCycles();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const handleTouchName = () => {
    setTouchNumber(false);
    setTouchDescription(false);
    setTouchedName(true);
  };

  const handeTouchNumber = () => {
    setTouchedName(false);
    setTouchDescription(false);
    setTouchNumber(true);
  };

  const handleTouchDescription = () => {
    setTouchedName(false);
    setTouchNumber(false);
    setTouchDescription(true);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    closeModalDetails();
    if (
      isAlphabets(inputs.name) == false ||
      isAlphabets(inputs.description) == false
    ) {
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: "Please input only alphabets",
        })
      );
    } else if (inputs.name.length >= 3 && inputs.description.length >= 3) {
      updateSettlement();
    } else {
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: "Please input at least 3 alphabets",
        })
      );
    }
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.menuBody}>
          <div className={styles.menuIcon} onClick={handleMenu}>
            <MenuIcon />
          </div>

          {menuOption && (
            <div className={styles.menuContent} onClick={handleClickAway}>
              <Menus
                icon={EditIcon}
                optionName="Edit Cycle"
                onclick={() => setIsOpenDetails(true)}
              />
              <Menus
                icon={LinkOffIcon}
                optionName="Delete Cycle"
                onclick={() => setIsOpen(true)}
              />
            </div>
          )}
        </div>
      </ClickAwayListener>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
        // className={styles.modal}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalHeader}>
            <div className={styles.background}>
              <div className={styles.linkDetails}>
                Delete settlement cycle link
              </div>
              <CloseIcon onClick={closeModal} />
            </div>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.confirmation}>
              Are you sure you want to delete this cycle?
            </div>
            <div className={styles.link}>
              All merchants with the settlement cycle will be set to a default
              30 days settlement cycle.
            </div>
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.cancel} onClick={closeModal}>
              No, cancel
            </div>
            <div
              className={styles.deactivate}
              onClick={(e) => {
                handleStatus(e);
              }}
            >
              Yes, delete cycle
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenDetails}
        onRequestClose={closeModalDetails}
        contentLabel="Example Modal"
        style={customStyles}
        // className={styles.modal}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalHeader}>
            <div className={styles.background}>
              <div className={styles.linkDetails}>Edit Settlement Cycle</div>
              <CloseIcon
                onClick={closeModalDetails}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div className={styles.businessForm}>
            <form onSubmit={handleSubmit} className={styles.form}>
              <OutlineTextInput
                handleChange={updateProps}
                inputName="name"
                inputLabel="Cycle name"
                inputValue={inputs.name}
                onFocus={handleTouchName}
                InputHelper={touchedName && errorText}
                error={touchedName && errorText}
              />
              <OutlineTextInput
                handleChange={updatePropsNumbers}
                inputName="full_payment_days"
                inputLabel="Number of Days"
                inputValue={inputs.full_payment_days}
                onFocus={handeTouchNumber}
                InputHelper={touchedNumber && errorText}
                error={touchedNumber && errorText}
              />
              <OutlineTextInput
                handleChange={updateProps}
                inputName="description"
                inputLabel="Description"
                inputValue={inputs.description}
                onFocus={handleTouchDescription}
                InputHelper={touchedNumber && errorText}
                error={touchedNumber && errorText}
                multiline
              />
            </form>
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.cancel} onClick={closeModalDetails}>
              Cancel
            </div>
            {disableFunction() ? (
              <div className={styles.deactivate} onClick={handleSubmit}>
                Save changes
              </div>
            ) : (
              <div className={styles.notClickable}>Save changes</div>
            )}
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default SettlementcycleMenu;
