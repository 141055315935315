export const combinedMenu = [
  {
    id: 1,
    menuName: "Home",
    url: "/dashboard",
    submenu: [
      {
        subId: "DS",
        sub: "Dashboard",
        subUrl: "/dashboard",
      },
      {
        subId: "DK",
        sub: "KYC Requests",
        subUrl: "/dashboard/compliance",
      },
    ],
  },
  {
    id: 2,
    menuName: "Transactions",
    url: "/dashboard/transactions",
    submenu: [
      {
        subId: "TA",
        sub: "All Transactions",
        subUrl: "/dashboard/transactions",
      },
      {
        subId: "TC",
        sub: "Customers",
        subUrl: "/dashboard/transactions/customers",
      },
      {
        subId: "TD",
        sub: "Chargebacks",
        subUrl: "/dashboard/transactions/chargebacks",
      },
    ],
  },
  {
    id: 3,
    menuName: "Accounts",
    url: "/dashboard/accounts",
    submenu: [
      {
        subId: "AB",
        sub: "Businesses",
        subUrl: "/dashboard/accounts",
      },
      {
        subId: "AS",
        sub: "Subsidiaries",
        subUrl: "/dashboard/accounts/subsidiaries",
      },
      {
        subId: "ASE",
        sub: "Settlements",
        subUrl: "/dashboard/accounts/settlements",
      },
    ],
  },
  {
    id: 4,
    menuName: "Settings",
    url: "/dashboard/settings",
    submenu: [
      {
        subId: "SU",
        sub: "Users/Admins",
        subUrl: "/dashboard/settings",
      },
      {
        subId: "SR",
        sub: "Roles & Permissions",
        subUrl: "/dashboard/settings/roles",
      },
      {
        subId: "SSC",
        sub: "Settlement Cycles",
        subUrl: "/dashboard/settings/settlement-cycle",
      },
      {
        subId: "SSG",
        sub: "Settlement Groups",
        subUrl: "/dashboard/settings/settlement-groups",
      },
      {
        subId: "SN",
        sub: "Notifications",
        subUrl: "/dashboard/settings/notifications",
      },
      {
        subId: "SP",
        sub: "Profile",
        subUrl: "/dashboard/settings/profile",
      },
    ],
  },
];
