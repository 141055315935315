import React, { useEffect } from 'react';
import styles from './Settlement.module.scss';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { settlementCycle } from '../../../utils/settlementCycle';
import { ReactComponent as MenuIcon } from '../../../assets/images/Icons/menu.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/images/Icons/calendar.svg';
import { ReactComponent as SettlementIcon } from '../../../assets/images/Icons/settlement-cycle-white.svg';
import settlementApi from '../../../api/settlementCycle';
import useApi from '../../../hooks/useApi';
import { customStyles } from '../../../constants/modalStyles';
import CreateRole from '../../../components/ModalContent/Role/CreateRole';
import Modal from 'react-modal';
import SettlementcycleModal from '../../../components/ModalContent/SettlementcycleModal/SettlementcycleModal';
import SettlementcycleMenu from '../../../components/MenuOption/SettlementcycleMenu/SettlementcycleMenu';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12)',
	borderRadius: '12px',
	padding: theme.spacing(2),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const settlementLength = Object.keys(settlementCycle).length;

const SettlementCycle = () => {
	const getSettlementCyclesApi = useApi(settlementApi.getSettlementCycles);

	const fetchSettlementCycles = async () => {
		getSettlementCyclesApi.request();
	};

	const settlementPlans = getSettlementCyclesApi?.data?.settlementPlans;

	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}
	useEffect(() => {
		fetchSettlementCycles();
	}, []);

	return (
		<div className={styles.settlementBody}>
			<div className={styles.topSections}>
				<div className={styles.header}>Settlement cycles</div>
				<div className={styles.chargeBack} onClick={() => setIsOpen(true)}>
					<SettlementIcon />
					<span style={{ padding: '2px' }}>New settlement cycle</span>
				</div>
			</div>

			<div>
				<Box sx={{ flexGrow: 1, marginTop: '1rem' }}>
					<Grid container spacing={2}>
						{!!settlementPlans &&
							settlementPlans?.map((data: any, index: any) => {
								return (
									<Grid item md={6} xs={12} lg={4} key={index}>
										<div className={styles.card}>
											<div className={styles.sectionTop}>
												<div className={styles.sectionTopLeft}>
													<div className={styles.title}>
														<h6 className={styles.bold}>{data?.name}</h6>
														<div className={styles.description}>
															{data?.description}
														</div>
													</div>
												</div>
												<div className={styles.icon}>
													<SettlementcycleMenu
														settlementData={data}
														fetchSettlementCycles={fetchSettlementCycles}
													/>
												</div>
											</div>
											<div className={styles.sectionBottom}>
												<CalendarIcon />{' '}
												<span className={styles.text}>
													Settlement cycle : {data?.noOfDays}
												</span>
											</div>
										</div>
									</Grid>
								);
							})}
					</Grid>
				</Box>
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}>
				<SettlementcycleModal
					fetchSettlementCycles={fetchSettlementCycles}
					closeModal={closeModal}
				/>
			</Modal>
		</div>
	);
};

export default SettlementCycle;
