import React, { useState, ReactNode, Fragment, useEffect } from 'react';
import styles from './Settlementcycle.module.scss';
import { ReactComponent as MenuIcon } from '../../../assets/images/Icons/menu.svg';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ReactComponent as LinkIcon } from '../../../assets/images/Icons/link-icon.svg';
import ViewIcon from '../../../assets/images/Icons/view-details.svg';
import EditIcon from '../../../assets/images/Icons/edit-icon.svg';
import DeleteIcon from '../../../assets/images/Icons/delete.svg';
import CopyIcon from '../../../assets/images/Icons/copy.svg';
import LinkOffIcon from '../../../assets/images/Icons/link-off.svg';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import Menus from '../Menus/Menus';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import client from '../../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import PaymentModal from '../../ModalContent/PaymentModal/PaymentModal';
import OutlineTextInput from '../../input/OutlineTextInput';
import SelectDropdown from '../../input/SelectDropdown';
import currencyApi from '../../../api/currencies';
import subsidiaryApi from '../../../api/subsidiaries';
import useApi from '../../../hooks/useApi';
import Select from 'react-select';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';

type MenuProps = {
	menuContent: string;
};

// const currency = ["NGN", "USD", "KES", "GHS", "ZAR"];

// const settlement_cycle = ["1", "5", "10", "15"]
const isAlphabets = (str: string) => /^[a-zA-Z ]*$/.test(str);
const SettlementgroupMenu = ({
	id,
	settlementData,
	fetchSettlementGroups,
	currencies,
	selectedCurrency,
	settlementPlans,
}: any) => {
	const dispatch = useDispatch();
	const [referenceData, setReferenceData] = useState<any>();
	const [menuOption, setMenuOption] = useState(false);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [modalIsOpenDetails, setIsOpenDetails] = React.useState(false);
	const [errorText, setErrorText] = React.useState('');
	const [touchedName, setTouchedName] = React.useState(false);
	const getSubsidiariesApi = useApi(subsidiaryApi.getSubsidiaries);

	const fetchSubsidiaries = async () => {
		getSubsidiariesApi.request();
	};
	const subsidiaries = getSubsidiariesApi?.data?.items;

	useEffect(() => {
		fetchSubsidiaries();
	}, []);

	function closeModal() {
		setIsOpen(false);
	}
	const closeModalDetails = () => {
		setIsOpenDetails(false);
	};
	const handleClickAway = () => {
		setMenuOption(false);
	};
	const handleMenu = () => {
		setMenuOption(!menuOption);
	};

	console.log('selectedCurrency', selectedCurrency);

	const deleteGroup = async () => {
		dispatch(openLoader());
		try {
			const data: any = await client.delete(
				`settlement/group/delete/${settlementData?.id}`
			);
			const message = data?.data?.message;
			fetchSettlementGroups();
			dispatch(closeLoader());

			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			const message = error?.response?.data?.message;
			dispatch(closeLoader());

			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	const handleStatus = (e: React.SyntheticEvent) => {
		e.preventDefault();
		closeModal();
		deleteGroup();
		fetchSettlementGroups();
	};

	interface initTypes {
		settlement_group_id: number;
		name: string;
	}

	const initProps: initTypes = {
		settlement_group_id: settlementData?.id,
		name: settlementData?.name,
	};
	const [inputs, setInputs] = React.useState(initProps);

	const disableFunction = () => {
		if (inputs.name && inputs.settlement_group_id) {
			return true;
		}
	};

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		if (isAlphabets(newValue)) {
			setErrorText('');
			setInputs((prevState: initTypes) => {
				return {
					...prevState,
					[inputName]: newValue,
				};
			});
		} else {
			setErrorText('Please input alphabets only');
		}
	};

	const handleTouchName = () => {
		setTouchedName(true);
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (inputs.name.length >= 3 && isAlphabets(inputs.name)) {
			updateSettlement();
		} else if (isAlphabets(inputs.name) == false) {
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: 'Please input only alphabets',
				})
			);
		} else {
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: 'Please input at least 3 alphabets',
				})
			);
		}
	};
	const [selectedCurrencyOption, setSelectedCurrencyOption] =
		React.useState<any>();
	let currencyOptions = currencies?.map(function (currency: any) {
		return {
			value: currency.id,
			label: currency.short_name,
		};
	});

	const [selectedSubsidiary, setSelectedSubsidiary] = React.useState<any>();
	let subsidiaryOptions = subsidiaries?.map(function (subsidiary: any) {
		return { value: subsidiary.id, label: subsidiary.name };
	});

	console.log('currencyOptions', currencyOptions);

	const selectedCurrencyOptionId = selectedCurrencyOption?.map(
		(item: any) => item.label
	);

	const selectedCurrencyId = {
		...[selectedCurrency],
	};

	const settlementRes = Object.entries({
		...[settlementData?.settlement_cycle_name],
	}).map(([value, label], index) => ({
		value: value,
		label: label,
	}));

	const res = Object.entries(selectedCurrencyId).map(
		([value, label], shortName) => ({
			value: value,
			label: label,
			shortName: shortName,
		})
	);

	console.log('res', res);

	const [selectedSettlementCycle, setSelectedSettlementCycle] =
		React.useState<any>();
	let settlementCycleOptions = settlementPlans?.map(function (
		settlementPlan: any
	) {
		return { value: settlementPlan.id, label: settlementPlan.name };
	});

	const selectedSubsidiaryId = selectedSubsidiary?.map(
		(item: any) => item.value
	);

	const updateSettlement = async () => {
		dispatch(openLoader());
		try {
			const data: any = await client.post('settlement/group/update', {
				...inputs,
				currencies: selectedCurrencyOptionId,
				settlement_cycle_id: !!selectedSettlementCycle?.value
					? !!selectedSettlementCycle?.value
					: settlementData?.settlement_cycle_id,
				subsidiaries: selectedSubsidiaryId,
			});
			const message = data?.data?.message;
			fetchSettlementGroups();
			closeModalDetails();
			dispatch(closeLoader());

			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			const message = error?.response?.data?.message;
			dispatch(closeLoader());

			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	return (
		<Fragment>
			<ClickAwayListener onClickAway={handleClickAway}>
				<div className={styles.menuBody}>
					<div className={styles.menuIcon} onClick={handleMenu}>
						<MenuIcon />
					</div>

					{menuOption && (
						<div className={styles.menuContent} onClick={handleClickAway}>
							<Menus
								icon={EditIcon}
								optionName='Edit Group'
								onclick={() => setIsOpenDetails(true)}
							/>
							<Menus
								icon={DeleteIcon}
								optionName='Delete Group'
								onclick={() => setIsOpen(true)}
							/>
						</div>
					)}
				</div>
			</ClickAwayListener>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}
				// className={styles.modal}
			>
				<div className={styles.modalBody}>
					<div className={styles.modalHeader}>
						<div className={styles.background}>
							<div className={styles.linkDetails}>Delete settlement group</div>
							<CloseIcon onClick={closeModal} />
						</div>
					</div>
					<div className={styles.modalContent}>
						<div className={styles.confirmation}>
							Are you sure you want to delete this group?
						</div>
						<div className={styles.link}>
							All merchants with the settlement group will be set to a default
							30 days settlement cycle.
						</div>
					</div>
					<div className={styles.modalFooter}>
						<div className={styles.cancel} onClick={closeModal}>
							No, cancel
						</div>
						<div
							className={styles.deactivate}
							onClick={(e) => {
								handleStatus(e);
							}}>
							Yes, delete group
						</div>
					</div>
				</div>
			</Modal>

			<Modal
				isOpen={modalIsOpenDetails}
				onRequestClose={closeModalDetails}
				contentLabel='Example Modal'
				style={customStyles}>
				<div className={styles.modalBody}>
					<div className={styles.modalHeader}>
						<div className={styles.background}>
							<div className={styles.linkDetails}>Edit Settlement Group</div>
							<CloseIcon
								onClick={closeModalDetails}
								style={{ cursor: 'pointer' }}
							/>
						</div>
					</div>
					<div className={styles.businessForm}>
						<form onSubmit={handleSubmit} className={styles.form}>
							<OutlineTextInput
								handleChange={updateProps}
								inputName='name'
								inputLabel='Group name'
								inputValue={inputs.name}
								onFocus={handleTouchName}
								InputHelper={touchedName && errorText}
								error={touchedName && errorText}
							/>

							<div>
								<label className={styles.inputLabel}>Settlement Cycle</label>
								<Select
									defaultValue={settlementRes}
									onChange={setSelectedSettlementCycle}
									options={settlementCycleOptions}
									// styles={customStyles}
									className={styles.select}
								/>
							</div>

							<div>
								<label className={styles.inputLabel}>Add Business</label>
								<Select
									defaultValue={selectedSubsidiary}
									onChange={setSelectedSubsidiary}
									options={subsidiaryOptions}
									isMulti
									// styles={customStyles}
									className={styles.select}
								/>
							</div>
							<div>
								<label className={styles.inputLabel}>Currency</label>

								<Select
									defaultValue={res}
									onChange={setSelectedCurrencyOption}
									options={currencyOptions}
									isMulti
									// styles={customStyles}
									className={styles.select}
								/>
							</div>
						</form>
					</div>
					<div className={styles.modalFooter}>
						<div className={styles.cancel} onClick={closeModalDetails}>
							Cancel
						</div>
						{disableFunction() ? (
							<div className={styles.deactivate} onClick={updateSettlement}>
								Save changes
							</div>
						) : (
							<div className={styles.notClickable}> Save changes</div>
						)}
					</div>
				</div>
			</Modal>
		</Fragment>
	);
};

export default SettlementgroupMenu;
