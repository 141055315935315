import React from 'react';

export interface SalesData {
	volume: string;
	account: string;
	merchantName: string;
	merchantId: string;
	value: string;
}

export const ColumnSales = [
	{
		Header: 'Merchant ID',
		accessor: 'merchantId',
	},
	{
		Header: 'Merchant name',
		accessor: 'merchantName',
	},
	{
		Header: 'Volume',
		accessor: 'volume',
	},
	{
		Header: 'Value',
		accessor: 'value',
	},
	{
		Header: 'Bank',
		accessor: 'account',
	},
];
