import React, { Fragment, useState, useEffect } from 'react';
import styles from './Business.module.scss';
import PaginationTable from '../../components/table/pagination-table';
import Grid from '@material-ui/core/Grid';
import Card from '../../components/Card/Card';
import Chart from '../../components/Chart/ChartInflowOutflow';
import TimerIcon from '../../assets/images/Icons/timer.svg';
import TableHeader from '../../components/TableHeader/TableHeader';
import SettlementIcon from '../../assets/images/Icons/settlement-icon.svg';
import BalanceIcon from '../../assets/images/Icons/balance-card-icon.svg';
import ChargebacksIcon from '../../assets/images/Icons/chargeback.svg';
import VirtualWalletIcon from '../../assets/images/Icons/virtual-wallets.svg';
import CardIcon from '../../assets/images/Icons/card-icon.svg';
import InfoIcon from '../../assets/images/Icons/info-icon.svg';
import client from '../../api/client';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../constants/toastStyles';
import Modal from 'react-modal';
import { customStyles } from '../../constants/modalStyles';
import CreateCustomer from '../../components/ModalContent/CreateCustomer/CreateCustomer';
import {
	ColumnTransactions,
	TransactionsData,
} from '../../types/TablesTypes/Transactions';
import {
	SubsidiariesData,
	ColumnSubsidiaries,
} from '../../types/TablesTypes/Subsidiaries';
import {
	CustomersData,
	ColumnCustomers,
} from '../../types/TablesTypes/Customers';
import StatusView from '../../components/StatusView/StatusView';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
	closeLoader,
	openLoader,
} from '../../redux/actions/loader/loaderActions';
import useApi from '../../hooks/useApi';
import transactionsApi from '../../api/transactions';
import subsidiariesApi from '../../api/subsidiaries';
import customersApi from '../../api/customers';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import inflowOutflowApi from '../../api/inflow';
import { numberWithCommas } from '../../utils/formatNumber';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ReactComponent as LinkIcon } from '../../assets/images/Icons/link-icon.svg';
import Trans from './Trans';
import Subs from './Subs';
import Cust from './Cust';

const tableStatus = [
	{ id: 1, name: 'Initiated' },
	{ id: 2, name: 'Pending-Verification' },
	{ id: 3, name: 'Successful' },
];

const tableBusinessType = [
	{ id: 1, name: 'Individual' },
	{ id: 2, name: 'Company' },
];

const tableCountry = [
	{ id: 1, name: 'Nigeria' },
	{ id: 2, name: 'Kenya' },
	{ id: 3, name: 'Ghana' },
	{ id: 3, name: 'South Africa' },
];

interface idTypes {
	id: number;
	setId: React.Dispatch<React.SetStateAction<number | null>>;
	name: string;
}

const SubsidiariesDetails = ({ id, setId, name }: idTypes) => {
	const [value, setValue] = useState('');
	const [status, setStatus] = useState<string>('');
	const [businessType, setBusinessType] = useState<string>('');
	const [country, setCountry] = useState<string>('');
	const [subsidiariesDetails, setSubsidiariesDetails] = useState<any>([]);
	const [customerDetails, setCustomerDetails] = useState<any>([]);
	const [transactionsDetails, setTransactionDetails] = useState<any>([]);
	const [transactionSummaryDetail, settransactionSummaryDetail] = useState<any>(
		[]
	);
	const [balancesChargebacks, setbalancesChargebacks] = useState<any>([]);
	const [tableRow, setTableRow] = useState<any[]>();
	const [tableSubsidiaries, setTableSubsidiaries] = useState<any[]>();
	const [tableCustomers, setTableCustomers] = useState<any[]>();
	const gettransactionsApi = useApi(transactionsApi.getTransactions);
	const getSubsidiariesApi = useApi(subsidiariesApi.getSubsidiaries);
	const getCustomersApi = useApi(customersApi.getCustomers);

	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	const [filterParam, setFilterParam] = useState<any>('year');
	const [inflowOutflowData, setInflowOutflowData] = useState<any>();

	const fetchInflow = async () => {
		const { data }: any = await client.get(
			`metric/inflow/outflow?type=${filterParam}&subsidiaryid=${id}`
		);
		setInflowOutflowData(data?.data);
	};

	useEffect(() => {
		fetchInflow();
	}, [filterParam]); 

	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}

	useEffect(() => {
		dispatch(openLoader());
		setIsLoading(true);
		client
			.get(`metric/transaction/summary?subsidiaryid=${id}`)
			.then((res: any) => {
				settransactionSummaryDetail(res?.data?.data);
				setIsLoading(false);
				dispatch(closeLoader());
			})
			.catch((error) => {
				dispatch(closeLoader());
				setIsLoading(false);
				if (error.response) {
					const message = error?.response?.data?.message;
					dispatch(
						openToastAndSetContent({
							toastStyles: ToastErrorStyles,
							toastContent: message,
						})
					);
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			});
	}, []);

	useEffect(() => {
		dispatch(openLoader());
		setIsLoading(true);
		client
			.get(`metric/balances/chargebacks?subsidiaryid=${id}`)
			.then((res: any) => {
				setbalancesChargebacks(res?.data?.data);
				setIsLoading(false);
				dispatch(closeLoader());
			})
			.catch((error) => {
				dispatch(closeLoader());
				setIsLoading(false);
				if (error.response) {
					const message = error?.response?.data?.message;
					dispatch(
						openToastAndSetContent({
							toastStyles: ToastErrorStyles,
							toastContent: message,
						})
					);
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			});
	}, []);

	const count = transactionSummaryDetail?.count;
	const volume = transactionSummaryDetail?.volume;
	const settlements = transactionSummaryDetail?.settlements;
	const balance = balancesChargebacks?.availableBalance;
	const ledger_balance = balancesChargebacks?.ledgerBalance;
	const chargebacks = balancesChargebacks.chargebacks;
	const balance_change =
		balancesChargebacks.lastWeekAvailableBalancePercentChange;
	const ledger_balance_change =
		balancesChargebacks.lastWeekLedgerBalancePercentChange;
	const chargebacks_change =
		balancesChargebacks.lastWeekChargebacksPercentChange;
	const settlement_change =
		transactionSummaryDetail?.lastWeekSettlementsPercentChange;
	const volume_change =
		transactionSummaryDetail?.lastWeekVolumePercentChange;
	const count_change = transactionSummaryDetail?.lastWeekCountPercentChange;

	useEffect(() => {
		dispatch(openLoader());
		setIsLoading(true);
		client
			.get(`order/all/paginated?customerid=${id}`)
			.then((res: any) => {
				// console.log("paginated", res?.data);
				setTransactionDetails(res?.data);
				setIsLoading(false);
				dispatch(closeLoader());
			})
			.catch((error) => {
				dispatch(closeLoader());
				setIsLoading(false);
				if (error.response) {
					const message = error?.response?.data?.message;
					dispatch(
						openToastAndSetContent({
							toastStyles: ToastErrorStyles,
							toastContent: message,
						})
					);
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			});
	}, []);

	useEffect(() => {
		dispatch(openLoader());
		setIsLoading(true);
		client
			.get(`order/all/paginated?subsidiaryid=${id}`)
			.then((res: any) => {
				// console.log("paginated", res?.data);
				setTransactionDetails(res?.data);
				setIsLoading(false);
				dispatch(closeLoader());
			})
			.catch((error) => {
				dispatch(closeLoader());
				setIsLoading(false);
				if (error.response) {
					const message = error?.response?.data?.message;
					dispatch(
						openToastAndSetContent({
							toastStyles: ToastErrorStyles,
							toastContent: message,
						})
					);
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			});
	}, []);

	useEffect(() => {
		dispatch(openLoader());
		setIsLoading(true);
		client
			.get(`subsidiary/all/paginated?id=${id}`)
			.then((res: any) => {
				// console.log("paginated", res?.data);
				setSubsidiariesDetails(res?.data);
				setIsLoading(false);
				dispatch(closeLoader());
			})
			.catch((error) => {
				dispatch(closeLoader());
				setIsLoading(false);
				if (error.response) {
					const message = error?.response?.data?.message;
					dispatch(
						openToastAndSetContent({
							toastStyles: ToastErrorStyles,
							toastContent: message,
						})
					);
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			});
	}, []);

	useEffect(() => {
		dispatch(openLoader());
		setIsLoading(true);
		client
			.get(`customer/all/paginated?id=${id}`)
			.then((res: any) => {
				// console.log("paginated", res?.data);
				setCustomerDetails(res?.data);
				setIsLoading(false);
				dispatch(closeLoader());
			})
			.catch((error) => {
				dispatch(closeLoader());
				setIsLoading(false);
				if (error.response) {
					const message = error?.response?.data?.message;
					dispatch(
						openToastAndSetContent({
							toastStyles: ToastErrorStyles,
							toastContent: message,
						})
					);
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			});
	}, []);

	const fetchtransactions = async () => {
		dataTransactions();
	};

	useEffect(() => {
		fetchtransactions();
	}, []);

	const dataTransactions = () => {
		const tempArr: any[] = [];
		transactionsDetails?.items
			?.slice(0)
			.reverse()
			.forEach((transactions: any, index: number) => {
				return tempArr.push({
					date: moment(transactions?.dateCreated).format(
						'MMMM Do YYYY, h:mm a'
					),
					merchantId: transactions?.customerId,
					merchantName: transactions?.customerName,
					email: transactions?.customerEmail,
					transactionAmount: `${transactions?.currency} ${numberWithCommas(
						transactions?.amount
					)}`,
					payment_channel: transactions?.paymentType,
					status: (
						<StatusView
							status={transactions.orderStatus}
							green='Successful'
							red='Pending-Verification'
							orange='Initiated'
						/>
					),
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransactions);
	}, [transactionsDetails?.items]);

	const fetchSubsidiaries = async () => {
		dataSubsidiaries();
	};

	useEffect(() => {
		fetchSubsidiaries();
	}, []);

	const dataSubsidiaries = () => {
		const tempArr: any[] = [];
		subsidiariesDetails?.items
			?.slice(0)
			.reverse()
			.forEach((subsidiaries: any, index: number) => {
				return tempArr.push({
					businessName: subsidiaries?.businessName,
					email: subsidiaries?.email,
					businessType: subsidiaries?.businessType,
					country: subsidiaries?.country,
					users: subsidiaries?.userCount,
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableSubsidiaries(dataSubsidiaries);
	}, [subsidiariesDetails?.items]);

	const fetchCustomers = async () => {
		dataCustomers();
	};

	const dataCustomers = () => {
		const tempArr: any[] = [];
		customerDetails?.items
			?.slice(0)
			.reverse()
			.forEach((customers: any, index: number) => {
				return tempArr.push({
					customerName: customers?.customerName,
					emailAddress: customers?.emailAddress,
					mobileNumber: customers?.mobileNumber,
					country: customers?.countryName,
					transactionCount: customers?.transactionCount,
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableCustomers(dataCustomers);
	}, [customerDetails?.items]);

	return (
		<div>
			{isLoading ? (
				<div />
			) : (
				<div className={styles.dashbordContainer}>
					<div className={styles.transactionsHeader}>
						<div
							style={{
								color: '#211F01',
								display: 'inline',
								cursor: 'pointer',
								fontWeight: '800px',
							}}
							onClick={() => setId(null)}>
							<ArrowBackIosIcon sx={{ fontSize: '20px' }} /> {name}
						</div>
						<div></div>
					</div>
					{transactionSummaryDetail.loading ? (
						<div className={styles.loading} />
					) : (
						<>
							<Grid container spacing={2}>
								<Grid item md={4} xs={12}>
									<Card
										icon={TimerIcon}
										percentage={count_change}
										amount={count}
										transactionType='Total transaction count'
									/>
								</Grid>
								<Grid item md={4} xs={12}>
									<Card
										icon={CardIcon}
										percentage={volume_change}
										amount={volume}
										transactionType='Total transaction balance'
									/>
								</Grid>
								<Grid item md={4} xs={12}>
									<Card
										icon={SettlementIcon}
										percentage={settlement_change}
										amount={settlements}
										transactionType='Total transaction settlements'
									/>
								</Grid>
								<Grid item md={4} xs={12}>
									<Card
										icon={BalanceIcon}
										percentage={balance_change}
										amount={balance}
										transactionType='Available balance'
										// info_icon={InfoIcon}
									/>
								</Grid>
								<Grid item md={4} xs={12}>
									<Card
										icon={CardIcon}
										percentage={ledger_balance_change}
										amount={ledger_balance}
										transactionType='Ledger balance'
									/>
								</Grid>
								<Grid item md={4} xs={12}>
									<Card
										icon={ChargebacksIcon}
										percentage={chargebacks_change}
										amount={chargebacks}
										transactionType='Chargebacks'
									/>
								</Grid>
								<Grid item xs={12}>
									<div className={styles.dashboardChart}>
										<Chart
											inflowOutflow={inflowOutflowData}
											filterParam={filterParam}
											setFilterParam={setFilterParam}
										/>
									</div>
								</Grid>
							</Grid>

							<div className={styles.mt1}>
								<Trans id={id} />
							</div>

							<div className={styles.mt1}>
								<Subs id={id} name={name} />
							</div>

							<div className={styles.mt1}>
								<Cust id={id} name={name} />
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default SubsidiariesDetails;
