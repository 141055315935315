import React, { useState, useEffect } from 'react';
import styles from './Business.module.scss';
import PaginationTable from '../../components/table/pagination-table';
import {
	ColumnSettlements,
	SettlementsData,
} from '../../types/TablesTypes/Settlements';
import StatusView from '../../components/StatusView/StatusView';
import TableHeader from '../../components/TableHeader/TableHeader';
import ViewIcon from '../../assets/images/Icons/view-details.svg';
import DeactivateIcon from '../../assets/images/Icons/deactivate-user.svg';
import settlementsApi from '../../api/settlements';
import useApi from '../../hooks/useApi';
import client from '../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import { numberWithCommas } from '../../utils/formatNumber';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../constants/toastStyles';
import Modal from 'react-modal';
import { customStyles } from '../../constants/modalStyles';
import CreateCustomer from '../../components/ModalContent/CreateCustomer/CreateCustomer';
import SettlementMenu from '../../components/MenuOption/SettlementMenu/SettlementMenu';
import {
	closeLoader,
	openLoader,
} from '../../redux/actions/loader/loaderActions';
import FilterModal from '../../components/filterConfig/FilterModal';
import { Dayjs } from 'dayjs';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../utils/datefunction';
import FilterButtonNew from '../../components/FilterButtonNew/FilterButtonNew';

const statusArray = [
	{ name: "Completed", value: "Completed" },
	{ name: "Failed", value: "Failed" },
	{ name: "Pending", value: "Pending" },
];

const Settlements = () => {
	const [tableRow, setTableRow] = useState<any[]>();
	const [currency, setCurrency] = useState<string>('');
	const [settlements, setSettlements] = useState<any>();
	const getSettlementsApi = useApi(settlementsApi.getSettlements);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}
	const dispatch = useDispatch();

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [paymentreference, setPaymentreference] = useState('');
	const [transactionreference, setTransactionreference] = useState('');
	const [status, setStatus] = useState('');
	const [bearer, setBearer] = useState(false);
	const [subsidiaryname, setSubsidiaryname] = useState('');

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setPaymentreference('');
		setTransactionreference('');
		setSubsidiaryname('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
	};

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const filteredArray: {
		name: string;
		value: string | Dayjs | null;
		setValue: React.Dispatch<React.SetStateAction<any>>;
		selective?: { name: string; value: string }[];
		selectHelper?: boolean;
		type: "select" | "date" | "text";
	  }[]  = [
		{
			name: "Date Range",
			value: fromDate,
			setValue: setFromDate,
			type: "date", // Explicitly set as "date"
		},
		{
			name: 'Settlement Status',
			value: status,
			setValue: setStatus,
			selective: statusArray,
			type: "select",
		},
		{
			name: 'Payment Reference',
			value: paymentreference,
			setValue: setPaymentreference,
			type: "text",
		},
		{
			name: 'Transaction Reference',
			value: transactionreference,
			setValue: setTransactionreference,
			type:'text'
		},
	];

	const fetchSettlements = async () => {
		dispatch(openLoader());
		try {
			const { data } = await client.get(
				`settlement/all?settlementstatus=${status}&subsidiaryname=${subsidiaryname}&paymentreference=${paymentreference}&fromdate=${fromDate}&todate=${toDate}&transactionreference=${transactionreference}&limit=${rowsPerPage}&page=${pageNumber}`
			);
			setSettlements(data);
			dispatch(closeLoader());
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error.response.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useEffect(() => {
		fetchSettlements();
	}, [bearer, subsidiaryname, pageNumber, rowsPerPage]);

	useEffect(() => {
		setPageNumber(settlements?.currentPage || 1);
		setPreviousPage(settlements?.previousPage);
		setNextPage(settlements?.nextPage);
	}, [settlements]);

	const menuOptions = [
		{
			id: 1,
			name: (status: string) => {
				return status === 'Active'
					? 'Blacklist Customer'
					: 'Whitelist Customer';
			},
			icon: DeactivateIcon,
			onclick: (
				customerEmail: string,
				e: React.SyntheticEvent,
				status: string
			) => {
				const payload = {
					customerEmail: customerEmail,
					action: status === 'Active' ? 'blacklist' : 'whitelist',
					reason: 'test',
				};
				e.preventDefault();
				client
					.post('/subsidiary/customer/blacklist-whitelist', payload)
					.then((data: any) => {
						fetchSettlements();
						const message = data?.data?.message;
						dispatch(
							openToastAndSetContent({
								toastStyles: ToastSuccessStyles,
								toastContent: message,
							})
						);
					})
					.catch((error: any) => {
						if (error.response) {
							const message = error?.response?.data?.message;
							dispatch(
								openToastAndSetContent({
									toastStyles: ToastErrorStyles,
									toastContent: message,
								})
							);
						} else if (error.request) {
							console.log(error.request);
						} else {
							console.log('Error', error.message);
						}
					});
			},
		},
		{
			id: 2,
			name: (id: number) => {
				return id ? 'View details' : null;
			},
			icon: ViewIcon,
			onclick: () => {},
		},
	];

	const dataSettlements = () => {
		const tempArr: SettlementsData[] = [];
		settlements?.items
			?.slice(0)
			.reverse()
			.forEach((settlement: any, index: number) => {
				return tempArr.push({
					businessName: settlement?.subsidiaryName,
					settlementAmount: `${settlement?.currency} ${numberWithCommas(
						settlement?.transactionAmount
					)}`,
					settlementFee: `${settlement?.currency} ${numberWithCommas(
						settlement?.fee
					)}`,
					currency: settlement?.currency,
					settlementCycle: settlement?.settlementCycle,
					status: (
						<StatusView
							status={settlement.settlementStatus}
							green='Completed'
							red={`${
								settlement.settlementStatus === null ? 'Failed' : 'N/A'
							}`}
							orange='Pending'
						/>
					),
					action: (
						<SettlementMenu
							subsidiaryId={settlement?.id}
							menuData={settlement}
						/>
					),
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataSettlements());
	}, [settlements?.items]);

	const itemLength = settlements?.items?.length;

	return (
		<div style={{ minHeight: '100vh' }}>
			<TableHeader
				pageName='Settlements'
				data={settlements?.items}
				dataLength={settlements?.totalItems}
				value={subsidiaryname}
				setValue={setSubsidiaryname}
				dropdown={dropdown}
				setDropdown={setDropdown}
				placeHolder='Search subsidiary name'
				FilterComponent={
					<FilterButtonNew
						eventDate={eventDate}
						setEventDate={setEventDate}
						dropdown={dropdown}
						setDropdown={setDropdown}
						setFromDate={setFromDate}
						setToDate={setToDate}
						fromDate={fromDate}
						toDate={toDate}
						setBearer={setBearer}
						clearHandler={clearHandler}
						clearAllHandler={clearHandler}
						filteredArray={filteredArray}
					/>
				}
			/>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}>
				<CreateCustomer
					fetchCustomers={fetchSettlements}
					closeModal={closeModal}
				/>
			</Modal>
			{getSettlementsApi.loading ? (
				<div className={styles.loading} />
			) : (
				<PaginationTable
					data={tableRow ? tableRow : []}
					columns={ColumnSettlements ? ColumnSettlements : []}
					value={value}
					emptyPlaceHolder={
						settlements?.totalItems == 0
							? 'You currently do not have any data'
							: 'Loading...'
					}
					total={settlements?.totalItems}
					totalPage={settlements?.totalPages}
					pageNumber={pageNumber}
					setPageNumber={setPageNumber}
					nextPage={nextPage}
					setNextPage={setNextPage}
					previousPage={previousPage}
					setPreviousPage={setPreviousPage}
					rowsPerPage={rowsPerPage}
					setRowsPerPage={setRowsPerPage}
				/>
			)}
		</div>
	);
};

export default Settlements;
