import React from 'react';

export interface ChargebackBreakdownData {
	acquirer: string;
	rrn: string;
	merchantName: string;
	amount: string;
	transactionReference: string;
}

export const ColumnChargebackBreakdown = [
	{
		Header: 'Acquirer',
		accessor: 'acquirer',
	},
	{
		Header: 'Merchant name',
		accessor: 'merchantName',
	},
	{
		Header: 'RRN',
		accessor: 'rrn',
	},
	{
		Header: 'Transaction reference',
		accessor: 'transactionReference',
	},
	{
		Header: 'Amount',
		accessor: 'amount',
	},
];
