import React, { useEffect, useRef, useState } from 'react';
import styles from './Settlements.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
import { ReactComponent as CopyIcon } from '../../../assets/images/Icons/u_copy.svg';
import Select from 'react-select';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import { Divider } from 'antd';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import client from '../../../api/client';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import downloady from '../../../assets/images/Icons/downloady.svg';
import { useHistory } from 'react-router-dom';
import MultipleUpload from '../../../components/multipleUpload/MultipleUpload';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';

function Bulk({ closeModal, fn }: any) {
	const [file, setFile] = useState<any[]>([]);
	const dispatch = useDispatch();

	const uploadMultipleFile = async () => {
		if (file && file?.length < 1) return window.alert('please, add a refund');

		dispatch(openLoader());
		try {
			const data: any = await client.post('settlement/log', {
				payout_requests: file,
			});

			const message = data?.data?.message;
			dispatch(closeLoader());

			closeModal();
			fn();
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			const message = error?.response?.data?.message;
			dispatch(closeLoader());

			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: 'something went wrong',
				})
			);
		}
	};
	return (
		<div className={styles.modalwrapper}>
			<div className={styles.modalhead}>
				<h3 className={styles.modalheadh3}>Log bulk settlement</h3>
				<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
			</div>
			<Divider style={{ margin: 0, padding: 0 }} />
			<div className={styles.modalcontent}>
				<MultipleUpload
					onDrop={(result: any) => {
						setFile(result);
					}}
				/>
				{/* <div className={styles.modalupload}>
					<img src={downloady} alt='' />

					<label
						style={{ cursor: 'pointer' }}
						onClick={() => ref.current.click()}>
						<button>Choose file to upload</button>
					</label>
					<input
						type='file'
						name='avatar'
						id='file'
						onChange={handleChange}
						ref={ref}
						hidden
					/>

					<p>Upload the refund CSV file to begin</p>
				</div> */}
				<p className={styles.modaluploadp}>
					Download sample CSV file for bulk settlement &nbsp;
					<span className={styles.sampleDownload}>
						<a
							href='https://docs.google.com/spreadsheets/d/14jX4nVXVi7xreuhH5Yox9jdDC7BU8QggENQnHEm4EsM/edit?usp=sharing'
							rel='noreferrer'
							target='_blank'
							download>
							here...
						</a>
					</span>
				</p>
				<Divider style={{ margin: 0, padding: 0 }} />

				<div className={styles.modalFooter}>
					<button className={styles.cancel} onClick={closeModal}>
						Cancel
					</button>

					<button onClick={uploadMultipleFile} className={styles.fund}>
						Continue
					</button>
				</div>
			</div>
		</div>
	);
}

export default Bulk;
