import React, { useState, useEffect } from "react";
import styles from "./NotFound.module.scss";
import WebhookCard from "../../components/WebhookCard/WebhookCard";
import useApi from "../../hooks/useApi";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  const history = useHistory();
  return (
    <div className={styles.webhooks}>
      <h3>Opss! Looks like you took a wrong turn</h3>
      <button onClick={() => history.goBack()}>Go Back</button>
    </div>
  );
};

export default NotFound;
