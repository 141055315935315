import React, { useState, Fragment } from "react";
import styles from "./Filterbutton.module.scss";
import { ReactComponent as DropArrow } from "../../assets/images/Icons/drop-arrow.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const FilterButton = ({ time, setFilterParam }: any) => {
  const [dropdown, setDropdown] = useState(false);

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleClickAway = () => {
    setDropdown(false);
  };
  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.exportMenu} onClick={handleDropdown}>
          <div className={styles.week}>
            {time} <DropArrow className={styles.dropArrow} />
          </div>

          {dropdown && (
            <div className={styles.exportDropdown}>
              <div
                onClick={() => {
                  setFilterParam("week");
                }}
                style={{ cursor: "pointer" }}
              >
                Week
              </div>
              <div
                onClick={() => {
                  setFilterParam("year");
                }}
                style={{ cursor: "pointer" }}
              >
                Year
              </div>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </Fragment>
  );
};

export default FilterButton;
