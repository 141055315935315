import React from "react";
import styles from "./FlagTransactionModal.module.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import settlementApi from '../../../api/settlements';
import { closeLoader, openLoader } from "../../../redux/actions/loader/loaderActions";

const FlagTransactionModal = ({ closeModal, data }: any) => {
  const dispatch = useDispatch();
  interface initTypes {
    comment: string;
  }

  // t.settlementStatusId != 5 && flagSettlement(t.id, t.settlementStatusId == 2 ? 'unflag' : 'flag');

  function flagSettlement() {
    const status = data?.settlementStatusId == 2 ? "unflag" : "flag";
    dispatch(openLoader());
    settlementApi.toggleFlagSettlement(data?.id, status, inputs)
      .then((res: any) => {
        dispatch(
          openToastAndSetContent({
            toastContent: res.data.message,
            toastStyles: ToastSuccessStyles,
          }))
      })
      .catch((error: any) => {
        const { message } = error?.response?.data ?? { message: "Unable to complete operation" };
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      })
      .finally(() => {
        dispatch(closeLoader());
        closeModal();
      })
  }


  const initProps: initTypes = {
    comment: "",
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (inputs.comment) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    flagSettlement();
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Flag Settlement</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="comment"
            inputLabel="Comment"
            inputValue={inputs.comment}
          />
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.activate} onClick={handleSubmit}>
            Submit
          </div>
        ) : (
          <div className={styles.deactivate}>Submit </div>
        )}
      </div>
    </div>
  );
};

export default FlagTransactionModal;
