import React from 'react';

export interface CreditData {
	paymentType: string;
	businessName: React.ReactNode;
	amount: string | number;
	transactionReference: string;
	date: string;
	action: React.ReactNode;
}

export const ColumnCredit = [
	{
		Header: 'Payment type',
		accessor: 'paymentType',
	},
	{
		Header: 'Business name',
		accessor: 'businessName',
	},
	{
		Header: 'Transaction amount',
		accessor: 'amount',
	},
	{
		Header: 'Transaction reference',
		accessor: 'transactionReference',
	},
	{
		Header: 'Date',
		accessor: 'date',
	},
	{
		Header: '',
		accessor: 'action',
	},
];

export interface CreditSummaryData {
	merchantName: React.ReactNode;
	amount: string | number;
	currency: string;
	date: string;
}

export const ColumnCreditSummary = [
	{
		Header: 'Date',
		accessor: 'date',
	},
	{
		Header: 'merchant name',
		accessor: 'merchantName',
	},
	{
		Header: 'Currency',
		accessor: 'currency',
	},
	{
		Header: 'Amount funded',
		accessor: 'amount',
	},
];

export interface CreditProviderData {
	merchantName: React.ReactNode;
	amount: string | number;
	cost: string | number;
	income: string | number;
	date: string;
}

export const ColumnCreditProvider = [
	{
		Header: 'Provider',
		accessor: 'merchantName',
	},
	{
		Header: 'Total amount funded',
		accessor: 'amount',
	},
	{
		Header: 'Cost',
		accessor: 'cost',
	},
	{
		Header: 'Income',
		accessor: 'income',
	},
	{
		Header: 'Date',
		accessor: 'date',
	},
];

export interface DebitData {
	debitType: string;
	businessName: React.ReactNode;
	amount: string | number;
	transactionReference: string;
	date: string;
	status: React.ReactNode;
	action: React.ReactNode;
}

export const ColumnDebit = [
	{
		Header: 'Business name',
		accessor: 'businessName',
	},
	{
		Header: 'Transaction amount',
		accessor: 'amount',
	},
	{
		Header: 'Transaction reference',
		accessor: 'transactionReference',
	},
	{
		Header: 'Debit type',
		accessor: 'debitType',
	},
	{
		Header: 'Date',
		accessor: 'date',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
	{
		Header: '',
		accessor: 'action',
	},
];
