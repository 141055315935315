import React, { Dispatch, useEffect, MouseEventHandler } from "react";
import styles from "./BasicGroupModal.module.scss";
import client from '../../../api/client';
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import { numberWithCommas } from "../../../utils/formatNumber";
import { Divider } from "antd";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { InputLabel, TextField } from '@material-ui/core';
import SelectWrapper from '../../Select';
import { useDispatch, useSelector } from "react-redux";
import { closeLoader, openLoader } from "../../../redux/actions/loader/loaderActions";
import Mark from '../../../assets/images/Icons/u_save.svg';
import { ToastErrorStyles, ToastSuccessStyles } from "../../../constants/toastStyles";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import { makeStyles } from '@material-ui/core';
import useApi from "../../../hooks/useApi";
import reports from '../../../api/report';

type BasicGroupModalProps = {
  closeModal: Function | any;
  cb: Function | any;
  fnName: Function | any;
  data?: any;
  isSubsidiaryGroup?: any
};
const BasicGroupModal = ({ closeModal, fnName, data, cb, isSubsidiaryGroup }: BasicGroupModalProps) => {
  const dispatch = useDispatch();
  const getPricingGroups = useApi(reports.getPricingGroups);
  const getLimitGroups = useApi(reports.getLimitGroups);
  // const isSubsidiaryGroup = fnName?.indexOf("SubsidiaryGroup") > -1;
  console.log({ isSubsidiaryGroup, data })
  const useStyles = makeStyles({
    root: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-input.MuiOutlinedInput-input':
      {
        textAlign: 'center',
        padding: '8.1px 14px',
      },
    },
    select: {
      '& .MuiOutlinedInput-root': {
        color: '#414141',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        borderRadius: '10px',
      },
      '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        outline: 'none',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
        backgroundColor: '#ffffff',
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: '#E0E0E0',
        backgroundColor: '#ffffff',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #E0E0E0',
      },
    },
  });
  const classes = useStyles();
  const validate = Yup.object({
    name: Yup.string().required('Required'),
  });

  const mapPricingGroups = () => (getPricingGroups?.data?.data ?? []).map((x: any) => { return { name: x.name, val: x.id } })
  const pricingGroupOptions = mapPricingGroups();

  const mapLimitGroups = () => (getLimitGroups?.data?.data ?? []).map((x: any) => { return { name: x.name, val: x.id } })
  const LimitGroupOptions = mapLimitGroups();


  useEffect(() => {
    isSubsidiaryGroup && getPricingGroups.request();
    isSubsidiaryGroup && getLimitGroups.request();
  }, []);


  const submitHandler = (values: any) => {
    console.log(values);
    dispatch(openLoader());
    var _rr: any = reports;
    var req = !data ? _rr[fnName.replace("get", "add")](values) : _rr[fnName.replace("get", "edit")](values)
    req.then((res: any) => {
      dispatch(closeLoader());
      dispatch(
        openToastAndSetContent({
          toastContent: res.data.message,
          toastStyles: ToastSuccessStyles,
        })
      );
      closeModal();
      cb();
    })
      .catch((error: any) => {
        dispatch(closeLoader());
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  }
  return (
    <div className={styles.modalwrapper}>
      <div className={styles.modalhead}>
        <h3 className={styles.modalheadh3}>{data ? "Update Group" : "Add a new group"}</h3>
        <CloseIcon
          onClick={closeModal}
          style={{ cursor: 'pointer' }}
        />
      </div>
      <Divider style={{ margin: 0, padding: 0 }} />
      <Formik
        initialValues={{
          id: data?.id ?? '',
          name: data?.name ?? '',
          description: data?.description ?? '',
          pricingGroupId: data?.pricingGroupId ?? '',
          limitGroupId: data?.limitGroupId ?? '',
        }}
        validationSchema={validate}
        onSubmit={submitHandler}>
        {(props) => (
          <div
            style={{
              padding: '32px 24px',
            }}>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>Name</span>
                    </InputLabel>
                    <Field
                      as={TextField}
                      helperText={
                        <ErrorMessage name='name'>
                          {(msg) => (
                            <span style={{ color: 'red' }}>{msg}</span>
                          )}
                        </ErrorMessage>
                      }
                      name='name'
                      variant='outlined'
                      margin='normal'
                      type='text'
                      size='small'
                      fullWidth
                      className={classes.select}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>Description</span>
                    </InputLabel>
                    <Field
                      as={TextField}
                      helperText={
                        <ErrorMessage name='description'>
                          {(msg) => (
                            <span style={{ color: 'red' }}>{msg}</span>
                          )}
                        </ErrorMessage>
                      }
                      name='description'
                      variant='outlined'
                      margin='normal'
                      type='text'
                      size='small'
                      fullWidth
                      className={classes.select}
                    />
                  </div>
                </Grid>
                {isSubsidiaryGroup && (
                  <>
                    <Grid item xs={12}>
                      <div className={styles.customerInfoSingle}>
                        <InputLabel>
                          <span className={styles.label}>Pricing Group</span>
                        </InputLabel>
                        <Field
                          as={SelectWrapper}
                          helperText={
                            <ErrorMessage name='pricingGroupId'>
                              {(msg) => (
                                <span style={{ color: 'red' }}>{msg}</span>
                              )}
                            </ErrorMessage>
                          }
                          name='pricingGroupId'
                          variant='outlined'
                          margin='normal'
                          type='text'
                          size='small'
                          options={pricingGroupOptions}
                          fullWidth
                          className={classes.select}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={styles.customerInfoSingle}>
                        <InputLabel>
                          <span className={styles.label}>Limit Group</span>
                        </InputLabel>
                        <Field
                          as={SelectWrapper}
                          helperText={
                            <ErrorMessage name='limitGroupId'>
                              {(msg) => (
                                <span style={{ color: 'red' }}>{msg}</span>
                              )}
                            </ErrorMessage>
                          }
                          name='limitGroupId'
                          variant='outlined'
                          margin='normal'
                          type='text'
                          size='small'
                          options={LimitGroupOptions}
                          fullWidth
                          className={classes.select}
                        />
                      </div>
                    </Grid>
                    {/* <Grid item xs={12}>
                      <div className={styles.customerInfoSingle}>
                        <InputLabel>
                          <span className={styles.label}>Name</span>
                        </InputLabel>
                        <Field
                          as={SelectWrapper}
                          helperText={
                            <ErrorMessage name='name'>
                              {(msg) => (
                                <span style={{ color: 'red' }}>{msg}</span>
                              )}
                            </ErrorMessage>
                          }
                          name='name'
                          variant='outlined'
                          margin='normal'
                          type='text'
                          size='small'
                          fullWidth
                          className={classes.select}
                        />
                      </div>
                    </Grid> */}
                  </>
                )}
                <Grid item xs={12}>
                  <button type='submit' className={styles.Downloadbutton}>
                    <span className={styles.Downloadbutton_span}>
                      <img src={Mark} alt='' />
                    </span>
                    Add
                  </button>
                </Grid>{' '}
              </Grid>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default BasicGroupModal;
