import React from 'react';
import styles from './Refund.module.scss';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import { Divider } from 'antd';

function Bulklogs() {
	const history = useHistory();
	return (
		<div>
			<div className={styles.transactionsHeader}>
				<div
					style={{
						color: '#211F01',
						display: 'inline',
						cursor: 'pointer',
						fontWeight: '800px',
					}}
					onClick={() => history.goBack()}>
					<ArrowBackIosIcon sx={{ fontSize: '20px' }} /> Refund logs
				</div>
				<div style={{ marginTop: '0px' }} className={styles.modalFooter}>
					<button className={styles.cancel}>Cancel</button>

					<button className={styles.fund}>Log Refund</button>
				</div>
			</div>

			<div className={styles.logdetails}>
				<div style={{ paddingTop: '24px' }} className={styles.logdetailswrap}>
					<h3 className={styles.logdetailswraph3}>Transaction reference</h3>
					<h3 className={styles.logdetailswraph3}>Amount</h3>
				</div>
				<Divider />
				<div className={styles.logdetailscontainer}>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
					<div className={styles.logdetailswrap}>
						<h4 className={styles.logdetailswraph4}>
							SAYSWICT-ab95cf961f454669a4
						</h4>
						<h6 className={styles.logdetailswraph6}>NGN 100,765.28</h6>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Bulklogs;
