const initialSubsidiaryState = {
  subsidiaryInfo: {},
};

export const subsidiaryInfoReducer = (
  state = initialSubsidiaryState,
  action
) => {
  switch (action.type) {
    case "SAVE_SUBSIDIARY_INFO": {
      return {
        ...state,
        subsidiaryInfo: { ...action.subsidiaryInfo },
      };
    }
    default: {
      return state;
    }
  }
};

export default subsidiaryInfoReducer;
