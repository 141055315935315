import React, { useMemo, useEffect, useState } from 'react';
import styles from './Customers.module.scss';
import FundIcon from '../../../assets/images/funded.svg';
import { useTable, useSortBy, useFilters, usePagination, useGlobalFilter, useAsyncDebounce, useRowSelect } from 'react-table';
import { ReactComponent as RightArrow } from '../../assets/images/Icons/U_next.svg';
import { ReactComponent as LeftArrowFaded } from '../../assets/images/Icons/U_previous.svg';
import moment from 'moment';
import { numberWithCommas } from '../../utils/formatNumber';
import StatusView from '../StatusView/StatusView';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';

interface PaginationTableModalProps {
	content: any
	onClose?: () => void;
}

const PaginationTableModal: React.FC<PaginationTableModalProps> = ({ content, onClose }) => {
	if (!content) return null;
	return (
		<>
			{console.log(' pag modal clicked')}
			<div className={styles.modalOverlay} style={{ display: content ? 'flex' : 'none' }}>
				<div className={styles.modalContent}>
					<div className={styles.transactionInfo}>
						<div className={styles.topTable}>
							<div className={styles.detailsHeader}>
								Transaction details
								<button className={styles.closeButton} onClick={onClose}>×</button>
							</div>
							<div className={styles.tableContent}>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Transaction date</div>
									<div className={styles.detailsKey}>{content.date || 'Not provided'}</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Status</div>
									<div className={styles.detailsKey}>
										<StatusView
											status={content?.status}
											green='Successful'
											blue='Initiated'
											orange='Pending'
											red='Failed'
											purple='Reversed'
										/>
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Payment Message</div>
									<div className={styles.detailsKey}>
										{content?.paymentResponseMessage || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Payment channel</div>
									<div className={styles.detailsKey}>
										{content?.paymentChannel || content?.paymentType || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Customer name</div>
									<div className={styles.detailsKey}>
										{content?.customerName || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Customer email</div>
									<div className={styles.detailsKey}>
										{content?.email || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Customer phone</div>
									<div className={styles.detailsKey}>
										{content?.customerPhone || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Merchant ID</div>
									<div className={styles.detailsKey}>
										{content?.subsidiaryId || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Account number</div>
									<div className={styles.detailsKey}>
										{content?.accountNumber || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Card Type</div>
									<div className={styles.detailsKey}>
										{content?.cardType || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Masked PAN</div>
									<div className={styles.detailsKey}>
										{content?.maskedPan || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>IP Addresss</div>
									<div className={styles.detailsKey}>
										{content?.ipAddress || "N/A"}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Transaction amount</div>
									<div className={styles.detailsKey}>
										{content?.currency}{content?.transactionAmount || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Payment Reference</div>
									<div className={styles.detailsKey}>
										{content?.paymentReference || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>RRN/SessionId</div>
									<div className={styles.detailsKey}>
										{content?.externalReference || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Fee</div>
									<div className={styles.detailsKey}>
										{content?.fee || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Who bore fee</div>
									<div className={styles.detailsKey}>
										{content?.whoBearsFee || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Customer Fee / Subsidiary Fee</div>
									<div className={styles.detailsKey}>
										{content?.customerFee} / {content?.subsidiaryFee}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Settlement Amount</div>
									<div className={styles.detailsKey}>
										{numberWithCommas(content?.settlementAmount)} {`(${content?.settlementStatus || 'N/A'})`}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Initial Settlement Date</div>
									<div className={styles.detailsKey}>
										{content?.initialSettlementDate || "Not Available"}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Final Settlement Date</div>
									<div className={styles.detailsKey}>
										{content?.finalSettlementDate || "Not Available"}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Merchant name</div>
									<div className={styles.detailsKey}>
										{content?.merchantName || 'Not provided'}
									</div>
								</div>{' '}
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Order reference</div>
									<div className={styles.detailsKey}>
										{content?.orderReference || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Note</div>
									<div className={styles.detailsKey}>
										{content?.remarks || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Payment Attempt Reference</div>
									<div className={styles.detailsKey}>
										{content?.orderPaymentReference}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Bank Provider</div>
									<div className={styles.detailsKey}>
										{content?.processorProviderBank || "Not Available"}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}


type DataColumn = {
	data: any;
	columns: any;
	emptyPlaceHolder: string;
	recent: boolean;
	value?: string;
	transactionId: Number;
	clickAction?: React.Dispatch<React.SetStateAction<any>> | undefined | null;
	total?: string | number | undefined;
	setPageNumber?: any;
	pageNumber?: any;
	nextPage?: number | null;
	previousPage?: number | null;
	setPreviousPage?: React.Dispatch<React.SetStateAction<number | null>>;
	setNextPage?: React.Dispatch<React.SetStateAction<number | null>>;
	rowsPerPage?: number;
	totalPage?: string | number | undefined;
	setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
	showModal?: boolean;
	allowBulkAction?: boolean;
	updateBulkActionData?: (data: any) => void;
};

const PaginationTable = ({
	data,
	columns,
	emptyPlaceHolder,
	recent,
	value,
	clickAction,
	total,
	setPageNumber,
	pageNumber,
	rowsPerPage,
	nextPage,
	previousPage,
	setPreviousPage,
	setNextPage,
	totalPage,
	setRowsPerPage,
	transactionId,
	showModal,
	allowBulkAction,
	updateBulkActionData
}: Partial<DataColumn>) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		canNextPage,
		canPreviousPage,
		pageOptions,
		pageCount,
		setPageSize,
		state: { pageIndex, pageSize, globalFilter },
		setGlobalFilter,
		prepareRow,
	} = useTable(
		{
			columns,
			data,
			initialState: {
				sortBy: [{ id: 'columnId', desc: false }],
				pageSize: rowsPerPage,
			},
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect
	);

	const [searchVal, setSearchVal] = useState(globalFilter);
	const [modalContent, setModalContent] = useState<any>(null);

	const onChangeSearch = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 1000);

	useEffect(() => {
		setGlobalFilter(value);
	}, [value]);

	const handleRowClick = (row: any) => {
		if (clickAction) {
			clickAction(row);
		}
		if (showModal) {
			setModalContent(row);
		}
		// setModalContent(row);
		// alert(
		// 	`Date: ${row.date}\n` +
		// 	`Merchant Name: ${row.merchantName}\n` +
		// 	`Email: ${row.email}\n` +
		// 	`Amount: ${row.transactionAmount}\n` +
		// 	`Payment Channel: ${row.paymentChannel}\n` +
		// 	`Status: ${row.status.props.status}\n` + // This will be a string, if using StatusView component
		// 	`Order Reference: ${row.orderReference || 'N/A'}\n` +
		// 	`Payment Reference: ${row.paymentReference || 'N/A'}\n` +
		// 	`Customer Phone: ${row.customerPhone || 'N/A'}\n` +
		// 	`Card Type: ${row.cardType || 'N/A'}\n` +
		// 	`Fee: ${row.fee || 'N/A'}\n` +
		// 	`Settlement Amount: ${row.settlementAmount || 'N/A'}\n` +
		// 	`Customer Name: ${row.customerName || 'No customerName'}\n` +
		// 	`Payment Response Message: ${row.paymentResponseMessage || 'N/A'}\n` +
		// 	`Customer Email: ${row.customerEmail || 'N/A'}\n` +
		// 	`Account Number: ${row.accountNumber || 'N/A'}\n` +
		// 	`IP Address: ${row.ipAddress || 'N/A'}\n` +
		// 	`Custom Fee Formatted: ${row.customFeeFormatted || 'N/A'}\n` +
		// 	`subsidiaryId: ${row.subsidiaryId || 'No sub id'} \n` +
		// 	`customerFee: ${row.customerFee || 'No customer fee'} \n` +
		// 	`externalReference: ${row.externalReference || 'No customer fee'} \n`
		// );
	};

	const closeModal = () => {
		setModalContent(null);
	};

	const [checkBoxData, setCheckBoxData] = useState<any>({})

	const HandleCheckBoxClick = (rowdata: any) => {
		if (!checkBoxData[rowdata.id]) checkBoxData[rowdata.id] = rowdata
		console.log({ x: checkBoxData[rowdata.id] })
		checkBoxData[rowdata.id].checkboxMarked = !checkBoxData[rowdata.id].checkboxMarked
		let finaldata = { ...checkBoxData };
		if (rowdata.id == "header") {
			if (!checkBoxData[rowdata.id].checkboxMarked) finaldata = {};
			else {
				data.map((d: any) => {
					if (!finaldata[d.id]) finaldata[d.id] = d
					finaldata[d.id].checkboxMarked = true
				})
			}
		}
		setCheckBoxData({ ...finaldata })
		updateBulkActionData?.({ ...finaldata })
	}

	const IsBoxChecked = (data: any): boolean => {
		if (!checkBoxData[data?.id]) return false;
		if (!checkBoxData[data?.id]?.checkboxMarked) return false;
		return true;
	}

	const HandleShowCheckBox = (cell: any) => {
		const data = cell?.row?.original;
		return <span onClick={(event) => {
			event.stopPropagation()
			HandleCheckBoxClick(data)
		}}>
			{IsBoxChecked(data) ? <CheckBox /> : <CheckBoxOutlineBlank />}
		</span>
	};

	return (
		<div className={styles.tableWrapper}>
			<table {...getTableProps()} style={{ width: '100%' }} className={styles.table}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column, index) => (
								<th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.tableHead}>
									{allowBulkAction && index == 0 && HandleShowCheckBox({ row: { original: { id: "header" } } })}
									{column.render('Header')}
									<span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()} style={{ width: '100%' }}>
					{page.map((row) => {
						prepareRow(row);
						return (
							<tr
								{...row.getRowProps()}
								className={clickAction ? styles.tableBodyHover : styles.tableBody}
								onClick={() => handleRowClick(row.original)}
							>
								{row.cells.map((cell, index) => (
									<td {...cell.getCellProps()} style={{ padding: '20px', fontFamily: 'HelveticaNeue', fontStyle: 'normal', fontWeight: 500, fontSize: '14px', lineHeight: '24px', letterSpacing: '-0.011em', color: '#424242', borderBottom: '1px solid #e5e5e5' }}>
										{allowBulkAction && index == 0 && HandleShowCheckBox(cell)}
										{cell.render('Cell')}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>

			{/* {modalContent && (
        <div className={styles.modalOverlay} style={{ display: modalContent ? 'flex' : 'none' }}>
          <div className={styles.modalContent}>
            <div className={styles.transactionInfo}>
              <div className={styles.topTable}>
                <div className={styles.detailsHeader}>
                  Transaction details
                  <button className={styles.closeButton} onClick={closeModal}>×</button>
                </div>
                <div className={styles.tableContent}>
                  <div className={styles.customerInfo}>
                    <div className={styles.detailsValue}>Transaction date</div>
                    <div className={styles.detailsKey}>{modalContent.date || 'Not provided'}</div>
                  </div>
				  <div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Status</div>
									<div className={styles.detailsKey}>
										<StatusView
											status={modalContent?.status}
											green='Successful'
											blue='Initiated'
											orange='Pending'
											red='Failed'
											purple='Reversed'
										/>
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Payment Message</div>
									<div className={styles.detailsKey}>
										{modalContent?.paymentResponseMessage || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Payment channel</div>
									<div className={styles.detailsKey}>
										{modalContent?.paymentChannel || modalContent?.paymentType || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Customer name</div>
									<div className={styles.detailsKey}>
										{modalContent?.customerName || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Customer email</div>
									<div className={styles.detailsKey}>
										{modalContent?.email || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Customer phone</div>
									<div className={styles.detailsKey}>
										{modalContent?.customerPhone || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Merchant ID</div>
									<div className={styles.detailsKey}>
										{modalContent?.subsidiaryId || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Account number</div>
									<div className={styles.detailsKey}>
										{modalContent?.accountNumber || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Card Type</div>
									<div className={styles.detailsKey}>
										{modalContent?.cardType || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Masked PAN</div>
									<div className={styles.detailsKey}>
										{modalContent?.maskedPan || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>IP Addresss</div>
									<div className={styles.detailsKey}>
										{modalContent?.ipAddress || "N/A"}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Transaction amount</div>
									<div className={styles.detailsKey}>
										{modalContent?.currency}{modalContent?.transactionAmount || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Payment Reference</div>
									<div className={styles.detailsKey}>
										{modalContent?.paymentReference || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>RRN/SessionId</div>
									<div className={styles.detailsKey}>
										{modalContent?.externalReference || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Fee</div>
									<div className={styles.detailsKey}>
										{modalContent?.fee || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Who bore fee</div>
									<div className={styles.detailsKey}>
										{modalContent?.whoBearsFee || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Customer Fee / Subsidiary Fee</div>
									<div className={styles.detailsKey}>
										{modalContent?.customerFee} / {modalContent?.subsidiaryFee}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Settlement Amount</div>
									<div className={styles.detailsKey}>
										{numberWithCommas(modalContent?.settlementAmount)} {`(${modalContent?.settlementStatus || 'N/A'})`}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Initial Settlement Date</div>
									<div className={styles.detailsKey}>
										{modalContent?.initialSettlementDate || "Not Available"}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Final Settlement Date</div>
									<div className={styles.detailsKey}>
										{modalContent?.finalSettlementDate || "Not Available"}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Merchant name</div>
									<div className={styles.detailsKey}>
										{modalContent?.merchantName || 'Not provided'}
									</div>
								</div>{' '}
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Order reference</div>
									<div className={styles.detailsKey}>
										{modalContent?.orderReference || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Note</div>
									<div className={styles.detailsKey}>
										{modalContent?.remarks || 'Not provided'}
									</div>
								</div>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Payment Attempt Reference</div>
									<div className={styles.detailsKey}>
										{modalContent?.orderPaymentReference}
									</div>
								</div>     
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Bank Provider</div>
									<div className={styles.detailsKey}>
										{modalContent?.processorProviderBank || "Not Available"}
									</div>
								</div>
							</div>
                </div>
              </div>
            </div>
          </div>
      )} */}

			{modalContent && showModal && (
				<>
					<PaginationTableModal content={modalContent} onClose={closeModal} />
				</>
			)}


			{data.length === 0 && (
				<div className={styles.emptyState}>
					<div className={styles.noTransaction}>{emptyPlaceHolder}</div>
				</div>
			)}

			{!recent ? (
				<>
					{total === 0 ? null : (
						<div className={styles.paginatedSection}>
							<div>
								<div className={styles.arrowDirections}>
									<div style={{ cursor: 'not-allowed', paddingRight: '40px' }}>
										<div>
											{pageNumber === 1 ? (
												<div>
													<LeftArrowFaded />
													<span className={styles.previous}>Previous</span>
												</div>
											) : (
												<div onClick={() => setPageNumber(previousPage)} style={{ cursor: 'pointer' }}>
													<RightArrow style={{ transform: 'rotate(180deg)', cursor: 'pointer' }} />
													<span className={styles.previousTrue}>Previous</span>
												</div>
											)}
										</div>
									</div>
									<span className={styles.pageNumber}>
										<span className={styles.pageIndex}>
											{pageNumber} of {totalPage || 1}
										</span>
										{total === 0 ? null : (
											<select
												value={pageSize}
												onChange={(e) => {
													setRowsPerPage && setRowsPerPage(Number(e.target.value));
													setPageSize(Number(e.target.value));
												}}
												className={styles.selection}
											>
												{[5, 10, 20, 30, 40, 50, 100].reverse().map((pageSize) => (
													<option key={pageSize} value={pageSize}>
														Show {pageSize}
													</option>
												))}
											</select>
										)}
									</span>
									{pageNumber === totalPage ? (
										<div style={{ cursor: 'not-allowed' }}>
											<div style={{ paddingLeft: '40px' }}>
												<span className={styles.nextFalse}>Next</span>
												<LeftArrowFaded style={{ transform: 'rotate(180deg)', cursor: 'pointer' }} />
											</div>
										</div>
									) : (
										<div onClick={() => setPageNumber(nextPage)}>
											<div style={{ cursor: 'pointer' }}>
												<span className={styles.next}>Next</span>
												<RightArrow />
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					)}
				</>
			) : null}
		</div>
	);
};

export default PaginationTable;
