import React from 'react';
import styles from './Debit.module.scss';
import DebitTable from './DebitTable/DebitTable';

interface summaryType {
	data: {
		currency: string;
		funding: number;
		refund: number;
		chargeback: number;
		rollingReserve: number;
	};
	status: string;
	statusCode: string;
	message: string;
}

function Debit() {
	return (
		<div style={{ width: '100%' }}>
			<DebitTable />
		</div>
	);
}

export default Debit;
