import React, { } from "react";
import styles from "./Business.module.scss";
import Modal from "react-modal";
import { computeCustomStyles } from "../../../constants/modalStyles";
import Grid from '@mui/material/Grid';
import Fees from "../../Fee&limit/Fees/Fees";
import Collection from "../../Fee&limit/Collection Limit/Collection";
import SettlementPlan from "../../Fee&limit/SettlementPlans/SettlementPlan";

function SubsidiaryConfigSection({ display, subdiaryInfo, groupInformation, closeModal, fieldName, currentValue, id }: any) {

  const [openPricongModal, setOpenPricingModal] = React.useState<any>(false)
  const [OpenLimitConfigModal, setOpenLimitConfigModal] = React.useState<any>(false)
  const [openSettlementPlanModal, setOpenSettlementPlanModal] = React.useState<any>(false)

  return (
    <div
      style={{
        padding: '0 24px',
        display: display ? '' : 'none',
      }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <div className={styles.subsidiaryGroupWrapper} onClick={() => setOpenPricingModal(true)}>
            <div className={styles.detailsValue}>Pricing Group</div>
            <div className={styles.detailsKey}>
              {groupInformation?.subsidiaryGroup?.pricingGroup?.name || 'Not Provided'}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <div className={styles.subsidiaryGroupWrapper} onClick={() => setOpenLimitConfigModal(true)}>
            <div className={styles.detailsValue}>Limit Group</div>
            <div className={styles.detailsKey}>
              {groupInformation?.subsidiaryGroup?.limitGroup?.name || 'Not Provided'}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <div className={styles.subsidiaryGroupWrapper} onClick={() => setOpenSettlementPlanModal(true)}>
            <div className={styles.detailsValue}>Settlement Group</div>
            <div className={styles.detailsKey}>
              {groupInformation?.subsidiaryGroup?.settlementGroup?.name || 'Not Provided'}
            </div>
          </div>
        </Grid>
      </Grid>
      <Modal
        isOpen={openPricongModal}
        onRequestClose={() => setOpenPricingModal(false)}
        contentLabel='Example Modal 1'
        style={computeCustomStyles()}>
        <div style={{ padding: "20px" }}>
          <Fees data={groupInformation?.pricings} />
        </div>
      </Modal>
      <Modal
        isOpen={OpenLimitConfigModal}
        onRequestClose={() => setOpenLimitConfigModal(false)}
        contentLabel='Example Modal 1'
        style={computeCustomStyles()}>
        <div style={{ padding: "20px" }}>
          <Collection data={groupInformation?.limits} />
        </div>
      </Modal>
      <Modal
        isOpen={openSettlementPlanModal}
        onRequestClose={() => setOpenSettlementPlanModal(false)}
        contentLabel='Example Modal 1'
        style={computeCustomStyles()}>
        <div style={{ padding: "20px" }}>
          <SettlementPlan data={groupInformation?.settlementPlans} />
        </div>
      </Modal>
    </div>
  );
}

export default SubsidiaryConfigSection;
