import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import styles from './ActionButton.module.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import currencyApi from '../../api/currencies';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import { useDispatch, useSelector } from 'react-redux';
import { changeCurrency, getCurrenciesAction } from '../../redux/actions/appSettings/appSettings';
import { GET_CURRENCIES } from '../../redux/actions/constants';
// import settlementCycleApi from '../../api/settlementCycle';
// import { actionButtonMenu } from '../../utils/actionButtonMenu';
// import KycIcon from '../../assets/images/Icons/kyc.svg';
// import SettlementCycleIcon from '../../assets/images/Icons/settlement-cycle.svg';
// import SettlementGroupIcon from '../../assets/images/Icons/settlement-groups.svg';
// import TermsIcon from '../../assets/images/Icons/terms.svg';
// import { StyleSharp } from '@material-ui/icons';
// import SettlementcycleModal from '../ModalContent/SettlementcycleModal/SettlementcycleModal';
// import SettlementgroupsModal from '../ModalContent/SettlementcycleModal/SettlementgroupsModal';
// import { customStyles } from '../../constants/modalStyles';
// import Modal from 'react-modal';

interface user {
	email: string;
	firstname: string;
	lastname: string;
}

interface curType {
	name: string;
	shortName: string;
	isAllowed: boolean;
	symbol: null;
	id: string | number;
	dateCreated: string;
	dateUpdated: string;
	dateDeleted: null;
	createdBy: string | number;
	updatedBy: null;
	deletedBy: null;
}

export default function ActionButton() {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const dispatch = useDispatch();
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const history = useHistory();
	const getCurrencies = async () => { dispatch(await getCurrenciesAction()); }

	useEffect(() => {
		getCurrencies();
	}, []);

	const { currencies, currency } = useSelector((state) => state.appSettingsReducer);
	// console.log({ currencies })
	// const getCurrenciesApi = useApi(currencyApi.getCurrencies);

	// const fetchCurrencies = async () => {
	// 	getCurrenciesApi.request();
	// };

	const [activeCurrency, setActiveCurrency] = useState(currency);

	// const currencies = getCurrenciesApi?.data?.data;

	const useStyles = makeStyles({
		root: {
			'&:hover': {
				background: 'none',
			},
		},
		list: {
			backgroundColor: '#ffffff',
			overflow: 'hidden',
			color: 'rgba(0, 40, 65, 0.8)',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			justifyContent: 'flex-start',
			padding: '0',
		},
		primary: {
			fontSize: '212px',
		},
		paper: {
			boxShadow: '0px 4px 11px rgba(0, 0, 0, 0.2)',
		},
	});
	const classes = useStyles();

	const HandleChangeCurrency = (currency: string) => {
		setAnchorEl(null);
		setActiveCurrency(currency);
		dispatch(changeCurrency(currency))
	};

	return (
		<div>
			<div
				id='fade-button'
				aria-controls='fade-menu'
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}>
				<div className={styles.buttonAction} onClick={handleClick}>
					Currency ({activeCurrency})
					<KeyboardArrowDownIcon />
				</div>
			</div>
			<Menu
				id='fade-menu'
				MenuListProps={{
					'aria-labelledby': 'fade-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				TransitionComponent={Fade}
				classes={{
					list: classes.list,
					paper: classes.paper,
					root: classes.root,
				}}>
				{currencies?.map((item: curType) => (
					<div key={item.shortName + Math.random()} className={styles.menuItem}>
						<div className={styles.account} onClick={() => HandleChangeCurrency(item.shortName)}>
							<div className={styles.image}>
								{item?.symbol ? (
									<img src={item?.symbol} alt='currency icon' />
								) : (
									<FlagCircleIcon />
								)}
							</div>
							<div className={styles.accountDetail}>{item?.shortName} ({item?.name})</div>
						</div>
					</div>
				))}
			</Menu>
		</div>
	);
}
