import React from 'react';

export interface CollectionLimitData {
	currency: string;
	internationalValue: string;
	id: string;
	value: string;
	valueType: string;
	limitGroupName: string;
	limitItemName: string;
	limitGroupId: string;
	limitItemId: string;
	currencyId: string;
	isActive: string;
	dateCreated: string;
	// action: React.ReactNode;
}

export const ColumnLimitCollection = [
	{
		Header: 'Id',
		accessor: 'id',
	}, {
		Header: 'Currency',
		accessor: 'currency',
	},
	{
		Header: 'Limit Name',
		accessor: 'limitItemName',
	},
	{
		Header: 'Limit Group',
		accessor: 'limitGroupName',
	},
	{
		Header: 'Limit Value',
		accessor: 'value',
	},
	{
		Header: 'Limit Value (Int.)',
		accessor: 'internationalValue',
	},
	{
		Header: 'Value Type',
		accessor: 'valueType',
	},
	{
		Header: 'Date Created',
		accessor: 'dateCreated',
	},
	// {
	// 	Header: '',
	// 	accessor: 'action',
	// },
];
