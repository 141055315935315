import React from "react";

export interface BalanceOperationData {
  appEnvironmentId?: string;
  approvedBy?: string;
  approvedByEmail?: string;
  approvedByName?: string;
  createdBy?: string;
  currency?: string;
  currencyId?: string;
  currencyShortName?: string;
  dateApproved?: string;
  dateCreated?: string;
  dateDeleted?: string;
  dateUpdated?: string;
  deletedBy?: string;
  direction?: string;
  fee?: string;
  id?: string;
  operationStatus?: string;
  paymentReference?: string;
  remarks?: string;
  responseCode?: string;
  responseMessage?: string;
  statusId?: string;
  subsidiary?: string;
  subsidiaryEmail?: string;
  subsidiaryId?: string;
  subsidiaryName?: string;
  transaction?: string;
  transactionAmount?: string;
  transactionId?: string;
  transactionReference?: string;
  updatedBy?: string;
  status: React.ReactNode;
  // action: React.ReactNode;
}

export const ColumnBalanceOperation = [
  {
    Header: "Operation Date",
    accessor: "dateCreated",
  },
  {
    Header: "Transaction Amount",
    accessor: "transactionAmount",
  },
  {
    Header: "Transaction Fee",
    accessor: "fee",
  },
  {
    Header: "Merchant Name",
    accessor: "subsidiaryName",
  },
  {
    Header: "Direction",
    accessor: "direction",
  },
  {
    Header: "Approved By",
    accessor: "approvedByName",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  // {
  //   Header: "Action",
  //   accessor: "action",
  // },
];
