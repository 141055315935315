import React from "react";
import styles from "./Chart.module.scss";
import { ReactComponent as DropArrow } from "../../assets/images/Icons/drop-arrow.svg";
import ChartGraphInflowOutflow from "../ChartGraph/ChartGraphInflowOutflow";
import ExportButton from "../ExportButton/ExportButton";
import { ChartDataInflowOutflow } from "../../constants/chartDataInflowOutflow";
import FilterButton from "../FilterButton/FilterButton";

const Chart = ({ inflowOutflow, filterParam, setFilterParam }: any) => {
  return (
    <div>
      <div className={styles.transactionContent}>
        <div className={styles.transactionDetails}>
          <div className={styles.transactions}>Transactions</div>
          <div className={styles.flow}>
            Track inflow and outflow of money over time
          </div> 
        </div>
        {filterParam === "year" ? (
          <div className={styles.timeLine}>
            <FilterButton time="This year" setFilterParam={setFilterParam} />
            <ExportButton data={inflowOutflow} fileName="Transactions Flow" />
          </div>
        ) : (
          <div className={styles.timeLine}>
            <FilterButton time="This week" setFilterParam={setFilterParam} />
            <ExportButton data={inflowOutflow} fileName="Transactions Flow" />
          </div>
        )}
      </div>
      <div className={styles.total}>
        <div className={styles.inflow}>
          <div className={styles.blue} />
          <span>Total inflow</span>
        </div>
        <div className={styles.outflow}>
          <div className={styles.orange} />
          <span>Total Outflow</span>
        </div>
      </div>
      <div className={styles.chartGraph}>
        <ChartGraphInflowOutflow inflowOutflow={inflowOutflow}/>
      </div>
    </div>
  );
};

export default Chart;
