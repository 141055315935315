import React from 'react';
import styles from './CreditSummary.module.scss';
import CreditSummaryTable from './CreditSummaryTable/CreditSummaryTable';

function CreditSummary() {
	return (
		<div style={{ width: '100%' }}>
			<CreditSummaryTable />
		</div>
	);
}

export default CreditSummary;
