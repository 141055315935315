import React from 'react';

export interface FeesData {
	id: string;
	country: string;
	type: string;
	paymentOption: string;
	paymentOptionCode: string;
	currency: string;
	pricingGroup: string;
	pricingGroupId: number;
	fee: string;
	cap: string;
	internationalFee: string;
	internationalCap: string;
	internationalFlatValue: string;
	flatValue: string;
	dateCreated: string;
	action?: React.ReactNode
}

export const ColumnFees = [
	{
		Header: 'Payment Option',
		accessor: 'paymentOption',
	},
	{
		Header: 'Pricing Group',
		accessor: 'pricingGroup',
	},
	// {
	// 	Header: 'Country',
	// 	accessor: 'country',
	// },
	{
		Header: 'Currency',
		accessor: 'currency',
	},
	{
		Header: 'Fee (%)',
		accessor: 'fee',
	},
	{
		Header: 'Cap',
		accessor: 'cap',
	},
	{
		Header: 'International Fee (%)',
		accessor: 'internationalFee',
	},
	{
		Header: 'International Cap',
		accessor: 'internationalCap',
	},
	{
		Header: 'Date Created',
		accessor: 'dateCreated',
	},
	// {
	// 	Header: '',
	// 	accessor: 'action',
	// },
];

export interface ApprovalData {
	date: string;
	limitType: string;
	requester: string;
	status: React.ReactNode;
}

export const ColumnApproval = [
	{
		Header: 'Request date',
		accessor: 'date',
	},
	{
		Header: 'Limit type',
		accessor: 'limitType',
	},
	{
		Header: 'Requester',
		accessor: 'requester',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
];

export interface GenData {
	frequency: string;
	dateCreated: string;
	cummulativeTransferLimit: string;
	currency: string;
	limitGroup: string;
	requester: string;
	type: string;
	singleTransferLimit: string;
	status: React.ReactNode;
	action?: React.ReactNode;
}

export const ColumnGen = [
	{
		Header: 'Requester',
		accessor: 'requester',
	},
	{
		Header: 'Limit Group',
		accessor: 'limitGroup',
	},
	{
		Header: 'Currency',
		accessor: 'currency',
	},
	{
		Header: 'Single Transfer Limit',
		accessor: 'singleTransferLimit',
	},
	{
		Header: 'Daily Transfer Limit',
		accessor: 'cummulativeTransferLimit',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
	{
		Header: 'Requested Date',
		accessor: 'dateCreated',
	},
	{
		Header: '',
		accessor: 'action',
	},
];
