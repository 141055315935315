import React from 'react';

export interface FruadData {
	merchantName: string;
	totalVolume: string;
	totalValue: string;
	acceptanceType: string;
	cardType: string;
}
export interface OtherFruadData {
	merchantName: string;
	totalVolume: string;
	totalValue: string;
	fraudType: string;
}

export const ColumnFruad = [
	{
		Header: 'Merchant name',
		accessor: 'merchantName',
	},
	{
		Header: 'Total volume',
		accessor: 'totalVolume',
	},
	{
		Header: 'Total value',
		accessor: 'totalValue',
	},
	{
		Header: 'Acceptance type',
		accessor: 'acceptanceType',
	},
	{
		Header: 'Card type',
		accessor: 'cardType',
	},
];

export const ColumnFruadOther = [
	{
		Header: 'Merchant name',
		accessor: 'merchantName',
	},
	{
		Header: 'Total volume',
		accessor: 'totalVolume',
	},
	{
		Header: 'Total value',
		accessor: 'totalValue',
	},
	{
		Header: 'Fraud type',
		accessor: 'fraudType',
	},
];
