import React from 'react';

export interface ChannelData {
	id: number;
	merchantId: string;
	payment: string;
	currency: string;
	cardtype: string;
	channel: string;
	channelmid: string;
	merchantName: string;
	bank: string;
	rail: string;
	status: string | React.ReactNode;
	action: React.ReactNode;
}

export const ColumnChannel = [
	{
		Header: 'ID',
		accessor: 'id',
	},
	{
		Header: 'Core MID',
		accessor: 'merchantId',
	},
	{
		Header: 'Merchant Name',
		accessor: 'merchantName',
	},
	{
		Header: 'Payment Method',
		accessor: 'payment',
	},
	{
		Header: 'Currency',
		accessor: 'currency',
	},
	{
		Header: 'Card Type',
		accessor: 'cardtype',
	},
	{
		Header: 'Channel Bank',
		accessor: 'channel',
	},
	{
		Header: 'Channel Rail',
		accessor: 'rail',
	},
	{
		Header: 'Channel MID',
		accessor: 'channelmid',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
	{
		Header: '',
		accessor: 'action',
	},
];
