import React, { useState, ReactNode, Fragment } from "react";
import styles from "./Menuoption.module.scss";
import { ReactComponent as MenuIcon } from "../../assets/images/Icons/menu.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";

type MenuProps = {
  menuContent: ReactNode;
};
const MenuOption = ({ menuContent }: MenuProps) => {
  const [menuOption, setMenuOption] = useState(false);

  const handleClickAway = () => {
    setMenuOption(false);
  };
  const handleMenu = () => {
    setMenuOption(!menuOption);
  };
  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.menuBody}>
          <div className={styles.menuIcon} onClick={handleMenu}>
            <MenuIcon />
          </div>

          {menuOption && (
            <div className={styles.menuContent} onClick={handleClickAway}>
              {menuContent}
            </div>
          )}
        </div>
      </ClickAwayListener>
    </Fragment>
  );
};

export default MenuOption;
