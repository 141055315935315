import { useState } from 'react';
import styles from './../Chargeback.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import { Divider } from 'antd';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import client from '../../../api/client';
import { ToastErrorStyles, ToastSuccessStyles, } from '../../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ErrorMessage, Form, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { openLoader, closeLoader, } from '../../../redux/actions/loader/loaderActions';
import { ReactComponent as IndividualIcon } from '../../../assets/images/Icons/won.svg';
import { ReactComponent as BusinessIcon } from '../../../assets/images/Icons/lost.svg';
import Mark from '../../../assets/images/Icons/u-mark.svg';
import Times from '../../../assets/images/Icons/u_multiply (1).svg';

interface dataComingTypes {
	currency: string;
	reference: string;
	amount: number;
	frequency: number;
	reason: string;
	due_date: string;
	type: string;
}

function FeedbackChargeBack({ closeModal, setBearer, object, validate2 }: any) {
	const [file, setFile] = useState<dataComingTypes[]>([]);
	const dispatch = useDispatch();
	const history = useHistory();

	const feedbackChargeBackHandler = async (values: any) => {
		{
			dispatch(openLoader());
			client.post(`/chargeback/feedback`, {
				...values,
				chargebackId: object.id,
			}).then((res: any) => {
				dispatch(closeLoader());
				// setBearer(true);
				dispatch(
					openToastAndSetContent({
						toastStyles: ToastSuccessStyles,
						toastContent: res.data.message,
					})
				);
				closeModal();
			}).catch((error) => {
				dispatch(closeLoader());
				if (error.response) {
					const message = error?.response?.data?.message;
					dispatch(
						openToastAndSetContent({
							toastStyles: ToastErrorStyles,
							toastContent: message,
						})
					);
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			});
		};
	}

	return (
		<div className={styles.modalwrapper}>
			<div className={styles.modalhead}>
				<h3 className={styles.modalheadh3}>Feedback on Chargeback</h3>
				<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
			</div>
			<Divider style={{ margin: 0, padding: 0 }} />
			<div className={styles.modalcontent}>
				<Formik
					initialValues={{
						comment: '',
					}}
					validationSchema={validate2}
					onSubmit={(values) => feedbackChargeBackHandler(values)}>
					{(props) => {
						const { comment } = props.values;
						return (
							<div
								style={{
									padding: '32px 24px',
								}}>
								<Form>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<div className={styles.customerInfo}>
												<p>
													Give feedback to merchant on this chargeback
													amounting to NGN {object?.amount} with transaction
													reference:
													{object.reference}
												</p>
											</div>
										</Grid>
										<Grid item xs={12}>
											<div className={styles.customerInfo}>
												<OutlineTextInput
													inputName='comment'
													inputLabel='Comment'
													inputValue={comment}
													handleChange={props.handleChange}
													InputHelper={<ErrorMessage name='comment' />}
													error={
														props.errors.comment && props.touched.comment
													}
												/>
											</div>
										</Grid>

										<Grid item xs={12} sm={6}>
											<button onClick={closeModal} type='button' className={styles.Downloadbutton_faint}>
												<span className={styles.Downloadbutton_span}>
													<img src={Times} alt='' />
												</span>
												Cancel
											</button>
										</Grid>
										<Grid item xs={12} sm={6}>
											<button className={styles.Downloadbutton}>
												<span className={styles.Downloadbutton_span}>
													<img src={Mark} alt='' />
												</span>
												Give feedback
											</button>
										</Grid>
									</Grid>
								</Form>
							</div>
						);
					}}
				</Formik>
			</div>
		</div>
	);
}

export default FeedbackChargeBack;
