import React, { useState, useEffect } from "react";
import styles from "./PostBalanceOperation.module.scss";
import { useHistory } from "react-router-dom";
import { Divider, Form, Input, InputNumber, Select, Switch } from "antd";
import { openToastAndSetContent } from "../../../../redux/actions/toast/toastActions";
import {
  openLoader,
  closeLoader,
} from "../../../../redux/actions/loader/loaderActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../../constants/toastStyles";
import client from "../../../../api/client";
import { useDispatch } from "react-redux";
import { ReactComponent as CloseIcon } from "../../../../assets/images/Icons/closenew.svg";
import ChargebackIcon from "../../../../assets/images/Icons/approve-white.svg";
import TimesIcon from "../../../../assets/images/Icons/closenew.svg";
import Curency from "../../../../api/currencies";
const { Option } = Select;

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */

function PostBalanceOperation({ closeModal, identifier, data, setIden, subsidiaryId, subsidiaryName }: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [INITIALVALUES, setInputs] = useState({
    channel: identifier === "edit" ? data?.channel : "",
    subsidiaryId: subsidiaryId,
    paymentMethod: identifier === "edit" ? data?.paymentmethod : "",
    scheme: identifier === "edit" ? data?.scheme : "",
    channelBank: identifier === "edit" ? data?.bank : "",
    channelMid: identifier === "edit" ? data?.mid : "",
    channelRate: identifier === "edit" ? "nil" : "nil",
    rail: identifier === "edit" ? data?.rail : "",
    username: identifier === "edit" ? data?.username : "",
    mccCode: identifier === "edit" ? data?.mccCode : "",
    password: identifier === "edit" ? data?.password : "",
    remark: identifier === "edit" ? "" : "",
    url: identifier === "edit" ? data?.url : "",
    currencyId: identifier === "edit" ? data?.currency : "",
    status: identifier === "edit" ? data?.status ?? "ACTIVE" : "INACTIVE",
    useStatementDescriptor: identifier === "edit" ? data?.useStatementDescriptor : false,
  })
  const [form] = Form.useForm();

  const handleInputChange = (name: string, event: any) => {
    console.log({ event })
    setInputs({ ...INITIALVALUES, [name]: event })
  };
  const cancelHandler = () => {
    closeModal();
    setIden("");
  };

  const updatedHandler = (values: any) => {
    dispatch(openLoader());
    const path = identifier === "edit" ? `${1}/treat` : "initiate"
    var method = client.post;
    method(`wallet/operation/${path}`, {
      ...values,
      status: INITIALVALUES.status,
      useStatementDescriptor: INITIALVALUES.useStatementDescriptor,
      subsidiaryId: subsidiaryId,
    })
      .then((res: any) => {
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: res.data.message,
            toastStyles: ToastSuccessStyles,
          })
        );
        cancelHandler();
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };


  const [channels, setChannels] = useState([
    {
      name: "DEBIT",
      shortName: "debit",
    },
    {
      name: "CREDIT",
      shortName: "credit",
    },
  ]);

  const [rails, setRails] = useState([
    {
      name: "MPGS",
      shortName: "MPGS",
    },
    {
      name: "VISA",
      shortName: "VISA",
    },
    {
      name: "VERVE",
      shortName: "VERVE",
    },
  ]);

  const [allowedcurrencies, setAllowedCurrencies] = useState([
    {
      name: "Nigeria Naira",
      shortName: "NGN",
      currencyId: 1,
      id: 1,
    },
  ]);

  useEffect(() => {
    Curency.getCurrencies()
      .then((res: any) => setAllowedCurrencies(res?.data?.data))
      .catch((err: any) => console.log(err));
  }, []);
  return (
    <div className={styles.modalwrapper}>
      <div className={styles.modalhead}>
        <h3 className={styles.modalheadh3}>
          {identifier === "add" ? "Post New Operation" : "Treat Operation"}
        </h3>
        <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
      </div>
      <Divider style={{ margin: 0, padding: 0 }} />
      <div className={styles.modalcontent}>
        <Form
          form={form}
          initialValues={INITIALVALUES}
          layout="vertical"
          name="nest-messages"
          onFinish={updatedHandler}
          validateMessages={validateMessages}
        >
          <div className={styles.flexInput}>
            <Form.Item
              className="selectOptions"
              style={{ flex: 1, marginRight: "16px" }}
              label="Direction (Debit or Credit)"
              name="action"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              {/* <Input
                style={{ borderRadius: "7px" }}
                className={styles.input}
                placeholder="channel"
              /> */}
              <Select placeholder="Direction" allowClear>
                {channels?.length &&
                  channels?.map(({ name, shortName }, i) => (
                    <Option key={name} value={shortName}>
                      {name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            {/* <Form.Item
              style={{ flex: 1, marginRight: "16px" }}
              label="Subsidiary ID"
              name="subsidiaryId"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{ borderRadius: "7px" }}
                className={styles.input}
                placeholder="subsidiaryId"
              />
            </Form.Item> */}
            {/* <Form.Item
              style={{ flex: 1, marginRight: "16px" }}
              label="Payment Method"
              name="paymentMethod"
              className="selectOptions"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                defaultValue="Bank Transfer"
                placeholder="payment method"
                allowClear
              >
                {paymeth?.length &&
                  paymeth?.map(({ name, value }, i) => (
                    <Option key={i} value={value}>
                      {name}
                    </Option>
                  ))}
              </Select>
            </Form.Item> */}
            <Form.Item
              style={{ flex: 1, marginRight: "16px" }}
              label="Currency"
              name="currencyId"
              // initialValue={"NGN"}
              className="selectOptions"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <select
                value={INITIALVALUES.currencyId}
                style={{
                  width: "100%",
                  padding: "20px 10px",
                  borderColor: "#d9d9d9",
                  borderRadius: "10px",
                }}
                name="currencyId"
                id="currency"
              >
                {allowedcurrencies?.length &&
                  allowedcurrencies?.map(({ name, shortName, id, currencyId }) => (
                    <option key={shortName} value={id ?? currencyId}>
                      {`${shortName} (${name})`}
                    </option>
                  ))}
              </select>
              {/* <Select>
                {allowedcurrencies?.length &&
                  allowedcurrencies?.map(({ name, short_name }) => (
                    <Option key={short_name} value={short_name}>
                      {`${name}(${short_name})`}
                    </Option>
                  ))}
              </Select> */}
            </Form.Item>
            <Form.Item
              style={{ flex: 1, marginRight: "16px" }}
              label="Amount"
              name="amount"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                type="number"
                style={{ borderRadius: "7px" }}
                className={styles.input}
                placeholder="amount"
              />
            </Form.Item>

            <Form.Item
              style={{ flex: 1, marginRight: "16px" }}
              label="Fee"
              name="fee"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                type="number"
                style={{ borderRadius: "7px" }}
                className={styles.input}
                placeholder="fee"
              />
            </Form.Item>
            <Form.Item
              style={{ flex: 1, marginRight: "16px" }}
              label="Remarks"
              name="remark"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{ borderRadius: "7px" }}
                className={styles.input}
                placeholder="remark"
              />
            </Form.Item>
            <Form.Item name="status" label="" valuePropName="checked">
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>Status</p> <Switch checked={INITIALVALUES.status == "ACTIVE"} onChange={(value) => handleInputChange("status", value ? "ACTIVE" : "INACTIVE")} />
              </div>
            </Form.Item>
          </div>

          <Divider />

          <div className={styles.buttonflex}>
            <Form.Item shouldUpdate>
              {() => (
                <button type="submit" className={styles.submitHandler}>
                  <div>
                    <img src={ChargebackIcon} alt="chargeback" />
                  </div>
                  <div className={styles.ml}>
                    {identifier === "edit" ? "Treat Operation" : "Submit Operation"}
                  </div>
                </button>
              )}
            </Form.Item>

            <button onClick={cancelHandler} className={styles.cancel}>
              <div>
                <img src={TimesIcon} alt="chargeback" />
              </div>{" "}
              <div className={styles.ml}>Cancel</div>
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default PostBalanceOperation;
