import React, { useState } from 'react';
import FraudOther from '../Fraudothersummary copy/FraudOther';
import Fraud from '../Fraudsummary/Fraud';
import styles from './FraudTab.module.scss';

function FraudTab() {
	interface dataTypes {
		id: Number;
		menu: String;
	}
	const [selectedNav, setSelectedNav] = useState<Number>(1);
	const dataRef: dataTypes[] = [
		{
			id: 1,
			menu: 'Chargeback summary',
		},
		{
			id: 2,
			menu: 'Fraud summary',
		},
	];
	return (
		<>
			<div className={styles.tabContainer}>
				{dataRef.map(({ id, menu }: dataTypes, i) => (
					<div
						key={i}
						onClick={() => {
							setSelectedNav(id);
						}}
						className={
							selectedNav === id ? styles.activeMenuItem : styles.subMenuItem
						}>
						{selectedNav === id ? (
							<div className={styles.activeItemColor}>{menu}</div>
						) : (
							<div style={{ color: 'rgba(10, 9, 3, 0.6)' }}>{menu}</div>
						)}
					</div>
				))}
			</div>

			<div>
				{selectedNav === 1 ? (
					<Fraud />
				) : selectedNav === 2 ? (
					<FraudOther />
				) : (
					''
				)}
			</div>
		</>
	);
}

export default FraudTab;
