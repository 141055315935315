import React, { useEffect } from "react";
import styles from "./Business.module.scss";
import Modal from "react-modal";
import { computeCustomStyles } from "../../../constants/modalStyles";
import Grid from '@mui/material/Grid';
import Fees from "../../Fee&limit/Fees/Fees";
import Collection from "../../Fee&limit/Collection Limit/Collection";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@material-ui/icons";
import Button from "../../../components/button/Button";
import subsidiaryApi from '../../../api/subsidiaries';
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import { ToastErrorStyles, ToastSuccessStyles } from "../../../constants/toastStyles";
import { closeLoader, openLoader } from "../../../redux/actions/loader/loaderActions";

function SubsidiaryPreferenceSection({ display, subdiaryInfo, groupInformation }: any) {
  const dispatch = useDispatch();
  const [config, setConfig] = React.useState<any>(groupInformation?.configs || [])
  const updateSubsidiaryConfig = (envid: number, configName: string = "noAuthChargeEnabled") => {
    let env: any = getEnvConfig(envid);
    if (env == null) {
      env = { appEnvironmentId: envid, [configName]: true }
      config.push(env);
    }
    else {
      env[configName] = !env[configName]
    }
    setConfig([...config])
  }

  const getEnvConfig = (envid: number): any => config.find((x: any) => x.appEnvironmentId == envid)

  const updateSubsidiaryConfigHandler = () => {
    dispatch(openLoader())
    subsidiaryApi.updateSubsidiaryConfig(subdiaryInfo?.businessInformation?.id, config)
      .then((res: any) => {
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastSuccessStyles,
            toastContent: res?.data?.message,
          }))
      })
      .catch(err => {
        console.log({ xx: err.response })
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastErrorStyles,
            toastContent: err?.response?.data?.message,
          }))
      }).finally(() => {
        dispatch(closeLoader())
      });
  }

  useEffect(() => {
    setConfig(groupInformation?.configs || [])
  }, [groupInformation?.configs])
  return (
    <div
      style={{
        padding: '0 24px 24px 24px',
        display: display ? '' : 'none',
      }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <div className={styles.subsidiaryGroupWrapper2} onClick={() => updateSubsidiaryConfig(1)}>
            {getEnvConfig(1)?.noAuthChargeEnabled ? < CheckBoxOutlined /> : <CheckBoxOutlineBlank />} <div className={styles.detailsValue}>Enable 2D Charge On Test Environment</div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <div className={styles.subsidiaryGroupWrapper2} onClick={() => updateSubsidiaryConfig(2)}>
            {getEnvConfig(2)?.noAuthChargeEnabled ? < CheckBoxOutlined /> : <CheckBoxOutlineBlank />} <div className={styles.detailsValue}>Enable 2D Charge On Live Environment</div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <div className={styles.subsidiaryGroupWrapper2} onClick={() => updateSubsidiaryConfig(1, 'internationalChargeEnabled')}>
            {getEnvConfig(1)?.internationalChargeEnabled ? < CheckBoxOutlined /> : <CheckBoxOutlineBlank />} <div className={styles.detailsValue}>Enable International Charge On Test Environment</div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <div className={styles.subsidiaryGroupWrapper2} onClick={() => updateSubsidiaryConfig(2, 'internationalChargeEnabled')}>
            {getEnvConfig(2)?.internationalChargeEnabled ? < CheckBoxOutlined /> : <CheckBoxOutlineBlank />} <div className={styles.detailsValue}>Enable International Charge On Live Environment</div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button value="Save preferences" onClick={updateSubsidiaryConfigHandler} className={styles.downloadbutton} />
        </Grid>
      </Grid>
    </div>
  );
}

export default SubsidiaryPreferenceSection;
