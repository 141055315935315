import React, { useEffect, useState } from "react";
import { Modal, Menu, Input, Select, Button } from "antd";
import { FilterOutlined, RightOutlined } from "@ant-design/icons";
import styles from "./FilterButtonNew.module.scss";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import useApi from "../../hooks/useApi";
import currencyApi from "../../api/currencies";

const { Option } = Select;

interface FilterButtonNewProps {
  eventDate?: string;
  setEventDate?: React.Dispatch<React.SetStateAction<string>>;
  dropdown: boolean; // Use this state to control modal visibility
  setDropdown: React.Dispatch<React.SetStateAction<boolean>>; // Update this state to open/close modal
  setFromDate?: React.Dispatch<React.SetStateAction<Dayjs | null | string>>;
  setToDate?: React.Dispatch<React.SetStateAction<Dayjs | null | string>>;
  fromDate?: Dayjs | null | string;
  toDate?: Dayjs | null | string;
  filteredArray: {
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[];
  setBearer?: React.Dispatch<React.SetStateAction<boolean>>;
  clearHandler?: () => void; // Existing individual clear function
  clearAllHandler?: () => void; // Add this
  applyAllHandler?: () => void; // Add this
}

const FilterButtonNew: React.FC<FilterButtonNewProps> = ({
  filteredArray,
  dropdown,
  setDropdown, // Use this to control modal visibility
  setBearer,
  setFromDate: parentSetFromDate,
  setToDate: parentSetToDate,
  clearAllHandler,
  applyAllHandler,
}) => {
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(
    filteredArray?.[0]?.name
  );
  const [eventDate, setEventDate] = useState<string>("");
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const getCountriesApi = useApi(currencyApi.getCountries);
  const countries = getCountriesApi?.data?.data;

  // Fetch countries
  useEffect(() => {
    if (!countries && !getCountriesApi.loading) {
      getCountriesApi.request();
    }
  }, [countries, getCountriesApi]);

  const handleDateRangeClick = (range: string) => {
    const today = dayjs();
    setEventDate(range);
  
    let newFromDate: Dayjs | null = null;
    let newToDate: Dayjs | null = null;
  
    // Set new date range based on the selected range
    switch (range) {
      case "today":
        newFromDate = today;
        newToDate = today;
        break;
      case "last7days":
        newFromDate = today.subtract(7, "day");
        newToDate = today;
        break;
      case "last30days":
        newFromDate = today.subtract(30, "day");
        newToDate = today;
        break;
      case "oneyear":
        newFromDate = today.subtract(1, "year");
        newToDate = today;
        break;
      default:
        newFromDate = null;
        newToDate = null;
    }
  
    // Update local state
    setFromDate(newFromDate);
    setToDate(newToDate);
  
    // Update parent state if applicable
    if (parentSetFromDate) {
      parentSetFromDate(newFromDate ? newFromDate.format("YYYY-MM-DD 00:00:00") : null);
    }
    if (parentSetToDate) {
      parentSetToDate(newToDate ? newToDate.format("YYYY-MM-DD 23:59:59") : null);
    }
  };
  

  const fromDateHandler = (newValue: Dayjs | null) => {
    setFromDate(newValue); // Update local state
    if (parentSetFromDate) {
      parentSetFromDate(newValue ? newValue.format("YYYY-MM-DD 00:00:00") : null);
    }
  };

  const toDateHandler = (newValue: Dayjs | null) => {
    setToDate(newValue); // Update local state
    if (parentSetToDate) {
      parentSetToDate(newValue ? newValue.format("YYYY-MM-DD 23:59:59") : null);
    }
  };

  const applyHandler = () => {
    if (parentSetFromDate) {
      parentSetFromDate(fromDate ? fromDate.format("YYYY-MM-DD 00:00:00") : null);
    }
    if (parentSetToDate) {
      parentSetToDate(toDate ? toDate.format("YYYY-MM-DD 23:59:59") : null);
    }
    setDropdown(false);
    setBearer?.(true);
  };


  const renderFilterContent = () => {
    const currentFilter = filteredArray.find(
      (filter) => filter.name === selectedFilter
    );

    if (!currentFilter) return <div>Select a filter to apply</div>;

    const { name, value, setValue, selective, type } = currentFilter;

    if (type === "date") {
      return (
        <div>
          <h3>Enter Date Range</h3>
          <div className={styles.dateWrapper}>
            <p className={styles.dateWrapper_p}>Date range</p>
            <div className={styles.dateWrapper_content}>
              {["today", "last7days", "last30days", "oneyear"].map((range) => (
                <button
                  key={range}
                  className={styles.dateWrapper_contentbutton}
                  style={{
                    color: eventDate === range ? "white" : "#ADADAB",
                    background:
                      eventDate === range
                        ? "#8E173E"
                        : "rgba(195, 202, 198, 0.3)",
                  }}
                  onClick={() => handleDateRangeClick(range)}
                >
                  {range === "today"
                    ? "Today"
                    : range === "last7days"
                    ? "Last 7 Days"
                    : range === "last30days"
                    ? "30 Days"
                    : "1 Year"}
                </button>
              ))}
            </div>
          </div>

          <div
            style={{
              margin: "27px 0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{ width: "100%", maxWidth: "400px" }}>
                <MobileDatePicker
                  label="Start date"
                  inputFormat="MM/DD/YYYY"
                  disableFuture
                  value={fromDate}
                  onChange={fromDateHandler}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </div>

              <div style={{ margin: "16px 0", width: "100%", maxWidth: "400px" }}>
                <MobileDatePicker
                  label="End date"
                  inputFormat="MM/DD/YYYY"
                  disableFuture
                  value={toDate}
                  onChange={toDateHandler}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </div>
            </LocalizationProvider>
          </div>

          <div style={{ marginTop: "20px", textAlign: "right" }}>
            <Button
              onClick={() => {
                setFromDate(null);
                setToDate(null);
                setEventDate("");
              }}
              style={{
                borderColor: "#959595",
                color: "#959595",
                borderRadius:'5px',
                height:'fit-content',
                padding:'8px 24px',
                outline: "none",
                boxShadow: "none",
              }}
            >
              Clear
            </Button>
            <Button
              onClick={() => setBearer?.(true)}
              style={{
                marginLeft: 8,
                backgroundColor: "#959595",
                color: "white",
                border: "none",
                borderRadius:'5px',
                height:'fit-content',
                padding:'8px 24px',
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div>
        <h4>{name}</h4>
        {type === "text" && (
          <Input
            placeholder={`Enter ${name}`}
            value={value ? String(value) : ""}
            onChange={(e) => setValue(e.target.value)}
            style={{
              borderColor: "#8E173E",
              color: "#8E173E",
              outline: "none",
              boxShadow: "none",
            }}
          />
        )}
        {type === "select" && (
          <Select
            value={value}
            onChange={(val) => setValue(val)}
            style={{
              width: "100%",
              borderColor: "#8E173E",
              color: "#8E173E",
              outline: "none",
              boxShadow: "none",
            }}
            placeholder={`Select ${name}`}
          >
            {selective?.map((option: any, index: number) => (
              <Option
                key={index}
                value={currentFilter.selectHelper ? option.name : option.value}
              >
                {option.name}
              </Option>
            ))}
          </Select>
        )}

        <div style={{ marginTop: "20px", textAlign: "right" }}>
          <Button
            onClick={clearAllHandler}
            // onClick={() => setValue("")}
            style={{
              borderColor: "#959595",
              color: "#959595",
              borderRadius:'5px',
              height:'fit-content',
              padding:'8px 24px',
              outline: "none",
              boxShadow: "none",
              margin:'0px 10px'
            }}
          >
            Clear
          </Button>
          <Button
            onClick={() => setBearer?.(true)}
            style={{
              color: "white",
              background: "#959595",
              borderRadius:'5px',
              height:'fit-content',
              padding:'8px 24px',
              outline: "none",
              boxShadow: "none",
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      title={
        <div>
          <h4 style={{ fontWeight: "400", marginBottom: "8px" }}>Filter</h4>
          <p style={{ margin: 0, color: "#565656" }}>See results in your view based . on the filters you select here.</p>
        </div>
      }
      open={dropdown}
      onCancel={() => setDropdown(false)} // Close modal by updating dropdown state
      footer={
        <div style={{ textAlign: "right",margin:'0px 20px' ,background:'#F8F8F8' }}>
          <Button
            onClick={clearAllHandler}
            style={{
              borderColor: "#959595",
              color: "#959595",
              borderRadius:'5px',
              height:'fit-content',
              padding:'8px 24px',
              outline: "none",
              boxShadow: "none",
            }}
          >
            Clear All
          </Button>
          <Button
            onClick={applyAllHandler}
            style={{
              marginLeft: 8,
              backgroundColor: "#959595",
              color: "white",
              border: "none",
              borderRadius:'5px',
              height:'fit-content',
              padding:'8px 24px',
            }}
          >
            Apply All
          </Button>
        </div>
      }
      width="60%"
      bodyStyle={{ display: "flex", background:'#F8F8F8', minHeight: "60vh" }}
    >
      <Menu
        mode="inline"
        style={{ width: "30%" }}
        selectedKeys={[selectedFilter!]}
        onClick={(e) => setSelectedFilter(e.key)}
        items={filteredArray.map((filter, index) => ({
          key: filter.name,
          label: (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>{filter.name}</span>
              <span style={{ color: "#8E173E", fontWeight:'600' }}> <RightOutlined /> </span>
            </div>
          ),
          style: {
            padding:'0px 20px',
            color: selectedFilter === filter.name ? "#8E173E" : "#565656", // Purple for selected item
            fontWeight: selectedFilter === filter.name ? "500" : "normal",
            backgroundColor:
              selectedFilter === filter.name ? "rgba(142, 23, 62, 0.1)" : "inherit", // Light purple background for selected
          },
        }))}
      />
      <div style={{ flex: 1,margin:'0px 20px', background:'white', padding: "20px" }}>{renderFilterContent()}</div>
    </Modal>
  );
};

export default FilterButtonNew;
