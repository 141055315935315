export const ToastSuccessStyles = {
	fontFamily: 'HelveticaNeue',
	fontStyle: 'normal',
	fontWeight: 600,
	fontSize: '16px',
	lineHeight: '24px',
	display: 'flex',
	alignItems: 'center',
	//   textAlign: "center",
	letterSpacing: '-0.011em',
	color: '#008243',
	backgroundColor: '#EBF5F0',
	//   boxSizing: "border-box",
	borderRadius: '8px',
	justifyContent: 'center',
};

export const ToastErrorStyles = {
	fontFamily: 'HelveticaNeue',
	fontStyle: 'normal',
	fontWeight: 600,
	fontSize: '16px',
	lineHeight: '24px',
	display: 'flex',
	alignItems: 'center',
	//   textAlign: "center",
	letterSpacing: '-0.011em',
	color: '#E00000',
	backgroundColor: '#FFECEC',
	//   boxSizing: "border-box",
	borderRadius: '8px',
	justifyContent: 'center',
};
