import React, { useEffect, useLayoutEffect, useState } from 'react';
import { ChangeEvent } from 'react';
import styles from './Profile.module.scss';
import ImagePlaceholder from '../../../assets/images/Icons/image-placeholder.png';
import { ReactComponent as UploadIcon } from '../../../assets/images/Icons/upload-image.svg';
import { ReactComponent as SaveIcon } from '../../../assets/images/Icons/save.svg';
import { ReactComponent as ResetIcon } from '../../../assets/images/Icons/reset-password.svg';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import { useSelector, useDispatch } from 'react-redux';
import client, { formClient } from '../../../api/client';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import { truncate } from 'fs';
import { saveSubsidiaryInfo } from '../../../redux/actions/subsidiaryInfo/subsidiaryInfoActions';

const isAlphabets = (str: string) => /^[a-zA-Z ]*$/.test(str);
const isNumbers = (str: string) =>
	/^(\+234|234)(701|702|703|704|705|706|707|708|709|802|803|804|805|806|807|808|809|810|811|812|813|814|815|816|817|818|819|909|908|901|902|903|904|905|906|907)([0-9]{7})$/.test(
		str
	);
const isEmail = (str: string) =>
	/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
		str
	);
let regexcode = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
const Profile = () => {
	const { subsidiaryInfo } = useSelector(
		(state) => state.subsidiaryInfoReducer
	);

	// console.log('subsidiaryInfo', subsidiaryInfo);

	const { avatar, firstName, emailAddress, lastName, mobileNumber } =
		subsidiaryInfo;

	const fetchMe = async () => {
		try {
			const res: any = await client.get(`/me`);
			dispatch(saveSubsidiaryInfo(res.data.user));
			//new line
			// console.log('this is user details: ' ,res.data.user.id)
			setInputs(prevState => ({
				...prevState,
				adminID: res.data.user.id, // Update adminID
			  }));
		} catch (error: any) {
			const { message } = error.response.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useLayoutEffect(() => {
		fetchMe();
	}, []);

	interface initTypes {
		firstname: string;
		lastname: string;
		email: string;
		phone: string;
		avatarImage: any;
		adminID: string, // new line
	}
	interface anuTypes {
		password: string;
		newPassword: string;
	}

	const dispatch = useDispatch();
	const initProps: initTypes = {
		firstname: firstName,
		lastname: lastName,
		email: emailAddress,
		phone: mobileNumber,
		avatarImage: ImagePlaceholder,
		adminID: '', // new line
	};

	console.log({ initProps })
	const anuProps: anuTypes = {
		password: '',
		newPassword: '',
	};

	const [inputs, setInputs] = React.useState(initProps);
	const [inputPassword, setInputPassword] = React.useState(anuProps);

	const [errorText, setErrorText] = React.useState('');
	const [touchedFirstName, setTouchedFirstName] = React.useState(false);
	const [touchedLastName, setTouchedLastName] = React.useState(false);
	const [touchedNumber, setTouchNumber] = React.useState(false);
	const [touchedEmail, setEmail] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState('');
	const [errorMessageConfirm, setErrorMessageConfirm] = React.useState('');
	const [password, setPassword] = useState('');
	const [new_password, setNewPassword] = useState('');

	const [touchedPassword, setTouchedPassword] = React.useState(false);
	const [touchedPasswordConfirm, setTouchedPasswordConfirm] =
		React.useState(false);

	const [file, setFile] = useState('');

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;

		if (isAlphabets(newValue)) {
			setErrorText('');
			setInputs((prevState: initTypes) => {
				return {
					...prevState,
					[inputName]: newValue,
				};
			});
		} else {
			setErrorText('Please input alphabets only');
		}
	};

	const updateEmailProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;

		if (isEmail(newValue)) {
			setErrorText('');
			setInputs((prevState: initTypes) => {
				return {
					...prevState,
					[inputName]: newValue,
				};
			});
		} else {
			setErrorText('Please input alphabets only');
		}
	};

	const updatePropsNumbers = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;

		if (newValue && isNumbers(newValue)) {
			setErrorText('');
			setInputs((prevState: initTypes) => {
				return {
					...prevState,
					[inputName]: newValue,
				};
			});
		} else {
			setErrorText('Please input numbers only');
		}
	};

	const updatePropsPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;

		if (regexcode.test(newValue)) {
			setErrorMessage('');
			setPassword(newValue);
		} else {
			setErrorMessage(
				'Password must contain atleast one lowercase, uppercase, digit & special case letter{.!@#$&*_}.'
			);
		}
	};

	const updatePropsPasswordConfirm = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const newValue = event.target.value;
		const inputName = event.target.name;

		if (password === newValue) {
			setErrorMessageConfirm('');
			setNewPassword(newValue);
		} else {
			setErrorMessageConfirm('Password must match');
		}
	};

	const updateImageProps = (event: any) => {
		const newValue = event?.target?.files[0];
		const inputName = event?.target?.name;
		setInputs((prevState: any) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};

	let formData = new FormData();

	const disableFunction = () => {
		if (inputs.firstname && inputs.lastname && inputs.email && inputs.phone) {
			return true;
		}
	};

	formData.append('firstname', inputs.firstname); //append the values with key, value pair
	formData.append('lastname', inputs.lastname);
	formData.append('emailaddress', inputs.email);
	formData.append('mobilenumber', inputs.phone);
	formData.append('avatar', inputs?.avatarImage);

	const handleSave = (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch(openLoader());
		client
			.post('user/update', formData)
			.then((data: any) => {
				dispatch(closeLoader());
				dispatch(
					openToastAndSetContent({
						toastContent: data?.data?.message,
						toastStyles: ToastSuccessStyles,
					})
				);
				fetchMe();
			})
			.catch((error: any) => {
				dispatch(closeLoader());
				const { message } = error.response.data;
				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			});
	};

	const changePassword = (e: React.SyntheticEvent) => {
		dispatch(openLoader());
		client
			.post(`user/password/change`, {
				password,
				new_password,
			})
			.then((res: any) => {
				dispatch(closeLoader());
			})
			.catch((error) => {
				dispatch(closeLoader());
				if (error.response) {
					const message = error?.response?.data?.message;
					dispatch(
						openToastAndSetContent({
							toastStyles: ToastErrorStyles,
							toastContent: message,
						})
					);
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			});
	};

	const handleFirstName = () => {
		setTouchedLastName(false);
		setTouchNumber(false);
		setEmail(false);
		setTouchedFirstName(true);
		setTouchedPassword(false);
	};

	const handleLastName = () => {
		setTouchedLastName(true);
		setTouchNumber(false);
		setEmail(false);
		setTouchedFirstName(false);
		setTouchedPassword(false);
	};

	const handleEmail = () => {
		setTouchedLastName(false);
		setTouchNumber(false);
		setEmail(true);
		setTouchedFirstName(false);
		setTouchedPassword(false);
	};

	const handlePhoneNumber = () => {
		setTouchedLastName(false);
		setTouchNumber(true);
		setEmail(false);
		setTouchedFirstName(false);
		setTouchedPassword(false);
	};

	return (
		<div className={styles.profile}>
			<div className={styles.profileCard}>
				<div className={styles.logoText}>Profile Picture</div>
				<div className={styles.imagePlaceholder}>
					<img
						src={inputs?.avatarImage ? inputs?.avatarImage : avatar}
						alt='logo-placeholder'
						width={100}
						height={100}
						style={{ borderRadius: '8px' }}
					/>
				</div>
				<div className={styles.uploadButton}>
					<UploadIcon />
					<input
						type='file'
						name='avatar'
						// value={inputs?.avatar}
						onChange={updateImageProps}
					/>
				</div>
			</div>
			<div className={styles.profileCard} style={{ marginTop: '30px' }}>
				<div className={styles.logoText}>User information</div>

				<div className={styles.profileForm}>
					<form className={styles.form}>
						<OutlineTextInput
							handleChange={updateProps}
							inputName='firstname'
							inputLabel='First Name'
							inputValue={inputs.firstname}
							onFocus={handleFirstName}
							InputHelper={touchedFirstName && errorText}
							error={touchedFirstName && errorText}
						/>
						<OutlineTextInput
							handleChange={updateProps}
							inputName='lastname'
							inputLabel='Last Name'
							inputValue={inputs.lastname}
							onFocus={handleLastName}
							InputHelper={touchedLastName && errorText}
							error={touchedLastName && errorText}
						/>
						<OutlineTextInput
							// handleChange={updateEmailProps}
							inputName='email'
							inputLabel='Email address'
							inputValue={inputs.email}
							inputType='email'
							// onFocus={handleEmail}
							InputHelper={touchedEmail && errorText}
							error={touchedEmail && errorText}
						/>
						<OutlineTextInput
							handleChange={updatePropsNumbers}
							inputName='phone'
							inputLabel='Phone number'
							inputValue={inputs.phone}
							onFocus={handlePhoneNumber}
							InputHelper={touchedNumber && errorText}
							error={touchedNumber && errorText}
						/>
					</form>
					<div className={styles.uploadButton} onClick={handleSave}>
						{disableFunction() ? (
							<div className={styles.deactivate}>
								<SaveIcon /> <span>Save changes</span>
							</div>
						) : (
							<div className={styles.notClickable}>
								{' '}
								<SaveIcon /> <span>Save changes</span>{' '}
							</div>
						)}
					</div>
				</div>
			</div>

			{/* <div className={styles.profileCard} style={{ marginTop: '30px' }}>
				<div className={styles.logoText}>Password</div>
				<div className={styles.subText}>Update your password</div>
				<div className={styles.profileForm}>
					<form className={styles.form}>
						<input
							type='password'
							className={styles.input}
							onChange={updatePropsPassword}
							name='password'
							placeholder='password'
						/>
						<p style={{ color: 'red' }}>{errorMessage}</p>

						<input
							type='password'
							className={styles.input}
							onChange={updatePropsPasswordConfirm}
							name='new_password'
							placeholder='confirm your password'
						/>
						<p style={{ color: 'red' }}>{errorMessageConfirm}</p>
					</form>
					<div
						className={styles.uploadButton}
						onClick={(e: any) => {
							changePassword(e);
						}}>
						<ResetIcon /> <span>Reset password</span>
					</div>
				</div>
			</div> */}
		</div>
	);
};

export default Profile;
