import React from 'react';

export interface ProviderData {
	providerName: string;
	providerShortName: string;
	currency: string;
	date: string;
	action?: React.ReactNode;
}

export const ColumnProvider = [
	{
		Header: 'Provider name',
		accessor: 'providerName',
	},
	{
		Header: 'Provider short name',
		accessor: 'providerShortName',
	},
	{
		Header: 'Currency',
		accessor: 'currency',
	},
	{
		Header: 'Date',
		accessor: 'date',
	},
	{
		Header: '',
		accessor: 'action',
	},
];
