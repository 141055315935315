import React, { useState, Fragment, useEffect } from 'react';
import styles from './BalanceOperation.module.scss';
import PaginationTable from '../../../components/table/pagination-table';
import {
	RollingReserveData,
	ColumnRollingReserve,
} from '../../../types/TablesTypes/RollingReserve';
import TableHeader from '../../../components/TableHeader/TableHeader';
import { numberWithCommas } from '../../../utils/formatNumber';
import { useDispatch, useSelector } from 'react-redux';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import moment from 'moment';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import client from '../../../api/client';
import {
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import Filtered from '../../../components/filterComponent/Filtered';
import { menuContent } from '../../../utils/menuContent';
import StatusView from '../../../components/StatusView/StatusView';
import FilterModal from '../../../components/filterConfig/FilterModal';
import { Dayjs } from 'dayjs';
import { useParams } from 'react-router-dom';
import { BalanceOperationData, ColumnBalanceOperation } from '../../../types/TablesTypes/BalanceOperation';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import AddChannel from '../../Channels/AddChannel/AddChannel';
import PostBalanceOperation from './PostBalanceOperation/PostBalanceOperation';
import OperationDetails from './OperationDetails/OperationDetails';

const BalanceOperation = () => {
	const params: any = useParams();
	const id = params.id;
	const [tableRow, setTableRow] = useState<any[]>();
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [iden, setIden] = useState<string>('');
	const [customerDetails, setCustomerDetails] = useState<any>([]);
	const [dropdown, setDropdown] = useState(false);
	const { currency } = useSelector((state) => state.appSettingsReducer);
	const dispatch = useDispatch();
	const [operationDetails, setOperationDetails] = useState<any>({
		isOpen: false,
		data: null
	});

	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);
	const [bearer, setBearer] = useState(false);
	const clearHandler = () => {
		setFromDate('');
		setToDate('');
		setDropdown(false);
		setBearer(true);
	};

	const filteredArray: any = [];
	function closeModal() {
		setIsOpen(false);
		fetchBalanceOperation()
	}

	function closeModalV2() {
		setOperationDetails({ isOpened: false })
		fetchBalanceOperation()
	}

	const addHandler = () => {
		setIden('add');
		setIsOpen(true);
	};

	const fetchBalanceOperation = () => {
		dispatch(openLoader());
		client
			.get(`/wallet/operations?currency=${currency}&subsidiaryId=${id}`)
			.then((res: any) => {
				setCustomerDetails(res.data);
				dispatch(closeLoader());
			})
			.catch((error) => {
				dispatch(closeLoader());
				if (error.response) {
					const { message } = error?.response?.data;
					dispatch(
						openToastAndSetContent({
							toastStyles: ToastErrorStyles,
							toastContent: message,
						})
					);
				} else if (error.request) {
					console.log(error.request);
				} else if (error?.response?.data?.message === 'Please login again') {
					sessionStorage.clear();
					window.location.href = '/';
				} else {
					console.log('Error', error.message);
				}
			});
	};
	useEffect(() => {
		fetchBalanceOperation();
	}, [currency]);

	const dataPaymentLinks = () => {
		const tempArr: BalanceOperationData[] = [];
		customerDetails?.data?.items.reverse()
			.forEach((balance: any, index: number) => {
				tempArr.push({
					...balance,
					dateCreated: moment(balance?.dateCreated).format('MMMM Do YYYY, h:mm a'),
					dateApproved: moment(balance?.dateApproved).format('MMMM Do YYYY, h:mm a'),
					transactionAmount: `${balance?.currencyShortName || currency} ${numberWithCommas(balance?.transactionAmount)}`,
					fee: `${balance?.currencyShortName || currency} ${numberWithCommas(balance?.fee)}`,
					currency: balance?.currencyShortName || currency,
					subsidiaryName: balance?.subsidiaryName,
					direction: balance?.direction,
					approvedByName: balance?.approvedByName ?? "Not Approved",
					id: balance?.id,
					status: (
						<StatusView
							status={balance?.operationStatus}
							green={'Successful'}
							orange={'Initiated'}
							red={'Failed'}
						/>
					),
					// action: <BalanceMenu data={balance} />,
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataPaymentLinks());
	}, [customerDetails?.data?.items]);
	return (
		<Fragment>
			<TableHeader
				pageName='Balance Operations'
				data={customerDetails?.data?.items}
				dataLength={customerDetails?.data?.page?.total}
				dropdown={dropdown}
				setDropdown={setDropdown}
				newButton={
					<div onClick={addHandler} className={styles.createLink}>
						Post New Operation
					</div>
				}
				FilterComponent={
					<>
						<FilterModal
							// eventDate={eventDate}
							// setEventDate={setEventDate}
							dropdown={dropdown}
							setDropdown={setDropdown}
							setFromDate={setFromDate}
							setToDate={setToDate}
							fromDate={fromDate}
							toDate={toDate}
							setBearer={setBearer}
							clearHandler={clearHandler}
							filteredArray={filteredArray}
						/>
					</>
				}
			/>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}>
				<PostBalanceOperation
					closeModal={closeModal}
					identifier={iden}
					subsidiaryId={id}
					setIden={setIden}
				/>
			</Modal>
			<Modal
				isOpen={operationDetails.isOpened}
				onRequestClose={closeModalV2}
				contentLabel='Example Modal'
				style={customStyles}>
				<OperationDetails
					closeModal={closeModalV2}
					identifier={iden}
					subsidiaryId={id}
					setIden={setIden}
					data={operationDetails.data}
				/>
			</Modal>
			{customerDetails.loading ? (
				<div className={styles.loading} />
			) : (
				<PaginationTable
					data={tableRow ? tableRow : []}
					columns={ColumnBalanceOperation ? ColumnBalanceOperation : []}
					emptyPlaceHolder='You currently do not have any operation'
					total={customerDetails?.data?.page?.total}
					totalPage={customerDetails?.data?.page?.totalPage}
					pageNumber={pageNumber}
					setPageNumber={setPageNumber}
					nextPage={nextPage}
					clickAction={(bus => setOperationDetails({ isOpened: true, data: bus }))}
					setNextPage={setNextPage}
					previousPage={previousPage}
					setPreviousPage={setPreviousPage}
					rowsPerPage={rowsPerPage}
					setRowsPerPage={setRowsPerPage}

				/>
			)}
		</Fragment>
	);
};

export default BalanceOperation;
