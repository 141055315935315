import { makeStyles } from "@material-ui/core";

export const useModalStyles = makeStyles({
    root: {
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-input.MuiOutlinedInput-input':
        {
            textAlign: 'center',
            padding: '8.1px 14px',
        },
    },
    select: {
        '& .MuiOutlinedInput-root': {
            color: '#414141',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '16px',
            borderRadius: '10px',
        },
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            outline: 'none',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
            backgroundColor: '#ffffff',
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#E0E0E0',
            backgroundColor: '#ffffff',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E0E0E0',
        },
    },
});