import React, { useState, useEffect } from 'react';
import styles from './Breakdown.module.scss';
import { useParams, useHistory } from 'react-router';
import axios from 'axios';
import client from '../../../../api/client';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../../redux/actions/toast/toastActions';
import {
	closeLoader,
	openLoader,
} from '../../../../redux/actions/loader/loaderActions';
import moment from 'moment';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import StatusView from '../../../../components/StatusView/StatusView';
import { ReactComponent as File } from '../../../../assets/images/Icons/u_fileu.svg';
import { ReactComponent as Exclaim } from '../../../../assets/images/Icons/u_exclaim.svg';
import Checkbox from '@mui/material/Checkbox';

const Breakdown = () => {
	const [customerDetails, setCustomerDetails] = useState<any>([]);
	const { id } = useParams<{ id: any }>();
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	// useEffect(() => {
	// 	dispatch(openLoader());
	// 	setIsLoading(true);
	// 	client
	// 		.get(`subsidiary/dashboard/fetch/customers/orders?customers?id=${id}`)
	// 		.then((res: any) => {
	// 			setCustomerDetails(res?.data?.items[0]);
	// 			setIsLoading(false);
	// 			dispatch(closeLoader());
	// 		})
	// 		.catch((error) => {
	// 			dispatch(closeLoader());
	// 			setIsLoading(false);
	// 			if (error.response) {
	// 				const { message } = error?.response?.data;
	// 				dispatch(
	// 					openToastAndSetContent({
	// 						toastStyles: ToastErrorStyles,
	// 						toastContent: message,
	// 					})
	// 				);
	// 			} else if (error?.response?.data?.message === 'Please login again') {
	// 				sessionStorage.clear();
	// 				window.location.href = '/';
	// 			} else if (error.request) {
	// 				console.log(error.request);
	// 			} else {
	// 				console.log('Error', error.message);
	// 			}
	// 		});
	// }, []);

	return (
		<div>
			{isLoading ? (
				<div />
			) : (
				<>
					<div className={styles.transactionsHeaderflex}>
						<div
							style={{
								color: '#211F01',
								display: 'inline',
								cursor: 'pointer',
								fontWeight: '800px',
							}}
							onClick={() => history.goBack()}>
							<ArrowBackIosIcon sx={{ fontSize: '20px' }} /> Back to Chargeback
							breakdown
						</div>
					
					</div>

					<div className={styles.detailBox}>
						<div className={styles.detailBoxCorner}>
							<div className={styles.transactionsHeaderleft}>
								<h3>NGN 45,000.00</h3>
								<StatusView
									status='successful'
									red='New'
									orange='Pending-Verification'
									green='successful'
								/>
							</div>
						</div>

						<Divider style={{ color: '#CECECD' }} />
						<div
							style={{
								padding: '0 24px',
							}}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Transaction reference
										</div>
										<div className={styles.detailsKey}>
											SW-ab84jk03ld25pr89{' '}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Date</div>
										<div className={styles.detailsKey}>12/02/2013</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Provider reference
										</div>
										<div className={styles.detailsKey}>SW-ab84jk03</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Merchant name</div>
										<div className={styles.detailsKey}>The Peters</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Transaction fee</div>
										<div className={styles.detailsKey}>NGN 800,000</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Fees charged</div>
										<div className={styles.detailsKey}>NGN 800,000</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Reference</div>
										<div className={styles.detailsKey}>
											SW-ab84jk03ld25pr89{' '}
										</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Payment type</div>
										<div className={styles.detailsKey}>Card</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Type</div>
										<div className={styles.detailsKey}>Merchant refund</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Cost</div>
										<div className={styles.detailsKey}>NGN 800,000</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Income</div>
										<div className={styles.detailsKey}>NGN 800,000</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Narration</div>
										<div className={styles.detailsKey}>
											This is a refund of your money, sir
										</div>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Breakdown;
