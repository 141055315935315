import React, { useState, Fragment, useEffect } from 'react';
import styles from './ProviderApproval.module.scss';
import PaginationTable from '../../../components/table/pagination-table';
import {
	ColumnProvider,
	ProviderData,
} from '../../../types/TablesTypes/provider';
import StatusView from '../../../components/StatusView/StatusView';
import TableHeader from '../../../components/TableHeader/TableHeader';
import ViewIcon from '../../../assets/images/Icons/view-details.svg';
import TransactionsMenu from '../../../components/MenuOption/TransactionsMenu/TransactionsMenu';
import transactionsApi from '../../../api/transactions';
import useApi from '../../../hooks/useApi';
import client from '../../../api/client';
import { numberWithCommas } from '../../../utils/formatNumber';
import moment from 'moment';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	AppstoreOutlined,
	MailOutlined,
	SettingOutlined,
} from '@ant-design/icons';
import Filtered from '../../../components/filterComponent/Filtered';
import { menuContent } from '../../../utils/menuContent';
import Mocked from './providerapproval.json';
import RefundsMenu from '../../../components/MenuOption/RefundsMenu/RefundsMenu';
import { Menu, Dropdown as MenuFunc, Row, Col } from 'antd';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import { ErrorMessage, Form, Formik } from 'formik';
import Mark from '../../../assets/images/Icons/u-mark.svg';
import Times from '../../../assets/images/Icons/u_multiply (2).svg';
import { Divider, Checkbox } from 'antd';
import { Grid } from '@material-ui/core';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

import * as Yup from 'yup';
import Approved from '../Approved/Approved';
import Declined from '../Declined/Declined';
import MenuOption from '../../../components/MenuOption/MenuOption';

const ProviderApproval = () => {
	const dispatch = useDispatch();
	const [status, setStatus] = useState<string>('');
	const [value, setValue] = useState('');
	const [reason, setReason] = useState('');
	const [details, setDetails] = useState<any>({});
	const [tableRow, setTableRow] = useState<any[]>();
	const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
	const [modalIsOpenApproved, setIsOpenApproved] = React.useState(false);
	const [modalIsOpenDeclined, setIsOpenDeclined] = React.useState(false);

	const [provider, setProvider] = useState<any>();
	const onChange = (checkedValues: CheckboxValueType[]) => {
		console.log('checked = ', checkedValues);
	};
	const plainOptions = ['Apple', 'Pear', 'Orange'];
	interface initTypes {
		providerName: string;
		providerShortName: string;
		currency: string;
		qr: string;
		internetbanking: string;
	}

	const initProps: initTypes = {
		providerName: '',
		providerShortName: '',
		currency: '',
		qr: '',
		internetbanking: '',
	};

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	const currencyOption = [{ name: 'USD' }, { name: 'NGN' }];
	const validate = Yup.object({
		country: Yup.string().required('Required'),
		payment_type: Yup.string().required('Required'),
		percentage_value: Yup.string().required('Required'),
		flat_value: Yup.string().required('Required'),
		currency: Yup.string().required('Required'),
	});
	// const [apiObject, setApiObject] = useState(null);

	// const { keyB: trxRef } = apiObject?.keyA || [{

	// }];

	function closeModalSingle() {
		setIsOpenSingle(false);
	}
	function closeModalApproved() {
		setIsOpenApproved(false);
	}
	function closeModalDeclined() {
		setIsOpenDeclined(false);
	}

	//for filtering
	const [valueChannel, setValueChannel] = useState('');
	const [valueStatus, setValueStatus] = useState('');
	const [dropdown, setDropdown] = useState(false);

	const menuOptions = [
		{
			id: 1,
			name: () => {
				return 'View provider details';
			},
			icon: ViewIcon,
			onclick: (details: any, e: any) => {
				setDetails(details);
				setIsOpenSingle(true);
			},
		},
	];

	const fetchApproval = async () => {
		dispatch(openLoader());
		try {
			const res: any = await client.get(
				`/pricing/pending/provider?limit=${rowsPerPage}&page=${pageNumber}`
			);
			setProvider(res.data.data);
			dispatch(closeLoader());
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};

	useEffect(() => {
		fetchApproval();
	}, [pageNumber, rowsPerPage]);

	useEffect(() => {
		setPageNumber(provider?.page?.currentPage || 1);
		setPreviousPage(provider?.page?.previousPage);
		setNextPage(provider?.page?.nextPage);
	}, [provider]);

	const dataTransaction = () => {
		const tempArr: ProviderData[] = [];
		provider?.items
			?.slice(0)
			.reverse()
			.forEach((transaction: any, index: number) => {
				tempArr.push({
					providerName: transaction?.provider,
					providerShortName: transaction?.shortName,
					currency: transaction?.currency,
					date: transaction?.dateCreated,
					action: (
						<MenuOption
							menuContent={
								<div>
									{menuOptions.map((option: any) => (
										<div
											key={option?.id}
											onClick={(e) => {
												option?.onclick(transaction, e);
											}}
											className={styles.menuContent}>
											<img
												src={option?.icon}
												alt={option?.name()}
												width={24}
												height={24}
											/>
											<div className={styles.optionName}>{option?.name()}</div>
										</div>
									))}
								</div>
							}
						/>
					),
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransaction());
	}, [provider?.items]);

	return (
		<div style={{ marginTop: '38px' }}>
			<TableHeader
				pageName='Pending Approval'
				dataLength={provider?.page?.total}
				data={provider?.items}
				value={value}
				setValue={setValue}
				exporting={false}
				filtering={false}
				searchfn={false}
				FilterComponent={
					<>
						<Filtered
							menuContent={menuContent}
							dropdown={dropdown}
							setDropdown={setDropdown}
							valueChannel={valueChannel}
							setValueChannel={setValueChannel}
							valueStatus={valueStatus}
							setValueStatus={setValueStatus}
							status={true}
							channel={true}
						/>
					</>
				}
			/>

			<PaginationTable
				data={tableRow ? tableRow : []}
				columns={ColumnProvider ? ColumnProvider : []}
				value={value}
				emptyPlaceHolder={
					provider?.page?.total == 0
						? 'You currently do not have any data'
						: 'Loading...'
				}
				total={provider?.page?.total}
				totalPage={provider?.page?.totalPage}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				nextPage={nextPage}
				setNextPage={setNextPage}
				previousPage={previousPage}
				setPreviousPage={setPreviousPage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
			/>

			{/* //MODAL FOR SINGLE */}
			<Modal
				isOpen={modalIsOpenSingle}
				onRequestClose={closeModalSingle}
				contentLabel='Example Modal'
				style={customStyles}>
				<div className={styles.modalwrapper}>
					<div className={styles.modalhead}>
						<h3 className={styles.modalheadh3}>Provider approval</h3>
						<CloseIcon
							onClick={closeModalSingle}
							style={{ cursor: 'pointer' }}
						/>
					</div>
					<Divider style={{ margin: 0, padding: 0 }} />
					<div
						style={{
							padding: '32px 24px',
						}}>
						<Row gutter={[16, 24]}>
							<Col span={8}>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Provider ID</div>
									<div className={styles.detailsKey}>{details?.id}</div>
								</div>
							</Col>
							<Col span={8}>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Date created</div>
									<div className={styles.detailsKey}>
										{moment(details?.dateCreated).format(
											'MMMM Do YYYY, h:mm a'
										)}
									</div>
								</div>
							</Col>
							<Col span={8}>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Provider</div>
									<div className={styles.detailsKey}>{details?.provider}</div>
								</div>
							</Col>
							<Col span={24}>
								<textarea
									value={reason}
									onChange={(e) => setReason(e.target.value)}
									rows={4}
									cols={50}
									style={{ width: '100%', padding: '5px 10px' }}
									placeholder='Give your reason'
								/>
							</Col>

							{/* <Col span={24}>
								<div className={styles.customerInfoSingle}>
									<Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
										<Row gutter={[16, 8]}>
											<Col span={24}>
												<Checkbox value='A'>
													International card transaction
												</Checkbox>
											</Col>
											<Col span={24}>
												<Checkbox value='B'>
													International account transaction
												</Checkbox>
											</Col>
											<Col span={24}>
												<Checkbox value='C'>Local card transaction</Checkbox>
											</Col>
											<Col span={24}>
												<Checkbox value='D'>Local account transaction</Checkbox>
											</Col>
											<Col span={24}>
												<Checkbox value='E'>USSD transaction</Checkbox>
											</Col>
										</Row>
									</Checkbox.Group>
								</div>
							</Col> */}
							{/* <Col span={12}>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>
										Internet banking transaction cost
									</div>
									<div className={styles.detailsKey}>NGN 5,005.98</div>
								</div>
							</Col>
							<Col span={12}>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>
										QR code transaction cost
									</div>
									<div className={styles.detailsKey}>NGN 5,005.98</div>
								</div>
							</Col> */}
							<Col span={12}>
								<button
									onClick={() => setIsOpenDeclined(true)}
									className={styles.declinebutton}>
									<div style={{ marginRight: '8px' }}>
										<img src={Times} alt='chargeback' />
									</div>
									<div className={styles.ml}>Decline</div>
								</button>
							</Col>
							<Col span={12}>
								<button
									onClick={() => setIsOpenApproved(true)}
									className={styles.approvebutton}>
									<div style={{ marginRight: '8px' }}>
										<img src={Mark} alt='chargeback' />
									</div>
									<div className={styles.ml}>Approve</div>
								</button>
							</Col>
						</Row>
					</div>
				</div>
			</Modal>

			{/* //MODAL FOR APPROVED */}
			<Modal
				isOpen={modalIsOpenApproved}
				onRequestClose={closeModalApproved}
				contentLabel='Example Modal'
				style={customStyles}>
				<Approved
					reason={reason}
					details={details}
					closeModal={closeModalApproved}
					identifier='provider'
					fn={fetchApproval}
				/>
			</Modal>

			{/* //MODAL FOR DECLINED */}
			<Modal
				isOpen={modalIsOpenDeclined}
				onRequestClose={closeModalDeclined}
				contentLabel='Example Modal'
				style={customStyles}>
				<Declined
					reason={reason}
					details={details}
					closeModal={closeModalDeclined}
					identifier='provider'
					fn={fetchApproval}
				/>
			</Modal>
		</div>
	);
};

export default ProviderApproval;
