import React from "react";
import styles from "./Emailverified.module.scss";
import OutlineTextInput from "../../components/input/OutlineTextInput";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import client from "../../api/client";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { saveSubsidiaryInfo } from "../../redux/actions/subsidiaryInfo/subsidiaryInfoActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../constants/toastStyles";
import Img from "../../assets/images/Logos/landing.png";
import logo from "../../assets/images/Logos/paymentgateway.png";

const EmailVerified = () => {
  const { pathname } = useLocation();
  const token = pathname?.split("/")[2];

  const todaysDate = new Date();
  const dispatch = useDispatch();
  const history = useHistory();
  interface initTypes {
    password?: string;
  }

  const initProps: initTypes = {
    password: "",
  };
  const [inputs, setInputs] = React.useState(initProps);

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    console.log(inputName);
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    client
      .post("user/verify/account", {
        token,
        password: inputs.password,
      })
      .then((data: any) => {
        dispatch(closeLoader());
        const { status, message } = data.data;

        if (status === "success") {
          history.push("/dashboard");
        } else {
          dispatch(
            openToastAndSetContent({
              toastContent: message,
              toastStyles: ToastErrorStyles,
            })
          );
        }
      })
      .catch((error: any) => {
        // <ErrorToast error={error} />;
        if (error.response) {
          dispatch(closeLoader());
          const { message } = error.response.data;
          dispatch(
            openToastAndSetContent({
              toastContent: message,
              toastStyles: ToastErrorStyles,
            })
          );
        } else if (error.request) {
          dispatch(closeLoader());
        } else {
          dispatch(closeLoader());
        }
      });
  };

  return (
    <div className={styles.signWrapper}>
      <div className={styles.leftwrapper}>
        <div className={styles.image}>
          <img src={logo} alt="" />
        </div>
        <div className={styles.formwrapper}>
          <h1 className={styles.form_heading}>Verify your Account</h1>
          <p className={styles.form_p}>Enter a new Password</p>
          <div className={styles.businessForm}>
            <form onSubmit={handleSubmit} className={styles.form}>
              <OutlineTextInput
                style={{ marginTop: "20px" }}
                handleChange={updateProps}
                inputName="password"
                inputLabel="Password"
                inputType="password"
                inputValue={inputs.password}
              />
              <p className={styles.forgetPassword}>
                I have an account already?{" "}
                <span
                  onClick={() => history.push("/")}
                  className={styles.forgetPasswordspan}
                >
                  Sign in
                </span>
              </p>
              <button onClick={handleSubmit} className={styles.signInButton}>
                Verify
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className={styles.rightwrapper}>
        <img className={styles.rightwrapper_img} src={Img} alt="" />
      </div>
    </div>
  );
};

export default EmailVerified;
