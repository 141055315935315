import React, { useEffect, useState } from 'react';
import styles from './Roles.module.scss';
import rolesApi from '../../../api/roles';
import permissionsApi from '../../../api/permissions';
import useApi from '../../../hooks/useApi';
import { ReactComponent as MarkIcon } from '../../../assets/images/Icons/mark.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/Icons/Edity.svg';
import { ReactComponent as EditBlackIcon } from '../../../assets/images/Icons/editblack.svg';
import CreateRole from '../../../components/ModalContent/Role/CreateRole';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import client from '../../../api/client';
import EditRole from '../../../components/ModalContent/Role/EditRole';
import PaginationTable from '../../../components/table/pagination-table';
import { ColumnRoles, RolesData } from '../../../types/TablesTypes/Roles';
import StatusView from '../../../components/StatusView/StatusView';
import TableHeader from '../../../components/TableHeader/TableHeader';
import usersApi from '../../../api/users';
import CreateCustomer from '../../../components/ModalContent/CreateCustomer/CreateCustomer';
import { useHistory } from 'react-router-dom';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';
import GroupTwo from '../../../assets/images/Icons/grouptwo.svg';
import GroupOne from '../../../assets/images/Icons/Groupone.svg';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import moment from 'moment';

interface roleCountType {
	id: Number;
	name: String;
	userCount: number;
}
const Roles = () => {
	const [roleDetails, setRoleDetails] = useState<any>(null);
	const [roleCount, setRoleCount] = useState<roleCountType[]>([]);
	const [modalIsOpen, setIsOpen] = React.useState(false);

	const dispatch = useDispatch();
	const getRolesApi = useApi(rolesApi.getRoles);
	const getPermissionsApi = useApi(permissionsApi.getPermissions);
	const history = useHistory();
	const [tableRow, setTableRow] = useState<any[]>();
	const [value, setValue] = useState('');
	const [role, setRole] = useState<string>('');
	const [users, setUsers] = useState<any>();
	const getUsersApi = useApi(usersApi.getUsers);
	const [errorText, setErrorText] = React.useState('');
	const [touchedName, setTouchedName] = React.useState(false);
	const [touchedDescription, setTouchDescription] = React.useState(false);
	const [modalIsOpenCreate, setIsOpenCreate] = React.useState(false);
	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(20);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	const fetchUsers = async () => {
		role.replace(/-|\s/g, '');
		dispatch(openLoader());
		try {
			const { data } = await client.get(
				`users/all/paginated?limit=${rowsPerPage}&page=${pageNumber}`
			);
			setUsers(data);
			dispatch(closeLoader());
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error.response.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useEffect(() => {
		fetchUsers();
	}, [role]);

	const dateRoles = () => {
		const tempArr: RolesData[] = [];
		roleCount
			?.slice(0)
			.reverse()
			.forEach((role: any, index: number) => {
				return tempArr.push({
					name: role?.name,
					userCount: role?.userCount,
					dateCreated: moment(role?.dateCreated).format('MMMM Do YYYY, h:mm a'),
					id: role?.id,
					status: (
						<StatusView
							status={"Active"}
							green='Active'
							red='InActive'
							orange='New'
						/>
					),
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dateRoles());
	}, [roleCount]);

	const fetchPermissions = async () => {
		getPermissionsApi.request();
	};

	const fetchRoles = async () => {
		getRolesApi.request();
	};

	const rolesData = getRolesApi?.data?.data;

	const permissionsData = getPermissionsApi?.data?.data;
	console.log('permissions data', permissionsData);

	const viewRoleDetails = async (id: Number) => {
		try {
			dispatch(openLoader());
			const data: any = await client.get(`role/details?roleid=${id}`);
			const message = data?.data?.message;
			setRoleDetails(data?.data?.data);
			// dispatch(
			// 	openToastAndSetContent({
			// 		toastStyles: ToastSuccessStyles,
			// 		toastContent: message,
			// 	})
			// );
		} catch (error: any) {
			const message = error?.response?.data?.message;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
		finally {
			dispatch(closeLoader());
		}
	};

	const viewRoleCount = async () => {
		try {
			const data: any = await client.get(`role/users`);
			const message = data?.data?.message;
			setRoleCount(data?.data?.data);
			// dispatch(
			// 	openToastAndSetContent({
			// 		toastStyles: ToastSuccessStyles,
			// 		toastContent: message,
			// 	})
			// );
		} catch (error: any) {
			const message = error?.response?.data?.message;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	function closeModal() {
		setIsOpen(false);
	}

	function closeModalCreate() {
		setIsOpenCreate(false);
	}

	const handleCreateRole = () => {
		setIsOpenCreate(true);
	};

	const handleEditRole = () => { };

	const SingleBox = ({ item }: { item: roleCountType }) => {
		return (
			<div
				onClick={() => viewRoleDetails(item.id)}
				className={styles.singlewrapper}>
				<div className={styles.singleTop}>
					<p>{item.userCount} ACCOUNTS</p>
					{item.userCount > 2 ? (
						<>
							<img src={GroupTwo} alt='' /> <AddIcon />
						</>
					) : item.userCount > 1 ? (
						<img src={GroupTwo} alt='' />
					) : item.userCount === 1 ? (
						<img src={GroupOne} alt='' />
					) : (
						''
					)}
				</div>
				<h5>{item.name}</h5>
				<p>Learn more</p>
			</div>
		);
	};

	useEffect(() => {
		fetchRoles();
		fetchPermissions();
		viewRoleCount();
	}, []);

	return (
		<>
			{!roleDetails ? (
				<div style={{ width: '100%' }}>
					<div className={styles.setting_box}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								margin: '10px 0px',
							}}>
							<div>
								<h5 className={styles.setting_h5}>Role Management</h5>
								<p className={styles.setting_p}>
									A role provides access to predefined menus and features so
									that depending on the assigned role (Superadmin, Admin,
									Editor, Viewer) an administrator can have access to what he
									needs.
								</p>
							</div>
							<div
								onClick={() => setIsOpenCreate(true)}
								className={styles.createLinkRole}>
								<EditBlackIcon /> &nbsp;&nbsp; Add new role
							</div>
						</div>

						{/* <div className={styles.settingsinglebox}>
							{roleCount.map((item, i) => (
								<SingleBox key={i} item={item} />
							))}
						</div> */}
					</div>

					<TableHeader
						pageName='Roles & Permissions'
						data={users?.items}
						dataLength={users?.totalItemss}
						value={value}
						setValue={setValue}
						exporting={false}
						filtering={false}
						searchfn={false}
						FilterComponent={
							<div>
								<div className={styles.filterSubHeading}>Roles</div>
								<div className={styles.filterBody}>
									{rolesData?.map((role: any, index: number) => (
										<div
											key={role?.id}
											onClick={() => {
												setRole(role?.name);
											}}>
											{role?.name}
										</div>
									))}
								</div>
							</div>
						}
					// newButton={
					// 	<div
					// 		onClick={() => setIsOpen(true)}
					// 		className={styles.createLink}>
					// 		<EditIcon /> &nbsp;&nbsp; Add Role
					// 	</div>
					// }
					/>
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel='Example Modal'
						style={customStyles}>
						<CreateCustomer
							fetchCustomers={fetchUsers}
							closeModal={closeModal}
							roles={rolesData}
						/>
					</Modal>
					{getUsersApi.loading ? (
						<div className={styles.loading} />
					) : (
						<PaginationTable
							data={tableRow ? tableRow : []}
							columns={ColumnRoles ? ColumnRoles : []}
							value={value}
							emptyPlaceHolder={
								tableRow?.length == 0
									? 'You currently do not have any data'
									: 'Loading...'
							}
							total={tableRow?.length}
							totalPage={Math.ceil((tableRow?.length ?? 0) / 20)}
							pageNumber={pageNumber}
							setPageNumber={setPageNumber}
							nextPage={nextPage}
							setNextPage={setNextPage}
							previousPage={previousPage}
							setPreviousPage={setPreviousPage}
							rowsPerPage={rowsPerPage}
							setRowsPerPage={setRowsPerPage}
							clickAction={(d: any) => { viewRoleDetails(d.id) }}
						/>
					)}
					<Modal
						isOpen={modalIsOpenCreate}
						onRequestClose={closeModalCreate}
						contentLabel='Example Modal'
						style={customStyles}>
						<CreateRole
							closeModal={closeModalCreate}
							fetchPaymentLink={fetchRoles}
							permissions={permissionsData}
						/>
					</Modal>
				</div>
			) : (
				<>
					<div className={styles.transactionsHeader}>
						<div
							style={{
								color: '#211F01',
								display: 'inline',
								cursor: 'pointer',
								fontWeight: '800px',
							}}
							onClick={() => setRoleDetails(null)}>
							<ArrowBackIosIcon sx={{ fontSize: '20px' }} /> {roleDetails?.name}
						</div>
					</div>
					<div className={styles.roles}>
						<h3 className={styles.rolesHeader}>{roleDetails?.name}</h3>
						<p className={styles.rolesInfo}>{roleDetails?.description}</p>

						<div className={styles.mt2}>
							<div className={styles.rolesHeader}>Permissions</div>
							<div className={styles.permissionsList}>
								{!!roleDetails &&
									roleDetails?.permission?.map((permission: any) => (
										<div className={styles.permissionsItems}>
											<div className={styles.rolesLabel}>
												{permission?.permission}
											</div>
											<div className={styles.mark}>
												<MarkIcon />
											</div>
										</div>
									))}
								<div className={styles.mt1}></div>
								<div className={styles.buttonend}>
									<button
										onClick={() => setIsOpen(true)}
										className={styles.editButton}>
										<EditIcon />
										<span>Edit role</span>
									</button>
								</div>

								<Modal
									isOpen={modalIsOpen}
									onRequestClose={closeModal}
									contentLabel='Example Modal'
									style={customStyles}>
									<EditRole
										closeModal={closeModal}
										fetchPaymentLink={fetchRoles}
										permissions={roleDetails?.permission}
										roleDetails={roleDetails}
										allPermissions={permissionsData}
									/>
								</Modal>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default Roles;
