import React from "react";

export interface RollingReserveData {
  settlementDate: string;
  dueDate: string;
  withheldAmount: string;
  settlementAmount: string;
  initialAmount: string;
  id: string;
  currency: string;
  balance: string;
  status: React.ReactNode;
  // action: React.ReactNode;
}

export const ColumnRollingReserve = [
  {
    Header: "Settlement Date",
    accessor: "settlementDate",
  },
  {
    Header: "Total Settlement Amount",
    accessor: "settlementAmount",
  },
  {
    Header: "Initial Settlement Amount",
    accessor: "initialAmount",
  },
  {
    Header: "Balance",
    accessor: "withheldAmount",
  },
  {
    Header: "Due Date",
    accessor: "dueDate",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  // {
  //   Header: "Action",
  //   accessor: "action",
  // },
];
