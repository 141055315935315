import React, { useState } from "react";
import styles from "./Business.module.scss";
import { ReactComponent as File } from "../../../assets/images/Icons/u_fileu.svg";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import { getEnvironmentConfig } from "../../../utils/getEnvironmentConfig";

function ViewFile({ file, name }: { file: string, name: string }) {
  const [active, setActive] = useState(false);
  const _file = file && file.indexOf("http") > -1 ? file : getEnvironmentConfig("FILE_SOURCE") + file;
  
  function closeModalSingle() {
    setActive(false);
  }
  const formatName = () => {
    if (!name) return 'Not provided'
    var splitted = name?.split('?');
    const params = splitted.length >= 2 ? new URLSearchParams(splitted[1]) : new URLSearchParams();
    const fileId = params.get('fileId');
    if (!fileId) return 'Not Provided'
    return name;
  }
  return (
    <>
      <div
        onClick={() => _file !== "" && setActive(true)}
        className={styles.fileview}
      >
        <span style={{ paddingRight: "10px" }}>{formatName()}</span>
        <File />
      </div>
      <Modal
        isOpen={active}
        onRequestClose={closeModalSingle}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className={styles.fileflex}>
          <img
            style={{
              width: "300px",
              height: "300px",
              objectFit: "cover",
              borderRadius: "4px",
            }}
            src={_file}
            alt="Click Download Button to open file"
          />
          <a
            className={styles.downloadbutton}
            style={{ color: "#ffffff" }}
            href={_file}
            download
            target="_blank"
            rel="noopener noreferrer"
          >
            Download
          </a>
        </div>
      </Modal>
    </>
  );
}

export default ViewFile;
