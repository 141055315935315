const initialAllPermissionsState = {
  allPermissions: {},
};

export const allPermissionsReducer = (
  state = initialAllPermissionsState,
  action
) => {
  switch (action.type) {
    case "SAVE_ALL_PERMISSIONS": {
      return {
        ...state,
        allPermissions: { ...action.allPermissions },
      };
    }
    default: {
      return state;
    }
  }
};

export default allPermissionsReducer;
