import React from 'react';

export interface FraudRatioData {
	ratio: string;
	cardType: string;
	merchantName: string;
	chargebackRatio: string;
	status: React.ReactNode;
}

export const ColumnFraudRatio = [
	{
		Header: 'Merchant name',
		accessor: 'merchantName',
	},
	{
		Header: 'Fraud ratio',
		accessor: 'ratio',
	},
	{
		Header: 'Card type',
		accessor: 'cardType',
	},
	{
		Header: 'Chargeback ratio',
		accessor: 'chargebackRatio',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
];
