import React, { useEffect, useState } from 'react';
import Settlements from '../Settlements';
import styles from './SettlementTab.module.scss';
import Mocked from '../Mocked.json';
import {
	closeLoader,
	openLoader,
} from '../../../../redux/actions/loader/loaderActions';
import client from '../../../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../../redux/actions/toast/toastActions';
import { ToastErrorStyles } from '../../../../constants/toastStyles';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../../../utils/datefunction';
import dayjs, { Dayjs } from 'dayjs';

function SettlementTab() {
	interface dataTypes {
		id: Number;
		menu: String;
	}
	const dispatch = useDispatch();
	const [selectedNav, setSelectedNav] = useState<Number>(1);
	const [settlement, setSettlement] = useState<any>();

	const dataRef: dataTypes[] = [
		// {
		// 	id: 1,
		// 	menu: 'Settlements due',
		// },
		// {
		// 	id: 2,
		// 	menu: 'Reviewed settlements',
		// },
		{
			id: 1,
			menu: 'Failed settlements',
		},
	];

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [status, setStatus] = useState('');
	const [bearer, setBearer] = useState(false);
	const [payment, setPayment] = useState('');
	const [transaction, setTransaction] = useState('');
	const [email, setEmail] = useState('');

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
		setPayment('');
		setTransaction('');
		setEmail('');
	};

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const filteredArray = [
		{
			name: 'Payment Reference',
			value: payment,
			setValue: setPayment,
		},
		{
			name: 'Transaction Reference',
			value: transaction,
			setValue: setTransaction,
		},
		{
			name: 'Email Address',
			value: email,
			setValue: setEmail,
		},
	];

	const fetchSettlement = async () => {
		dispatch(openLoader());
		try {
			const res: any = await client.get(
				`settlement/all?settlementstatus=Failed&subsidiaryname=${status}&fromdate=${fromDate}&todate=${toDate}&limit=${rowsPerPage}&page=${pageNumber}&paymentreference=${payment}&transactionreference=${transaction}&emailaddress=${email}`
			);
			setSettlement(res.data.data);
			setBearer(false);

			dispatch(closeLoader());
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error.response.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useEffect(() => {
		fetchSettlement();
	}, [bearer, pageNumber, rowsPerPage, status]);

	useEffect(() => {
		setPageNumber(settlement?.current_page || 1);
		setPreviousPage(settlement?.previous_page);
		setNextPage(settlement?.next_page);
	}, [settlement]);
	return (
		<>
			{/* <div className={styles.tabContainer}>
				{dataRef.map(({ id, menu }: dataTypes, i) => (
					<div
						key={i}
						onClick={() => {
							setSelectedNav(id);
						}}
						className={
							selectedNav === id ? styles.activeMenuItem : styles.subMenuItem
						}>
						{selectedNav === id ? (
							<div className={styles.activeItemColor}>{menu}</div>
						) : (
							<div style={{ color: 'rgba(10, 9, 3, 0.6)' }}>{menu}</div>
						)}
					</div>
				))}
			</div>

			<div>
				{selectedNav === 1 ? (
					<Settlements mocked={Mocked} stats='Due' />
				) : selectedNav === 2 ? (
					<Settlements mocked={Mocked} stats='Pending' />
				) : selectedNav === 3 ? (
					<Settlements mocked={Mocked} stats='Failed' />
				) : (
					''
				)}

				{selectedNav === 1 ? (
					<Settlements mocked={Mocked} stats='Failed' />
				) : (
					''
				)}
			</div> */}
			<Settlements />
		</>
	);
}

export default SettlementTab;
