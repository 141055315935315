import React, { useEffect } from 'react';
import styles from './Business.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
import { ReactComponent as CopyIcon } from '../../assets/images/Icons/u_copy.svg';
import Select from 'react-select';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import { Divider } from 'antd';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import { formClient } from '../../../api/client';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { serialize } from "object-to-formdata";

function UploadAdditionalDocument({ closeModal, fn, id }: any) {
	interface initTypes {
		subsidiaryId: number;
		reason: string;
		status: string;
	}

	const initProps: initTypes = {
		subsidiaryId: id,
		reason: '',
		status: '',
	};
	const [uploadFiles, setUploadFiles] = React.useState<any>([
		{ id: 1 }
	]);

	const [uploadFilesResponse, setUploadFilesResponse] = React.useState<any>();

	const dispatch = useDispatch();

	const handleSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		console.log({ uploadFiles })
		dispatch(openLoader());
		const formData = serialize({});
		for (let x = 0; x < uploadFiles?.length; x++) {
			const element = uploadFiles[x];
			console.log({ element })
			formData.append(`documents[${x}].documentName`, element.documentName)
			for (let y = 0; y < element.files.length; y++) {
				const file = element.files[y];
				console.log({ file })
				formData.append(`documents[${x}].files`, file)
			}
		}
		formClient
			.post(`/kyc/subsidiary/${id}/document/upload`, formData)
			.then((data: any) => {
				dispatch(closeLoader());
				// closeModal();
				// dispatch(
				// 	openToastAndSetContent({
				// 		toastContent: data?.data?.message,
				// 		toastStyles: ToastSuccessStyles,
				// 	})
				// );

				// fn();
				setUploadFilesResponse(data?.data)
				setUploadFiles([])
				fn();
			})
			.catch((error: any) => {
				dispatch(closeLoader());
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			});
	};


	const removeFile = (id: number) => {
		const filtered = uploadFiles.filter((x: any) => x.id != id)
		setUploadFiles([...filtered]);
	}


	const addNewFile = () => {
		setUploadFilesResponse({});
		setUploadFiles([...uploadFiles, { id: uploadFiles.length + 1 }])
	}

	const updateProps = (id: number, event: any) => {
		const inputName = event.target.name;
		const newValue = (inputName == "files") ? event.target.files : event.target.value;
		uploadFiles.map((x: any) => {
			if (x.id == id) {
				x[inputName] = newValue
			}
		})
		setUploadFiles([...uploadFiles]);
	};

	const disabledFunction = () => {
		console.log("disable", { uploadFiles })
		if (uploadFiles.length < 1) {
			return true;
		} else {
			const noname = uploadFiles.filter((x: any) => !x.documentName || !x.files)
			if (noname.length > 0) return true;
			return false;
		}
	};

	const status = [{ name: 'Approved' }, { name: 'Declined' }];

	return (
		<div>
			<div className={styles.modalhead}>
				<h3 className={styles.modalheadh3}>Upload Additional Documents</h3>
				<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
			</div>
			<Divider style={{ margin: 0, padding: 0 }} />
			<div className={styles.modalcontent}>
				<>
					{uploadFiles.map((fileinput: any) => (
						<div className={styles.fileInputWrapper}>
							<div className={styles.input}>
								<OutlineTextInput
									handleChange={(e) => updateProps(fileinput.id, e)}
									inputName='documentName'
									inputLabel='Enter document name'
									inputValue={fileinput.documentName}
									// inputSelect
									// inputOption={status}
									style={{ width: '100%' }}
								/>
							</div>

							<div className={styles.input}>
								<OutlineTextInput
									handleChange={(e) => updateProps(fileinput.id, e)}
									inputName='files'
									inputLabel='Select file to upload'
									inputType='file'
									multiple={true}
									// inputValue={fileinput.files}
									style={{ width: '100%' }}
								/>
							</div>
							<div className={styles.deletefileBtn}>
								<CloseIcon onClick={() => removeFile(fileinput.id)} style={{ cursor: 'pointer' }} />
							</div>
						</div>
					))}
					<button
						onClick={() => addNewFile()}
						className={styles.addMoreFileButton}>
						Add More Files
					</button>
				</>
				<div className={styles.uploadFileResponse}>
					{
						uploadFilesResponse?.data?.map((res: any) =>
							<div className={styles["uploadFileResponse" + res.status]}>
								[{res?.data?.documentName}] {res?.data?.fileName} - {res?.message}
							</div>
						)
					}
				</div>

				<Divider style={{ margin: 0, padding: 0 }} />

				<div className={styles.modalFooter}>
					<button className={styles.cancel} onClick={closeModal}>
						Cancel
					</button>

					<button
						disabled={disabledFunction()}
						onClick={handleSubmit}
						className={styles.fund}>
						Submit Files
					</button>
				</div>
			</div>
		</div>
	);
}

export default UploadAdditionalDocument;
