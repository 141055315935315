import React from 'react';
import styles from './OtpComplete.module.scss';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import client from '../../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import { useHistory } from 'react-router-dom';
import { Box, Button, TextField } from '@material-ui/core';
// import { useForm } from "react-hook-form";

const OtpCompleteModal = ({ trigger, closeModal, otp, setOtp }: any) => {
	// const { register, handleSubmit, errors } = useForm();
	interface initTypes {
		otp: null | number;
	}

	const initProps: initTypes = {
		otp: null,
	};

	return (
		<div className={styles.modalBody}>
			<div className={styles.modalHeader}>
				<div className={styles.background}>
					<div className={styles.linkDetails}>Verify OTP</div>
					<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
				</div>
			</div>
			<div className={styles.businessForm}>
				<div style={{ margin: '20px 0px' }}>
					<OutlineTextInput
						handleChange={(e) => setOtp(e.target.value)}
						inputName='otp'
						inputLabel='OTP'
						inputType='number'
						inputValue={otp}
						handlePress={(e: any) => {
							if (e.key === 'Enter') {
								trigger();
							}
						}}
					/>
				</div>
			</div>
			<div className={styles.modalFooter}>
				<div className={styles.cancel} onClick={closeModal}>
					Cancel
				</div>

				<button
					disabled={
						otp?.toString()?.length > 0 && !Number.isNaN(otp) ? false : true
					}
					className={styles.deactivate}
					onClick={() => trigger()}>
					Verify
				</button>
			</div>
		</div>
	);
};

export default OtpCompleteModal;
