import React, { useState, Fragment, useEffect } from 'react';
import styles from './Business.module.scss';
import PaginationTable from '../../components/table/pagination-table';
import {
	ColumnBusinessModule,
	BusinessModuleData,
} from '../../types/TablesTypes/BusinessModule';
import MenuOption from '../../components/MenuOption/MenuOption';
import StatusView from '../../components/StatusView/StatusView';
import TableHeader from '../../components/TableHeader/TableHeader';
import ViewIcon from '../../assets/images/Icons/view-details.svg';
import DeactivateIcon from '../../assets/images/Icons/deactivate-user.svg';
import { ReactComponent as LinkIcon } from '../../assets/images/Icons/link-icon.svg';
import businessApi from '../../api/businesses';
import useApi from '../../hooks/useApi';
import client from '../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../constants/toastStyles';
import Modal from 'react-modal';
import { customStyles } from '../../constants/modalStyles';
import CreateCustomer from '../../components/ModalContent/CreateCustomer/CreateCustomer';
import { useHistory } from 'react-router-dom';
import CreateBusiness from './CreateBusiness/CreateBusiness';
import {
	closeLoader,
	openLoader,
} from '../../redux/actions/loader/loaderActions';
import BusinessModuleDetails from '../../views/Accounts/BusinessModuleDetails';
import moment from 'moment';
import FilterModal from '../../components/filterConfig/FilterModal';
import dayjs, { Dayjs } from 'dayjs';
import currencyApi from '../../api/currencies';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../utils/datefunction';
import FilterButtonNew from '../../components/FilterButtonNew/FilterButtonNew';

const tableStatus = [
	{ id: 1, name: 'New' },
	{ id: 2, name: 'Pending-Verification' },
];

const Business = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [tableRow, setTableRow] = useState<any[]>();
	const [details, setDetails] = useState<number | null>(null);
	const [businessName, setBusinessName] = useState<string>('');

	const [businesses, setBusinesses] = useState<any>();
	const getBusinessApi = useApi(businessApi.getBusiness);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}

	const getCurrenciesApi = useApi(currencyApi.getCurrencies);
	const getCountriesApi = useApi(currencyApi.getCountries);

	const fetchCurrencies = async () => {
		getCurrenciesApi.request();
		getCountriesApi.request();
	};

	const openBusinessAction = async (business: any) => {
		// console.log({ business })
		// setDetails(business.id);
		// setBusinessName(business.businessName);
		const businessId = business.id;
		history.push(`/dashboard/accounts/${businessId}/business-details`)
	};
	const currencies = getCurrenciesApi?.data?.data;
	const countries = getCountriesApi?.data?.data;

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [country, setCountry] = useState('');
	const [status, setStatus] = useState('');
	const [email, setEmail] = useState('');
	const [merchantId, setMerchantId] = useState<string>('');

	const [bearer, setBearer] = useState(false);
	const [businessname, setBusinessname] = useState('');

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setCountry('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
	};

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const filteredArray: {
		name: string;
		value: string | Dayjs | null;
		setValue: React.Dispatch<React.SetStateAction<any>>;
		selective?: { name: string; value: string }[];
		selectHelper?: boolean;
		type: "select" | "date" | "text";
	  }[]  = [
		{
			name: "Date Range",
			value: fromDate,
			setValue: setFromDate,
			type: "date", // Explicitly set as "date"
		},
		{
			name: 'Email Address',
			value: email,
			setValue: setEmail,
			type:'text'
		},
		{
			name: "Merchant Id",
			value: merchantId,
			setValue: setMerchantId,
			type: "text",
		  },
		{
			name: 'Country',
			value: country,
			setValue: setCountry,
			selective: countries,
			type:'select'
		},
		{
			name: 'Status',
			value: status,
			setValue: setStatus,
			type:'select',
			selective: [
				{ name: 'Pending-Verification', value:'Pending-Verification' },
				{ name: 'New', value:'New' },
				{ name: 'Active', value:'Active' },
				{ name: 'Pending-Approval', value:'Pending-Approval' },
			],
		},
	];
	useEffect(() => {
		fetchCurrencies();
	}, []);

	const fetchBusinesses = async () => {
		dispatch(openLoader());
		try {
			const { data }: any = await client.get(
				`subsidiary/all/paginated?status=${status}&Id=${merchantId}&email=${email}&country=${country}&fromdate=${fromDate}&todate=${toDate}&name=${businessname}&limit=${rowsPerPage}&page=${pageNumber}`
			);
			setBusinesses(data.data);
			dispatch(closeLoader());
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error.response.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useEffect(() => {
		fetchBusinesses();
	}, [bearer, businessname, pageNumber, rowsPerPage]);

	useEffect(() => {
		// console.log({ businesses })
		setPageNumber(businesses?.page || 1);
		setPreviousPage(businesses?.page - 1 || 1);
		setNextPage(businesses?.page + 1);
	}, [businesses]);

	const menuOptions = [
		{
			id: 1,
			name: (id: number) => {
				return 'View business details';
			},
			icon: ViewIcon,
			onclick: (
				emailAddress: string,
				e: any,
				status: string,
				businessId: number,
				businessName: string
			) => {
				history.push(`dashboard/accounts/${businessId}/business-details`)
				// setDetails(businessId);
				// setBusinessName(businessName);
			},
		},
	];

	const dataBusinesses = () => {
		const tempArr: BusinessModuleData[] = [];
		businesses?.items
			?.slice(0)
			.reverse()
			.forEach((business: any, index: number) => {
				return tempArr.push({
					businessName: business?.businessName,
					emailAddress: business?.email,
					id: business?.id,
					businessType: business?.businessType,
					countryName: business?.country,
					subsidiaries: business?.noOfSubsidiaries ?? 1,
					action: (
						<MenuOption
							menuContent={
								<div>
									{menuOptions.map((option: any) => (
										<div
											key={option?.id}
											onClick={(e) => {
												option?.onclick(
													business?.emailAddress,
													e,
													business?.status,
													business?.id,
													business?.businessName
												);
											}}
											className={styles.menuContent}>
											<img
												src={option?.icon}
												alt={option?.name(business?.id)}
												width={24}
												height={24}
											/>
											<div className={styles.optionName}>
												{option?.name(business?.status)}
											</div>
										</div>
									))}
								</div>
							}
						/>
					),
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataBusinesses());
	}, [businesses?.items]);

	useEffect(() => {
		// console.log('table', tableRow);
	}, [tableRow]);

	const itemLength = businesses?.items?.length;
	// console.log({ details })
	return (
		<div style={{ minHeight: '100vh' }}>
			{!details ? (
				<>
					<TableHeader
						pageName='Businesses'
						data={businesses?.items}
						dataLength={businesses?.totalItems}
						value={businessname}
						setValue={setBusinessname}
						dropdown={dropdown}
						setDropdown={setDropdown}
						placeHolder='Search business name'
						FilterComponent={
							<FilterButtonNew
								eventDate={eventDate}
								setEventDate={setEventDate}
								dropdown={dropdown}
								setDropdown={setDropdown}
								setFromDate={setFromDate}
								setToDate={setToDate}
								fromDate={fromDate}
								toDate={toDate}
								setBearer={setBearer}
								clearHandler={clearHandler}
								clearAllHandler={clearHandler}
								filteredArray={filteredArray}
							/>
						}
					/>

					{getBusinessApi.loading ? (
						<div className={styles.loading} />
					) : (
						<PaginationTable
							data={tableRow ? tableRow : []}
							columns={ColumnBusinessModule ? ColumnBusinessModule : []}
							emptyPlaceHolder={
								businesses?.totalItems == 0
									? 'You currently do not have any data'
									: 'Loading...'
							}
							value={value}
							clickAction={(d) => openBusinessAction(d)}
							total={businesses?.totalItems}
							totalPage={businesses?.totalPages}
							pageNumber={pageNumber}
							setPageNumber={setPageNumber}
							nextPage={nextPage}
							setNextPage={setNextPage}
							previousPage={previousPage}
							setPreviousPage={setPreviousPage}
							rowsPerPage={rowsPerPage}
							setRowsPerPage={setRowsPerPage}
						/>
					)}
				</>
			) : (
				<BusinessModuleDetails />
			)}
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}>
				<CreateBusiness
					fetchBusinesses={fetchBusinesses}
					closeModal={closeModal}
				/>
			</Modal>
		</div>
	);
};

export default Business;
