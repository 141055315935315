import React, { useState, useEffect } from 'react';
import styles from './Chargeback.module.scss';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import { Divider } from 'antd';
import Mocked from './bulkchargeback.json';
import {
	ColumnChargebackBulk,
	ChargebackBulkData,
} from '../../types/TablesTypes/Chargeback';
import PaginationTable from '../../components/table/pagination-table';

function BulklogsChargeback() {
	const history = useHistory();
	const [tableRow, setTableRow] = useState<any[]>();
	const [value, setValue] = useState('');
	const [chargeback, setChargeback] = useState<any>(Mocked);

	const dataTransaction = () => {
		const tempArr: ChargebackBulkData[] = [];
		chargeback
			?.slice(0)
			.reverse()
			.forEach((transaction: any, index: number) => {
				tempArr.push({
					transactionReference: transaction?.transactionReference,
					provider: transaction?.provider,
					stage: transaction?.stage,
					type: transaction?.type,
					amount: transaction?.amount,
					reason: transaction?.reason,
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransaction());
	}, [chargeback]);

	const itemLength = chargeback?.length;

	return (
		<div>
			<div className={styles.transactionsHeader}>
				<div
					style={{
						color: '#211F01',
						display: 'inline',
						cursor: 'pointer',
						fontWeight: '800px',
					}}
					onClick={() => history.goBack()}>
					<ArrowBackIosIcon sx={{ fontSize: '20px' }} /> Chargeback logs
				</div>
				<div style={{ marginTop: '0px' }} className={styles.modalFooter}>
					<button className={styles.cancel}>Cancel</button>

					<button className={styles.fund}>Log Refund</button>
				</div>
			</div>
			<div>
				<PaginationTable
					data={tableRow ? tableRow : []}
					columns={ColumnChargebackBulk ? ColumnChargebackBulk : []}
					value={value}
					emptyPlaceHolder={
						itemLength == 0
							? 'You currently do not have any data'
							: 'Loading...'
					}
				/>
			</div>
		</div>
	);
}

export default BulklogsChargeback;
