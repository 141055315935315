import React from "react";

export interface SubsidiariesData {
  businessName: string;
  email: string;
  businessType: string;
  country: string;
  users: number;
  subsidiaryGroup: string;
  action: React.ReactNode;
}

export const ColumnSubsidiaries = [
  {
    Header: "Business name",
    accessor: "businessName",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Business type",
    accessor: "businessType",
  },
  {
    Header: "Country",
    accessor: "country",
  },
  {
    Header: "# of users",
    accessor: "users",
  },
  {
    Header: "Subsidiary of",
    accessor: "subsidiaryGroup",
  },
  {
    Header: "",
    accessor: "action",
  },
];

export const ColumnSubsidiariesPer = [
	{
		Header: 'Subsidiary name',
		accessor: 'businessName',
	},
	{
		Header: 'Email',
		accessor: 'email',
	},
	{
		Header: 'Business type',
		accessor: 'businessType',
	},
	{
		Header: 'Country',
		accessor: 'country',
	},
	{
		Header: '# of users',
		accessor: 'users',
	},

];
