import React, { useState, useEffect } from 'react';
import styles from './TransferFees.module.scss';
import TableHeader from '../../../components/TableHeader/TableHeader';
import client from '../../../api/client';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import { useDispatch, useSelector } from 'react-redux';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import Filtered from '../../../components/filterComponent/Filtered';
import { menuContent } from '../../../utils/menuContent';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import Mark from '../../../assets/images/Icons/u_save.svg';
import { Divider } from 'antd';
import { Grid } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { InputLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import SelectWrapper from '../../../components/Select';
import useApi from '../../../hooks/useApi';
import reports from '../../../api/report';

const useStyles = makeStyles({
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-input.MuiOutlinedInput-input':
		{
			textAlign: 'center',
			padding: '8.1px 14px',
		},
	},
	select: {
		'& .MuiOutlinedInput-root': {
			color: '#414141',
			fontFamily: 'Roboto',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '14px',
			lineHeight: '16px',
			borderRadius: '10px',
		},
		'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			outline: 'none',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
			backgroundColor: '#ffffff',
		},
		'& .MuiInputLabel-root.Mui-focused': {
			color: '#E0E0E0',
			backgroundColor: '#ffffff',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: '1px solid #E0E0E0',
		},
	},
});

const TransferFees = () => {
	const getPricingGroups = useApi(reports.getPricingGroups);
	const dispatch = useDispatch();
	const [payout, setPayout] = useState<any>();
	const [value, setValue] = useState('');
	const [tableRow, setTableRow] = useState<any[]>();
	const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
	const classes = useStyles();
	const { currencies } = useSelector((state) => state.appSettingsReducer);
	const currencyOption = currencies.map((curr: any) => ({ name: `${curr.name} (${curr.shortName})`, val: curr.shortName }));
	const pricingGroupOption = getPricingGroups?.data?.data?.map((curr: any) => ({ name: `${curr.name}`, val: curr.id }));

	// const currencyOption = [{ name: 'USD' }, { name: 'NGN' }];
	const validate = Yup.object({
		fee: Yup.number().required('Required').min(0).max(1000),
		cap: Yup.string().required('Required'),
	});
	// const [apiObject, setApiObject] = useState(null);

	// const { keyB: trxRef } = apiObject?.keyA || [{

	// }];

	function closeModalSingle() {
		setIsOpenSingle(false);
	}

	//for filtering
	const [valueChannel, setValueChannel] = useState('');
	const [valueStatus, setValueStatus] = useState('');
	const [dropdown, setDropdown] = useState(false);

	const fetchPayout = async () => {
		dispatch(openLoader());
		try {
			const res: any = await client.get(`/pricing/payout`);
			setPayout(res.data.data);
			dispatch(closeLoader());
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};


	useEffect(() => {
		fetchPayout();
		getPricingGroups.request();
	}, []);

	return (
		<div style={{ marginTop: '38px' }}>
			<TableHeader
				pageName='sales volume and value'
				dataLength={payout?.page?.total}
				data={payout?.items}
				value={value}
				setValue={setValue}
				exporting={false}
				filtering={false}
				searchfn={false}
				newButton={
					<div
						onClick={() => setIsOpenSingle(true)}
						className={styles.createLink}>
						Add a new Payout fee
					</div>
				}
				FilterComponent={
					<>
						<Filtered
							menuContent={menuContent}
							dropdown={dropdown}
							setDropdown={setDropdown}
							valueChannel={valueChannel}
							setValueChannel={setValueChannel}
							valueStatus={valueStatus}
							setValueStatus={setValueStatus}
							status={true}
							channel={true}
						/>
					</>
				}
			/>

			<div className={styles.tableContent}>
				<div className={styles.customerInfo}>
					<div style={{ fontWeight: 'bold' }} className={styles.detailsValue}>
						Pricing Group
					</div><div style={{ fontWeight: 'bold' }} className={styles.detailsValue}>
						currency
					</div>
					<div style={{ fontWeight: 'bold' }} className={styles.detailsKey}>
						Fee
					</div>
					<div style={{ fontWeight: 'bold' }} className={styles.detailsKey}>
						Cap
					</div>
				</div>
				{payout?.items?.map(({ fee, cap, currency, pricingGroup }: { fee: number; cap: number, currency: string, pricingGroup: string }) => (
					<div className={styles.customerInfo}>
						<div className={styles.detailsValue}>{pricingGroup}</div>
						<div className={styles.detailsValue}>{currency}</div>
						<div className={styles.detailsKey}>{fee * 100}%</div>
						<div className={styles.detailsKey}>{cap}</div>
					</div>
				))}
			</div>

			{/* //MODAL FOR SINGLE */}
			<Modal
				isOpen={modalIsOpenSingle}
				onRequestClose={closeModalSingle}
				contentLabel='Example Modal'
				style={customStyles}>
				<div className={styles.modalwrapper}>
					<div className={styles.modalhead}>
						<h3 className={styles.modalheadh3}>Add a fee</h3>
						<CloseIcon
							onClick={closeModalSingle}
							style={{ cursor: 'pointer' }}
						/>
					</div>
					<Divider style={{ margin: 0, padding: 0 }} />
					<Formik
						initialValues={{
							fee: '',
							cap: '',
							pricingGroupId: 1,
							currency: 'USD',
						}}
						validationSchema={validate}
						onSubmit={(values) => {
							console.log(values);
							dispatch(openLoader());
							client
								.post(`pricing/payout/add`, {
									pricingGroupId: values.pricingGroupId,
									currency: values.currency,
									fee: Number(values.fee) / 100,
									cap: Number(values.cap),
								})
								.then((res: any) => {
									dispatch(closeLoader());
									dispatch(
										openToastAndSetContent({
											toastContent: res.data.message,
											toastStyles: ToastSuccessStyles,
										})
									);
									closeModalSingle();
									fetchPayout();
								})
								.catch((error: any) => {
									dispatch(closeLoader());
									const { message } = error?.response?.data;
									dispatch(
										openToastAndSetContent({
											toastContent: message,
											toastStyles: ToastErrorStyles,
										})
									);
								});
						}}>
						{(props) => (
							<div
								style={{
									padding: '32px 24px',
								}}>
								<Form>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<div className={styles.customerInfoSingle}>
												<InputLabel>
													<span className={styles.label}>Pricing Group</span>
												</InputLabel>
												<Field
													as={SelectWrapper}
													helperText={
														<ErrorMessage name='pricingGroupId'>
															{(msg) => (
																<span style={{ color: 'red' }}>{msg}</span>
															)}
														</ErrorMessage>
													}
													name='pricingGroupId'
													variant='outlined'
													margin='normal'
													type='text'
													size='small'
													fullWidth
													options={pricingGroupOption}
													className={classes.select}
													placeholder='Currency'
													defaultValue={pricingGroupOption?.length > 0 && pricingGroupOption[0]}
												/>
											</div>
										</Grid>
										<Grid item xs={12}>
											<div className={styles.customerInfoSingle}>
												<InputLabel>
													<span className={styles.label}>Currency</span>
												</InputLabel>
												<Field
													as={SelectWrapper}
													helperText={
														<ErrorMessage name='currency'>
															{(msg) => (
																<span style={{ color: 'red' }}>{msg}</span>
															)}
														</ErrorMessage>
													}
													name='currency'
													variant='outlined'
													margin='normal'
													type='text'
													size='small'
													fullWidth
													options={currencyOption}
													className={classes.select}
													placeholder='Currency'
													defaultValue={currencyOption && currencyOption[0]}
												/>
											</div>
										</Grid>
										<Grid item xs={12}>
											<div className={styles.customerInfoSingle}>
												<InputLabel>
													<span className={styles.label}>Cap</span>
												</InputLabel>
												<Field
													as={TextField}
													helperText={
														<ErrorMessage name='cap'>
															{(msg) => (
																<span style={{ color: 'red' }}>{msg}</span>
															)}
														</ErrorMessage>
													}
													name='cap'
													variant='outlined'
													margin='normal'
													type='text'
													size='small'
													fullWidth
													className={classes.select}
												/>
											</div>
										</Grid>
										<Grid item xs={12}>
											<div className={styles.customerInfoSingle}>
												<InputLabel>
													<span className={styles.label}>Fee</span>
												</InputLabel>
												<Field
													as={TextField}
													helperText={
														<ErrorMessage name='fee'>
															{(msg) => (
																<span style={{ color: 'red' }}>{msg}</span>
															)}
														</ErrorMessage>
													}
													name='fee'
													variant='outlined'
													margin='normal'
													type='text'
													size='small'
													fullWidth
													className={classes.select}
												/>
											</div>
										</Grid>

										<Grid item xs={12}>
											<button type='submit' className={styles.Downloadbutton}>
												<span className={styles.Downloadbutton_span}>
													<img src={Mark} alt='' />
												</span>
												Add Fee
											</button>
										</Grid>{' '}
									</Grid>
								</Form>
							</div>
						)}
					</Formik>
				</div>
			</Modal>
		</div>
	);
};

export default TransferFees;
