import React from 'react';

export interface settlementPlanData {
	id: string;
	partialPaymentDays: string;
	initialPaymentDays: string;
	fullPaymentDays: string;
	finalPaymentDays: string;
	partialOrFull: string;
	settlementType: string;
	percentage: number;
	initialPercentage: string;
	settlementDestination: string;
	settlementDestinationId: string;
	finalPercentage: string;
	currency: string;
	currencyId: string;
	name: string;
	isActive: string;
	settlementGroup: string;
	settlementGroupId: number;
	dateCreated: string;
	action?: React.ReactNode
}

export const ColumnSettlementPlans = [
	{
		Header: 'Plan Name',
		accessor: 'name',
	},
	{
		Header: 'Settlement Group',
		accessor: 'settlementGroup',
	},
	{
		Header: 'Currency',
		accessor: 'currency',
	},
	{
		Header: 'Destination',
		accessor: 'settlementDestination',
	},
	{
		Header: 'Settlement Type',
		accessor: 'settlementType',
	},
	{
		Header: 'Initial Settlement Day',
		accessor: 'initialPaymentDays',
	},
	{
		Header: 'Final Settlement Day',
		accessor: 'finalPaymentDays',
	},
	{
		Header: 'Initial Amount',
		accessor: 'initialPercentage',
	},
	{
		Header: 'Final Amount',
		accessor: 'finalPercentage',
	},
	{
		Header: 'Date Created',
		accessor: 'dateCreated',
	},
	// {
	// 	Header: '',
	// 	accessor: 'action',
	// },
];

export interface ApprovalData {
	date: string;
	limitType: string;
	requester: string;
	status: React.ReactNode;
}

export const ColumnApproval = [
	{
		Header: 'Request date',
		accessor: 'date',
	},
	{
		Header: 'Limit type',
		accessor: 'limitType',
	},
	{
		Header: 'Requester',
		accessor: 'requester',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
];

export interface GenData {
	frequency: string;
	dateCreated: string;
	cummulativeTransferLimit: string;
	currency: string;
	limitGroup: string;
	requester: string;
	type: string;
	singleTransferLimit: string;
	status: React.ReactNode;
	action?: React.ReactNode;
}

export const ColumnGen = [
	{
		Header: 'Requester',
		accessor: 'requester',
	},
	{
		Header: 'Limit Group',
		accessor: 'limitGroup',
	},
	{
		Header: 'Currency',
		accessor: 'currency',
	},
	{
		Header: 'Single Transfer Limit',
		accessor: 'singleTransferLimit',
	},
	{
		Header: 'Daily Transfer Limit',
		accessor: 'cummulativeTransferLimit',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
	{
		Header: 'Requested Date',
		accessor: 'dateCreated',
	},
	{
		Header: '',
		accessor: 'action',
	},
];
