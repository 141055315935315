import React, { useState, Fragment, useEffect } from 'react';
import styles from './Provider.module.scss';
import PaginationTable from '../../../components/table/pagination-table';
import {
	ColumnProvider,
	ProviderData,
} from '../../../types/TablesTypes/provider';
import StatusView from '../../../components/StatusView/StatusView';
import TableHeader from '../../../components/TableHeader/TableHeader';
import TransactionsMenu from '../../../components/MenuOption/TransactionsMenu/TransactionsMenu';
import transactionsApi from '../../../api/transactions';
import useApi from '../../../hooks/useApi';
import client from '../../../api/client';
import { numberWithCommas } from '../../../utils/formatNumber';
import moment from 'moment';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	AppstoreOutlined,
	MailOutlined,
	SettingOutlined,
} from '@ant-design/icons';
import Filtered from '../../../components/filterComponent/Filtered';
import { menuContent } from '../../../utils/menuContent';
import Mocked from './provider.json';
import RefundsMenu from '../../../components/MenuOption/RefundsMenu/RefundsMenu';
import { Menu, Dropdown as MenuFunc, Row, Col } from 'antd';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import Mark from '../../../assets/images/Icons/u_save.svg';
import { Divider, Checkbox } from 'antd';
import { Grid } from '@material-ui/core';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { InputLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import SelectWrapper from '../../../components/Select';
import * as Yup from 'yup';
import DeleteIcon from '../../../assets/images/Icons/delete.svg';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles({
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-input.MuiOutlinedInput-input':
			{
				textAlign: 'center',
				padding: '8.1px 14px',
			},
	},
	select: {
		'& .MuiOutlinedInput-root': {
			color: '#414141',
			fontFamily: 'Roboto',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '14px',
			lineHeight: '16px',
			borderRadius: '10px',
		},
		'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			outline: 'none',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
			backgroundColor: '#ffffff',
		},
		'& .MuiInputLabel-root.Mui-focused': {
			color: '#E0E0E0',
			backgroundColor: '#ffffff',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: '1px solid #E0E0E0',
		},
	},
});
interface methodProps {
	typeId: number | string;
	cost: number;
	cap: null | number;
}

const Provider = () => {
	const dispatch = useDispatch();
	const [status, setStatus] = useState<string>('');
	const [value, setValue] = useState('');
	const [tableRow, setTableRow] = useState<any[]>();
	const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
	const [provider, setProvider] = useState<any>();
	const [category, setCategory] = useState<any>();
	const [method, setMethod] = useState<methodProps[]>([]);
	const [input, setInput] = useState<methodProps>({
		typeId: '',
		cost: 0,
		cap: 0,
	});

	const onChangeHandler = (e: any) => {
		setInput({ ...input, [e.target.name]: e.target.value });
	};

	const classes = useStyles();

	const validate = Yup.object({
		provider: Yup.string().required('Required'),
		provider_short_name: Yup.string().required('Required'),
	});

	const pushHandler = () => {
		setMethod((prev) => [...prev, input]);
		setInput({
			typeId: '',
			cost: 0,
			cap: 0,
		});
	};

	const deleteHandler = (index: number) => {
		const filter = method.filter((_, i) => i !== index);
		setMethod(filter);
	};

	//PAGINATION
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	function closeModalSingle() {
		setIsOpenSingle(false);
	}

	//for filtering
	const [valueChannel, setValueChannel] = useState('');
	const [valueStatus, setValueStatus] = useState('');
	const [dropdown, setDropdown] = useState(false);

	const fetchPaymentMethod = async () => {
		try {
			const res: any = await client.get(`fraud/risk/payment/options`);
			setCategory(res.data.data);
		} catch (error: any) {
			const { message } = error.response.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useEffect(() => {
		fetchPaymentMethod();
	}, []);

	const fetchProvider = async () => {
		dispatch(openLoader());
		try {
			const res: any = await client.get(
				`/pricing/provider?limit=${rowsPerPage}&page=${pageNumber}`
			);
			setProvider(res.data.data);
			dispatch(closeLoader());
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};

	useEffect(() => {
		fetchProvider();
	}, [pageNumber, rowsPerPage]);

	useEffect(() => {
		setPageNumber(provider?.page?.currentPage || 1);
		setPreviousPage(provider?.page?.previousPage);
		setNextPage(provider?.page?.nextPage);
	}, [provider]);

	const dataTransaction = () => {
		const tempArr: ProviderData[] = [];
		provider?.items
			?.slice(0)
			.reverse()
			.forEach((transaction: any, index: number) => {
				tempArr.push({
					providerName: transaction.provider,
					providerShortName: transaction.shortName,
					currency: transaction.currency,
					date: transaction?.dateCreated,
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransaction());
	}, [provider?.items]);

	return (
		<div style={{ marginTop: '38px' }}>
			<TableHeader
				pageName='Provider'
				dataLength={provider?.page?.total}
				data={provider?.items}
				value={value}
				setValue={setValue}
				exporting={false}
				filtering={false}
				searchfn={false}
				newButton={
					<div
						onClick={() => setIsOpenSingle(true)}
						className={styles.createLink}>
						Add a new provider
					</div>
				}
				FilterComponent={
					<>
						<Filtered
							menuContent={menuContent}
							dropdown={dropdown}
							setDropdown={setDropdown}
							valueChannel={valueChannel}
							setValueChannel={setValueChannel}
							valueStatus={valueStatus}
							setValueStatus={setValueStatus}
							status={true}
							channel={true}
						/>
					</>
				}
			/>

			<PaginationTable
				data={tableRow ? tableRow : []}
				columns={ColumnProvider ? ColumnProvider : []}
				value={value}
				emptyPlaceHolder={
					provider?.page?.total == 0
						? 'You currently do not have any data'
						: 'Loading...'
				}
				total={provider?.page?.total}
				totalPage={provider?.page?.totalPage}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				nextPage={nextPage}
				setNextPage={setNextPage}
				previousPage={previousPage}
				setPreviousPage={setPreviousPage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
			/>

			{/* //MODAL FOR SINGLE */}
			<Modal
				isOpen={modalIsOpenSingle}
				onRequestClose={closeModalSingle}
				contentLabel='Example Modal'
				style={customStyles}>
				<div className={styles.modalwrapper}>
					<div className={styles.modalhead}>
						<h3 className={styles.modalheadh3}>Add a new provider</h3>
						<CloseIcon
							onClick={closeModalSingle}
							style={{ cursor: 'pointer' }}
						/>
					</div>
					<Divider style={{ margin: 0, padding: 0 }} />
					<Formik
						initialValues={{
							provider: '',
							providerShortName: '',
						}}
						validationSchema={validate}
						onSubmit={(values) => {
							console.log(values);
							dispatch(openLoader());

							client
								.post(`pricing/provider/add`, {
									provider: values.provider,
									providerShortName: values.providerShortName,
									currency_id: 1,
									payment_methods: method,
								})
								.then((res: any) => {
									dispatch(closeLoader());
									dispatch(
										openToastAndSetContent({
											toastContent: res.data.message,
											toastStyles: ToastSuccessStyles,
										})
									);
									closeModalSingle();
								})
								.catch((error: any) => {
									dispatch(closeLoader());
									const { message } = error?.response?.data;
									dispatch(
										openToastAndSetContent({
											toastContent: message,
											toastStyles: ToastErrorStyles,
										})
									);
								});
						}}>
						{(props) => (
							<div
								style={{
									padding: '32px 24px',
								}}>
								<Form>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={6}>
											<div className={styles.customerInfoSingle}>
												<InputLabel>
													<span className={styles.label}>Provider</span>
												</InputLabel>
												<Field
													as={TextField}
													helperText={
														<ErrorMessage name='provider'>
															{(msg) => (
																<span style={{ color: 'red' }}>{msg}</span>
															)}
														</ErrorMessage>
													}
													name='provider'
													variant='outlined'
													margin='normal'
													type='text'
													size='small'
													fullWidth
													className={classes.select}
												/>
											</div>
										</Grid>
										<Grid item xs={12} sm={6}>
											<div className={styles.customerInfoSingle}>
												<InputLabel>
													<span className={styles.label}>
														Provider short name
													</span>
												</InputLabel>
												<Field
													as={TextField}
													helperText={
														<ErrorMessage name='provider_short_name'>
															{(msg) => (
																<span style={{ color: 'red' }}>{msg}</span>
															)}
														</ErrorMessage>
													}
													name='provider_short_name'
													variant='outlined'
													margin='normal'
													type='text'
													size='small'
													fullWidth
													className={classes.select}
												/>
											</div>
										</Grid>
										<Grid item xs={12}>
											<div className={styles.customerInfoSingle}>
												<div className={styles.boxProvider}>
													<div style={{ flex: 2 }}>
														<InputLabel>
															<span className={styles.label}>Type</span>
														</InputLabel>

														<TextField
															onChange={onChangeHandler}
															variant='outlined'
															margin='normal'
															size='small'
															select
															name='type_id'
															fullWidth
															className={classes.select}>
															{category &&
																category.map((option: any) => (
																	<MenuItem key={option.id} value={option.id}>
																		{option.name}
																	</MenuItem>
																))}
														</TextField>
													</div>
													<div style={{ flex: 1, margin: '0 10px' }}>
														<InputLabel>
															<span className={styles.label}>Cost</span>
														</InputLabel>
														<TextField
															variant='outlined'
															margin='normal'
															type='text'
															size='small'
															name='cost'
															fullWidth
															className={classes.select}
															onChange={onChangeHandler}
															value={input.cost}
														/>
													</div>
													<div style={{ flex: 1 }}>
														<InputLabel>
															<span className={styles.label}>Cap</span>
														</InputLabel>
														<TextField
															variant='outlined'
															name='cap'
															margin='normal'
															type='text'
															size='small'
															fullWidth
															className={classes.select}
															onChange={onChangeHandler}
															value={input.cap}
														/>
													</div>
												</div>
												<p onClick={pushHandler} className={styles.payment_p}>
													Add payment method +
												</p>
											</div>
										</Grid>
										<Grid item xs={12}>
											<div className={styles.inputPickWrapper}>
												{method.map((item: methodProps, i: number) => (
													<div className={styles.boxProvider}>
														<div style={{ flex: 2 }}>
															<TextField
																variant='outlined'
																margin='normal'
																type='text'
																size='small'
																name='cost'
																fullWidth
																className={classes.select}
																value={
																	category.find(
																		(iden: any) => iden.id === item.typeId
																	)?.name
																}
																disabled
															/>
														</div>
														<div style={{ flex: 1, margin: '0 10px' }}>
															<TextField
																variant='outlined'
																margin='normal'
																type='text'
																size='small'
																name='cost'
																fullWidth
																className={classes.select}
																value={item.cost}
																disabled
															/>
														</div>
														<div style={{ flex: 1 }}>
															<TextField
																variant='outlined'
																name='cap'
																margin='normal'
																type='text'
																size='small'
																fullWidth
																className={classes.select}
																value={item.cap}
																disabled
															/>
														</div>
														<div
															style={{
																flex: 1,
																cursor: 'pointer',
																display: 'flex',
																justifyContent: 'flex-end',
															}}
															onClick={() => deleteHandler(i)}>
															<img src={DeleteIcon} alt='' />
														</div>
													</div>
												))}
											</div>
										</Grid>
										<Grid item xs={12}>
											<button
												disabled={method.length >= 1 ? false : true}
												className={styles.Downloadbutton}>
												<span className={styles.Downloadbutton_span}>
													<img src={Mark} alt='' />
												</span>
												Add Provider
											</button>
										</Grid>{' '}
									</Grid>
								</Form>
							</div>
						)}
					</Formik>
				</div>
			</Modal>
		</div>
	);
};

export default Provider;
