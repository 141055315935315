import React from "react";
import styles from "./Menus.module.scss";

const Menus = ({ optionName, icon, onclick }: any) => {
  return (
    <div className={styles.menuSub} onClick={onclick}>
      <img
        src={icon}
        alt={optionName}
        width={24}
        height={24}
        style={{ cursor: "pointer" }}
      />
      <div className={styles.optionName}>{optionName}</div>
    </div>
  );
};

export default Menus;
