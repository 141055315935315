import React, { useState, Fragment } from "react";
import styles from "./ApprovePayoutMenu.module.scss";
import { ReactComponent as MenuIcon } from "../../../assets/images/Icons/menu.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import RefundIcon from "../../../assets/images/Icons/refund-icon.svg"; // This can be removed if not used
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import Menus from "../Menus/Menus";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import client from "../../../api/client";
import { batch, useDispatch } from "react-redux";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import moment from "moment";
import { numberWithCommas } from "../../../utils/formatNumber";
import StatusView from "../../StatusView/StatusView";
import PayoutVerificationModal from "../../ModalContent/PayoutVerificationModal/PayoutVerificationModal";
import { closeLoader, openLoader } from "../../../redux/actions/loader/loaderActions";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import { ToastErrorStyles, ToastSuccessStyles } from "../../../constants/toastStyles";
import PayoutVerificationModalSecondLevel from "../../ModalContent/PayoutVerificationModalSecondLevel/PayoutVerificationModalSecondLevel";
import PayoutVerificationBankToBankModalSecondLevel from "../../ModalContent/PayoutVerificationModalSecondLevel/PayoutVerificationBankToBankModalSecondLevel";

type MenuProps = {
  data: any;
  cb?: Function | null | undefined;
};
interface initTypes {
  otp: string | undefined;
  batchId: number | undefined;
  subsidiaryId: string | undefined;
}

const initProps: initTypes = {
  otp: '',
  subsidiaryId: undefined,
  batchId: undefined,
};

const ApproveBankToBankPayoutMenu = ({ data, cb }: MenuProps) => {
  const dispatch = useDispatch();
  const [menuOption, setMenuOption] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [verifyPayoutModalIsOpen, setVerifyPayoutModalIsOpen] = React.useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function closeVerifyPayoutModalIsOpen() {
    setVerifyPayoutModalIsOpen(false);
    cb && cb();
  }

  const verifyPayload = {
    otp: "",
    batchId : data?.batchId,
		payoutId: data?.payoutId
	};

  const resendVerifyPayoutOtp = async () => {
    dispatch(openLoader());
    try {
      const batchId = data?.batchId;
      // const res: any = await client.get(`/subsidiary/payout/resend/otp/${batchId}`);
      const res: any = await client.post(`/wema/debit/initiate-second-level`,verifyPayload);
      const message = res?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
      setVerifyPayoutModalIsOpen(true);
      closeModal();
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  const handleClickAway = () => {
    setMenuOption(false);
  };

  const handleMenu = () => {
    setMenuOption(!menuOption);
  };

  const approvePayout = async () => {
    dispatch(openLoader());
    try {
      console.log('the data from approve menu',data)
      const res: any = await client.post('/wema/debit/approve-second-level',
        {
          // otp: inputs.otp,
          payoutId: data?.payoutId,
          batchId: data?.batchId
        }
      );
      const message = res?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
      setVerifyPayoutModalIsOpen(true);
      closeMenu();
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  // const declinePayout = async () => {
  //   dispatch(openLoader());
  //   try {
  //     const batchId = data?.batchId;
  //     const res: any = await client.post('/payout/initiate-second-level', { batchId: batchId });
  //     const message = res?.data?.message;
  //     dispatch(
  //       openToastAndSetContent({
  //         toastStyles: ToastSuccessStyles,
  //         toastContent: message,
  //       })
  //     );
  //     closeMenu();
  //   } catch (error: any) {
  //     const { message } = error?.response?.data;
  //     dispatch(
  //       openToastAndSetContent({
  //         toastStyles: ToastErrorStyles,
  //         toastContent: message,
  //       })
  //     );
  //   } finally {
  //     dispatch(closeLoader());
  //   }
  // };

  const declinePayout = async () => {
    dispatch(openLoader());
    try {
      const batchId = data?.batchId;
      // const res: any = await client.get(`/subsidiary/payout/resend/otp/${batchId}`);
      const res: any = await client.post(`/wema/debit/decline-second-level`,verifyPayload);
      const message = res?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  const closeMenu = () => {
    setMenuOption(false);
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.menuBody}>
          <div className={styles.menuIcon} onClick={handleMenu}>
            <MenuIcon />
          </div>

          {menuOption && (
            <div className={styles.menuContent} onClick={handleClickAway}>
              <div className={styles.view}> <Menus icon={ViewIcon} optionName={"View Details"} onclick={() => setIsOpen(true)} /></div>
              <div className={styles.approveDeclineContainer}>
                <button className={styles.approve} onClick={resendVerifyPayoutOtp} > Approve </button>
                {/* <button className={styles.approve} onClick={() => setVerifyPayoutModalIsOpen(true)} > Approve </button> */}
                <button className={styles.decline} onClick={declinePayout} > Decline </button>
              </div>
            </div>
          )}
        </div>
      </ClickAwayListener>
      
      {/* Modal for viewing details */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Payout Details Modal"
        style={customStyles}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalHeader}>
            <div className={styles.background}>
              <div className={styles.linkDetails}>Payout Details</div>
              <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
            </div>
          </div>
          {data && (
            <div className={styles.modalContent}>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Date Initiated</div>
                <div className={styles.content}>
                  {moment(data?.dateInitiated).format("MMMM Do YYYY, h:mm a")}
                </div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Status</div>
                <div className={styles.content}>
                  <StatusView
                    status={data?.status}
                    green={"Approved || Successful"}
                    red={"Rejected || Failed || Declined"}
                    orange={"Processing || Pending"}
                    blue={"Awaiting Verification"}
                    purple="Awaiting Approval"
                  />
                </div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Bank Name</div>
                <div className={styles.content}>{data?.bankName}</div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Payout Fee</div>
                <div className={styles.content}>
                  {`${data?.debitCurrency} ${numberWithCommas(data?.fee)}`}
                </div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Date Settled</div>
                <div className={styles.content}>
                  {moment(data?.dateSettled).format("MMMM Do YYYY, h:mm a")}
                </div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Amount</div>
                <div className={styles.content}>{`${data?.debitCurrency
                  } ${numberWithCommas(data?.amount)}`}</div>
              </div>
              {/* <div className={styles.modalDetails}>
                <div className={styles.link}>Type</div>
                <div className={styles.content}> {data?.type}</div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Initiator</div>
                <div className={styles.content}> {data?.initiator}</div>
              </div> */}
              <div className={styles.modalDetails}>
                <div className={styles.link}>Approver</div>
                <div className={styles.content}>
                  {" "}
                  {data?.approver || "N/A"}{" "}
                </div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Account Number</div>
                <div className={styles.content}> {data?.accountNumber}</div>
              </div>
              {/* <div className={styles.modalDetails}>
                <div className={styles.link}>Debit Currency</div>
                <div className={styles.content}> {data?.debitCurrency}</div>
              </div> */}
              <div className={styles.modalDetails}>
                <div className={styles.link}>Recipient Name</div>
                <div className={styles.content}> {data?.recipientName}</div>
              </div>
              {/* <div className={styles.modalDetails}>
                <div className={styles.link}>Batch Reference#</div>
                <div className={styles.content}>{data?.batchReference}</div>
              </div> */}
              <div className={styles.modalDetails}>
                <div className={styles.link}>Payout Reference#</div>
                <div className={styles.content}>{data?.payoutReference}</div>
              </div>
              {data?.status?.toLowerCase() === "awaiting verification" && (
                <div className={styles.verifyPayoutBtn}>
                  <button onClick={resendVerifyPayoutOtp}>Verify Payout</button>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>

      {/* Modal for payout verification */}
      <Modal
        isOpen={verifyPayoutModalIsOpen}
        onRequestClose={closeVerifyPayoutModalIsOpen}
        contentLabel="Payout Verification Modal"
        style={customStyles}
      >
        {/* <PayoutVerificationModalSecondLevel data={data} closeModal={closeVerifyPayoutModalIsOpen} /> */}
        <PayoutVerificationBankToBankModalSecondLevel data={data} closeModal={closeVerifyPayoutModalIsOpen}  />
      </Modal>
    </Fragment>
  );
};

export default ApproveBankToBankPayoutMenu;
