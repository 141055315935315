import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ParentContainer from "../components/ParentContainer/ParentContainer";
import SignIn from "../views/SignIn/SignIn";
import ForgotPassword from "../views/SignIn/ForgotPassword";
import DashboardRoutes from "./DashboardRoutes";
import PrivateRoute from "./PrivateRoutes";
import PublicRoute from "./PublicRoutes";
import EmailVerified from "../views/EmailVerified/EmailVerified";
import NotFound from "../views/NotFound/NotFound";

export default function AppRoutes() {
	return (
		<Router>
			{/* <ScrollToTop /> */}
			<Switch>
				<PublicRoute exact path='/'>
					<SignIn />
				</PublicRoute>
				<PublicRoute exact path='/forgot-password/:email'>
					<ForgotPassword />
				</PublicRoute>
				<PublicRoute exact path='/email-verified/:token?'>
					<EmailVerified />
				</PublicRoute>
				<PrivateRoute path='/dashboard'>
					<DashboardRoutes />
				</PrivateRoute>
				<PublicRoute exact path='*'>
					<NotFound />
				</PublicRoute>
			</Switch>
		</Router>
	);
}
