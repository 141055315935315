import React, { useState } from 'react';
import styles from './Createbusiness.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import client from '../../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';

const isAlphabets = (str: string) => /^[a-zA-Z ]*$/.test(str);
// const isNumbers = (str: string) => /^[0-9]*$/.test(str);
// const isEmail = (str: string) =>
//   /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
//     str
//   );
const CreateBusiness = ({ closeModal, fetchBusinesses }: any) => {
	const dispatch = useDispatch();
	const [errorText, setErrorText] = React.useState('');

	interface initTypes {
		firstName: string;
		lastName: string;
		userEmail: string;
		password: string;
	}

	const initProps: initTypes = {
		firstName: '',
		lastName: '',
		userEmail: '',
		password: '',
	};

	const [inputs, setInputs] = React.useState(initProps);

	const payload = {
		firstName: inputs?.firstName,
		lastName: inputs?.lastName,
		userEmail: inputs?.userEmail,
		password: inputs?.password,
	};
	let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;

	const disableFunction = () => {
		if (
			inputs.firstName &&
			inputs.lastName &&
			inputs.userEmail &&
			regex.test(inputs?.password)
		) {
			return true;
		}
	};

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;

		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};

	const createNewCustomer = async () => {
		try {
			const data: any = await client.post('business/create', payload);

			const message = data?.data?.message;
			fetchBusinesses();
			closeModal();
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			const message = error?.response?.data?.message;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		createNewCustomer();
	};
	return (
		<div className={styles.modalBody}>
			<div className={styles.modalHeader}>
				<div className={styles.background}>
					<div className={styles.linkDetails}>Add new business</div>
					<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
				</div>
			</div>
			<div className={styles.businessForm}>
				<form onSubmit={handleSubmit} className={styles.form}>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='firstName'
						inputLabel='First name'
						inputValue={inputs.firstName}
						inputType='text'
					/>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='lastName'
						inputLabel='Last name'
						inputValue={inputs.lastName}
						inputType='text'
					/>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='userEmail'
						inputLabel='User email'
						inputType='email'
						inputValue={inputs.userEmail}
					/>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='password'
						inputLabel='Password'
						inputType='password'
						inputValue={inputs.password}
					/>
				</form>
			</div>
			<div className={styles.modalFooter}>
				<div className={styles.cancel} onClick={closeModal}>
					Cancel
				</div>
				{disableFunction() ? (
					<div className={styles.deactivate} onClick={handleSubmit}>
						Create
					</div>
				) : (
					<div className={styles.notClickable}>Create </div>
				)}
			</div>
		</div>
	);
};

export default CreateBusiness;
