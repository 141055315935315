import React, { useState, useEffect } from 'react';
import styles from './Credit.module.scss';
import { useParams, useHistory } from 'react-router';
import axios from 'axios';
import client from '../../../api/client';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';
import moment from 'moment';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import StatusView from '../../../components/StatusView/StatusView';
import { ReactComponent as File } from '../../../assets/images/Icons/u_fileu.svg';
import { ReactComponent as Exclaim } from '../../../assets/images/Icons/u_exclaim.svg';
import Checkbox from '@mui/material/Checkbox';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import { ReactComponent as CloseIcon2 } from '../../../assets/images/Icons/close.svg';

const CreditDetails = () => {
	const [customerDetails, setCustomerDetails] = useState<any>();
	const { id } = useParams<{ id: any }>();
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	const [modalIsOpen, setIsOpen] = useState(false);

	function closeModal() {
		setIsOpen(false);
	}

	const fetchDetails = () => {
		dispatch(openLoader());

		client
			.get(`wallet/transactions?id=${id}`)
			.then((res: any) => {
				setCustomerDetails(res?.data?.data?.items[0]);

				dispatch(closeLoader());
			})
			.catch((error) => {
				dispatch(closeLoader());

				if (error.response) {
					const { message } = error?.response?.data;
					dispatch(
						openToastAndSetContent({
							toastStyles: ToastErrorStyles,
							toastContent: message,
						})
					);
				} else if (error?.response?.data?.message === 'Please login again') {
					sessionStorage.clear();
					window.location.href = '/';
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			});
	};

	useEffect(() => {
		fetchDetails();
	}, []);

	const handleStatus = async () => {
		dispatch(openLoader());
		try {
			const data: any = await client.put(`wallet/transaction/flag/${id}`);

			const message = data?.data?.message;
			dispatch(closeLoader());
			fetchDetails();

			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
			closeModal();
		} catch (error: any) {
			dispatch(closeLoader());
			const message = error?.response?.data?.message;

			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	return (
		<div>
			<div className={styles.transactionsHeaderflex}>
				<div
					style={{
						color: '#211F01',
						display: 'inline',
						cursor: 'pointer',
						fontWeight: '800px',
					}}
					onClick={() => history.goBack()}>
					<ArrowBackIosIcon sx={{ fontSize: '20px' }} /> Back to Credits
				</div>
				<div>
					<button
						onClick={() => setIsOpen(true)}
						className={styles.transactionsHeader_button}>
						Flag as fraudulent
					</button>
				</div>
			</div>

			<div className={styles.detailBox}>
				<div className={styles.detailBoxCorner}>
					<div className={styles.transactionsHeaderleft}>
						<h3>NGN {customerDetails?.totalAmount}</h3>
						<StatusView
							status={`${customerDetails?.status}`}
							red='Declined'
							orange='Pending'
							green='Successful'
						/>
					</div>
				</div>

				<Divider style={{ color: '#CECECD' }} />
				<div
					style={{
						padding: '0 24px',
					}}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={2} lg={2}>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Transaction reference</div>
								<div className={styles.detailsKey}>
									{customerDetails?.transactionReference}
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={2}>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Date</div>
								<div className={styles.detailsKey}>
									{customerDetails?.dateCreated}
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={6} md={2} lg={2}>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Provider reference</div>
								<div className={styles.detailsKey}>
									{' '}
									{customerDetails?.providerReference}
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Merchant name</div>
								<div className={styles.detailsKey}>
									{' '}
									{customerDetails?.businessName}
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={6} md={2} lg={2}>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Transaction fee</div>
								<div className={styles.detailsKey}>
									NGN {customerDetails?.transactionAmount}
								</div>
							</div>
						</Grid>{' '}
						<Grid item xs={12} sm={6} md={2} lg={3}>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Fees charged</div>
								<div className={styles.detailsKey}>
									NGN {customerDetails?.fee}
								</div>
							</div>
						</Grid>{' '}
						<Grid item xs={12} sm={6} md={2} lg={3}>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Reference</div>
								<div className={styles.detailsKey}>
									{customerDetails?.reference}
								</div>
							</div>
						</Grid>{' '}
						<Grid item xs={12} sm={6} md={2} lg={3}>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Payment type</div>
								<div className={styles.detailsKey}>
									{' '}
									{customerDetails?.paymentType}
								</div>
							</div>
						</Grid>{' '}
						<Grid item xs={12} sm={6} md={2} lg={3}>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Type</div>
								<div className={styles.detailsKey}>
									{' '}
									{customerDetails?.type}
								</div>
							</div>
						</Grid>{' '}
						<Grid item xs={12} sm={6} md={2} lg={2}>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Cost</div>
								<div className={styles.detailsKey}>
									NGN {customerDetails?.cost}
								</div>
							</div>
						</Grid>{' '}
						<Grid item xs={12} sm={6} md={2} lg={2}>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Income</div>
								<div className={styles.detailsKey}>
									NGN {customerDetails?.income}
								</div>
							</div>
						</Grid>{' '}
						<Grid item xs={12} sm={6} md={2} lg={2}>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Narration</div>
								<div className={styles.detailsKey}>
									{customerDetails?.narration}
								</div>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>

			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}
				// className={styles.modal}
			>
				<div className={styles.modalBody}>
					<div className={styles.modalHeader}>
						<div className={styles.background}>
							<div className={styles.linkDetails}>Flag</div>
							<CloseIcon2 onClick={closeModal} />
						</div>
					</div>
					<div className={styles.modalContent}>
						<div className={styles.confirmation}>
							Are you sure you want to perform this action?
						</div>
					</div>
					<div className={styles.modalFooter}>
						<div className={styles.cancel} onClick={closeModal}>
							No, cancel
						</div>
						<div className={styles.deactivate} onClick={handleStatus}>
							Yes, Flag
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default CreditDetails;
