const initialNavbarState = {
	navbarRoute: 'OVERVIEW',
};

export const navbarReducer = (state = initialNavbarState, action) => {
	switch (action.type) {
		case 'CHANGE_NAVBAR': {
			return {
				...state,
				navbarRoute: action.navbarRoute,
			};
		}
		default: {
			return state;
		}
	}
};

export default navbarReducer;
