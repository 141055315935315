import client from "./client";

const getTransactionsSummary = (currency = "NGN") =>
  client.get("metric/transaction/summary?currency=" + currency);

const getDashboardTransactionsSummary = (currency = "NGN") =>
  client.get("/metric/v2?currency=" + currency);

export default { getTransactionsSummary, getDashboardTransactionsSummary };

