import { CSSProperties, ReactNode } from "react";
import { AnyAction } from "redux";
import {
  CLOSE_NEW_MODAL,
  OPEN_AND_SET_NEW_MODAL_CONTENT,
} from "../../actions/constants";

const initialModalState = {
  newModalContent: "Hello",
  newModalStyles: {
    content: {
      width: "444px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: { zIndex: 18000 },
  },
  newModalOpened: false,
};

const newModalReducer = (state = initialModalState, action) => {
  switch (action.type) {
    case CLOSE_NEW_MODAL: {
      return { ...state, newModalOpened: false };
    }
    case OPEN_AND_SET_NEW_MODAL_CONTENT: {
      return {
        ...state,
        newModalOpened: true,
        newModalContent: action.newModalContent,
        newModalStyles: { ...state.newModalStyles, ...action.newModalStyles },
      };
    }
    default: {
      return state;
    }
  }
};

export default newModalReducer;
