import React from 'react';

export interface CollectionData {
	businessType: string;
	country: string;
	minAmount: string;
	maxAmount: string;
	cummulativeDaily: string;
	complianceStatus: React.ReactNode;
}

export const ColumnCollection = [
	{
		Header: 'Business type',
		accessor: 'businessType',
	},
	{
		Header: 'Country/Currency',
		accessor: 'country',
	},
	{
		Header: 'Min. amount/txn',
		accessor: 'minAmount',
	},
	{
		Header: 'Max. amount/txn',
		accessor: 'maxAmount',
	},
	{
		Header: 'Cummulative daily',
		accessor: 'cummulativeDaily',
	},
	{
		Header: 'Compliance status',
		accessor: 'complianceStatus',
	},
];


export interface TransferData {
	transactionType: string;
	currency: string;
	frequencyLimit: string;
	transferLimit: string;
	limitGroup: string;
	limitGroupId: string;
	cummulativeDaily: string;
}

export const ColumnTransfer = [
	{
		Header: 'Transaction type',
		accessor: 'transactionType',
	},
	{
		Header: 'Limit Group',
		accessor: 'limitGroup',
	},
	{
		Header: 'Currency',
		accessor: 'currency',
	},
	{
		Header: 'Frequency limit',
		accessor: 'frequencyLimit',
	},
	{
		Header: 'Single Transfer limit',
		accessor: 'transferLimit',
	},
	{
		Header: 'Cummulative daily',
		accessor: 'cummulativeDaily',
	},
];