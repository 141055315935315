import React, { useState, useEffect } from 'react';
import styles from './Refund.module.scss';
import PaginationTable from '../../components/table/pagination-table';
import { ColumnRefund, RefundData } from '../../types/TablesTypes/Refund';
import StatusView from '../../components/StatusView/StatusView';
import TableHeader from '../../components/TableHeader/TableHeader';
import client from '../../api/client';
import { numberWithCommas } from '../../utils/formatNumber';
import {
	openLoader,
	closeLoader,
} from '../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import {
	ToastErrorStyles,
} from '../../constants/toastStyles';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import { Menu, Dropdown as MenuFunc } from 'antd';
import Modal from 'react-modal';
import { customStyles } from '../../constants/modalStyles';
import Single from './Single';
import Bulk from './Bulk';
import { Dayjs } from 'dayjs';
import FilterModal from '../../components/filterConfig/FilterModal';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../utils/datefunction';
import moment from 'moment';
import FilterButtonNew from '../../components/FilterButtonNew/FilterButtonNew';

const Refund = () => {
	const dispatch = useDispatch();
	const [tableRow, setTableRow] = useState<any[]>();
	const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
	const [modalIsOpenBulk, setIsOpenBulk] = React.useState(false);
	const [refund, setRefund] = useState<any>();
	// const [apiObject, setApiObject] = useState(null);

	// const { keyB: trxRef } = apiObject?.keyA || [{

	// }];

	function closeModalSingle() {
		setIsOpenSingle(false);
	}
	function closeModalBulk() {
		setIsOpenBulk(false);
	}

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [orderReference, setOrderReference] = useState('');
	const [name, setName] = useState('');
	const [status, setStatus] = useState('');
	const [email, setEmail] = useState('');
	const [bearer, setBearer] = useState(false);

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setName('');
		setOrderReference('');
		setStatus('');
		setEmail('');
		setDropdown(false);
		setBearer(true);
	};

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const filteredArray: {
		name: string;
		value: string | Dayjs | null;
		setValue: React.Dispatch<React.SetStateAction<any>>;
		selective?: { name: string; value: string }[];
		selectHelper?: boolean;
		type: "select" | "date" | "text";
	  }[]  = [
		{
			name: "Date Range",
			value: fromDate,
			setValue: setFromDate,
			type: "date", // Explicitly set as "date"
		},
		{
			name: 'Order Reference',
			value: orderReference,
			setValue: setOrderReference,
			type:'text',
		},
		{
			name: 'Customer Name',
			value: name,
			setValue: setName,
			type:'text'
		},
		{
			name: 'Status',
			value: status,
			setValue: setStatus,
			type:'select',
			selective: [
				{ name: 'Declined', value:'Declined' },
				{ name: 'Approved', value:'Approved' },
				{ name: 'Processing', value:'Processing' },
				{ name: 'Awaiting Response', value:'Awaiting Response' },
			],
		},
	];

	const fetchRefund = async () => {
		dispatch(openLoader());
		try {
			const { data } = await client.get(
				`/refund/all/paginated?status=${status}&orderreference=${orderReference}&customername=${name}&email=${email}&fromdate=${fromDate}&todate=${toDate}&limit=${rowsPerPage}&page=${pageNumber}`
			);
			setRefund(data);
			dispatch(closeLoader());
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};

	useEffect(() => {
		fetchRefund();
	}, [bearer, email, pageNumber, rowsPerPage]);

	const dataTransaction = () => {
		const tempArr: RefundData[] = [];
		refund?.items
			?.slice(0)
			.reverse()
			.forEach((transaction: any, index: number) => {
				tempArr.push({
					amount: `${transaction.currency} ${numberWithCommas(transaction?.refundAmount)}`,
					merchantId: transaction.customerId,
					amountCharged: transaction.amount,
					businessName: transaction.customerName,
					transactionReference: transaction?.orderReference,
					date: moment(transaction?.dateCreated).format('MMMM Do YYYY, h:mm a'),
					status: (
						<StatusView
							status={transaction?.refundStatus}
							green='Approved'
							red='Declined'
							orange='Processing'
							blue='Awaiting Response'
						/>
					),
					// action: <RefundsMenu customerId={transaction.customer_id} />,
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransaction());
	}, [refund?.items]);

	const itemLength = refund?.items?.length;

	const menu = (
		<Menu
			items={[
				{
					key: '1',
					label: (
						<p onClick={() => setIsOpenSingle(true)}>Log a single refund</p>
					),
				},
				{
					key: '2',
					label: <p onClick={() => setIsOpenBulk(true)}>Log bulk refunds</p>,
				},
			]}
		/>
	);

	return (
		<div className={styles.dashbordContainer}>
			<TableHeader
				pageName='Refunds'
				dataLength={refund?.totalItems}
				data={refund?.items}
				value={email}
				setValue={setEmail}
				dropdown={dropdown}
				placeHolder='search by email address'
				setDropdown={setDropdown}
				newButton={
					<MenuFunc overlay={menu}>
						<div className={styles.createLink}>Log Refund</div>
					</MenuFunc>
				}
				searchfn={true}
				FilterComponent={
					<FilterButtonNew
						eventDate={eventDate}
						setEventDate={setEventDate}
						dropdown={dropdown}
						setDropdown={setDropdown}
						setFromDate={setFromDate}
						setToDate={setToDate}
						fromDate={fromDate}
						toDate={toDate}
						setBearer={setBearer}
						clearHandler={clearHandler}
						clearAllHandler={clearHandler}
						filteredArray={filteredArray}
					/>
				}
			/>

			<PaginationTable
				data={tableRow ? tableRow : []}
				columns={ColumnRefund ? ColumnRefund : []}
				value={value}
				emptyPlaceHolder={
					refund?.totalItems == 0
						? 'You currently do not have any data'
						: 'Loading...'
				}
				total={refund?.totalItems}
				totalPage={refund?.totalPages}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				nextPage={nextPage}
				setNextPage={setNextPage}
				previousPage={previousPage}
				setPreviousPage={setPreviousPage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
			/>

			{/* //MODAL FOR SINGLE */}
			<Modal
				isOpen={modalIsOpenSingle}
				onRequestClose={closeModalSingle}
				contentLabel='Example Modal'
				style={customStyles}>
				<Single closeModal={closeModalSingle} fn={fetchRefund} />
			</Modal>
			{/* //MODAL FOR BULK */}
			<Modal
				isOpen={modalIsOpenBulk}
				onRequestClose={closeModalBulk}
				contentLabel='Example Modal'
				style={customStyles}>
				<Bulk closeModal={closeModalBulk} fn={fetchRefund} />
			</Modal>
		</div>
	);
};

export default Refund;
