import React from 'react';

export interface ReportData {
	transactionReference: string;
	destination: string;
	amount: string;
	initiate: string;
	date: string;
	status: any;
}

export const ColumnReport = [
	{
		Header: 'Transaction reference',
		accessor: 'transactionReference',
	},
	{
		Header: 'Destination',
		accessor: 'destination',
	},
	{
		Header: 'Initiated by',
		accessor: 'initiate',
	},
	{
		Header: 'Amount due',
		accessor: 'amount',
	},
	{
		Header: 'Transaction date',
		accessor: 'date',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
];
