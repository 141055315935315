import React, { useState } from 'react';
import styles from './TransactionTab.module.scss';
import AllTransactions from '../AllTransactions';
import Customers from '../Customers';
import Chargebacks from '../Chargebacks';
import { useParams } from 'react-router-dom';
import TransactionDetails from '../TransactionDetails/TransactionDetails';

function TransactionTab() {
	interface dataTypes {
		id: Number;
		menu: String;
	}
	const [selectedNav, setSelectedNav] = useState<Number>(1);
	const { id } = useParams<{ id: any }>();
	const dataRef: dataTypes[] = [
		{
			id: 1,
			menu: 'All Transactions',
		},
		{
			id: 2,
			menu: 'Customers',
		},
		// {
		// 	id: 3,
		// 	menu: 'Invoices',
		// },
	];
	return (
		<>
			<div className={styles.tabContainer}>
				{dataRef.map(({ id, menu }: dataTypes, i) => (
					<div
						key={i}
						onClick={() => {
							setSelectedNav(id);
						}}
						className={
							selectedNav === id ? styles.activeMenuItem : styles.subMenuItem
						}>
						{selectedNav === id ? (
							<div className={styles.activeItemColor}>{menu}</div>
						) : (
							<div style={{ color: 'rgba(10, 9, 3, 0.6)' }}>{menu}</div>
						)}
					</div>
				))}
			</div>
		
				<div>
					{selectedNav === 1 ? (
						<AllTransactions />
					) : selectedNav === 2 ? (
						<Customers />
					) : selectedNav === 3 ? (
						<Chargebacks />
					) : (
						''
					)}
				</div>
		</>
	);
}

export default TransactionTab;
