import React, { useState } from 'react';
import SettlementPS from '../SettlementPS/SettlementPS';
import SettlementTransaction from '../SettlementTransaction/SettlementPS';
import SettlementTS from '../SettlementTS/SettlementTS';
import styles from './ReconciliationTab.module.scss';

function AccountTab() {
	interface dataTypes {
		id: Number;
		menu: String;
	}
	const [selectedNav, setSelectedNav] = useState<Number>(1);
	const dataRef: dataTypes[] = [
		{
			id: 1,
			menu: 'Settlements transactions',
		},
		{
			id: 2,
			menu: 'Settlements transactions summary',
		},
		{
			id: 3,
			menu: 'Acquirer/Provider summary',
		},
	];
	return (
		<>
			{/* <div className={styles.tabContainer}>
				{dataRef.map(({ id, menu }: dataTypes, i) => (
					<div
						key={i}
						onClick={() => {
							setSelectedNav(id);
						}}
						className={
							selectedNav === id ? styles.activeMenuItem : styles.subMenuItem
						}>
						{selectedNav === id ? (
							<div className={styles.activeItemColor}>{menu}</div>
						) : (
							<div style={{ color: 'rgba(10, 9, 3, 0.6)' }}>{menu}</div>
						)}
					</div>
				))}
			</div>

			<div>
				{selectedNav === 1 ? (
					<SettlementPS />
				) : selectedNav === 2 ? (
					<SettlementTransaction />
				) : selectedNav === 3 ? (
					<SettlementTS />
				) : (
					''
				)}
			</div> */}
			<SettlementPS />
		</>
	);
}

export default AccountTab;
