import React, { useState } from 'react';
import FeesApproval from '../Fees Approvals/FeesApproval';
import LimitApproval from '../Limit Approval/LimitApproval';
import PendingFees from '../PendingFees/PendingFees';
import PendingLimit from '../PendingLimit/PendingLimit';
import ProviderApproval from '../ProviderApproval/ProviderApproval';
import TransferFees from '../TransferFees/TransferFees';
import styles from './ApprovalTab.module.scss';

function ApprovalTab() {
	interface dataTypes {
		id: Number;
		menu: String;
	}
	const [selectedNav, setSelectedNav] = useState<Number>(1);
	const dataRef: dataTypes[] = [
		{
			id: 1,
			menu: 'Providers setup approval',
		},
		{
			id: 2,
			menu: 'Fees approval',
		},
		{
			id: 3,
			menu: 'Limits approval',
		},
	];
	return (
		<>
			<div className={styles.tabContainer}>
				{dataRef.map(({ id, menu }: dataTypes, i) => (
					<div
						key={i}
						onClick={() => {
							setSelectedNav(id);
						}}
						className={
							selectedNav === id ? styles.activeMenuItem : styles.subMenuItem
						}>
						{selectedNav === id ? (
							<div className={styles.activeItemColor}>{menu}</div>
						) : (
							<div style={{ color: 'rgba(10, 9, 3, 0.6)' }}>{menu}</div>
						)}
					</div>
				))}
			</div>

			<div>
				{selectedNav === 1 ? (
					<ProviderApproval />
				) : selectedNav === 2 ? (
					<PendingFees title="Pending Fees"/>
				) : selectedNav === 3 ? (
					<PendingLimit title="Pending Approval"/>
				) : (
					''
				)}
			</div>
		</>
	);
}

export default ApprovalTab;
