import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ children, ...rest }: any) => {
  const access_token = sessionStorage.getItem("accessToken");
  return (
    <Route
      {...rest}
      render={() => (access_token ? children : <Redirect to="/" />)}
    />
  );
};

export default PrivateRoute;
