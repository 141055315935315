import React, { useState, useEffect } from 'react';
import styles from './Business.module.scss';
import Grid from '@material-ui/core/Grid';
import Card from '../../components/Card/Card';
import SettlementIcon from '../../assets/images/Icons/settlement-icon.svg';
import BalanceIcon from '../../assets/images/Icons/balance-card-icon.svg';
import TimerIcon from '../../assets/images/Icons/timer.svg';
import RefundIcon from '../../assets/images/Icons/Icons/refunds.svg';
import creditCard from '../../assets/images/Icons/Icons/creditCard.svg';
import transactionsProcessed from '../../assets/images/Icons/Icons/transactionsProcessed.svg';
import client from '../../api/client';
import {
	ToastErrorStyles,
} from '../../constants/toastStyles';
import { useDispatch, useSelector } from 'react-redux';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
	closeLoader,
	openLoader,
} from '../../redux/actions/loader/loaderActions';
import useApi from '../../hooks/useApi';
import customersApi from '../../api/customers';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ReactComponent as LinkIcon } from '../../assets/images/Icons/link-icon.svg';
import Trans from './Trans';
import Cust from './Cust';
import { useHistory, useParams } from 'react-router-dom';
import Settlements from '../Settlement/Settlements/Settlements';

interface idTypes {
	id: number;
	name: string;
	modalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const BusinessModuleDetails = () => {

	//view options dropdown
	const [istoggleDropdownOpen, setIstoggleDropdownOpen] = useState(false);
	const toggleDropdown = () => {
		setIstoggleDropdownOpen(!istoggleDropdownOpen);
	};


	const history: any = useHistory();
	const params: any = useParams();
	const id = params.id;
	const name = id;
	const [transactionSummaryDetail, settransactionSummaryDetail] = useState<any>(
		[]
	);
	const [balancesChargebacks, setbalancesChargebacks] = useState<any>([]);
	const [tableCustomers, setTableCustomers] = useState<any[]>();
	const getCustomersApi = useApi(customersApi.getCustomers);
	const { currency } = useSelector((state) => state.appSettingsReducer);

	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	const [filterParam, setFilterParam] = useState<any>('year');
	const [inflowOutflowData, setInflowOutflowData] = useState<any>();

	const fetchInflow = async () => {
		const { data }: any = await client.get(
			`metric/inflow/outflow?type=${filterParam}&businessid=${id}&currency=${currency}`
		);
		setInflowOutflowData(data?.data);
	};

	const openBusinessAction = async (businessId: any, page: any) => {
		// console.log({ business })
		// setDetails(business.id);
		// setBusinessName(business.businessName);
		history.push(`/dashboard/accounts/${businessId}/${page}`)
	};
	useEffect(() => {
		fetchInflow();
	}, [filterParam, currency]);

	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}

	// api call to get details for details summary
	useEffect(() => {
		dispatch(openLoader());
		setIsLoading(true);
		client
			// .get(`metric/transaction/summary?businessid=${id}&currency=${currency}`)
			.get(`metric/v2?SubsidiaryId=${id}&Currency=${currency}`)
			.then((res: any) => {
				settransactionSummaryDetail(res?.data?.data);
				setIsLoading(false);
				dispatch(closeLoader());
			})
			.catch((error) => {
				dispatch(closeLoader());
				setIsLoading(false);
				if (error.response) {
					const message = error?.response?.data?.message;
					dispatch(
						openToastAndSetContent({
							toastStyles: ToastErrorStyles,
							toastContent: message,
						})
					);
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			});
	}, [currency]);

	useEffect(() => {
		dispatch(openLoader());
		setIsLoading(true);
		client
			.get(`metric/balances/chargebacks?businessid=${id}&currency=${currency}`)
			.then((res: any) => {
				setbalancesChargebacks(res?.data?.data);
				setIsLoading(false);
				dispatch(closeLoader());
			})
			.catch((error) => {
				dispatch(closeLoader());
				setIsLoading(false);
				if (error.response) {
					const message = error?.response?.data?.message;
					dispatch(
						openToastAndSetContent({
							toastStyles: ToastErrorStyles,
							toastContent: message,
						})
					);
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			});
	}, [currency]);

	// Function to format numbers as currency
	const formatCurrency = (amount: number) => {
		return amount.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
	};

	// Unformatted variable declaration for details summary
	const unformattedResponse = {
		transactionVolume: transactionSummaryDetail?.transactionsProcessedVolume ?? 0,
		transactionCount: transactionSummaryDetail?.transactionsProcessedCount ?? 0,
		settlements: transactionSummaryDetail?.totalSettlement ?? 0,
		settlementCount: transactionSummaryDetail?.totalSettlementCount ?? 0,
		collections: transactionSummaryDetail?.totalCollection ?? 0,
		collectionCount: transactionSummaryDetail?.totalCollectionCount ?? 0,
		payouts: transactionSummaryDetail?.totalPayout ?? 0,
		payoutCount: transactionSummaryDetail?.totalPayoutCount ?? 0,
		chargebacks: transactionSummaryDetail?.totalChargebacks ?? 0,
		chargebackCount: transactionSummaryDetail?.totalChargebackCount ?? 0,
		refunds: transactionSummaryDetail?.totalRefunds ?? 0,
		refundCount: transactionSummaryDetail?.totalRefundCount ?? 0,
		ledgerBalance: transactionSummaryDetail?.ledgerBalance ?? null,
		settlementBalance: transactionSummaryDetail?.settlementBalance ?? null,
		availableBalance: transactionSummaryDetail?.availableBalance ?? null,
	};
	

	// Variable declaration for details summary
	const response = Object.fromEntries(
		Object.entries(unformattedResponse).map(([key, value]) => [
			key,
			// Format only the volume fields
			['volume', 'settlements', 'count'].includes(key)
				? typeof value === 'number'
					? formatCurrency(value)
					: value
				: value,
		])
	);


	const balance = balancesChargebacks?.availableBalance;
	const ledger_balance = balancesChargebacks?.ledgerBalance;
	const chargebacks = balancesChargebacks.chargebacks;
	const balance_change = balancesChargebacks.lastWeekAvailableBalancePercentChange;
	const ledger_balance_change = balancesChargebacks.lastWeekLedgerBalancePercentChange;
	const chargebacks_change = balancesChargebacks.lastWeekChargebacksPercentChange;

	return (
		<div>
			{isLoading ? (
				<div />
			) : (
				<div className={styles.dashbordContainer}>
					<div className={styles.transactionsHeader}>
						<div
							style={{
								color: '#211F01',
								display: 'inline',
								cursor: 'pointer',
								fontWeight: '800px',
							}}
							onClick={() => history.goBack()}>
							<ArrowBackIosIcon sx={{ fontSize: '20px' }} /> {name} ID
						</div>
						{/* new dropdown option  */}
						{/* <div className={styles.businessActionButtons}> */}
						<div className={styles.dropdown}>
							<div className={styles.dropbtnBox}> <button className={styles.dropbtn} onClick={toggleDropdown}> View Options </button> </div>
							{istoggleDropdownOpen && (
								<div className={styles.dropdownContent}>
									<div className={styles.dropdownContentText} onClick={() => { openBusinessAction(id, 'funding-history'); setIsOpen(false); }} > Funding History </div>
									<div className={styles.dropdownContentText} onClick={() => { openBusinessAction(id, 'wallet-history'); setIsOpen(false); }} > Balance History </div>
									<div className={styles.dropdownContentText} onClick={() => { openBusinessAction(id, 'rolling-reserve'); setIsOpen(false); }} > Rolling Reserve </div>
									<div className={styles.dropdownContentText} onClick={() => { openBusinessAction(id, 'rolling-reserve'); setIsOpen(false); }} > Get Payment Link </div>
									<div className={styles.dropdownContentText} onClick={() => { openBusinessAction(id, 'balance-operation'); setIsOpen(false); }} > Balance Operation </div>
								</div>
							)}
						</div>
						{/* </div> */}
					</div>
					{transactionSummaryDetail.loading ? (
						<div className={styles.loading} />
					) : (
						<>
							<Grid container spacing={2}>
								<Grid item md={4} xs={12}>
									<Card
										icon={transactionsProcessed}
										currency={currency}
										percentage={response.transactionCount}
										amount={response.transactionVolume}
										transactionType='Total Transactions Processed'
									/>
								</Grid>
								<Grid item md={4} xs={12}>
									<Card
										icon={TimerIcon}
										currency={currency}
										percentage={response.settlementCount}
										amount={response.settlements}
										transactionType='Total Settlements'
									/>
								</Grid>
								<Grid item md={4} xs={12}>
									<Card
										icon={SettlementIcon}
										currency={currency}
										percentage={response.collectionCount}
										amount={response.collections}
										transactionType='Total Collection'
									/>
								</Grid>
								<Grid item md={4} xs={12}>
									<Card
										icon={BalanceIcon}
										currency={currency}
										percentage={response.payoutCount}
										amount={response.payouts}
										transactionType='Total Payouts'
									/>
								</Grid>
								<Grid item md={4} xs={12}>
									<Card
										icon={creditCard}
										percentage={response.chargebackCount}
										amount={response.chargebacks}
										currency={currency}
										transactionType='Total Chargebacks'
									/>
								</Grid>
								<Grid item md={4} xs={12}>
									<Card
										icon={RefundIcon}
										percentage={response.refundCount}
										amount={response.refunds}
										currency={currency}
										transactionType='Total Refunds'
									/>
								</Grid>
								{/* <Grid item md={4} xs={12}>
									<Card
										icon={SettlementIcon}
										percentage={response.merchantCount}
										amount={response.merchantCount}
										transactionType='Total Merchant Count'
									/>
								</Grid> */}
							</Grid>							
							<div className={styles.mt1}>
								<Trans id={id} />
								{/* {console.log(`id from bdm${id}`)} */}
							</div>
							{/* <div className={styles.mt1}>
								<Subs id={id} name={name} />
							</div> */}

							<div className={styles.mt1}>
								<Settlements subsidiaryId={id} />
								{/* <Cust id={id} name={name} /> */}
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default BusinessModuleDetails;
