import React from 'react';

export interface PendingSettlementData {
	amount: string;
	narration: string;
	accountNumber: string;
	initiate: string;
	reference: string;
	bankCode: string;
	action?: React.ReactNode;
}

export const ColumnPendingSettlement = [
	{
		Header: 'Amount due',
		accessor: 'amount',
	},
	{
		Header: 'Narration',
		accessor: 'narration',
	},
	{
		Header: 'Account number/bank',
		accessor: 'accountNumber',
	},
	{
		Header: 'Initiated by',
		accessor: 'initiate',
	},
	{
		Header: 'Reference',
		accessor: 'reference',
	},
	{
		Header: 'Bank code',
		accessor: 'bankCode',
	},
	{
		Header: 'Action',
		accessor: 'action',
	},
];
