import React, { useEffect } from "react";
import styles from "./Settlement.module.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch, useSelector } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import useApi from "../../../hooks/useApi";
import reports from '../../../api/report';
import { closeLoader, openLoader } from "../../../redux/actions/loader/loaderActions";

const isAlphabets = (str: string) => /^[a-zA-Z ]*$/.test(str);
const isNumbers = (str: string) => /^[0-9]*$/.test(str);

const SettlementcycleModal = ({ closeModal, data }: any) => {
  const dispatch = useDispatch();
  console.log({ data })
  const getSettlementGroups = useApi(reports.getSettlementGroups);

  const mapSettlementGroups = () => (getSettlementGroups?.data?.data ?? []).map((x: any) => { return { name: x.name, value: x.id } })
  const settlementGroupOptions = mapSettlementGroups();
  const settlementDestination = [{ name: 'Merchant Bank Account', value: 2 }, { name: 'Merchant Available Balance', value: 1 }]
  const settlementPercentage = [{ name: 'Full Settlement', value: 'F' }, { name: 'Partial Settlement', value: 'P' }]
  const settlementPlanStatuses = [{ name: 'Active', value: true }, { name: 'Inactive', value: false }]

  const { currencies } = useSelector((state) => state.appSettingsReducer);
  const currencyOption = currencies.map((curr: any) => ({ name: `${curr.name} (${curr.shortName})`, value: curr.id }));

  interface initTypes {
    name: string;
    description: string;
    partialPaymentDays: number;
    fullPaymentDays: number;
    partialOrFull: number;
    percentage: number;
    currencyId: number;
    settlementPlanId: number;
    settlementType: string;
    isActiveStatus: string;
    isActive: boolean;
    settlementGroupId: number
    settlementDestinationId: number
  }

  const initProps: initTypes = {
    name: data?.name ?? "",
    description: data?.description ?? "",
    partialPaymentDays: data?.partialPaymentDays ?? "",
    fullPaymentDays: data?.fullPaymentDays ?? "",
    settlementType: data?.partialOrFull ?? "",
    partialOrFull: data?.partialOrFull ?? "",
    percentage: data?.percentage ?? "",
    currencyId: data?.currencyId ?? "",
    settlementPlanId: data?.id ?? 0,
    isActiveStatus: data?.isActive ?? false,
    isActive: data?.isActive ?? false,
    settlementGroupId: data?.settlementGroupId ?? 0,
    settlementDestinationId: data?.settlementDestinationId ?? "",
  };
  console.log({ initProps })
  const [inputs, setInputs] = React.useState(initProps);
  const [errorText, setErrorText] = React.useState("");
  const [touchedName, setTouchedName] = React.useState(false);
  const [touchedNumber, setTouchNumber] = React.useState(false);
  const [touchedDescription, setTouchDescription] = React.useState(false);

  const disableFunction = () => {
    if (inputs.name && inputs.partialPaymentDays && inputs.settlementType && inputs.percentage && inputs.currencyId && inputs.settlementGroupId) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;

    // if (isAlphabets(newValue)) {
    setErrorText("");
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
    // } else {
    //   setErrorText("Please input alphabets only");
    // }
  };

  const updatePropsNumbers = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;

    // if (isNumbers(newValue)) {
    setErrorText("");
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
    // } else {
    //   setErrorText("Please input numbers only");
    // }
  };

  const createPaymentLink = async () => {
    try {
      const path = data ? "plan/edit" : "plan/create"
      inputs.percentage = inputs.percentage / 100;
      inputs.settlementPlanId = data?.id ?? 0;
      inputs.isActive = inputs.isActiveStatus == "Active";
      dispatch(openLoader())
      const res: any = await client.post(`settlement/${path}`, inputs);
      const message = res?.data?.message;
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
    finally {
      dispatch(closeLoader())
    }
  };

  const handleTouchName = () => {
    setTouchNumber(false);
    setTouchDescription(false);
    setTouchedName(true);
  };

  const handeTouchNumber = () => {
    setTouchedName(false);
    setTouchDescription(false);
    setTouchNumber(true);
  };

  const handleTouchDescription = () => {
    setTouchedName(false);
    setTouchNumber(false);
    setTouchDescription(true);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createPaymentLink();
  };

  useEffect(() => {
    getSettlementGroups.request();
  }, []);
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>{data ? "Edit Settlement Plan" : "New Settlement Plan"}</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="settlementGroupId"
            inputLabel="Settlement Group"
            inputValue={inputs.settlementGroupId}
            onFocus={handleTouchName}
            InputHelper={touchedName && errorText}
            error={touchedName && errorText}
            inputSelect
            disabled={!!data}
            inputOption={settlementGroupOptions}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="currencyId"
            inputLabel="Currency"
            inputValue={inputs.currencyId}
            onFocus={handleTouchName}
            InputHelper={touchedName && errorText}
            error={touchedName && errorText}
            inputOption={currencyOption}
            disabled={!!data}
            inputSelect
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="settlementType"
            inputLabel="Settlement Type"
            inputValue={inputs.settlementType}
            onFocus={handleTouchName}
            InputHelper={touchedName && errorText}
            error={touchedName && errorText}
            inputOption={settlementPercentage}
            inputSelect
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="settlementDestinationId"
            inputLabel="Settlement Destination"
            inputValue={inputs.settlementDestinationId}
            onFocus={handleTouchName}
            InputHelper={touchedName && errorText}
            error={touchedName && errorText}
            inputOption={settlementDestination}
            inputSelect
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="name"
            inputLabel="Plan Name"
            inputValue={inputs.name}
            onFocus={handleTouchName}
            InputHelper={touchedName && errorText}
            error={touchedName && errorText}
          />
          <OutlineTextInput
            handleChange={updatePropsNumbers}
            inputName="percentage"
            inputLabel="Initial Settlement (%)"
            inputValue={inputs.percentage}
            onFocus={handeTouchNumber}
            inputType="number"
            InputHelper={touchedNumber && errorText}
            error={touchedNumber && errorText}
          />
          <OutlineTextInput
            handleChange={updatePropsNumbers}
            inputName="partialPaymentDays"
            inputLabel="Initial Settlement Day"
            inputValue={inputs.partialPaymentDays}
            onFocus={handeTouchNumber}
            InputHelper={touchedNumber && errorText}
            error={touchedNumber && errorText}
            inputType="number"
          />
          <OutlineTextInput
            handleChange={updatePropsNumbers}
            inputName="fullPaymentDays"
            inputLabel="Final Settlement Day"
            inputValue={inputs.fullPaymentDays}
            onFocus={handeTouchNumber}
            InputHelper={touchedNumber && errorText}
            error={touchedNumber && errorText}
            inputType="number"
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="description"
            inputLabel="Description"
            inputValue={inputs.description}
            onFocus={handleTouchDescription}
            InputHelper={touchedNumber && errorText}
            error={touchedNumber && errorText}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="isActiveStatus"
            inputLabel="Status"
            inputValue={inputs.isActiveStatus}
            onFocus={handleTouchDescription}
            InputHelper={touchedNumber && errorText}
            error={touchedNumber && errorText}
            inputOption={settlementPlanStatuses}
            inputSelect

          />
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Submit
          </div>
        ) : (
          <div className={styles.notClickable}>Submit </div>
        )}
      </div>
    </div>
  );
};

export default SettlementcycleModal;
