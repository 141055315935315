import React from 'react';
import styles from './ProviderSummary.module.scss';
import { numberWithCommas } from '../../../utils/formatNumber';
import ProviderSummaryTable from './ProviderSummaryTable/ProviderSummaryTable';

function ProviderSummary() {
	return (
		<div style={{ width: '100%' }}>
			<ProviderSummaryTable />
		</div>
	);
}

export default ProviderSummary;
