import React from "react";

export interface RolesData {
  name: string;
  dateCreated: string;
  userCount: string;
  status: React.ReactNode;
  id: number
  // action: React.ReactNode;
}

export const ColumnRoles = [
  {
    Header: "Role Name",
    accessor: "name",
  },
  {
    Header: "Number of Users",
    accessor: "userCount",
  },
  {
    Header: "Date Created",
    accessor: "dateCreated",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  // {
  //   Header: "",
  //   accessor: "action",
  // },
];