import React from 'react';
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';
import { ChartData } from '../../constants/chartData';
import styles from './Chartgraph.module.scss';

const ChartGraph = ({ inflowData }: any) => {
	let chartData = inflowData?.map(function (chart: any) {
		return {
			name: chart.name_date,
			inflow: chart.inflow,
			outflow: chart.outflow,
			amt: chart.outflow,
		};
	});
	return (
		<ResponsiveContainer
			width='100%'
			height='100%'
			className={styles.chartContent}>
			<LineChart
				width={500}
				height={300}
				data={chartData}
				margin={{
					top: 2,
					right: 2,
					left: 0,
					bottom: -8,
				}}>
				<CartesianGrid strokeDasharray='1 0' />
				<XAxis dataKey='name' />
				<YAxis />
				<Tooltip />
				<Legend />
				<Line
					type='monotone'
					dataKey='inflow'
					stroke='#0C73B8'
					strokeDasharray='5 5'
					strokeWidth={2}
				/>
				<Line
					type='monotone'
					dataKey='outflow'
					stroke='#FF7E35'
					strokeDasharray='5 5'
					strokeWidth={2}
				/>
			</LineChart>
		</ResponsiveContainer>
	);
};

export default ChartGraph;
