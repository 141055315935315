import React, { useState } from 'react';
import Notifications from '../Notifications/Notifications';
import Profile from '../Profile/Profile';
import Roles from '../Roles/Roles';
import SettlementCycle from '../Settlement/SettlementCycle';
import SettlementGroups from '../Settlement/SettlementGroups';
import Users from '../User/Users';
import styles from './SettingsTab.module.scss';

function SettingsTab() {
	interface dataTypes {
		id: Number;
		menu: String;
	}
	const [selectedNav, setSelectedNav] = useState<Number>(1);
	const dataRef: dataTypes[] = [
		{
			id: 1,
			menu: 'Users',
		},
		{
			id: 2,
			menu: 'Roles & Permissions',
		},
		// {
		// 	id: 3,
		// 	menu: 'Settlement Cycles',
		// },
		// {
		// 	id: 4,
		// 	menu: 'Settlement Groups',
		// },
		// {
		// 	id: 5,
		// 	menu: 'Notifications',
		// },
		{
			id: 6,
			menu: 'Profile',
		},
	];
	return (
		<>
			<div className={styles.tabContainer}>
				{dataRef.map(({ id, menu }: dataTypes, i) => (
					<div
						key={i}
						onClick={() => {
							setSelectedNav(id);
						}}
						className={
							selectedNav === id ? styles.activeMenuItem : styles.subMenuItem
						}>
						{selectedNav === id ? (
							<div className={styles.activeItemColor}>{menu}</div>
						) : (
							<div style={{ color: 'rgba(10, 9, 3, 0.6)' }}>{menu}</div>
						)}
					</div>
				))}
			</div>

		
				{selectedNav === 1 ? (
					<Users />
				) : selectedNav === 2 ? (
					<Roles />
				) : selectedNav === 3 ? (
					<SettlementCycle />
				) : selectedNav === 4 ? (
					<SettlementGroups />
				) : selectedNav === 5 ? (
					<Notifications />
				) : selectedNav === 6 ? (
					<Profile />
				) : (
					''
				)}
			
		</>
	);
}

export default SettingsTab;
