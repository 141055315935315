// import client from "./client";

// const getAllPayouts = () => client.get("/merchants/payouts");
// export default { getAllPayouts };

import client from "./client";

const getAllPayouts = (params) => {
    return client.get("/merchants/payouts", {
      params: {
        SearchableColumns: params.SearchableColumns || '',    
        PageNumber: params.PageNumber || 1,                
        PageSize: params.PageSize || 10,    
        StartDate: params.StartDate || '',
        EndDate: params.EndDate || '',
        Filter: params.Filter || null,
        SelectedSearchColumn: params.SelectedSearchColumn || '',
        Keyword: params.Keyword || '',
        PageSkip: params.PageSkip || 0,
      },
    });
};

// Function to get pending payouts
const getPendingPayouts = (params) => {
  return client.get("/merchants/pending-payouts", {
      params: {
          SearchableColumns: params.SearchableColumns || '',
          PageNumber: params.PageNumber || 1,
          PageSize: params.PageSize || 10,
          StartDate: params.StartDate || '',
          EndDate: params.EndDate || '',
          Filter: params.Filter || null,
          SelectedSearchColumn: params.SelectedSearchColumn || '',
          Keyword: params.Keyword || '',
          PageSkip: params.PageSkip || 0,
      },
  });
};

// Function to get wema bank to bank  pending payouts
const getPendingBankToBankPayouts = (params) => {
  return client.get("/wema-debits", {
      params: {
          SearchableColumns: params.SearchableColumns || '',
          PageNumber: params.PageNumber || 1,
          PageSize: params.PageSize || 10,
          StartDate: params.StartDate || '',
          EndDate: params.EndDate || '',
          Filter: params.Filter || null,
          SelectedSearchColumn: params.SelectedSearchColumn || '',
          Keyword: params.Keyword || '',
          PageSkip: params.PageSkip || 0,
      },
  });
};


  
  export default { getAllPayouts, getPendingPayouts,getPendingBankToBankPayouts };
  