import React from 'react';
import NavBar from '../../components/navBar/NavBar';
import styles from './TopBar.module.scss';

function TopBar({
	selectedMenu,
	selectedNav,
	setSelectedMenu,
	setSelectedNav,
	collapse,
	setCollapse,
}: {
	selectedMenu: number;
	selectedNav: number;
	setSelectedMenu: any;
	setSelectedNav: any;
	collapse: boolean;
	setCollapse: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	return (
		<>
			<div className={styles.navContainer}>
				<NavBar
					selectedMenu={selectedMenu}
					selectedNav={selectedNav}
					setSelectedMenu={setSelectedMenu}
					setSelectedNav={setSelectedNav}
					collapse={collapse}
					setCollapse={setCollapse}
				/>
			</div>
		</>
	);
}

export default TopBar;
