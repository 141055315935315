import React, { useEffect } from 'react';
import styles from './Business.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
import { ReactComponent as CopyIcon } from '../../assets/images/Icons/u_copy.svg';
import Select from 'react-select';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import { Divider } from 'antd';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import client from '../../../api/client';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';

function Verify({ closeModal, fn, id, accountManagers }: any) {
	interface initTypes {
		subsidiaryId: number;
		reason: string;
		status: string;
		accountManagerId?: string; 
	}

	const initProps: initTypes = {
		subsidiaryId: id,
		reason: '',
		status: '',
		accountManagerId: ''
	};
	const [inputs, setInputs] = React.useState(initProps);

	const dispatch = useDispatch();

	const handleSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch(openLoader());
		client
			.post('/kyc/subsidiary/verify', { ...inputs })
			.then((data: any) => {
				dispatch(closeLoader());
				closeModal();
				dispatch(
					openToastAndSetContent({
						toastContent: data?.data?.message,
						toastStyles: ToastSuccessStyles,
					})
				);

				fn();
			})
			.catch((error: any) => {
				dispatch(closeLoader());
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			});
	};

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};

	const disabledFunction = () => {
		if (inputs.reason === '' || inputs.status === '') {
			return true;
		} else {
			return false;
		}
	};

	const status = [{ name: 'Approved' }, { name: 'Declined' }];

	return (
		<div className={styles.modalwrapper}>
			<div className={styles.modalhead}>
				<h3 className={styles.modalheadh3}>Verify Merchant</h3>
				<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
			</div>
			<Divider style={{ margin: 0, padding: 0 }} />
			<div className={styles.modalcontent}>
				<div className={styles.input}>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='status'
						inputLabel='Status'
						inputValue={inputs.status}
						inputSelect
						inputOption={status}
						style={{ width: '100%' }}
					/>
				</div>
				
				{inputs.status === 'Approved' && (
					<div className={styles.input}>
						<Select
							options={
								accountManagers
									? accountManagers.map((manager: any) => ({
											value: manager.adminUserId,
											label: manager.name,
									}))
									: []
							}
							onChange={(option: any) => {
								setInputs((prev) => ({ ...prev, accountManagerId: option.value }));
							}}
							placeholder="Select Account Manager"
							className={styles.modalselect}
							menuPortalTarget={document.body} 
							styles={{
								menu: (provided) => ({
									...provided,
									maxHeight: 150, // limit dropdown height
									overflowY: 'auto', // enable scrolling
									zIndex: '50'
								}),
								menuList: (provided) => ({
									...provided,
									maxHeight: 150, // limit dropdown list height
									overflowY: 'auto', // enable scrolling
								}),
							}}
						/>
					</div>
				)}

				<div className={styles.input}>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='reason'
						inputLabel='Reason'
						inputValue={inputs.reason}
						style={{ width: '100%' }}
					/>
				</div>

				<Divider style={{ margin: 0, padding: 0 }} />

				<div className={styles.modalFooter}>
					<button className={styles.cancel} onClick={closeModal}>
						Cancel
					</button>

					<button
						disabled={disabledFunction()}
						onClick={handleSubmit}
						className={styles.fund}>
						Verify
					</button>
				</div>
			</div>
		</div>
	);
}

export default Verify;
