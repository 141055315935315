import React, { ReactNode } from 'react';

export interface AssessmentData {
	merchantName: string;
	cardScheme: string;
	amount: string;
	document: React.ReactNode;
}

export const ColumnAssessment = [
	{
		Header: 'Merchant name',
		accessor: 'merchantName',
	},
	{
		Header: 'Card scheme',
		accessor: 'cardScheme',
	},
	{
		Header: 'Amount',
		accessor: 'amount',
	},
	{
		Header: 'Document',
		accessor: 'document',
	},
];
