import React from 'react';
import styles from './AddAdminUser.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import OutlineTextInput from '../../input/OutlineTextInput';
import client from '../../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import OutlineTextInputSpecial from '../../input/OutlineTextInputSpecial';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';

const AddAdminUser = ({ closeModal, roles, data }: any) => {
	const dispatch = useDispatch();
	interface initTypes {
		firstName: string;
		email: string;
		lastName: string;
		role: string;
	}

	const initProps: initTypes = {
		firstName: data?.firstName || '',
		email: data?.emailAddress || '',
		lastName: data?.lastName || '',
		role: data?.role ?? '',
	};
	const [inputs, setInputs] = React.useState(initProps);

	const disableFunction = () => {
		if (inputs.firstName && inputs.email && inputs.lastName && inputs.role) {
			return true;
		}
	};

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};

	const handleUserCreateOrUpdate = async () => {
		dispatch(openLoader());
		try {
			var path = data ? "user/profile/update" : "user/create";
			const res: any = await client.post(path, inputs);
			const message = res?.data?.message || `${data ? "Update user" : "Add user"} successfully`;;
			dispatch(closeLoader());
			closeModal();
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			const message = error?.response?.data?.message || `Unable to ${data ? "update user" : "add user"}`;
			dispatch(closeLoader());

			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		handleUserCreateOrUpdate();
	};
	return (
		<div className={styles.modalBody}>
			<div className={styles.modalHeader}>
				<div className={styles.background}>
					<div className={styles.linkDetails}>{data ? "Edit User" : "Add New User"}</div>
					<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
				</div>
			</div>
			<div className={styles.businessForm}>
				<form onSubmit={handleSubmit} className={styles.form}>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='firstName'
						inputLabel='First name'
						inputValue={inputs.firstName}
					/>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='lastName'
						inputLabel='Last name'
						inputValue={inputs.lastName}
					/>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='email'
						inputLabel='Email address'
						inputValue={inputs.email}
					/>

					<OutlineTextInput
						handleChange={updateProps}
						inputName='role'
						inputLabel='Role'
						inputValue={inputs.role}
						inputSelect
						inputOption={roles}
					/>
				</form>
			</div>
			<div className={styles.modalFooter}>
				<div className={styles.cancel} onClick={closeModal}>
					Cancel
				</div>
				{disableFunction() ? (
					<div className={styles.deactivate} onClick={handleSubmit}>
						{data ? "Edit User" : "Add New User"}
					</div>
				) : (
					<div className={styles.notClickable}>{data ? "Edit User" : "Add New User"}</div>
				)}
			</div>
		</div>
	);
};

export default AddAdminUser;
