import React from "react";

export interface CustomersData {
  customerName: string;
  emailAddress: string;
  mobileNumber: string;
  country: string;
  transactionCount: string;
  action: React.ReactNode;
}

export const ColumnCustomers = [
  {
    Header: "Customer Name",
    accessor: "customerName",
  },
  {
    Header: "Email Address",
    accessor: "emailAddress",
  },
  {
    Header: "Mobile Number",
    accessor: "mobileNumber",
  },
  {
    Header: "Country",
    accessor: "country",
  },
  {
    Header: "Transaction Count",
    accessor: "transactionCount",
  },
  {
    Header: "",
    accessor: "action",
  },
];
