import React, { useState, useEffect } from 'react';
import styles from './Customerdetails.module.scss';
import client from '../../../api/client';
import {
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import {
	ColumnTransactionsPerCustomer,
} from '../../../types/TablesTypes/Transactions';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';
import transactionApi from '../../../api/transactions';
import useApi from '../../../hooks/useApi';
import StatusView from '../../../components/StatusView/StatusView';
import TableHeader from '../../../components/TableHeader/TableHeader';
import PaginationTable from '../../../components/table/pagination-table';
import { numberWithCommas } from '../../../utils/formatNumber';
import moment from 'moment';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Dayjs } from 'dayjs';
import FilterModal from '../../../components/filterConfig/FilterModal';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../../utils/datefunction';
import FilterButtonNew from '../../../components/FilterButtonNew/FilterButtonNew';

const tableStatus = [
	{ id: 1, name: 'Initiated' },
	{ id: 2, name: 'Pending-Verification' },
	{ id: 3, name: 'Successful' },
];
interface IDtypes {
	id: number | null;
	setId: React.Dispatch<React.SetStateAction<number | null>>;
}

const CustomerDetails = ({ id, setId }: IDtypes) => {
	const [tableRow, setTableRow] = useState<any[]>();
	const [customerDetails, setCustomerDetails] = useState<any>([]);
	const [transactionDetails, setTransactionDetails] = useState<any>([]);
	const getTransactionApi = useApi(transactionApi.getTransactions);
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [paymentreference, setPaymentReference] = useState('');
	const [orderreference, setOrderReference] = useState('');
	const [status, setStatus] = useState('');
	const [bearer, setBearer] = useState(false);

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setPaymentReference('');
		setOrderReference('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
	};

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const filteredArray: {
		name: string;
		value: string | Dayjs | null;
		setValue: React.Dispatch<React.SetStateAction<any>>;
		selective?: { name: string; value: string }[];
		selectHelper?: boolean;
		type: "select" | "date" | "text";
	  }[]  = [
		{
			name: "Date Range",
			value: fromDate,
			setValue: setFromDate,
			type: "date", // Explicitly set as "date"
		},
		{
			name: 'Payment Reference',
			value: paymentreference,
			setValue: setPaymentReference,
			type:'text'
		},
		{
			name: 'Order Reference',
			value: orderreference,
			setValue: setOrderReference,
			type:'text'
		},
		{
			name: 'Status',
			value: status,
			setValue: setStatus,
			selective: [
				{ name: 'Successful', value:'Successful' },
				{ name: 'Initiated', value:'Initiated' },
				{ name: 'Failed', value:'Failed' },
				{ name: 'Reversed', value:'Reversed' },
			],
			type:'select'
		},
	];

	useEffect(() => {
		dispatch(openLoader());
		setIsLoading(true);
		client
			.get(`/customer/all/paginated/?id=${id}`)
			.then((res: any) => {
				setCustomerDetails(res?.data?.items[0]);
				setIsLoading(false);
				dispatch(closeLoader());
			})
			.catch((error) => {
				dispatch(closeLoader());
				setIsLoading(false);
				if (error.response) {
					const message = error?.response?.data?.message;
					dispatch(
						openToastAndSetContent({
							toastStyles: ToastErrorStyles,
							toastContent: message,
						})
					);
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			});
	}, []);

	const fetchTransactions = async () => {
		status.replace(/-|\s/g, '');
		dispatch(openLoader());
		try {
			const { data }: any = await client.get(
				`order/all/paginated?customerid=${id}&status=${status}&paymentreference=${paymentreference}&orderreference=${orderreference}&fromdate=${fromDate}&todate=${toDate}&limit=${rowsPerPage}&page=${pageNumber}`
			);
			setTransactionDetails(data.data);

			dispatch(closeLoader());
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useEffect(() => {
		fetchTransactions();
	}, [bearer, pageNumber, rowsPerPage]);

	useEffect(() => {
		setPageNumber(transactionDetails?.page?.currentPage || 1);
		setPreviousPage(transactionDetails?.page?.currentPage - 1);
		setNextPage(transactionDetails?.page?.currentPage + 1);
	}, [transactionDetails]);

	const dataTransactionDetails = () => {
		const tempArr: any[] = [];
		transactionDetails?.items
			?.slice(0)
			.reverse()
			.forEach((transactions: any, index: number) => {
				return tempArr.push({
					date: moment(transactions?.dateCreated).format(
						'MMMM Do YYYY, h:mm a'
					),
					transactionAmount: `${transactions?.currency} ${numberWithCommas(
						transactions?.amount
					)}`,
					paymentChannel: transactions?.paymentType || 'NIL',
					reference: transactions.paymentReference,
					merchantName: transactions?.customerName,
					status: (
						<StatusView
							status={transactions?.orderStatus}
							green='Successful'
							orange='Initiated'
							red='Failed'
							blue='Reversed'
						/>
					),
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransactionDetails());
	}, [transactionDetails?.items]);

	return (
		<div>
			{isLoading ? (
				<div />
			) : (
				<>
					<div className={styles.transactionInfo}>
						<div className={styles.transactionsHeader}>
							<div
								style={{
									color: '#616161',
									display: 'inline',
									cursor: 'pointer',
								}}
								onClick={() => setId(null)}>
								Customers
							</div>
							{' >'} Customer Details
						</div>
					</div>

					<div className={styles.detailBox}>
						<h3 className={styles.detailh3}>Customer Details</h3>
						<Divider style={{ color: '#CECECD' }} />
						<div style={{ padding: '0 24px' }}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Customer Name</div>
										<div className={styles.detailsKey}>
											{customerDetails?.customerName}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Customer email</div>
										<div className={styles.detailsKey}>
											{customerDetails?.emailAddress}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Customer phone</div>
										<div className={styles.detailsKey}>
											{customerDetails?.mobileNumber}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Country</div>
										<div className={styles.detailsKey}>
											{customerDetails?.countryName}
										</div>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
				</>
			)}

			<div style={{ minHeight: '100vh' }}>
				<TableHeader
					pageName='Transactions'
					data={transactionDetails.items}
					dataLength={transactionDetails?.page?.total}
					value={paymentreference}
					setValue={setPaymentReference}
					dropdown={dropdown}
					setDropdown={setDropdown}
					searchfn={false}
					FilterComponent={
						<FilterButtonNew
							eventDate={eventDate}
							setEventDate={setEventDate}
							dropdown={dropdown}
							setDropdown={setDropdown}
							setFromDate={setFromDate}
							setToDate={setToDate}
							fromDate={fromDate}
							toDate={toDate}
							setBearer={setBearer}
							clearHandler={clearHandler}
							clearAllHandler={clearHandler}
							filteredArray={filteredArray}
						/>
					}
				/>

				<PaginationTable
					data={tableRow ? tableRow : []}
					columns={
						ColumnTransactionsPerCustomer ? ColumnTransactionsPerCustomer : []
					}
					value={value}
					emptyPlaceHolder={
						transactionDetails?.page?.total == 0
							? 'You currently do not have any transactions'
							: 'Loading...'
					}
					total={transactionDetails?.page?.total}
					totalPage={transactionDetails?.page?.totalPage}
					pageNumber={pageNumber}
					setPageNumber={setPageNumber}
					nextPage={nextPage}
					setNextPage={setNextPage}
					previousPage={previousPage}
					setPreviousPage={setPreviousPage}
					rowsPerPage={rowsPerPage}
					setRowsPerPage={setRowsPerPage}
				/>
			</div>
		</div>
	);
};

export default CustomerDetails;
