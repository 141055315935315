import React, { useEffect } from 'react';
import styles from './BusinessDetails/Business.module.scss';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import client from '../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../constants/toastStyles';

interface AdditionalPreferencesProps {
    groupInformation: any;
    businessDetails: any; 
    display: any;
	subId: string
  }

const SubsidiaryAdditionalPreferenceSection: React.FC<AdditionalPreferencesProps> = ({ groupInformation,display,subId ,businessDetails }) => {
	const dispatch = useDispatch();
	// const getBearsFeeApi = useApi(bearsFeeApi.getBearsFee);
	// const getPaymentOptionsApi = useApi(paymentOptionsApi.getPaymentOptions);
	// const getTwoFaOnLoginApi = useApi(twoFaOnLogin.getTwoFaOnLogin);
	const [selectedValue, setSelectedValue] = React.useState('Customer');

	const fetchBearsFee = async () => {
		if (!subId) {
			console.error("subId is undefined");
			return;
		  }
		const { data }: any = await client.get(
			`/subsidiary/${subId}/payment/fees/view`
		);
		setSelectedValue(!!data?.data && (data?.data?.whoBearsFee ?? "Customer"));
	};

	const fetchPaymentOptions = async () => {
		if (!subId) {
			console.error("subId is undefined");
			return;
		  }
		const { data }: any = await client.get(
			`/subsidiary/${subId}/payment/options/view`
		);
		setPaymentOptions(data?.data ?? [])
		// setCheckedBt(!!data?.data && data?.data?.includes('Bank Transfer'));
		// setCheckedUssd(!!data?.data && data?.data?.includes('USSD'));
		// setCheckedBank(!!data?.data && data?.data?.includes('Bank Account'));
		// setCheckedCard(!!data?.data && data?.data?.includes('Card Payment'));
	};

	const apiCalls = async () => {
		await Promise.all([
			fetchBearsFee(),
			fetchPaymentOptions(),
			// fetchTwoFaOnLogin(),
		]);
	};
	useEffect(() => {
		if (subId) {
			apiCalls();
		  }
	}, [subId]);

	interface initTypes {
		Customer?: string;
		Subsidiary?: string;
		split?: string;
	}

	const initProps: initTypes = {
		Customer: 'Customer',
		Subsidiary: 'Subsidiary',
		split: 'Split',
	};

	const setPaymentOption = async (check: any, option: string) => {
		try {
			var path = !!check ? "set" : "remove"
			const data: any = await client.post(`/subsidiary/${subId}/payment/options/${path}`, { paymentOption: option, });
			const message = data?.data?.message;
			fetchPaymentOptions();
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	const handleChangeCard = (event: React.ChangeEvent<HTMLInputElement>) => {
		// setCheckedCard(event.target.checked);
		setPaymentOption(
			event.target.checked,
			event.target.name
		);
	};


	const [paymentOptions, setPaymentOptions] = React.useState<any[]>([]);

	const [inputs, setInputs] = React.useState(initProps);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const bearer =
			event.target.value === 'Customer'
				? 'CBF'
				: event.target.value === 'Subsidiary'
					? 'SBF'
					: event.target.value === 'Split'
						? 'SPLIT'
						: 'SBF';


		client
			.post(`/subsidiary/${subId}/payment/fees/set`, {
				feeBearer: bearer,
			})
			.then((data: any) => {
				fetchBearsFee();
				dispatch(
					openToastAndSetContent({
						toastContent: data?.data?.message,
						toastStyles: ToastSuccessStyles,
					})
				);
			})
			.catch((error: any) => {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			});
	};

	return (
        <div style={{
            padding: '0 24px 24px 24px',
            display: display ? '' : 'none',
          }}>
            <div className={styles.preferencesBody}>
                <div className={styles.accounts}>
                    <div className={styles.firsthand}>
                        <div className={styles.accountInfo}>Transactions</div>
                        <div className={styles.accountEarnings}>
                            Who should be charged transation fees
                        </div>
                    </div>
                    <div className={styles.secondhand}>
                        <div className={styles.radioSelection}>
                            <Radio
                                checked={selectedValue === inputs.Subsidiary}
                                onChange={handleChange}
                                value={inputs.Subsidiary}
                                name='SBF'
                                inputProps={{ 'aria-label': inputs.Subsidiary }}
                                size='small'
                            />
                            <div className={styles.radioLabel}>
                                Charge me the transaction fees
                            </div>
                        </div>
                        <div className={styles.radioSelection}>
                            <Radio
                                checked={selectedValue === inputs.Customer}
                                onChange={handleChange}
                                value={inputs.Customer}
                                name='CBF'
                                inputProps={{ 'aria-label': inputs.Customer }}
                                size='small'
                            />
                            <div className={styles.radioLabel}>
                                Charge my customers the transaction fees{' '}
                            </div>
                        </div>
                        {/* <div className={styles.radioSelection}>
                            <Radio
                                checked={selectedValue === inputs.split}
                                onChange={handleChange}
                                value={inputs.split}
                                name='SPLIT'
                                inputProps={{ 'aria-label': inputs.split }}
                                size='small'
                            />
                            <div className={styles.radioLabel}>Split the transaction fees</div>
                        </div> */}
                    </div>
                </div>

                <div className={styles.accounts}>
                    <div className={styles.firsthand}>
                        <div className={styles.accountInfo}>Payment methods</div>
                        <div className={styles.accountEarnings}>
                            Payment methods available to customers
                        </div>
                    </div>
                    <div className={styles.secondhand}>
                        <div className={styles.paymentList}>
                            {paymentOptions.map((paymentOption) => <div key={paymentOption.code} className={styles.checkboxContent}>
                                <Checkbox
                                    checked={paymentOption.isEnabled}
                                    onChange={handleChangeCard}
                                    name={paymentOption.name}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <div className={styles.checkboxLabel}>Enable {paymentOption.name}</div>
                            </div>)}
                        </div>
                    </div>
                </div>

            </div>
        </div>          
	);
};

export default SubsidiaryAdditionalPreferenceSection;
