import React, { useEffect } from 'react';
import styles from './Initiatepayout.module.scss';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import OutlineTextInput from '../../input/OutlineTextInput';
import client from '../../../api/client';
import { useDispatch, useSelector } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import Select from 'react-select';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';
import BeneficiaryPayout from './BeneficiaryPayout';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import PayoutVerificationModal from '../PayoutVerificationModal/PayoutVerificationModal';

const MerchantToMerchant = ({ closeModal, fn, beneficiary, fn2 }: any) => {
	const dispatch = useDispatch();
	const [banks, setBanks] = React.useState<any>([]);
	const [selectedBank, setSelectedBank] = React.useState<any>();
	const [selectedType, setSelectedType] = React.useState<any>({
		value: 'Bank',
		label: 'Bank',
	});
	const [currenciesOption, setCurrenciesOption] = React.useState<any>([]);
	const { currencies, currency } = useSelector((state) => state?.appSettingsReducer)
	const [selectedCurrencyInput, setSelectedCurrencyInput] = React.useState<any>({
		label: currency,
		value: currency
	});
	const [resolveData, setResolvedData] = React.useState<any>();
	const [resolveMerchantData, setResolvedMerchantData] = React.useState<any>();
	const [active, setActive] = React.useState('new');

	const [modalIsOpen, setIsModalOpen] = React.useState({
		isOpened: false,
		data: null
	});

	const closePayoutVerificationModal = () => {
		setIsModalOpen({ isOpened: false, data: null })
		// fn();
		// fn2();
		closeModal();
	}

	const fetchBanks = async () => {
		try {
			const data: any = await client.get('/payout/banks');
			setBanks(data?.data?.banks);
		} catch (error: any) {
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	const handlePayoutResponse = async (cbData: any) => {
		try {
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: cbData?.message ?? "",
				})
			);
			if (cbData.statusCode == "00") {
				fn();
				fn2();
				closeModal();
				return;
			}
			else if (cbData.statusCode == "007") {
				setIsModalOpen({ isOpened: true, data: cbData })
			} else {
				const { message } = cbData?.message || { message: "Unable to initiate payout at the moment" };
				dispatch(
					openToastAndSetContent({
						toastStyles: ToastErrorStyles,
						toastContent: message,
					})
				);
			}
		} catch (error: any) {
			const { message } = error?.response?.data || { message: "Unable to get auth code, please try again" };
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
		finally {
			dispatch(closeLoader())
		}
	};

	let selectedBanksOptions = banks?.map(function (bank: any) {
		return {
			value: bank.id,
			label: bank.name,
			code: bank.bankCode,
		};
	});


	interface initTypes {
		SourceAccountNumber: string | undefined;
		DestinationAccountNumber: string | undefined;
		description: string;
		amount: number | undefined;
	}

	const initProps: initTypes = {
		SourceAccountNumber: undefined,
		DestinationAccountNumber: undefined,
		description: '',
		amount: undefined,
	};
	const [inputs, setInputs] = React.useState(initProps);

	const disableFunction = () => {
		if (
			inputs.SourceAccountNumber &&
			inputs.amount &&
			!!resolveData?.data?.accountName &&
			inputs.description
		) {
			return true;
		}
	};


	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};


	useEffect(() => {
		fetchBanks();
		// fetchCurrencies();
	}, []);


	useEffect(() => {
		const bene = currencies?.map((x: any) => ({ value: x.shortName, label: `${x.shortName}` }));
		console.log({ bene })
		if (bene && bene.length > 0) setCurrenciesOption([...bene])
	}, [currencies])


	const payloadBank = {
		bankCode: 'Merchant',
		bankId: selectedBank?.value,
		creditCurrency: selectedCurrencyInput.value,
		transactionType: 'MerchantToMerchant',
		AccountName: resolveData?.data?.accountName,
		amount: inputs.amount,
		description: inputs.description,
		subsidiaryId: inputs.SourceAccountNumber,
		accountNumber: inputs.DestinationAccountNumber,
		// accountNumber: inputs.DestinationAccountNumber,
	};

	const initiatePayout = async () => {
		dispatch(openLoader());
		try {
			const data: any = await client.post('/payout/initiate',payloadBank);
			const message = data?.data?.message;
			handlePayoutResponse(data?.data);
		} catch (error: any) {
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
		finally {
			dispatch(closeLoader());
		}
	};


	const resolveMerchant = async (accountNumber: string, type: string) => {
		if (!accountNumber || accountNumber.length < 1) return;
		dispatch(openLoader());
		try {
			const data: any = await client.post('/merchant/resolve', {
				accountNumber,
				type: 'Merchant',
			});
			dispatch(closeLoader());
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: data?.data?.message ?? 'Merchant resolved successfully',
				})
			);

			if (type === 'source') {
				setResolvedData(data?.data);
			} else if (type === 'destination') {
				setResolvedMerchantData(data?.data);
			}
            
		} catch (error: any) {
			const { message } = error?.response?.data;
			dispatch(closeLoader());
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	useEffect(() => {
		if (inputs?.SourceAccountNumber) resolveMerchant(inputs?.SourceAccountNumber, 'source');
	}, [inputs?.SourceAccountNumber]);

	useEffect(() => {
		if (inputs?.DestinationAccountNumber) resolveMerchant(inputs?.DestinationAccountNumber, 'destination');
	}, [inputs?.DestinationAccountNumber]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		initiatePayout();
	};

	return (
		<div className={styles.modalBody}>
			<div className={styles.modalHeader}>
				<div className={styles.background}>
					<div className={styles.linkDetails}>Merchant To Merchant</div>
					<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
				</div>
			</div>
			{active === 'new' && (
				<>
					<div className={styles.businessForm}>
						<form onSubmit={handleSubmit} className={styles.form}>
                            <OutlineTextInput
                                handleChange={updateProps}
                                inputName='SourceAccountNumber'
                                inputLabel='Source Merchant ID'
                                inputValue={inputs.SourceAccountNumber}
                            />
                            {resolveData && resolveData?.data?.accountName && (
                                <p>
                                    Account Name:{' '}
                                    <span style={{ color: 'green' }}>
                                        {resolveData?.data?.accountName}
                                    </span>
                                </p>
                            )}
                            <OutlineTextInput
                                handleChange={updateProps}
                                inputName='DestinationAccountNumber'
                                inputLabel='Destination Merchant ID'
                                inputValue={inputs.DestinationAccountNumber}
                            />
                            {resolveMerchantData && resolveMerchantData?.data?.accountName && (
                                <p>
                                    Account Name:{' '}
                                    <span style={{ color: 'green' }}>
                                        {resolveMerchantData?.data?.accountName}
                                    </span>
                                </p>
                            )}
							<OutlineTextInput
								handleChange={updateProps}
								inputName='amount'
								inputLabel='Amount'
								inputValue={inputs.amount}
							/>
							<OutlineTextInput
								handleChange={updateProps}
								inputName='description'
								inputLabel='Description'
								inputValue={inputs.description}
								notRequired= {false} 
							/>							
						</form>
					</div>
					<div className={styles.modalFooter}>
						<div className={styles.cancel} onClick={closeModal}>
							Cancel
						</div>
						{disableFunction() ? (
							<div className={styles.deactivate} onClick={handleSubmit}>
								Initiate payout
							</div>
						) : (
							<div className={styles.notClickable}> Initiate payout</div>
						)}
					</div>
				</>
			)}
			{active === 'ben' && <BeneficiaryPayout payoutCb={handlePayoutResponse} fn={fn} closeModal={closeModal} beneficiary={beneficiary} />}
			<Modal
				isOpen={modalIsOpen.isOpened}
				onRequestClose={closeModal}
				contentLabel='Verify Payout'
				style={customStyles}>
				<PayoutVerificationModal data={modalIsOpen.data} closeModal={closePayoutVerificationModal} />
			</Modal>
		</div>
	);
};

export default MerchantToMerchant;
function setResolvedDestinationData(data: any) {
    throw new Error('Function not implemented.');
}

