import React, { useEffect } from 'react';
import styles from './Initiatepayout.module.scss';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import OutlineTextInput from '../../input/OutlineTextInput';
import client from '../../../api/client';
import { useDispatch, useSelector } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import Select from 'react-select';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';
import BeneficiaryPayout from './BeneficiaryPayout';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import PayoutVerificationModal from '../PayoutVerificationModal/PayoutVerificationModal';

const MerchantToBankPayout = ({ closeModal, fn, beneficiary, fn2 }: any) => {
	const dispatch = useDispatch();
	const [banks, setBanks] = React.useState<any>([]);
	const [selectedBank, setSelectedBank] = React.useState<any>();
	const [resolveMerchantData, setResolvedMerchantData] = React.useState<any>();
	const [currenciesOption, setCurrenciesOption] = React.useState<any>([]);
	const { currencies, currency } = useSelector((state) => state?.appSettingsReducer)
	const [selectedCurrencyInput, setSelectedCurrencyInput] = React.useState<any>({
		label: currency,
		value: currency
	});
	const [resolveData, setResolvedData] = React.useState<any>();
	const [active, setActive] = React.useState('new');

	const [modalIsOpen, setIsModalOpen] = React.useState({
		isOpened: false,
		data: null
	});

	const closePayoutVerificationModal = () => {
		setIsModalOpen({ isOpened: false, data: null })
		// fn();
		// fn2();
		closeModal();
	}

	const fetchBanks = async () => {
		try {
			const data: any = await client.get('/payout/banks');
			setBanks(data?.data?.banks);
		} catch (error: any) {
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	const handlePayoutResponse = async (cbData: any) => {
		try {
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: cbData?.message ?? "",
				})
			);
			if (cbData.statusCode == "00") {
				fn();
				fn2();
				closeModal();
				return;
			}
			else if (cbData.statusCode == "007") {
				setIsModalOpen({ isOpened: true, data: cbData })
			} else {
				const { message } = cbData?.message || { message: "Unable to initiate payout at the moment" };
				dispatch(
					openToastAndSetContent({
						toastStyles: ToastErrorStyles,
						toastContent: message,
					})
				);
			}
		} catch (error: any) {
			const { message } = error?.response?.data || { message: "Unable to get auth code, please try again" };
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
		finally {
			dispatch(closeLoader())
		}
	};

	let selectedBanksOptions = banks?.map(function (bank: any) {
		return {
			value: bank.id,
			label: bank.name,
			code: bank.bankCode,
		};
	});


	interface initTypes {
		merchantID: string | undefined;
		accountNumber: string | undefined;
		DestinationAccountNumber: string | undefined;
		description: string;
		amount: number | undefined;
	}

	const initProps: initTypes = {
		accountNumber: undefined,
		DestinationAccountNumber: undefined,
		description: '',
		amount: undefined,
		merchantID: undefined
	};
	const [inputs, setInputs] = React.useState(initProps);

	const disableFunction = () => {
		if (
			inputs.DestinationAccountNumber &&
			inputs.merchantID &&
			inputs.amount &&
			!!resolveData?.data?.accountname &&
			inputs.description
		) {
			return true;
		}
	};


	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};


	useEffect(() => {
		fetchBanks();
		// fetchCurrencies();
	}, []);


	useEffect(() => {
		const bene = currencies?.map((x: any) => ({ value: x.shortName, label: `${x.shortName}` }));
		// console.log({ bene })
		if (bene && bene.length > 0) setCurrenciesOption([...bene])
	}, [currencies])


	const payloadBank = {
		bankCode: selectedBank?.code ,
		bankId: selectedBank?.value,
		creditCurrency: selectedCurrencyInput.value,
		AccountName: resolveData?.data?.accountName,
		amount: inputs.amount,
		description: inputs.description,
		subsidiaryId: inputs.merchantID,
		accountNumber: inputs.DestinationAccountNumber,
		transactionType: 'MerchantToBank',
	};

	const initiatePayout = async () => {
		dispatch(openLoader());
		try {
			const data: any = await client.post('/payout/initiate',payloadBank);
			const message = data?.data?.message;
			handlePayoutResponse(data?.data);
		} catch (error: any) {
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
		finally {
			dispatch(closeLoader());
		}
	};

	const resolveMerchantIdAccount = async () => {
		if (!!inputs?.merchantID && (inputs?.merchantID?.length < 0)) return;
		dispatch(openLoader());
		try {
			const data: any = await client.post('/merchant/resolve', {
				accountNumber: inputs?.merchantID,
				type: 'Merchant',
			});
			setResolvedMerchantData(data?.data);
			dispatch(closeLoader());
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: data?.data?.message ?? 'Merchant resolved successfully',
				})
			);
		} catch (error: any) {
			const { message } = error?.response?.data;
			dispatch(closeLoader());
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};
	useEffect(() => {
		if (inputs?.merchantID) resolveMerchantIdAccount();
	}, [inputs?.merchantID]);

	const resolveAccount = async () => {
		if (!!inputs?.DestinationAccountNumber && (inputs?.DestinationAccountNumber?.length < 10)) return;
		dispatch(openLoader());
		try {

			const payload: any = {
				type: 'Bank',
				accountNumber: inputs?.DestinationAccountNumber,
			};
	
			// Only add bankCode if it exists
			if (selectedBank?.code) {
				payload.bankCode = selectedBank.code;
			}

			const data: any = await client.post('/resolve/account',payload)
			setResolvedData(data?.data);
			const message = data?.data?.message;
			dispatch(closeLoader());
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			const { message } = error?.response?.data;
			dispatch(closeLoader());
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};
	useEffect(() => {
		if (inputs?.DestinationAccountNumber && selectedBank?.code !== '') resolveAccount();
	}, [inputs?.DestinationAccountNumber, selectedBank?.code]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		initiatePayout();
	};

	return (
		<div className={styles.modalBody}>
			<div className={styles.modalHeader}>
				<div className={styles.background}>
					<div className={styles.linkDetails}>Merchant To Bank</div>
					<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
				</div>
			</div>
			{active === 'new' && (
				<>
					<div className={styles.businessForm}>
						<form onSubmit={handleSubmit} className={styles.form}>
							<div className={styles.modalselect}>
								<label className={styles.inputLabel}>Bank</label>
									<Select
										defaultValue={selectedBank}
										onChange={setSelectedBank}
										options={selectedBanksOptions}
										className={styles.select}
										styles={{
											// Fixes the overlapping problem of the component
											menu: (provided) => ({
												...provided,
												zIndex: 9999,
												width: '100%',
											}),
										}}
									/>
							</div>
							<OutlineTextInput
                                handleChange={updateProps}
                                inputName='merchantID'
                                inputLabel='Source Merchant ID'
                                inputValue={inputs.merchantID}
                            />
							{resolveMerchantData && (
								<p>
									Account Name:{' '}
									<span style={{ color: 'green' }}>
										{resolveMerchantData?.data?.accountName}
									</span>{' '}
								</p>
							)}							
                            <OutlineTextInput
                                handleChange={updateProps}
                                inputName='DestinationAccountNumber'
                                inputLabel='Destination Account Number'
                                inputValue={inputs.DestinationAccountNumber}
                            />
							{resolveData && (
								<p>
									Account Name:{' '}
									<span style={{ color: 'green' }}>
										{resolveData?.data?.accountname}
									</span>{' '}
								</p>
							)}							
							<OutlineTextInput
								handleChange={updateProps}
								inputName='amount'
								inputLabel='Amount'
								inputValue={inputs.amount}
							/>
							<OutlineTextInput
								handleChange={updateProps}
								inputName='description'
								inputLabel='Description'
								inputValue={inputs.description}
								notRequired= {false}
							/>
							{/* <div className={styles.modalselect}>
								<label className={styles.inputLabel}>Select Currency</label>
								<Select
									defaultValue={selectedCurrencyInput}
									onChange={setSelectedCurrencyInput}
									options={currenciesOption}
									className={styles.select}
									styles={{
										// Fixes the overlapping problem of the component
										menu: (provided) => ({
											...provided,
											zIndex: 9999,
											width: '100%',
										}),
									}}
								/>
							</div> */}

						</form>
					</div>
					<div className={styles.modalFooter}>
						<div className={styles.cancel} onClick={closeModal}>
							Cancel
						</div>
						{disableFunction() ? (
							<div className={styles.deactivate} onClick={handleSubmit}>
								Initiate payout
							</div>
						) : (
							<div className={styles.notClickable}> Initiate payout</div>
						)}
					</div>
				</>
			)}
			{active === 'ben' && <BeneficiaryPayout payoutCb={handlePayoutResponse} fn={fn} closeModal={closeModal} beneficiary={beneficiary} />}
			<Modal
				isOpen={modalIsOpen.isOpened}
				onRequestClose={closeModal}
				contentLabel='Verify Payout'
				style={customStyles}>
				<PayoutVerificationModal data={modalIsOpen.data} closeModal={closePayoutVerificationModal} />
			</Modal>
		</div>
	);
};

export default MerchantToBankPayout;
